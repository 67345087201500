import Joi from 'joi';
import { validacaoClassificacao } from '../ClassificacaoDadosLancamentoForm/JoiValidators';

export const validacao = {
    id: Joi.number().allow('', null).optional(),
    ordemColetaId: Joi.number().allow('', null).optional(),
    pesoBruto: Joi.number().integer().positive().required().messages({
        'string.base': 'O campo não pode ficar em branco',
        'number.base': 'O campo deve ser um inteiro positivo',
        'number.integer': 'O campo deve ser um inteiro',
        'number.positive': 'O campo deve ser um inteiro positivo',
        'any.empty': 'O campo não pode ficar em branco',
        'any.required': 'O campo não pode ficar em branco',
    }),
    tara: Joi.number()
        .integer()
        .positive()
        .required()
        .custom((value, helpers) => {
            const { pesoBruto } = helpers.state.ancestors[0];

            if (!pesoBruto) {
                return helpers.error('any.weightIsRequired');
            }

            const pesoBrutoNumber = parseFloat(pesoBruto);
            const taraNumber = parseFloat(value);

            if (taraNumber >= pesoBrutoNumber) {
                return helpers.error('any.taraGreaterThanWeight');
            }

            return value;
        })
        .messages({
            'string.base': 'O campo não pode ficar em branco',
            'number.base': 'O campo deve ser um inteiro positivo',
            'number.integer': 'O campo deve ser um inteiro',
            'number.positive': 'O campo deve ser um inteiro positivo',
            'string.empty': 'O campo não pode ficar em branco',
            'any.required': 'O campo não pode ficar em branco',
            'any.weightIsRequired':
                'Peso Bruto é necessário para validar a Tara',
            'any.taraGreaterThanWeight': 'Tara deve ser menor que o Peso Bruto',
            'any.invalid': '{{#message}}',
        }),
    pesoLiquido: Joi.number()
        .integer()
        .positive()
        .allow('', null)
        .optional()
        .messages({
            'number.base': 'O campo deve ser um inteiro positivo',
            'number.integer': 'O campo deve ser um inteiro',
            'number.positive': 'O campo deve ser um inteiro positivo',
        }),
    classificacao: Joi.object({
        id: Joi.number().allow('', null).optional(),
        ordemColetaId: Joi.number().allow('', null).optional(),
        transgenia: Joi.string().optional().allow('', null),
        classificacaoAmostras: Joi.array()
            .items(validacaoClassificacao)
            .required()
            .min(1)
            .messages({
                'array.base': 'Adicione ao menos uma Classificação',
                'array.empty': 'Adicione ao menos uma Classificação',
                'any.required': 'Adicione ao menos uma Classificação',
                'array.min': 'Adicione ao menos uma Classificação',
            }),
    }),
    ticketCaminhaoCarregado: Joi.object({
        nome: Joi.string().required().messages({
            'string.base': 'Anexe um arquivo',
            'string.empty': 'Anexe um arquivo',
            'any.required': 'Anexe um arquivo',
        }),
        stringBase64: Joi.string().allow('', null).optional(),
        tipo: Joi.string().allow('', null).optional(),
        id: Joi.number().allow('', null).optional(),
    })
        .required()
        .messages({
            'any.required': 'Anexo Obrigatório',
        }),
    laudo: Joi.object({
        nome: Joi.string().required().messages({
            'string.base': 'Anexe um arquivo',
            'string.empty': 'Anexe um arquivo',
            'any.required': 'Anexe um arquivo',
        }),
        stringBase64: Joi.string().allow('', null).optional(),
        tipo: Joi.string().allow('', null).optional(),
        id: Joi.number().allow('', null).optional(),
    })
        .required()
        .messages({
            'any.required': 'Anexo Obrigatório',
        }),
    ordemColeta: Joi.object().optional().allow(null),
    notaRetornoMaxysErp: Joi.object().optional().allow(null),
    tipoLoteEmbarque: Joi.string().allow('', null).optional(),
    cte: Joi.object({
        nome: Joi.string().required().messages({
            'string.base': 'Anexe um arquivo',
            'string.empty': 'Anexe um arquivo',
            'any.required': 'Anexe um arquivo',
        }),
        stringBase64: Joi.string().allow('', null).optional(),
        tipo: Joi.string().allow('', null).optional(),
        id: Joi.number().allow('', null).optional(),
    })
        .allow(null)
        .optional(),
    mdfe: Joi.object({
        nome: Joi.string().required().messages({
            'string.base': 'Anexe um arquivo',
            'string.empty': 'Anexe um arquivo',
            'any.required': 'Anexe um arquivo',
        }),
        stringBase64: Joi.string().allow('', null).optional(),
        tipo: Joi.string().allow('', null).optional(),
        id: Joi.number().allow('', null).optional(),
    })
        .allow(null)
        .optional(),
    observacoes: Joi.array().optional().allow(null),
    responsavelDoubleCheck: Joi.string().optional().allow(null, ''),
    statusDoubleCheck: Joi.string().optional().allow(null, ''),
};

export const validacaoSemNota = Joi.object({
    ...validacao,
    notasAnteriores: Joi.object().optional().allow(null),
});

export const validacaoComNota = Joi.object({
    ...validacao,
    notasAnteriores: Joi.array().required().min(1),
});
