import { Container, Divider } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageTitle from '../../Components/PageTitle/PageTitle';
import SpinnerLoading from '../../Components/SpinnerLoading/SpinnerLoading';
import useGenericService from '../../hooks/useGenericService';
import { ApiResources, FrontEndPathResources } from '../../Models/Api';
import Contratante from '../../Models/Contratante';
import LoginResponse from '../../Models/responses/LoginResponse';
import Usuario, {
    ChavesArmazenamentoStorage,
    StatusRequisicao,
} from '../../Models/Usuario';
import Formulario from './Formulario';

type Params = {
    id: string;
};

export default function Ver() {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { id } = useParams<Params>();
    const [contratante, setContratante] = useState<Contratante>();

    const { getService } = useGenericService();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        let loginResponse = localStorage.getItem(
            ChavesArmazenamentoStorage.LOGIN_RESPONSE
        );

        if (loginResponse) {
            const usuario: Usuario = (
                JSON.parse(loginResponse) as LoginResponse
            ).usuario;

            if (usuario.trocarSenha) {
                window.location.pathname = FrontEndPathResources.AlterarSenha;
            }
        }
    }, []);

    useEffect(() => {
        setIsLoading(true);
        const carregarContratante = async () => {
            const servico = getService(ApiResources.Contratante);

            try {
                const resposta = await servico.api.get('/' + id);

                if (resposta.status === StatusRequisicao.OK) {
                    setContratante(resposta.data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    enqueueSnackbar('Não foi possível encontrar o registro', {
                        variant: 'error',
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
                }
            } catch (error) {
                setIsLoading(false);
            }
        };
        carregarContratante();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, enqueueSnackbar]);

    return (
        <SpinnerLoading isLoading={isLoading}>
            <Container>
                <PageTitle title={'Visualizando Contratante ' + id} />
                <Divider />
                <Formulario disabled contratante={contratante} />
            </Container>
        </SpinnerLoading>
    );
}
