import { Divider, List, ListItem, ListItemText } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Produto from '../../Models/Produto';
import Amostra from '../../Models/Amostra';
import { styles } from './helpers/Styles';

export default function useAuditoriaCamposProduto() {

    const getAmostrasRemovidas = (amostrasAnteriores: Amostra[], amostrasAtuais: Amostra[]) => {
        var amostrasR: Amostra[] = [];

        amostrasAnteriores.forEach((amostraAnterior) => {
            if (amostrasAtuais.filter((amostraAtual) =>
                amostraAtual.nome === amostraAnterior.nome).length === 0) {

                amostrasR.push(amostraAnterior)
            }
        })

        return amostrasR;
    }

    const getAmostrasInseridas = (amostrasAnteriores: Amostra[], amostrasAtuais: Amostra[]) => {

        var amostrasInseridas: Amostra[] = [];

        amostrasAtuais.forEach((amostraAtual) => {

            if (amostrasAnteriores.filter((amostraAnterior) =>
                amostraAtual.nome === amostraAnterior.nome).length === 0) {
                amostrasInseridas.push(amostraAtual)
            }

        })

        return amostrasInseridas
    }

    const getItensAlterados = (
        produtoAtual: Produto,
        produtoAnterior: Produto
    ) => {
        return (
            <List sx={styles().listAuditoria}>
                {produtoAtual.nome !==
                    produtoAnterior.nome && (
                        <ListItem>
                            <ListItemText
                                primary="Nome"
                                secondary={
                                    <div style={styles().listItemText}>
                                        {produtoAnterior.nome}
                                        <ArrowForwardIcon
                                            fontSize="small"
                                            style={styles().arrowForwardIcon}
                                        />{' '}
                                        {produtoAtual.nome}
                                    </div>
                                }
                            />
                        </ListItem>
                    )}

                {getAmostrasRemovidas(produtoAnterior.amostras, produtoAtual.amostras).map(amostra => {
                    return (<>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                            <ListItemText
                                primary="Amostras Removidas"
                                secondary={
                                    <div style={styles().listItemText}>
                                        {amostra.nome}
                                    </div>
                                }
                            />
                        </ListItem>
                    </>)
                }
                )}

                {getAmostrasInseridas(produtoAnterior.amostras, produtoAtual.amostras).map(amostra => {
                    return (<>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                            <ListItemText
                                primary="Amostras Inseridas"
                                secondary={
                                    <div style={styles().listItemText}>
                                        {amostra.nome}
                                    </div>
                                }
                            />
                        </ListItem>
                    </>)
                })}

            </List >
        );
    };

    return {
        getItensAlterados,
    };
}
