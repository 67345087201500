import { StyleSheet } from '../../../StyleSheet';

export const styles = StyleSheet.create({
    buttonLogin: {
        backgroundColor: '#0E366B',
        marginTop: '1.5rem',
        marginBottom: '1px',
        padding: '1rem 2rem',
    },
    esqueciSenha: {
        color: '#0E366B',
        cursor: 'pointer',
        fontSize: '13px',
        marginBottom: '5px',
    },
    formControl: {
        marginTop: 25,
        flexDirection: 'row',
    },
    circularProgress: { color: '#fff' },
    buttonSearch: {
        display: 'flex',
        marginTop: 3,
        padding: "1rem 2rem",
        margin: "0.875rem 0.125rem",
        backgroundColor: '#0E366B',
        '&:hover': {
            backgroundColor: '#154F9D',
        },
    },
    boxForm: {
        maxWidth: '350px',
        width: '60%',
        marginX: 'auto',
        marginY: 'auto',
        borderRadius: '0.5rem',
        backgroundColor: 'white',
        padding: '2rem',
        paddingX: '2rem',
        justifyContent: 'center',
    },
    img: { objectFit: 'cover', width: '100%', height: '100%' },
});
