import JoiCpfCnpj from 'cpf-cnpj-validator';
import { default as JoiOriginal } from 'joi';

export default function useMotoristaValidation() {
    const Joi = JoiOriginal.extend(JoiCpfCnpj);

    const validacaoMotorista = Joi.object({
        Name: Joi.string().trim().required().min(3).max(60).messages({
            'string.base': 'O nome deve ser uma string',
            'string.empty': 'O nome não pode ficar em branco',
            'any.required': 'O nome não pode ficar em branco',
            'string.min': 'Deve ter no mínimo 3 caracteres',
            'string.max': 'Permitido até 60 caracteres',
        }),
        FatherName: Joi.string().trim().required().min(3).max(60).messages({
            'string.base': 'O nome do pai deve ser uma string',
            'string.empty': 'O nome do pai não pode ficar em branco',
            'any.required': 'O nome do pai não pode ficar em branco',
            'string.min': 'Deve ter no mínimo 3 caracteres',
            'string.max': 'Permitido até 60 caracteres',
        }),
        MotherName: Joi.string().trim().required().min(3).max(60).messages({
            'string.base': 'O nome da mãe deve ser uma string',
            'string.empty': 'O nome da mãe não pode ficar em branco',
            'any.required': 'O nome da mãe não pode ficar em branco',
            'string.min': 'Deve ter no mínimo 3 caracteres',
            'string.max': 'Permitido até 60 caracteres',
        }),
        Document: Joi.document().cpf().messages({
            'string.base': 'O CPF não pode ficar em branco',
            'string.empty': 'O CPF não pode ficar em branco',
            'any.required': 'O CPF não pode ficar em branco',
        }),

        DateOfBirthday: Joi.date().max('now').required().messages({
            'date.base': 'Data inválida',
            'date.required': 'Não pode ficar em branco',
            'date.max': 'Não deve ser maior que a data atual',
        }),
        IssueDateDocument2: Joi.date().max('now').required().messages({
            'date.base': 'Data inválida',
            'date.required': 'Não pode ficar em branco',
            'date.max': 'Não deve ser maior que a data atual',
        }),
        DueDateDocument3: Joi.date().required().messages({
            'date.base': 'Data inválida',
            'date.required': 'Não pode ficar em branco',
        }),
        FirstDateDocument3: Joi.date().max('now').required().messages({
            'date.base': 'Data inválida',
            'date.required': 'Não pode ficar em branco',
            'date.max': 'Não deve ser maior que a data atual',
        }),
        Document2: Joi.string().max(20).required().messages({
            'string.base': 'O RG não pode ficar em branco',
            'string.empty': 'O RG não pode ficar em branco',
            'any.required': 'O RG não pode ficar em branco',
            'string.max': 'RG deve ter no máximo 20 caracteres',
        }),
        Document3: Joi.string().max(15).required().messages({
            'string.base': 'CNH não pode ficar em branco',
            'string.empty': 'CNH não pode ficar em branco',
            'string.max': 'CNH deve ter no máximo 15 caracteres',
            'any.required': 'CNH não pode ficar em branco',
        }),
        IssueStateDocument2: Joi.string().required().max(2).messages({
            'string.base': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
            'any.required': 'Não pode ficar em branco',
            'string.max': 'Campo inválido',
        }),
        EmitterDocument3: Joi.string().required().max(2).messages({
            'string.base': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
            'any.required': 'Não pode ficar em branco',
            'string.max': 'Campo inválido',
        }),
        CategoryDocument3: Joi.string().required().max(2).messages({
            'string.base': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
            'any.required': 'Não pode ficar em branco',
            'string.max': 'Categoria inválida',
        }),
        cidadeIbge: Joi.object({
            id: Joi.number().required().messages({
                'number.base': 'Não pode ficar em branco',
                'number.required': 'Não pode ficar em branco',
            }),
        })
            .unknown()
            .messages({
                'object.base': 'Não pode ficar em branco',
            }),
        cidadeIbgeMotorista: Joi.object({
            id: Joi.number().required().messages({
                'number.base': 'Não pode ficar em branco',
                'number.required': 'Não pode ficar em branco',
            }),
        })
            .unknown()
            .messages({
                'object.base': 'Não pode ficar em branco',
            }),
        Postcode: Joi.string().min(8).max(8).required().messages({
            'string.max': 'Deve ter 8 caracteres',
            'string.min': 'Deve ter 8 caracteres',
            'any.required': 'Não pode ficar em branco',
            'string.required': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
        }),
        Address: Joi.string().max(60).required().messages({
            'string.max': 'Deve ter no máximo 60 caracteres',
            'any.required': 'Não pode ficar em branco',
            'string.required': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
        }),
        Number: Joi.string().max(7).required().messages({
            'string.max': 'Deve ter no máximo 7 caracteres',
            'any.required': 'Não pode ficar em branco',
            'string.required': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
        }),
        County: Joi.string().max(100).required().messages({
            'string.max': 'Deve ter no máximo 100 caracteres',
            'any.required': 'Não pode ficar em branco',
            'string.required': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
        }),
    });

    return { validacaoMotorista };
}
