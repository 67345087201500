import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Grid, TextField } from '@mui/material';
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from 'chart.js';
import { format, isDate } from 'date-fns';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import SpinnerLoading from '../../../Components/SpinnerLoading/SpinnerLoading';
import { ApiResources } from '../../../Models/Api';
import EmbarquesPorMes from '../../../Models/relatorios/EmbarquesPorMes';
import useGenericService from '../../../hooks/useGenericService';
import { styles } from '../helpers/Styles';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

interface Props {
    fazerRequisicao?: boolean;
}

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: true,
            text: 'Gráfico de barras do total de embarques em KG por mês',
        },
    },
};

const labels = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
];

export type DataGraficoRelatorioPesoPorMes = {
    labels: string[];
    datasets: {
        label: string;
        data: number[];
        backgroundColor: string[];
    }[];
};

export default function RelatorioPesoPorMes({ fazerRequisicao = true }: Props) {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [anoReferencia, setAnoReferencia] = useState<Date>(new Date());
    const [embarquesPorMes, setEmbarquesPorMes] = useState<EmbarquesPorMes>();

    // const [dadosGrafico, setDadosGrafico] =
    //     useState<DataGraficoRelatorioPesoPorMes>();

    const { getService } = useGenericService();

    useEffect(() => {
        setIsLoading(true);
        const buscarEmbarquesPorMes = async () => {
            const servico = getService(ApiResources.Relatorio);

            const resposta = await servico.api.get(
                '/quantidade-embarques-mes',
                {
                    params: {
                        anoReferencia: format(anoReferencia, 'Y').toString(),
                    },
                }
            );

            setEmbarquesPorMes(resposta.data);
            setIsLoading(false);
        };

        if (isDate(anoReferencia) && fazerRequisicao) {
            buscarEmbarquesPorMes();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [anoReferencia, fazerRequisicao]);

    // useEffect(() => {
    //     setIsLoading(true);
    //     if (embarquesPorMes) {
    //         setDadosGrafico({
    //             labels,
    //             datasets: [
    //                 {
    //                     label: 'Venda',
    //                     data: embarquesPorMes.venda.map(
    //                         (em) => em.pesoTotalEmbarcado
    //                     ),
    //                     backgroundColor: ['rgba(255, 99, 132, 0.5)'],
    //                 },
    //                 {
    //                     label: 'Compra',
    //                     data: embarquesPorMes.compra.map(
    //                         (em) => em.pesoTotalEmbarcado
    //                     ),
    //                     backgroundColor: ['rgba(53, 162, 235, 0.5)'],
    //                 },
    //             ],
    //         });
    //     }
    //     setIsLoading(false);
    // }, [embarquesPorMes]);

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Venda',
                data: embarquesPorMes?.venda?.map((e) => e.pesoTotalEmbarcado),
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Compra',
                data: embarquesPorMes?.compra?.map((e) => e.pesoTotalEmbarcado),
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };

    return (
        <SpinnerLoading isLoading={isLoading}>
            <Grid container spacing={2} style={styles.grid}>
                <Grid item xs={12} lg={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Ano referência"
                            views={['year']}
                            inputFormat="yyyy"
                            value={anoReferencia}
                            onChange={(newValue: any) => {
                                if (isDate(newValue)) {
                                    setAnoReferencia(newValue);
                                }
                            }}
                            renderInput={(params: any) => (
                                <TextField {...params} />
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
                <Bar options={options} data={data} />
            </Grid>
        </SpinnerLoading>
    );
}
