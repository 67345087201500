import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Divider, List, ListItem, ListItemText } from '@mui/material';
import Usuario from '../../Models/Usuario';
import { styles } from './helpers/Styles';

export default function useAuditoriaCamposUsuario() {
    const getItensAlterados = (
        usuarioAtual: Usuario,
        usuarioAnterior: Usuario
    ) => {
        return (
            <List sx={styles().listStyle}>
                {usuarioAtual?.pessoa?.nome !==
                    usuarioAnterior?.pessoa?.nome && (
                    <ListItem>
                        <ListItemText
                            primary="Nome"
                            secondary={
                                <div style={styles().listItemText}>
                                    {usuarioAnterior?.pessoa?.nome}
                                    <ArrowForwardIcon
                                        fontSize="small"
                                        style={styles().arrowForwardIcon}
                                    />{' '}
                                    {usuarioAtual?.pessoa?.nome}
                                </div>
                            }
                        />
                    </ListItem>
                )}

                {usuarioAtual?.cpf !== usuarioAnterior?.cpf && (
                    <>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                            <ListItemText
                                primary="CPF"
                                secondary={
                                    <div style={styles().listItemText}>
                                        {usuarioAnterior?.cpf}{' '}
                                        <ArrowForwardIcon
                                            fontSize="small"
                                            style={styles().arrowForwardIcon}
                                        />{' '}
                                        {usuarioAtual?.cpf}
                                    </div>
                                }
                            />
                        </ListItem>
                    </>
                )}

                {usuarioAtual?.cnpj !== usuarioAnterior?.cnpj && (
                    <>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                            <ListItemText
                                primary="CNPJ"
                                secondary={
                                    <div style={styles().listItemText}>
                                        {usuarioAnterior?.cnpj}{' '}
                                        <ArrowForwardIcon
                                            fontSize="small"
                                            style={styles().arrowForwardIcon}
                                        />{' '}
                                        {usuarioAtual?.cnpj}
                                    </div>
                                }
                            />
                        </ListItem>
                    </>
                )}

                {usuarioAnterior?.id !== usuarioAtual?.id && (
                    <>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                            <ListItemText
                                primary="Usuário - ID"
                                secondary={
                                    <div style={styles().listItemText}>
                                        {usuarioAnterior?.id}
                                        <ArrowForwardIcon
                                            fontSize="small"
                                            style={styles().arrowForwardIcon}
                                        />{' '}
                                        {usuarioAtual?.id}
                                    </div>
                                }
                            />
                        </ListItem>
                    </>
                )}

                {usuarioAnterior?.login !== usuarioAtual?.login && (
                    <>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                            <ListItemText
                                primary="Usuário - Login"
                                secondary={
                                    <div style={styles().listItemText}>
                                        {usuarioAnterior?.login}
                                        <ArrowForwardIcon
                                            fontSize="small"
                                            style={styles().arrowForwardIcon}
                                        />{' '}
                                        {usuarioAtual?.login}
                                    </div>
                                }
                            />
                        </ListItem>
                    </>
                )}

                {usuarioAnterior?.senha !== usuarioAtual?.senha && (
                    <>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                            <ListItemText
                                primary="Usuário - Senha"
                                secondary={
                                    <div style={styles().listItemText}>
                                        {usuarioAnterior?.senha}
                                        <ArrowForwardIcon
                                            fontSize="small"
                                            style={styles().arrowForwardIcon}
                                        />{' '}
                                        {usuarioAtual?.senha}
                                    </div>
                                }
                            />
                        </ListItem>
                    </>
                )}

                {usuarioAnterior?.trocarSenha !== usuarioAtual?.trocarSenha && (
                    <>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                            <ListItemText
                                primary="Usuário - Trocar Senha"
                                secondary={
                                    <div style={styles().listItemText}>
                                        {String(usuarioAnterior?.trocarSenha)}
                                        <ArrowForwardIcon
                                            fontSize="small"
                                            style={styles().arrowForwardIcon}
                                        />{' '}
                                        {String(usuarioAtual?.trocarSenha)}
                                    </div>
                                }
                            />
                        </ListItem>
                    </>
                )}

                {usuarioAnterior?.pessoa?.contato?.email !==
                    usuarioAtual?.pessoa?.contato?.email && (
                    <>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                            <ListItemText
                                primary="Contato - Email"
                                secondary={
                                    <div style={styles().listItemText}>
                                        {
                                            usuarioAnterior?.pessoa?.contato
                                                ?.email
                                        }
                                        <ArrowForwardIcon
                                            fontSize="small"
                                            style={styles().arrowForwardIcon}
                                        />{' '}
                                        {usuarioAtual?.pessoa?.contato?.email}
                                    </div>
                                }
                            />
                        </ListItem>
                    </>
                )}

                {usuarioAnterior?.pessoa?.contato?.telefone !==
                    usuarioAtual?.pessoa?.contato?.telefone && (
                    <>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                            <ListItemText
                                primary="Contato - Telefone"
                                secondary={
                                    <div style={styles().listItemText}>
                                        {
                                            usuarioAnterior?.pessoa?.contato
                                                ?.telefone
                                        }
                                        <ArrowForwardIcon
                                            fontSize="small"
                                            style={styles().arrowForwardIcon}
                                        />{' '}
                                        {
                                            usuarioAtual?.pessoa?.contato
                                                ?.telefone
                                        }
                                    </div>
                                }
                            />
                        </ListItem>
                    </>
                )}

                {usuarioAnterior?.pessoa?.contato?.celular !==
                    usuarioAtual?.pessoa?.contato?.celular && (
                    <>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                            <ListItemText
                                primary="Contato - Celular"
                                secondary={
                                    <div style={styles().listItemText}>
                                        {
                                            usuarioAnterior?.pessoa?.contato
                                                ?.celular
                                        }
                                        <ArrowForwardIcon
                                            fontSize="small"
                                            style={styles().arrowForwardIcon}
                                        />{' '}
                                        {usuarioAtual?.pessoa?.contato?.celular}
                                    </div>
                                }
                            />
                        </ListItem>
                    </>
                )}
            </List>
        );
    };

    return {
        getItensAlterados,
    };
}
