import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { useMemo } from 'react';
import { useAuth } from '../../AuthProvider';
import { FrontEndPathResources } from '../../Models/Api';
import { EnumAutorizacao, RecursosPerfisEnum } from '../../Models/Usuario';
import { IRecursos } from '../../Screens/PerfilUsuario/Formulario';
import MenuItem from './helpers/MenuItem';

export interface IMenuItemsProps {
    title: string;
    icon: any;
    path: string;
    requirePermission?: string;
}

const menuItems: IMenuItemsProps[] = [
    {
        title: 'Dashboard',
        icon: <DashboardIcon />,
        path: FrontEndPathResources.Dashboard,
        requirePermission: RecursosPerfisEnum.DASHBOARD_VER,
    },
    {
        title: 'Ordem de carregamento',
        icon: <AssignmentIcon />,
        path: FrontEndPathResources.OrdemCarregamento,
        requirePermission: RecursosPerfisEnum.ORDEM_CARREGAMENTO_VER,
    },
    {
        title: 'Lote embarque',
        icon: <AssignmentIcon />,
        path: FrontEndPathResources.LoteEmbarque,
        requirePermission: RecursosPerfisEnum.LOTE_EMBARQUE_VER,
    },
    {
        title: 'Lotes por transportadora',
        icon: <AssignmentIcon />,
        path: FrontEndPathResources.LoteEmbarquePorTransportadora,
        requirePermission: RecursosPerfisEnum.LOTE_EMBARQUE_POR_TRANSPORTADORA,
    },
    {
        title: 'Faturamento',
        icon: <AssignmentIcon />,
        path: FrontEndPathResources.DoubleCheck,
        requirePermission: RecursosPerfisEnum.DOUBLE_CHECK_ALTERAR,
    },
    {
        title: 'Cliente/Fornecedor',
        icon: <StorefrontIcon />,
        path: FrontEndPathResources.ClienteFornecedor,
        requirePermission: RecursosPerfisEnum.CLIENTES_FORNECEDORES_VER,
    },
    {
        title: 'Classificador',
        icon: <PersonOutlineIcon />,
        path: FrontEndPathResources.Classificador,
        requirePermission: RecursosPerfisEnum.CLASSIFICADORES_VER,
    },
    {
        title: 'Produto',
        icon: <CategoryOutlinedIcon />,
        path: FrontEndPathResources.Produto,
        requirePermission: RecursosPerfisEnum.PRODUTOS_VER,
    },
    {
        title: 'Amostra',
        icon: <ScienceOutlinedIcon />,
        path: FrontEndPathResources.Amostra,
        requirePermission: RecursosPerfisEnum.AMOSTRAS_VER,
    },
    {
        title: 'Transportadora',
        icon: <LocalShippingOutlinedIcon />,
        path: FrontEndPathResources.Transportadora,
        requirePermission: RecursosPerfisEnum.TRANSPORTADORAS_VER,
    },
    {
        title: 'Junção',
        icon: <RvHookupIcon />,
        path: FrontEndPathResources.Juncao,
        requirePermission: RecursosPerfisEnum.JUNCAO_CRIAR,
    },
    {
        title: 'Integração',
        icon: <IntegrationInstructionsIcon />,
        path: FrontEndPathResources.IntegracaoMaxys,
        requirePermission: RecursosPerfisEnum.INTEGRACOES_VER,
    },
    {
        title: 'Perfil de Usuário',
        icon: <GroupWorkIcon />,
        path: FrontEndPathResources.PerfilUsuario,
        requirePermission: RecursosPerfisEnum.PERFIIS_USUARIOS_VER,
    },
    {
        title: 'Cadastro de Usuário',
        icon: <AdminPanelSettingsOutlinedIcon />,
        path: FrontEndPathResources.CadastroUsuario,
        requirePermission: RecursosPerfisEnum.CADASTRO_USUARIOS_VER,
    },
    // {
    //     title: 'Administrador',
    //     icon: <CategoryOutlinedIcon />,
    //     path: FrontEndPathResources.Administrador,
    //     requirePermission: RecursosPerfisEnum.ADMINISTRADOR_VER,
    // },
];

interface Props {
    onMenuClick: () => void;
}

export default function MenuLateral({ onMenuClick }: Props) {
    const { usuarioLogado } = useAuth();

    const isNotTipoAcessoSuporte =
        usuarioLogado?.autorizacoes[0]?.tipoAcesso !== EnumAutorizacao.Suporte;

    const allowedMenus = useMemo(() => {
        return menuItems.filter((item) => {
            return usuarioLogado?.perfilUsuario?.recursos.find(
                (a: IRecursos) => a.nome === item.requirePermission
            );
        });
    }, [usuarioLogado]);

    return usuarioLogado?.trocarSenha &&
        usuarioLogado!.login === 'TRATOLOG' ? null : (
        <>
            {isNotTipoAcessoSuporte ? (
                <>
                    {allowedMenus.map((item) => (
                        <MenuItem
                            key={item.title}
                            item={item}
                            onMenuClick={onMenuClick}
                        />
                    ))}
                </>
            ) : (
                <>
                    <MenuItem
                        item={{
                            title: 'Cadastro de Usuário',
                            icon: <CategoryOutlinedIcon />,
                            path: FrontEndPathResources.CadastroUsuario,
                            requirePermission:
                                RecursosPerfisEnum.CADASTRO_USUARIOS_VER,
                        }}
                        onMenuClick={onMenuClick}
                    />
                </>
            )}
        </>
    );
}
