import { joiResolver } from '@hookform/resolvers/joi';
import { Button, Container, Divider, Grid, TextField } from '@mui/material';
import Joi from 'joi';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import SelectRecursos from '../../Components/Select/SelectRecursos';
import { FrontEndPathResources } from '../../Models/Api';
import PerfilUsuario from '../../Models/PerfilUsuario';
import { styles } from './helpers/Styles';

const validacao = Joi.object({
    nome: Joi.string().trim().required().min(3).max(50).messages({
        'string.base': 'O nome deve ser uma string',
        'string.empty': 'O nome não pode ficar em branco',
        'any.required': 'O nome não pode ficar em branco',
        'string.min': 'Deve ter no mínimo 3 caracteres',
        'string.max': 'Permitido até 50 caracteres',
    }),
    recursos: Joi.array().required().min(1).messages({
        'array.min': 'Deve ser selecionado ao menos 1 recurso',
    }),
});

export interface IRecursos {
    id: number;
    nome: string;
}

export interface ICamposFormulario {
    nome: string;
    recursos: IRecursos[];
}

interface Props {
    disabled?: boolean;
    perfilUsuario?: PerfilUsuario;
    onSubmit?: (dadosFormulario: ICamposFormulario) => {};
}

export default function Formulario({
    disabled = false,
    perfilUsuario,
    onSubmit,
}: Props) {
    const navigate = useNavigate();

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<ICamposFormulario>({
        resolver: joiResolver(validacao),
        criteriaMode: 'all',
    });

    useEffect(() => {
        perfilUsuario &&
            reset({
                nome: perfilUsuario.nome,
                recursos: perfilUsuario.recursos,
            });
    }, [reset, perfilUsuario]);

    return (
        <Container>
            <form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit((data) => onSubmit && onSubmit(data))}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="nome"
                            control={control}
                            defaultValue={''}
                            render={({ field: { ref, ...field } }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        inputProps={{ maxLength: 120 }}
                                        fullWidth
                                        label="Nome"
                                        autoFocus
                                        error={!!errors.nome}
                                        helperText={
                                            errors.nome
                                                ? errors.nome.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Divider textAlign="left" style={styles().divider}>
                    Permissões de acesso
                </Divider>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={10}>
                        <Controller
                            name="recursos"
                            control={control}
                            defaultValue={[]}
                            render={(props) => {
                                return (
                                    <SelectRecursos
                                        {...props}
                                        options={[]}
                                        required
                                        disabled={disabled}
                                        inputRef={props.field.ref}
                                        label="Permissões"
                                        onChange={(_, recursos) =>
                                            props.field.onChange(recursos)
                                        }
                                        fullWidth
                                        multiple
                                        error={!!errors.recursos}
                                        helperText={
                                            errors.recursos
                                                ? (errors.recursos as any)
                                                      .message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} justifyContent="end">
                    {!disabled ? (
                        <Button
                            type="submit"
                            variant="contained"
                            style={styles().button}
                            size="large"
                        >
                            Salvar
                        </Button>
                    ) : (
                        <Button
                            onClick={() => {
                                navigate(
                                    FrontEndPathResources.PerfilUsuario +
                                        '/alterar/' +
                                        perfilUsuario?.id
                                );
                            }}
                            variant="contained"
                            style={styles().button}
                            size="large"
                            data-test-id="botaoSalvar"
                        >
                            Alterar
                        </Button>
                    )}
                </Grid>
            </form>
        </Container>
    );
}
