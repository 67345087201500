import { Container, Divider } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageTitle from '../../Components/PageTitle/PageTitle';
import SpinnerLoading from '../../Components/SpinnerLoading/SpinnerLoading';
import { MensagemErroRequisicaoApi } from '../../Config/Api';
import { ApiResources, FrontEndPathResources } from '../../Models/Api';
import Contratante from '../../Models/Contratante';
import Usuario, {
    ChavesArmazenamentoStorage,
    StatusRequisicao,
} from '../../Models/Usuario';
import LoginResponse from '../../Models/responses/LoginResponse';
import useGenericService from '../../hooks/useGenericService';
import Formulario, { CamposFormularioTratados } from './Formulario';

type Params = {
    id: string;
};

export default function Alterar() {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { id } = useParams<Params>();
    const [contratante, setContratante] = useState<Contratante>();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const { getService } = useGenericService();

    useEffect(() => {
        let loginResponse = localStorage.getItem(
            ChavesArmazenamentoStorage.LOGIN_RESPONSE
        );

        if (loginResponse) {
            const usuario: Usuario = (
                JSON.parse(loginResponse) as LoginResponse
            ).usuario;

            if (usuario.trocarSenha) {
                window.location.pathname = FrontEndPathResources.AlterarSenha;
            }
        }
    }, []);

    useEffect(() => {
        setIsLoading(true);
        const carregarContratante = async () => {
            const servico = getService(ApiResources.Contratante);

            try {
                const resposta = await servico.api.get('/' + id);
                if (resposta.status === StatusRequisicao.OK) {
                    setContratante(resposta.data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    enqueueSnackbar('Não foi possível encontrar o registro', {
                        variant: 'error',
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
                }
            } catch (error) {
                setIsLoading(false);
            }
        };
        carregarContratante();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, enqueueSnackbar]);

    const onSubmit = async (dadosFormulario: CamposFormularioTratados) => {
        setIsLoading(true);
        const servico = getService(ApiResources.Contratante);

        try {
            const resultado = await servico.api.put('/' + id, dadosFormulario);

            if (resultado.status === StatusRequisicao.OK) {
                enqueueSnackbar(
                    'Contratante ' +
                        resultado.data.nome +
                        ' atualizado com sucesso!',
                    {
                        variant: 'success',
                        onClick: () => {
                            closeSnackbar();
                        },
                    }
                );
                navigate(FrontEndPathResources.Contratante);
                setIsLoading(false);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);

            (error as MensagemErroRequisicaoApi[]).forEach((erro) => {
                enqueueSnackbar(erro.atributo + ': ' + erro.mensagem, {
                    variant: 'error',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
            });
        }
    };

    return (
        <SpinnerLoading isLoading={isLoading}>
            <Container>
                <PageTitle title={'Alterando Contratante ' + id} />
                <Divider />
                <Formulario
                    contratante={contratante}
                    onSubmit={onSubmit}
                    permitirSenhaVazia
                />
            </Container>
        </SpinnerLoading>
    );
}
