import Contato from './Contato';

export enum TipoPessoa {
    Fisica = 'pessoa_fisica',
    Juridica = 'pessoa_juridica',
}

export default class Pessoa {
    constructor(
        public id: number,
        public nome: string,
        public contato?: Contato
    ) {}
}
