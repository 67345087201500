import { joiResolver } from '@hookform/resolvers/joi';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';
import {
    Alert,
    Button,
    Container,
    Grid,
    IconButton,
    TextField,
    Tooltip,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { PermissionGate } from '../../Components/Navegacao/PermissionGate';
import { MensagemErroRequisicaoApi } from '../../Config/services/ConfigService';
import { ApiResources, FrontEndPathResources } from '../../Models/Api';
import IntegracaoMaxys from '../../Models/IntegracaoMaxys';
import { RecursosPerfisEnum, StatusRequisicao } from '../../Models/Usuario';
import useGenericService from '../../hooks/useGenericService';
import { usePermission } from '../../hooks/usePermission';
import { validacao } from './helpers/JoiValidators';
import { styles } from './helpers/Styles';

export type CamposFormulario = {
    /*  Recepção de lotes de embarque e emissão de notas: ERP principal
        *** checkbox atuais "Integração automática" e
        "desativar criar e alterar as ordens de carregamento no portal"
        preenchimento OBRIGATÓRIO   */
    urlIntegracaoAtac: string;
    usuarioIntegracaoAtac: string;
    senhaIntegracaoAtac: string;
    tokenIntegracaoAtac: string;

    /*  Validação de Gestão de Riscos: comunicação com o ERP secundário
        *** usado somente no processo da validação da junção
        preenchimento NÃO obrigatório   */
    urlIntegracaoLoteEmbarquesOrdens: string;
    usuarioIntegracaoLoteEmbarquesOrdens: string;
    senhaIntegracaoLoteEmbarquesOrdens: string;
    tokenIntegracaoLoteEmbarquesOrdens: string;

    /*  Maxys XML: comunicação do Maxys xml (mesma base do ERP pricipal)
        preenchimento NÃO obrigatório   */
    urlIntegracaoXml: string;
    usuarioIntegracaoXml: string;
    senhaIntegracaoXml: string;
    tokenIntegracaoXml: string;
    codigoOrgIntegracaoXml: number | null;
};

export default function IntegracaoMaxysForm() {
    const [integracaoAtacCadastrada, setIntegracaoAtacCadastrada] =
        useState<IntegracaoMaxys>();

    const [
        integracaoLoteEmbarquesOrdensCadastrada,
        setIntegracaoLoteEmbarquesOrdensCadastrada,
    ] = useState<IntegracaoMaxys>();

    const [integracaoXmlCadastrada, setIntegracaoXmlCadastrada] =
        useState<IntegracaoMaxys>();

    //TODO - finalizar aqui
    const [resultadoTesteAtac, setResultadoTesteAtac] =
        useState<boolean>(false);

    const [
        resultadoTesteLoteEmbarquesOrdens,
        setResultadoTesteLoteEmbarquesOrdens,
    ] = useState<boolean>();

    const [resultadoTesteXml, setResultadoTesteXml] = useState<boolean>();

    const [tokenGeradoIntAtac, setTokenIntAtac] = useState<string>('');

    const [tokenGeradoIntLoteEmbarquesOrdens, setTokenIntLoteEmbarquesOrdens] =
        useState<string>('');

    const [tokenGeradoIntXml, setTokenIntXml] = useState<string>('');

    const { isAllowed: isAllowedAlterar } = usePermission(
        RecursosPerfisEnum.INTEGRACOES_ALTERAR
    );

    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        watch,
        reset,
    } = useForm<CamposFormulario>({
        resolver: joiResolver(validacao),
        criteriaMode: 'all',
    });

    const watchUrlAtac = watch('urlIntegracaoAtac');
    const watchSenhaAtac = watch('senhaIntegracaoAtac');
    const watchUsuarioAtac = watch('usuarioIntegracaoAtac');
    const watchTokenAtac = watch('tokenIntegracaoAtac');

    const watchUrlLoteEmbarquesOrdens = watch(
        'urlIntegracaoLoteEmbarquesOrdens'
    );
    const watchSenhaLoteEmbarquesOrdens = watch(
        'senhaIntegracaoLoteEmbarquesOrdens'
    );
    const watchUsuarioLoteEmbarquesOrdens = watch(
        'usuarioIntegracaoLoteEmbarquesOrdens'
    );
    const watchTokenLoteEmbarquesOrdens = watch(
        'tokenIntegracaoLoteEmbarquesOrdens'
    );

    const watchUrlXml = watch('urlIntegracaoXml');
    const watchSenhaXml = watch('senhaIntegracaoXml');
    const watchUsuarioXml = watch('usuarioIntegracaoXml');
    const watchTokenXml = watch('tokenIntegracaoXml');
    const watchcodigoOrgXml = watch('codigoOrgIntegracaoXml');

    const { getService } = useGenericService();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const tratarDados = async (dadosFormulario: CamposFormulario) => {
        const servico = getService(ApiResources.IntegracaoMaxys);

        const integracoes: IntegracaoMaxys[] = [];

        if (
            dadosFormulario.senhaIntegracaoAtac &&
            dadosFormulario.usuarioIntegracaoAtac &&
            dadosFormulario.urlIntegracaoAtac
        ) {
            const integracaoAtacSalvar: IntegracaoMaxys = {
                id: integracaoAtacCadastrada
                    ? integracaoAtacCadastrada.id
                    : null,
                senha: dadosFormulario.senhaIntegracaoAtac,
                usuario: dadosFormulario.usuarioIntegracaoAtac,
                url: dadosFormulario.urlIntegracaoAtac,
                tipo: 'ATAC',
                tokenEasymbark: dadosFormulario.tokenIntegracaoAtac,
                codigoOrganizacao: null, //usado somente no tipo XML
            };
            integracoes.push(integracaoAtacSalvar);
        }

        if (
            dadosFormulario.senhaIntegracaoLoteEmbarquesOrdens &&
            dadosFormulario.usuarioIntegracaoLoteEmbarquesOrdens &&
            dadosFormulario.urlIntegracaoLoteEmbarquesOrdens
        ) {
            const integracaoLoteEmbarquesOrdensSalvar: IntegracaoMaxys = {
                id: integracaoLoteEmbarquesOrdensCadastrada
                    ? integracaoLoteEmbarquesOrdensCadastrada.id
                    : null,
                senha: dadosFormulario.senhaIntegracaoLoteEmbarquesOrdens,
                usuario: dadosFormulario.usuarioIntegracaoLoteEmbarquesOrdens,
                url: dadosFormulario.urlIntegracaoLoteEmbarquesOrdens,
                tipo: 'LoteEmbarquesOrdens',
                tokenEasymbark:
                    dadosFormulario.tokenIntegracaoLoteEmbarquesOrdens,
                codigoOrganizacao: null, //usado somente no tipo XML
            };
            integracoes.push(integracaoLoteEmbarquesOrdensSalvar);
        }

        if (
            dadosFormulario.senhaIntegracaoXml &&
            dadosFormulario.usuarioIntegracaoXml &&
            dadosFormulario.urlIntegracaoXml
        ) {
            const integracaoXmlSalvar: IntegracaoMaxys = {
                id: integracaoXmlCadastrada ? integracaoXmlCadastrada.id : null,
                senha: dadosFormulario.senhaIntegracaoXml,
                usuario: dadosFormulario.usuarioIntegracaoXml,
                url: dadosFormulario.urlIntegracaoXml,
                tipo: 'XML',
                tokenEasymbark: dadosFormulario.tokenIntegracaoXml,
                codigoOrganizacao: dadosFormulario.codigoOrgIntegracaoXml,
            };
            integracoes.push(integracaoXmlSalvar);
        }

        if (!(integracoes.length > 0)) {
            enqueueSnackbar(
                'Não é possível salvar os registros de integrações por falta de dados necessários!',
                {
                    variant: 'error',
                    onClick: () => {
                        closeSnackbar();
                    },
                }
            );
            return;
        } else {
            try {
                const resultado = await servico.api.post('', integracoes);
                if (resultado.status === StatusRequisicao.OK) {
                    enqueueSnackbar('Integrações registradas com sucesso!', {
                        variant: 'success',
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
                    let collecReturn = resultado.data.body as IntegracaoMaxys[];

                    collecReturn.forEach((element) => {
                        if (element.tipo === 'ATAC')
                            setIntegracaoAtacCadastrada(element);
                        else if (element.tipo === 'LoteEmbarquesOrdens')
                            setIntegracaoLoteEmbarquesOrdensCadastrada(element);
                        else if (element.tipo === 'XML')
                            setIntegracaoXmlCadastrada(element);
                    });
                } else {
                    if (resultado.status === StatusRequisicao.BAD_REQUEST) {
                        enqueueSnackbar('resultado.', {
                            variant: 'error',
                            onClick: () => {
                                closeSnackbar();
                            },
                        });
                    }
                }
            } catch (error) {
                (error as MensagemErroRequisicaoApi[]).forEach((erro) => {
                    enqueueSnackbar(erro.atributo + ': ' + erro.mensagem, {
                        variant: 'error',
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
                });
            }
        }
    };

    async function remover(tipo: string) {
        const servico = getService(ApiResources.IntegracaoMaxys);

        try {
            const resultado = await servico.api.delete(`/${tipo}`);
            if (resultado.status === StatusRequisicao.OK) {
                enqueueSnackbar('Remoção da Integração com sucesso!', {
                    variant: 'success',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
                return true;

            } else {
                if (resultado.status === StatusRequisicao.BAD_REQUEST) {
                    enqueueSnackbar('resultado.', {
                        variant: 'error',
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
                }
                return false;
            }
        } catch (error) {
            (error as MensagemErroRequisicaoApi[]).forEach((erro) => {
                enqueueSnackbar(erro.atributo + ': ' + erro.mensagem, {
                    variant: 'error',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
            });
        }
    }

    function resetIntegracaoLoteEmbarque() {
        setValue('urlIntegracaoLoteEmbarquesOrdens', '');
        setValue('usuarioIntegracaoLoteEmbarquesOrdens', '');
        setValue('senhaIntegracaoLoteEmbarquesOrdens', '');
        setValue('tokenIntegracaoLoteEmbarquesOrdens', '');
        setTokenIntLoteEmbarquesOrdens('');
    }

    function resetIntegracaoXml() {
        setValue('urlIntegracaoXml', '');
        setValue('usuarioIntegracaoXml', '');
        setValue('senhaIntegracaoXml', '');
        setValue('tokenIntegracaoXml', '');
        setTokenIntXml('');
        setValue('codigoOrgIntegracaoXml', 0);
    }

    function resetIntegracaoAtac() {
        setValue('urlIntegracaoAtac', '');
        setValue('usuarioIntegracaoAtac', '');
        setValue('senhaIntegracaoAtac', '');
        setValue('tokenIntegracaoAtac', '');
        setTokenIntAtac('');
    }

    const testarConexao = async (
        tipoIntegracao: 'ATAC' | 'LoteEmbarquesOrdens' | 'XML'
    ) => {
        let url: string | null = null;
        let usuario: string | null = null;
        let senha: string | null = null;
        let codigoOrganizacao: number | null = null;

        if (tipoIntegracao === 'ATAC') {
            url = watchUrlAtac;
            usuario = watchUsuarioAtac;
            senha = watchSenhaAtac;
        } else if (tipoIntegracao === 'LoteEmbarquesOrdens') {
            url = watchUrlLoteEmbarquesOrdens;
            usuario = watchUsuarioLoteEmbarquesOrdens;
            senha = watchSenhaLoteEmbarquesOrdens;
        } else if (tipoIntegracao === 'XML') {
            url = watchUrlXml;
            usuario = watchUsuarioXml;
            senha = watchSenhaXml;
        }

        if (url && usuario && senha) {
            const servico = getService(ApiResources.IntegracaoMaxys);

            const integracaoTestar: IntegracaoMaxys = {
                id: null,
                senha: senha,
                usuario: usuario,
                url: url,
                tipo: tipoIntegracao,
                tokenEasymbark: '',
                codigoOrganizacao: codigoOrganizacao,
            };

            try {
                const resultado = await servico.api.post(
                    '/testar-conexao',
                    integracaoTestar
                );
                if (resultado.status === StatusRequisicao.OK) {
                    const teste = resultado.data as boolean;

                    if (tipoIntegracao === 'ATAC') {
                        setResultadoTesteAtac(teste);
                    } else if (tipoIntegracao === 'LoteEmbarquesOrdens') {
                        setResultadoTesteLoteEmbarquesOrdens(teste);
                    } else if (tipoIntegracao === 'XML') {
                        setResultadoTesteXml(teste);
                    }

                    if (teste === true) {
                        enqueueSnackbar(
                            'Teste de Conexão Realizado com Sucesso!',
                            {
                                variant: 'success',
                                onClick: () => {
                                    closeSnackbar();
                                },
                            }
                        );
                    } else if (teste === false) {
                        enqueueSnackbar(
                            'Não é possível fazer uma conexão com estes dados!',
                            {
                                variant: 'error',
                                onClick: () => {
                                    closeSnackbar();
                                },
                            }
                        );
                    }
                } else if (resultado.status === StatusRequisicao.BAD_REQUEST) {
                    enqueueSnackbar('', {
                        variant: 'error',
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
                }
            } catch (error) {
                (error as MensagemErroRequisicaoApi[]).forEach((erro) => {
                    enqueueSnackbar(erro.atributo + ': ' + erro.mensagem, {
                        variant: 'error',
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
                });
            }
        } else {
            enqueueSnackbar(
                'Os valores de Url, Usuário e Senha são obrigatórios para o teste de conexão!',
                {
                    variant: 'warning',
                    onClick: () => {
                        closeSnackbar();
                    },
                }
            );
        }
    };

    async function gerarToken(
        tipoIntegracao: 'ATAC' | 'LoteEmbarquesOrdens' | 'XML'
    ) {
        try {
            const servico = getService(ApiResources.Token);

            const resposta = await servico.api.post('', {
                tipoIntegracao: tipoIntegracao,
            });

            if (resposta.status === StatusRequisicao.CREATED) {
                if (tipoIntegracao === 'ATAC') {
                    setTokenIntAtac(resposta.data);
                    setValue('tokenIntegracaoAtac', resposta.data);
                } else if (tipoIntegracao === 'LoteEmbarquesOrdens') {
                    setTokenIntLoteEmbarquesOrdens(resposta.data);
                    setValue(
                        'tokenIntegracaoLoteEmbarquesOrdens',
                        resposta.data
                    );
                } else if (tipoIntegracao === 'XML') {
                    setTokenIntXml(resposta.data);
                    setValue('tokenIntegracaoXml', resposta.data);
                }
            }
        } catch (error: any) {
            enqueueSnackbar(
                'Erro ao gerar token: ' + String(error[0].mensagem),
                {
                    variant: 'error',
                    onClick: () => {
                        closeSnackbar();
                    },
                }
            );
        }
    }

    async function revogarToken(tipoIntegracao: String) {
        try {
            const servico = getService(ApiResources.Token);

            const resposta = await servico.api.put('', {
                tipoIntegracao: tipoIntegracao,
            });

            if (resposta.status === StatusRequisicao.OK) {
                if (tipoIntegracao === 'ATAC') {
                    setTokenIntAtac(resposta.data);
                    reset({
                        tokenIntegracaoAtac: resposta.data,
                        ...control._formValues,
                    });
                } else if (tipoIntegracao === 'LoteEmbarquesOrdens') {
                    setTokenIntLoteEmbarquesOrdens(resposta.data);
                    reset({
                        tokenIntegracaoLoteEmbarquesOrdens: resposta.data,
                        ...control._formValues,
                    });
                } else if (tipoIntegracao === 'XML') {
                    setTokenIntXml(resposta.data);
                    reset({
                        tokenIntegracaoXml: resposta.data,
                        ...control._formValues,
                    });
                }
            }
        } catch (error: any) {
            enqueueSnackbar('Erro ao gerar token: ' + error[0].mensagem, {
                variant: 'error',
                onClick: () => {
                    closeSnackbar();
                },
            });
        }
    }
    useEffect(() => {
        const buscarIntegracoesCadastradas = async () => {
            const servico = getService(ApiResources.IntegracaoMaxys);
            try {
                const resultado = await servico.api.get('');

                if (resultado.status === StatusRequisicao.OK) {
                    resultado.data.forEach((integracao: any) => {
                        if (integracao.tipo === 'ATAC') {
                            setIntegracaoAtacCadastrada(integracao);
                        } else if (integracao.tipo === 'LoteEmbarquesOrdens') {
                            setIntegracaoLoteEmbarquesOrdensCadastrada(
                                integracao
                            );
                        } else if (integracao.tipo === 'XML') {
                            setIntegracaoXmlCadastrada(integracao);
                        }
                    });
                }
            } catch (error) {}
        };

        buscarIntegracoesCadastradas();
    }, []);

    useEffect(() => {
        if (integracaoAtacCadastrada) {
            setValue('urlIntegracaoAtac', integracaoAtacCadastrada.url);
            setValue('usuarioIntegracaoAtac', integracaoAtacCadastrada.usuario);
            setValue('senhaIntegracaoAtac', integracaoAtacCadastrada.senha);
            setValue(
                'tokenIntegracaoAtac',
                integracaoAtacCadastrada.tokenEasymbark
            );
            setTokenIntAtac(integracaoAtacCadastrada.tokenEasymbark);
        }
        if (integracaoLoteEmbarquesOrdensCadastrada) {
            setValue(
                'urlIntegracaoLoteEmbarquesOrdens',
                integracaoLoteEmbarquesOrdensCadastrada.url
            );
            setValue(
                'usuarioIntegracaoLoteEmbarquesOrdens',
                integracaoLoteEmbarquesOrdensCadastrada.usuario
            );
            setValue(
                'senhaIntegracaoLoteEmbarquesOrdens',
                integracaoLoteEmbarquesOrdensCadastrada.senha
            );
            setValue(
                'tokenIntegracaoLoteEmbarquesOrdens',
                integracaoLoteEmbarquesOrdensCadastrada.tokenEasymbark
            );
            setTokenIntLoteEmbarquesOrdens(
                integracaoLoteEmbarquesOrdensCadastrada.tokenEasymbark
            );
        }
        if (integracaoXmlCadastrada) {
            setValue('urlIntegracaoXml', integracaoXmlCadastrada.url);
            setValue('usuarioIntegracaoXml', integracaoXmlCadastrada.usuario);
            setValue('senhaIntegracaoXml', integracaoXmlCadastrada.senha);
            setValue(
                'tokenIntegracaoXml',
                integracaoXmlCadastrada.tokenEasymbark
            );
            setTokenIntXml(integracaoXmlCadastrada.tokenEasymbark);
            setValue(
                'codigoOrgIntegracaoXml',
                integracaoXmlCadastrada.codigoOrganizacao
            );
        }
    }, [
        integracaoAtacCadastrada,
        integracaoLoteEmbarquesOrdensCadastrada,
        integracaoXmlCadastrada,
    ]);

    return (
        <PermissionGate
            recurso={RecursosPerfisEnum.INTEGRACOES_VER}
            redirect={FrontEndPathResources.IntegracaoMaxys}
        >
            <Container>
                <form
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit(tratarDados)}
                >
                    <Accordion style={{ padding: 8 }}>
                        <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            style={styles.cardBackground1}
                        >
                            <Typography>
                                Integração ERP Maxys Principal
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <div style={styles.tooltipContainer}>
                                    <Alert
                                        variant="outlined"
                                        severity="info"
                                        style={styles.alert}
                                    >
                                        Recepção de lotes de embarque, emissão
                                        de notas e vinculação de clifor do
                                        usuário
                                    </Alert>
                                    <Tooltip
                                        title="Remover Integração"
                                        placement="top"
                                        disableInteractive
                                    >
                                        <IconButton
                                            aria-label="remover integração"
                                            onClick={() => {
                                                remover(
                                                    String(
                                                        integracaoAtacCadastrada?.tipo
                                                    )
                                                ).then(value => value && resetIntegracaoLoteEmbarque())
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={12}>
                                        <Controller
                                            name="urlIntegracaoLoteEmbarquesOrdens"
                                            control={control}
                                            defaultValue={''}
                                            render={({
                                                field: { ref, ...field },
                                                fieldState: { error },
                                            }) => {
                                                return (
                                                    <TextField
                                                        {...field}
                                                        inputRef={ref}
                                                        margin="normal"
                                                        fullWidth
                                                        label="Url de integração"
                                                        error={
                                                            !!errors.urlIntegracaoLoteEmbarquesOrdens
                                                        }
                                                        helperText={
                                                            errors.urlIntegracaoLoteEmbarquesOrdens
                                                                ? errors
                                                                      .urlIntegracaoLoteEmbarquesOrdens
                                                                      .message
                                                                : ''
                                                        }
                                                        disabled={
                                                            isAllowedAlterar
                                                                ? false
                                                                : true
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={6}>
                                        <Controller
                                            name="usuarioIntegracaoLoteEmbarquesOrdens"
                                            control={control}
                                            defaultValue={''}
                                            render={({
                                                field: { ref, ...field },
                                                fieldState: { error },
                                            }) => {
                                                return (
                                                    <TextField
                                                        {...field}
                                                        inputRef={ref}
                                                        margin="normal"
                                                        fullWidth
                                                        label="Usuário"
                                                        error={
                                                            !!errors.usuarioIntegracaoLoteEmbarquesOrdens
                                                        }
                                                        helperText={
                                                            errors.usuarioIntegracaoLoteEmbarquesOrdens
                                                                ? errors
                                                                      .usuarioIntegracaoLoteEmbarquesOrdens
                                                                      .message
                                                                : ''
                                                        }
                                                        disabled={
                                                            isAllowedAlterar
                                                                ? false
                                                                : true
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <Controller
                                            name="senhaIntegracaoLoteEmbarquesOrdens"
                                            control={control}
                                            defaultValue={''}
                                            render={({
                                                field: { ref, ...field },
                                                fieldState: { error },
                                            }) => {
                                                return (
                                                    <TextField
                                                        {...field}
                                                        inputRef={ref}
                                                        margin="normal"
                                                        fullWidth
                                                        label="Senha"
                                                        type="password"
                                                        id="LoteEmbarquesOrdens-password"
                                                        autoComplete="current-LoteEmbarquesOrdens-password"
                                                        error={
                                                            !!errors.senhaIntegracaoLoteEmbarquesOrdens
                                                        }
                                                        helperText={
                                                            errors.senhaIntegracaoLoteEmbarquesOrdens
                                                                ? errors
                                                                      .senhaIntegracaoLoteEmbarquesOrdens
                                                                      .message
                                                                : ''
                                                        }
                                                        disabled={
                                                            isAllowedAlterar
                                                                ? false
                                                                : true
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={10}>
                                        <Controller
                                            name="tokenIntegracaoLoteEmbarquesOrdens"
                                            control={control}
                                            defaultValue={''}
                                            render={({
                                                field: { ref, ...field },
                                                fieldState: { error },
                                            }) => {
                                                return (
                                                    <TextField
                                                        {...field}
                                                        disabled={true}
                                                        value={
                                                            tokenGeradoIntLoteEmbarquesOrdens
                                                        }
                                                        inputRef={ref}
                                                        margin="normal"
                                                        fullWidth
                                                        label="Token de integração"
                                                        error={
                                                            !!errors.tokenIntegracaoLoteEmbarquesOrdens
                                                        }
                                                        helperText={
                                                            errors.tokenIntegracaoLoteEmbarquesOrdens
                                                                ? errors
                                                                      .tokenIntegracaoLoteEmbarquesOrdens
                                                                      .message
                                                                : ''
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        lg={2}
                                        alignSelf="center"
                                    >
                                        <Tooltip
                                            title="Copiar Token"
                                            placement="top"
                                            disableInteractive
                                        >
                                            <IconButton
                                                aria-label="copy"
                                                onClick={() => {
                                                    navigator.clipboard.writeText(
                                                        watchTokenLoteEmbarquesOrdens
                                                    );
                                                }}
                                            >
                                                <ContentCopyIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                            title="Gerar Token"
                                            placement="top"
                                            disableInteractive
                                        >
                                            <IconButton
                                                aria-label="gerar token"
                                                onClick={() => {
                                                    gerarToken(
                                                        'LoteEmbarquesOrdens'
                                                    );
                                                }}
                                            >
                                                <ReplayIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                            title="Revogar Token"
                                            placement="top"
                                            disableInteractive
                                        >
                                            <IconButton
                                                aria-label="revogar token"
                                                onClick={() => {
                                                    revogarToken(
                                                        'LoteEmbarquesOrdens'
                                                    );
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    spacing={2}
                                    justifyContent="end"
                                >
                                    <Button
                                        type="button"
                                        variant="contained"
                                        style={
                                            resultadoTesteLoteEmbarquesOrdens
                                                ? styles.testSuccessButton
                                                : styles.button
                                        }
                                        size="small"
                                        onClick={() => {
                                            testarConexao(
                                                'LoteEmbarquesOrdens'
                                            );
                                        }}
                                        disabled={!isAllowedAlterar}
                                    >
                                        {resultadoTesteLoteEmbarquesOrdens
                                            ? 'Teste de Conexão Bem Sucedido'
                                            : 'Testar Conexão Principal'}
                                    </Button>
                                </Grid>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion style={{ padding: 8 }}>
                        <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                            style={styles.cardBackground2}
                        >
                            <Typography>
                                Integração ERP Maxys Secundário
                            </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Typography>
                                <div style={styles.tooltipContainer}>
                                    <Alert
                                        variant="outlined"
                                        severity="info"
                                        style={styles.alert}
                                    >
                                        Validação de Gestão de Riscos
                                    </Alert>
                                    <Tooltip
                                        title="Remover Integração"
                                        placement="top"
                                        disableInteractive
                                    >
                                        <IconButton
                                            aria-label="remover integração"
                                            onClick={() => {
                                                remover(
                                                    String(
                                                        integracaoLoteEmbarquesOrdensCadastrada?.tipo
                                                    )
                                                ).then(
                                                    (value) =>
                                                        value &&
                                                        resetIntegracaoAtac()
                                                );
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={12}>
                                        <Controller
                                            name="urlIntegracaoAtac"
                                            control={control}
                                            defaultValue={''}
                                            render={({
                                                field: { ref, ...field },
                                                fieldState: { error },
                                            }) => {
                                                return (
                                                    <TextField
                                                        {...field}
                                                        inputRef={ref}
                                                        margin="normal"
                                                        fullWidth
                                                        label="Url (integração secundária)"
                                                        error={
                                                            !!errors.urlIntegracaoAtac
                                                        }
                                                        helperText={
                                                            errors.urlIntegracaoAtac
                                                                ? errors
                                                                      .urlIntegracaoAtac
                                                                      .message
                                                                : ''
                                                        }
                                                        disabled={
                                                            isAllowedAlterar
                                                                ? false
                                                                : true
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={6}>
                                        <Controller
                                            name="usuarioIntegracaoAtac"
                                            control={control}
                                            defaultValue={''}
                                            render={({
                                                field: { ref, ...field },
                                                fieldState: { error },
                                            }) => {
                                                return (
                                                    <TextField
                                                        {...field}
                                                        inputRef={ref}
                                                        margin="normal"
                                                        fullWidth
                                                        label="Usuário (integração secundária)"
                                                        error={
                                                            !!errors.usuarioIntegracaoAtac
                                                        }
                                                        helperText={
                                                            errors.usuarioIntegracaoAtac
                                                                ? errors
                                                                      .usuarioIntegracaoAtac
                                                                      .message
                                                                : ''
                                                        }
                                                        disabled={
                                                            isAllowedAlterar
                                                                ? false
                                                                : true
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <Controller
                                            name="senhaIntegracaoAtac"
                                            control={control}
                                            defaultValue={''}
                                            render={({
                                                field: { ref, ...field },
                                                fieldState: { error },
                                            }) => {
                                                return (
                                                    <TextField
                                                        {...field}
                                                        inputRef={ref}
                                                        margin="normal"
                                                        fullWidth
                                                        label="Senha (integração secundária)"
                                                        type="password"
                                                        id="password"
                                                        autoComplete="current-password"
                                                        error={
                                                            !!errors.senhaIntegracaoAtac
                                                        }
                                                        helperText={
                                                            errors.senhaIntegracaoAtac
                                                                ? errors
                                                                      .senhaIntegracaoAtac
                                                                      .message
                                                                : ''
                                                        }
                                                        disabled={
                                                            isAllowedAlterar
                                                                ? false
                                                                : true
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={10}>
                                        <Controller
                                            name="tokenIntegracaoAtac"
                                            control={control}
                                            defaultValue={''}
                                            render={({
                                                field: { ref, ...field },
                                                fieldState: { error },
                                            }) => {
                                                return (
                                                    <TextField
                                                        {...field}
                                                        disabled={true}
                                                        value={
                                                            tokenGeradoIntAtac
                                                        }
                                                        inputRef={ref}
                                                        margin="normal"
                                                        fullWidth
                                                        label="Token de integração"
                                                        error={
                                                            !!errors.tokenIntegracaoAtac
                                                        }
                                                        helperText={
                                                            errors.tokenIntegracaoAtac
                                                                ? errors
                                                                      .tokenIntegracaoAtac
                                                                      .message
                                                                : ''
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        lg={2}
                                        alignSelf="center"
                                    >
                                        <Tooltip
                                            title="Copiar Token"
                                            placement="top"
                                            disableInteractive
                                        >
                                            <IconButton
                                                aria-label="copy"
                                                onClick={() => {
                                                    navigator.clipboard.writeText(
                                                        watchTokenAtac
                                                    );
                                                }}
                                            >
                                                <ContentCopyIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                            title="Gerar Token"
                                            placement="top"
                                            disableInteractive
                                        >
                                            <IconButton
                                                aria-label="gerar token"
                                                onClick={() => {
                                                    gerarToken('ATAC');
                                                }}
                                            >
                                                <ReplayIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                            title="Revogar Token"
                                            placement="top"
                                            disableInteractive
                                        >
                                            <IconButton
                                                aria-label="revogar token"
                                                onClick={() => {
                                                    revogarToken('ATAC');
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    spacing={2}
                                    justifyContent="end"
                                >
                                    <Button
                                        type="button"
                                        variant="contained"
                                        style={
                                            resultadoTesteAtac
                                                ? styles.testSuccessButton
                                                : styles.button
                                        }
                                        size="small"
                                        onClick={() => {
                                            testarConexao('ATAC');
                                        }}
                                        disabled={!isAllowedAlterar}
                                    >
                                        {resultadoTesteAtac
                                            ? 'Teste de Conexão Bem Sucedido'
                                            : 'Testar conexão secundária'}
                                    </Button>
                                </Grid>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion style={{ padding: 8 }}>
                        <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                            style={styles.cardBackground3}
                        >
                            <Typography>Integração Maxys XML</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <div style={styles.tooltipContainer}>
                                    <Alert
                                        variant="outlined"
                                        severity="info"
                                        style={styles.alert}
                                    >
                                        Maxys XML (mesma base do ERP Maxys
                                        principal)
                                    </Alert>
                                    <Tooltip
                                        title="Remover Integração"
                                        placement="top"
                                        disableInteractive
                                    >
                                        <IconButton
                                            aria-label="remover integração"
                                            onClick={() => {
                                                remover(
                                                    String(
                                                        integracaoXmlCadastrada?.tipo
                                                    )
                                                ).then(value => value && resetIntegracaoXml());
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={12}>
                                        <Controller
                                            name="urlIntegracaoXml"
                                            control={control}
                                            defaultValue={''}
                                            render={({
                                                field: { ref, ...field },
                                                fieldState: { error },
                                            }) => {
                                                return (
                                                    <TextField
                                                        {...field}
                                                        inputRef={ref}
                                                        margin="normal"
                                                        fullWidth
                                                        label="Url (Maxys XML)"
                                                        error={
                                                            !!errors.urlIntegracaoXml
                                                        }
                                                        helperText={
                                                            errors.urlIntegracaoXml
                                                                ? errors
                                                                      .urlIntegracaoXml
                                                                      .message
                                                                : ''
                                                        }
                                                        disabled={
                                                            isAllowedAlterar
                                                                ? false
                                                                : true
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={4}>
                                        <Controller
                                            name="usuarioIntegracaoXml"
                                            control={control}
                                            defaultValue={''}
                                            render={({
                                                field: { ref, ...field },
                                                fieldState: { error },
                                            }) => {
                                                return (
                                                    <TextField
                                                        {...field}
                                                        inputRef={ref}
                                                        margin="normal"
                                                        fullWidth
                                                        label="Usuário (Maxys XML)"
                                                        error={
                                                            !!errors.usuarioIntegracaoXml
                                                        }
                                                        helperText={
                                                            errors.usuarioIntegracaoXml
                                                                ? errors
                                                                      .usuarioIntegracaoXml
                                                                      .message
                                                                : ''
                                                        }
                                                        disabled={
                                                            isAllowedAlterar
                                                                ? false
                                                                : true
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <Controller
                                            name="senhaIntegracaoXml"
                                            control={control}
                                            defaultValue={''}
                                            render={({
                                                field: { ref, ...field },
                                                fieldState: { error },
                                            }) => {
                                                return (
                                                    <TextField
                                                        {...field}
                                                        inputRef={ref}
                                                        margin="normal"
                                                        fullWidth
                                                        label="Senha (Maxys XML)"
                                                        type="password"
                                                        id="xml-password"
                                                        autoComplete="current-xml-password"
                                                        error={
                                                            !!errors.senhaIntegracaoXml
                                                        }
                                                        helperText={
                                                            errors.senhaIntegracaoXml
                                                                ? errors
                                                                      .senhaIntegracaoXml
                                                                      .message
                                                                : ''
                                                        }
                                                        disabled={
                                                            isAllowedAlterar
                                                                ? false
                                                                : true
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <Controller
                                            name="codigoOrgIntegracaoXml"
                                            control={control}
                                            render={({
                                                field: { ref, ...field },
                                                fieldState: { error },
                                            }) => {
                                                return (
                                                    <TextField
                                                        {...field}
                                                        inputRef={ref}
                                                        margin="normal"
                                                        fullWidth
                                                        label="Código Organização (Maxys XML)"
                                                        type="number"
                                                        id="xml-codigo-organizacao"
                                                        autoComplete="current-xml-cod-org"
                                                        error={
                                                            !!errors.codigoOrgIntegracaoXml
                                                        }
                                                        helperText={
                                                            errors.codigoOrgIntegracaoXml
                                                                ? errors
                                                                      .codigoOrgIntegracaoXml
                                                                      .message
                                                                : ''
                                                        }
                                                        disabled={
                                                            isAllowedAlterar
                                                                ? false
                                                                : true
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={10}>
                                        <Controller
                                            name="tokenIntegracaoXml"
                                            control={control}
                                            defaultValue={''}
                                            render={({
                                                field: { ref, ...field },
                                                fieldState: { error },
                                            }) => {
                                                return (
                                                    <TextField
                                                        {...field}
                                                        disabled={true}
                                                        value={
                                                            tokenGeradoIntXml
                                                        }
                                                        inputRef={ref}
                                                        margin="normal"
                                                        fullWidth
                                                        label="Token de integração"
                                                        error={
                                                            !!errors.tokenIntegracaoXml
                                                        }
                                                        helperText={
                                                            errors.tokenIntegracaoXml
                                                                ? errors
                                                                      .tokenIntegracaoXml
                                                                      .message
                                                                : ''
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        lg={2}
                                        alignSelf="center"
                                    >
                                        <Tooltip
                                            title="Copiar Token"
                                            placement="top"
                                            disableInteractive
                                        >
                                            <IconButton
                                                aria-label="copy"
                                                onClick={() => {
                                                    navigator.clipboard.writeText(
                                                        watchTokenXml
                                                    );
                                                }}
                                                disabled={
                                                    isAllowedAlterar
                                                        ? false
                                                        : true
                                                }
                                            >
                                                <ContentCopyIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                            title="Gerar Token"
                                            placement="top"
                                            disableInteractive
                                        >
                                            <IconButton
                                                aria-label="gerar token"
                                                onClick={() => {
                                                    gerarToken('XML');
                                                }}
                                            >
                                                <ReplayIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                            title="Revogar Token"
                                            placement="top"
                                            disableInteractive
                                        >
                                            <IconButton
                                                aria-label="revogar token"
                                                onClick={() => {
                                                    revogarToken('XML');
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    spacing={2}
                                    justifyContent="end"
                                >
                                    <Button
                                        type="button"
                                        variant="contained"
                                        style={
                                            resultadoTesteXml
                                                ? styles.testSuccessButton
                                                : styles.button
                                        }
                                        size="small"
                                        onClick={() => {
                                            testarConexao('XML');
                                        }}
                                        disabled={!isAllowedAlterar}
                                    >
                                        {resultadoTesteXml
                                            ? 'Teste de Conexão Bem Sucedido'
                                            : 'Testar Conexão XML'}
                                    </Button>
                                </Grid>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <PermissionGate
                        recurso={RecursosPerfisEnum.INTEGRACOES_ALTERAR}
                        redirect={FrontEndPathResources.IntegracaoMaxys}
                    >
                        <Grid container spacing={2} justifyContent="end">
                            <Button
                                type="submit"
                                variant="contained"
                                style={styles.button}
                                size="large"
                            >
                                Salvar
                            </Button>
                        </Grid>
                    </PermissionGate>
                </form>
            </Container>
        </PermissionGate>
    );
}
