import { IBaseUseCase } from '../../../../types/interfaces/IBaseUseCase';
import { LotePorTransportadoraDtoResponse } from '../../types/LotePorTransportadoraDtoResponse';
import { IFindLotesEmbarqueByFilter } from '../dtos/IFindLotesEmbarqueByFilter';

import { ILotesPorTransportadoraRepository } from '../repositories/ILotesPorTransportadoraRepository';

export class FindLotesEmbarqueByDocumentoTransportadora
    implements
        IBaseUseCase<
            IFindLotesEmbarqueByFilter,
            LotePorTransportadoraDtoResponse[]
        >
{
    constructor(
        private readonly repository: ILotesPorTransportadoraRepository
    ) {}

    execute(
        param: IFindLotesEmbarqueByFilter
    ): Promise<LotePorTransportadoraDtoResponse[]> {
        return this.repository.findLotesEmbarqueByDocumentoTransportadora(
            param
        );
    }
}
