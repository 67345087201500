import { StandardTextFieldProps, TextField } from '@mui/material';
import InputMask from 'react-input-mask';

type Props = StandardTextFieldProps;

export default function InputTelefone({ label = 'Telefone', ...props }: Props) {
    const { onBlur, value, onChange, disabled, inputRef, ...remainingProps } =
        props;

    return (
        <InputMask
            mask={label == 'Telefone' ? "(99) 9999-9999" : "(99) 99999-9999"}
            maskPlaceholder={null}
            onChange={onChange}
            onBlur={onBlur}
            value={value as string}
            disabled={disabled}
        >
            <TextField
                label={label}
                inputProps={{ autoComplete: 'off' }}
                {...remainingProps}
                inputRef={inputRef}
                fullWidth
            />
        </InputMask>
    );
}
