import { joiResolver } from '@hookform/resolvers/joi';
import {
    Button,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
} from '@mui/material';
import JoiCpfCnpj from 'cpf-cnpj-validator';
import { default as JoiOriginal } from 'joi';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import DividerComp from '../../Components/Divider/DividerComp';
import InputCelular from '../../Components/Input/InputCelular';
import InputCnpj from '../../Components/Input/InputCnpj';
import InputTelefone from '../../Components/Input/InputTelefone';
import Util from '../../Helpers/Util';
import ViaCepService from '../../Helpers/ViaCepService';
import { FrontEndPathResources } from '../../Models/Api';
import Contato from '../../Models/Contato';
import Contratante, { TipoInscricao } from '../../Models/Contratante';
import Endereco from '../../Models/Endereco';
import { StatusRequisicao, UsuarioTratado } from '../../Models/Usuario';
import { styles } from './helpers/Styles';
import { VisibilityOff, Visibility } from '@mui/icons-material';

export type CamposFormulario = {
    nome: string;
    nomeFantasia: string;
    cnpj: string;
    login: string;
    senha: string;
    cep: string | null;
    cidade: string | null;
    estado: string | null;
    logradouro: string | null;
    bairro: string | null;
    numero: string | null;
    complemento: string | null;
    ibge: string | null;
    email: string;
    telefone: string;
    celular: string;
    inscricaoEstadual?: string;
    inscricaoMunicipal?: string;
};

export interface CamposFormularioTratados
    extends Omit<
        CamposFormulario,
        | 'cep'
        | 'cidade'
        | 'estado'
        | 'logradouro'
        | 'bairro'
        | 'numero'
        | 'complemento'
        | 'ibge'
        | 'login'
        | 'senha'
        | 'email'
        | 'telefone'
        | 'celular'
        | 'inscricaoEstadual'
        | 'inscricaoMunicipal'
    > {
    endereco: Endereco | null;
    usuario: UsuarioTratado;
    contato: Contato | null;
}

interface Props {
    disabled?: boolean;
    contratante?: Contratante;
    permitirSenhaVazia?: boolean;
    onSubmit?: (dadosFomulario: CamposFormularioTratados) => {};
}

const serviceViaCep = new ViaCepService();

export default function Formulario({
    disabled = false,
    contratante,
    permitirSenhaVazia = false,
    onSubmit,
}: Props) {
    const Joi = JoiOriginal.extend(JoiCpfCnpj);

    const navigate = useNavigate();
    let refInputNumero = useRef<HTMLInputElement | null>(null);

    const [mensagemErroPadraoSenha, setMensagemErroPadraoSenha] =
        useState<string>();

    const [isIsento, setIsIsento] = useState<boolean>(false);
        
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    
    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };


    const validacao = Joi.object({
        nome: Joi.string().trim().required().min(3).max(150).messages({
            'string.base': 'O nome deve ser uma string',
            'string.empty': 'O nome não pode ficar em branco',
            'any.required': 'O nome não pode ficar em branco',
            'string.min': 'Deve ter no mínimo 3 caracteres',
            'string.max': 'Permitido até 150 caracteres',
        }),
        nomeFantasia: Joi.string().trim().required().min(3).max(150).messages({
            'string.base': 'O nome fantasia deve ser uma string',
            'string.empty': 'O nome fantasia não pode ficar em branco',
            'any.required': 'O nome fantasia não pode ficar em branco',
            'string.min': 'Deve ter no mínimo 3 caracteres',
            'string.max': 'Permitido até 150 caracteres',
        }),
        cnpj: Joi.document().cnpj().messages({
            'string.empty': 'O CNPJ não pode ficar em branco',
            'any.required': 'O CNPJ não pode ficar em branco',
        }),
        inscricaoMunicipal: Joi.string().allow('', null).optional(),
        inscricaoEstadual: Joi.string().allow('', null).optional(),

        email: Joi.string()
            .email({ tlds: { allow: false } })
            .allow('', null)
            .messages({
                'string.email': 'Deve ser um e-mail válido',
            }),
        telefone: Joi.string().allow('', null).optional(),
        celular: Joi.string().allow('', null).optional(),
        login: Joi.string().trim().required().messages({
            'string.base': 'O login deve ser uma string',
            'string.empty': 'O login não pode ficar em branco',
            'any.required': 'O login não pode ficar em branco',
        }),
        senha: permitirSenhaVazia
            ? Joi.string().allow('', null)
            : Joi.string().trim().required().min(8).messages({
                'string.base': 'A senha deve ser uma string',
                'string.empty': 'A senha não pode ficar em branco',
                'any.required': 'A senha não pode ficar em branco',
                'string.min': 'A senha deve ter no mínimo 8 caracteres'
            }),
        id: Joi.number().optional(),
        cep: Joi.string().max(10).required().messages({
            'string.max': 'Deve ter no máximo 10 caracteres',
            'string.required': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
        }),
        logradouro: Joi.string().max(100).required().messages({
            'string.max': 'Deve ter no máximo 100 caracteres',
            'string.required': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
        }),
        bairro: Joi.string().max(100).required().messages({
            'string.max': 'Deve ter no máximo 100 caracteres',
            'string.required': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
        }),
        cidade: Joi.string().max(150).required().messages({
            'string.base': 'Não pode ficar em branco',
            'string.required': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
            'string.max': 'Deve ter no máximo 150 caracteres',
        }),
        estado: Joi.string().max(2).required().messages({
            'string.base': 'Não pode ficar em branco',
            'string.required': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
            'string.max': 'Deve ter no máximo 2 caracteres',
        }),
        numero: Joi.string().max(6).required().messages({
            'string.base': 'Não pode ficar em branco',
            'string.required': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
            'string.max': 'Deve ter no máximo 6 caracteres',
        }),
        complemento: Joi.string().max(100).allow('', null),
        ibge: Joi.string().allow('', null),
    });

    const {
        control,
        reset,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<CamposFormulario>({
        resolver: joiResolver(validacao),
        criteriaMode: 'all',
    });

    useEffect(() => {
        const preencherFormulario = (contratante: Contratante) => {
            if (
                contratante.endereco?.inscricaoEstadual?.toLocaleLowerCase() ===
                'isento' ||
                contratante.endereco?.inscricaoMunicipal?.toLocaleLowerCase() ===
                'isento' ||
                contratante.endereco?.inscricaoMunicipal === null ||
                contratante.endereco?.inscricaoEstadual === null
            ) {
                setIsIsento(true);
            }

            reset({
                nome: contratante.nome,
                cnpj: contratante.cnpj,
                login: contratante.usuario.login,
                nomeFantasia: contratante
                    ? contratante.nomeFantasia
                    : undefined,
                celular: contratante.contato
                    ? contratante.contato.celular
                    : undefined,
                email: contratante.contato
                    ? contratante.contato.email
                    : undefined,
                telefone: contratante.contato
                    ? contratante.contato.telefone
                    : undefined,
                cep: contratante.endereco
                    ? contratante.endereco.cep
                    : undefined,
                estado: contratante.endereco
                    ? contratante.endereco.estado
                    : undefined,
                cidade: contratante.endereco
                    ? contratante.endereco.cidade
                    : undefined,
                bairro: contratante.endereco
                    ? contratante.endereco.bairro
                    : undefined,
                logradouro: contratante.endereco
                    ? contratante.endereco.logradouro
                    : undefined,
                complemento: contratante.endereco
                    ? contratante.endereco.complemento
                    : undefined,
                numero: contratante.endereco
                    ? contratante.endereco.numero
                    : undefined,
                ibge: contratante.endereco
                    ? contratante.endereco.ibge
                    : undefined,
                inscricaoEstadual: contratante.endereco
                    ? contratante.endereco.inscricaoEstadual
                    : undefined,
                inscricaoMunicipal: contratante.endereco
                    ? contratante.endereco.inscricaoMunicipal
                    : undefined,
            });
        };

        if (contratante) {
            preencherFormulario(contratante);
        }
    }, [reset, contratante, disabled]);

    const tratarDados = (dadosFormulario: CamposFormulario) => {
        if (dadosFormulario.senha) {
            if (
                !Util.temNumeroECaracteresEspeciais(dadosFormulario.senha) ||
                !Util.temLetraMaiuscula(dadosFormulario.senha)
            ) {
                setMensagemErroPadraoSenha(
                    'A senha deve conter ao menos 1 número, 1 caractere especial e 1 letra maiúscula.'
                );
                return;
            }
        }

        if (onSubmit) {
            const {
                cep,
                bairro,
                logradouro,
                cidade,
                estado,
                numero,
                complemento,
                ibge,
                inscricaoEstadual,
                inscricaoMunicipal,
                login,
                senha,
                email,
                telefone,
                celular,
                ...resto
            } = dadosFormulario;

            let enderecoFormat: Endereco | null = getEndereco(
                cep,
                cidade,
                estado,
                logradouro,
                bairro,
                numero,
                complemento,
                ibge,
                inscricaoEstadual,
                inscricaoMunicipal
            );

            let contatoFormat: Contato | null = getContato(
                email,
                celular,
                telefone
            );

            const dadosFormTratados: CamposFormularioTratados = {
                ...resto,
                endereco: enderecoFormat,
                usuario: {
                    login,
                    senha: !senha && senha === '' ? null : senha,
                },
                contato: contatoFormat,
            };
            onSubmit(dadosFormTratados);
        }
    };

    const getEndereco = (
        cep: string | null,
        cidade: string | null,
        estado: string | null,
        logradouro: string | null,
        bairro: string | null,
        numero: string | null,
        complemento: string | null,
        ibge: string | null,
        inscricaoEstadual?: string,
        inscricaoMunicipal?: string,
        id?: number
    ) => {
        if (
            bairro === '' &&
            cep === '' &&
            logradouro === '' &&
            complemento === '' &&
            numero === '' &&
            cidade === '' &&
            estado === '' &&
            inscricaoEstadual === '' &&
            inscricaoMunicipal === ''
        ) {
            return null;
        } else {
            const enderecoRetornar: Endereco = {
                cidade: cidade,
                estado: estado,
                bairro: bairro,
                cep: cep,
                logradouro: logradouro,
                complemento: complemento,
                numero: numero,
                ibge: ibge,
                id: id,
                inscricaoEstadual: inscricaoEstadual,
                inscricaoMunicipal: inscricaoMunicipal,
            };
            return enderecoRetornar;
        }
    };

    const getContato = (
        email?: string,
        celular?: string,
        telefone?: string
    ) => {
        if (email === '' && celular === '' && telefone === '') return null;
        else {
            const contatoRetornar: Contato = {
                email: email,
                celular: celular,
                telefone: telefone,
            };
            return contatoRetornar;
        }
    };

    const buscarCep = async (cep: string) => {
        try {
            const resposta = await serviceViaCep.buscarEndereco(cep);

            if (resposta.status === StatusRequisicao.OK) {
                setValue('cidade', resposta?.data?.localidade);
                setValue('estado', resposta?.data?.uf);
                setValue('logradouro', resposta?.data?.logradouro);
                setValue('bairro', resposta?.data?.bairro);
                setValue('ibge', resposta?.data?.ibge);

                if (
                    refInputNumero !== null &&
                    refInputNumero.current !== null
                ) {
                    refInputNumero.current.focus();
                }
            }
        } catch (error) { }
    };

    return (
        <Container>
            <form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(tratarDados)}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="nome"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        required
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        label="Nome"
                                        autoFocus
                                        error={!!errors.nome}
                                        helperText={
                                            errors.nome
                                                ? errors.nome.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="cnpj"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <InputCnpj
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        required
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        label="CNPJ"
                                        error={!!errors.cnpj}
                                        helperText={
                                            errors.cnpj
                                                ? errors.cnpj.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="nomeFantasia"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        required
                                        disabled={disabled}
                                        fullWidth
                                        label="Nome fantasia"
                                        error={!!errors.nomeFantasia}
                                        helperText={
                                            errors.nomeFantasia
                                                ? errors.nomeFantasia.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>

                <DividerComp texto="Inscrições" />

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <FormControl>
                            <FormGroup>
                                <FormControlLabel
                                    id="smart-check-box"
                                    value={TipoInscricao.Isento}
                                    control={
                                        <Checkbox
                                            disabled={disabled}
                                            value={TipoInscricao.Isento}
                                            inputProps={{
                                                'aria-label':
                                                    TipoInscricao.Isento,
                                            }}
                                            checked={isIsento}
                                            onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>
                                            ) => {
                                                setIsIsento(
                                                    event.target.checked
                                                );
                                                if (isIsento === true) {
                                                    setValue(
                                                        'inscricaoMunicipal',
                                                        ''
                                                    );
                                                    setValue(
                                                        'inscricaoEstadual',
                                                        ''
                                                    );
                                                } else {
                                                    setValue(
                                                        'inscricaoMunicipal',
                                                        'isento'
                                                    );
                                                    setValue(
                                                        'inscricaoEstadual',
                                                        'isento'
                                                    );
                                                }
                                            }}
                                        />
                                    }
                                    label="Isento"
                                />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="inscricaoMunicipal"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        disabled={isIsento || disabled}
                                        fullWidth
                                        label="Inscrição municipal"
                                        inputProps={{ maxLength: 15 }}
                                        error={!!errors.inscricaoMunicipal}
                                        helperText={
                                            errors.inscricaoMunicipal
                                                ? errors.inscricaoMunicipal
                                                    .message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="inscricaoEstadual"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        disabled={isIsento || disabled}
                                        fullWidth
                                        inputProps={{ maxLength: 15 }}
                                        label="Inscrição estadual"
                                        error={!!errors.inscricaoEstadual}
                                        helperText={
                                            errors.inscricaoEstadual
                                                ? errors.inscricaoEstadual
                                                    .message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>

                <DividerComp texto="Login no portal" />

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="login"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        required
                                        fullWidth
                                        disabled={disabled}
                                        label="Usuário"
                                        error={!!errors.login}
                                        helperText={
                                            errors.login
                                                ? errors.login.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="senha"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        fullWidth
                                        required
                                        label="Senha"
                                        disabled={disabled}
                                        type={showPassword ? 'text' : 'password'}
                                        id="password"
                                        autoComplete="current-password"
                                        error={!!errors.senha}
                                        helperText={
                                            errors.senha
                                                ? errors.senha.message
                                                : ''
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={
                                                            handleClickShowPassword
                                                        }
                                                        onMouseDown={
                                                            handleMouseDownPassword
                                                        }
                                                        edge="end"
                                                    >
                                                        {showPassword ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>

                {mensagemErroPadraoSenha && (
                    <p style={styles().error}>
                        {mensagemErroPadraoSenha}
                    </p>
                )}

                <DividerComp texto="Endereço" />

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={3}>
                        <Controller
                            name="cep"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        required
                                        disabled={disabled}
                                        fullWidth
                                        onChange={(
                                            event: React.ChangeEvent<
                                                | HTMLTextAreaElement
                                                | HTMLInputElement
                                            >
                                        ) => {
                                            event.currentTarget.value =
                                                event.currentTarget.value =
                                                event.currentTarget.value.replace(
                                                    /[^0-9]/g,
                                                    ''
                                                );

                                            field.onChange(event);
                                        }}
                                        label="CEP"
                                        onBlur={(event) => {
                                            buscarCep(event.target.value);
                                        }}
                                        error={!!errors.cep}
                                        helperText={
                                            errors.cep ? errors.cep.message : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="cidade"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        required
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        label="Cidade"
                                        error={!!errors.cidade}
                                        helperText={
                                            errors.cidade
                                                ? errors.cidade.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <Controller
                            name="estado"
                            control={control}
                            defaultValue=""
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        required
                                        disabled={disabled}
                                        fullWidth
                                        label="Estado"
                                        error={!!errors.estado}
                                        helperText={
                                            errors.estado
                                                ? errors.estado.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="logradouro"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        required
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        label="Logradouro"
                                        error={!!errors.logradouro}
                                        helperText={
                                            errors.logradouro
                                                ? errors.logradouro.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Controller
                            name="bairro"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        required
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        label="Bairro"
                                        error={!!errors.bairro}
                                        helperText={
                                            errors.bairro
                                                ? errors.bairro.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <Controller
                            name="numero"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        margin="normal"
                                        required
                                        inputRef={refInputNumero}
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        label="Número"
                                        error={!!errors.numero}
                                        helperText={
                                            errors.numero
                                                ? errors.numero.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="complemento"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        label="Complemento"
                                        error={!!errors.complemento}
                                        helperText={
                                            errors.complemento
                                                ? errors.complemento.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>

                <DividerComp texto="Contato" />

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        label="Email"
                                        error={!!errors.email}
                                        helperText={
                                            errors.email
                                                ? errors.email.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="telefone"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <InputTelefone
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        label="Telefone"
                                        error={!!errors.telefone}
                                        helperText={
                                            errors.telefone
                                                ? errors.telefone.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="celular"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <InputCelular
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        label="Celular"
                                        error={!!errors.celular}
                                        helperText={
                                            errors.celular
                                                ? errors.celular.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2} justifyContent="end">
                    {!disabled ? (
                        <Button
                            type="submit"
                            variant="contained"
                            style={styles().button}
                            size="large"
                        >
                            Salvar
                        </Button>
                    ) : (
                        <Button
                            onClick={() => {
                                navigate(
                                    FrontEndPathResources.Contratante +
                                    '/alterar/' +
                                    contratante?.id
                                );
                            }}
                            variant="contained"
                            style={styles().button}
                            size="large"
                        >
                            Alterar
                        </Button>
                    )}
                </Grid>
            </form>
        </Container>
    );
}
