import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';
import Embarque from '../../Models/Embarque';
import Upload from '../../Components/Upload/Upload';
import { useState } from 'react';
import NotaFiscal from '../../Models/NotaFiscal';
import { Arquivo } from './Listar';
import { styles } from './helpers/Styles';

interface Props {
    embarque: Embarque;
    fecharClicado: () => void;
    aberto: boolean;
    enviarAnexoClicado: (notaFiscal: NotaFiscal) => void;
}

export default function ModalAnexarNota({
    embarque,
    fecharClicado,
    aberto = false,
    enviarAnexoClicado,
}: Props) {
    const [notaFiscal, setNotaFiscal] = useState<NotaFiscal>();

    const [arquivoSelecionado, setArquivoSelecionado] = useState<Arquivo>();

    const tratarDados = () => {
        if (notaFiscal) {
            enviarAnexoClicado(notaFiscal);
        }
    };

    return (
        <Dialog
            open={aberto}
            onClose={() => fecharClicado()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Anexar nota fiscal.
            </DialogTitle>
            <DialogContent>
                <Box sx={{ bgcolor: 'white', p: 2, }} >
                    <Upload
                        setFile={(file) => {
                            setArquivoSelecionado(file);

                            let fileToLoad = file;
                            let fileReader = new FileReader();

                            fileReader.onload = function (fileLoadedEvent) {
                                file = fileLoadedEvent!.target!.result;
                                setNotaFiscal(
                                    new NotaFiscal(embarque.id, file)
                                );
                            };
                            fileReader.readAsDataURL(fileToLoad);
                        }}
                        arquivosSuportados={'.pdf, .xml'}
                        mensagemIsDragAtivo="Arraste a nota fiscal aqui."
                        mensagemPadrao="Araste a nota fiscal aqui ou clique para selecionar do
                        computador"
                    />
                    {arquivoSelecionado ? (
                        <List>
                            <ListItem key={arquivoSelecionado.name}>
                                <ListItemText
                                    primary={`${arquivoSelecionado.name}`}
                                />
                            </ListItem>
                        </List>
                    ) : (
                        <></>
                    )}

                    <Button
                        style={styles().button}
                        variant="contained"
                        type="submit"
                        onClick={() => {
                            tratarDados();
                        }}
                    >
                        Enviar
                    </Button>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => fecharClicado()} autoFocus>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
