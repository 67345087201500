import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { List, ListItem, ListItemText } from '@mui/material';
import PerfilUsuario from '../../Models/PerfilUsuario';
import { styles } from './helpers/Styles';

export default function useAuditoriaCamposPerfilUsuario() {
    const getItensAlterados = (
        perfilUsuarioAtual: PerfilUsuario,
        perfilUsuarioAnterior: PerfilUsuario
    ) => {
        return (
            <List sx={styles().listStyle}>
                {perfilUsuarioAtual.nome !== perfilUsuarioAnterior.nome && (
                    <ListItem>
                        <ListItemText
                            primary="Nome"
                            secondary={
                                <div style={styles().listItemText}>
                                    {perfilUsuarioAnterior.nome}
                                    <ArrowForwardIcon
                                        fontSize="small"
                                        style={styles().arrowForwardIcon}
                                    />{' '}
                                    {perfilUsuarioAtual.nome}
                                </div>
                            }
                        />
                    </ListItem>
                )}
            </List>
        );
    };

    return {
        getItensAlterados,
    };
}
