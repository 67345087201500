import { Grid, InputAdornment, TextField } from '@mui/material';
import { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IDoubleCheck } from '../entities/IDoubleCheck';
import Util from '../../../Helpers/Util';

interface OrdemColetaFormProps {}

const OrdemColetaForm: FunctionComponent<OrdemColetaFormProps> = () => {
    const { control } = useFormContext<IDoubleCheck>();

    return (
        <Grid container item spacing={2}>
            <Grid item xs={12} lg={2}>
                <Controller
                    name="coleta.id"
                    control={control}
                    defaultValue={0}
                    render={({
                        field: { ref, onChange, onBlur, value, ...field },
                        fieldState: { error },
                    }) => (
                        <TextField
                            {...field}
                            margin="normal"
                            fullWidth
                            value={value}
                            label="Número ordem coleta"
                            error={!!error?.message}
                            helperText={error?.message ? error?.message : ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={onChange}
                            disabled={true}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} lg={2}>
                <Controller
                    name="coleta.peso"
                    control={control}
                    defaultValue={0}
                    render={({
                        field: { ref, onChange, onBlur, value, ...field },
                        fieldState: { error },
                    }) => (
                        <TextField
                            {...field}
                            margin="normal"
                            fullWidth
                            value={Util.formatNumberWithKG(value)}
                            label="Peso estimado"
                            error={!!error?.message}
                            helperText={error?.message ? error?.message : ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={onChange}
                            disabled={true}
                            InputProps={{
                                endAdornment: <InputAdornment disableTypography position="end">KG</InputAdornment>,
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} lg={2}>
                <Controller
                    name="coleta.produto"
                    control={control}
                    defaultValue={''}
                    render={({
                        field: { ref, onChange, onBlur, value, ...field },
                        fieldState: { error },
                    }) => (
                        <TextField
                            {...field}
                            margin="normal"
                            fullWidth
                            value={value}
                            label="Produto"
                            error={!!error?.message}
                            helperText={error?.message ? error?.message : ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={onChange}
                            disabled={true}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} lg={2}>
                <Controller
                    name="coleta.lote"
                    control={control}
                    defaultValue={0}
                    render={({
                        field: { ref, onChange, onBlur, value, ...field },
                        fieldState: { error },
                    }) => (
                        <TextField
                            {...field}
                            margin="normal"
                            fullWidth
                            value={value}
                            label="Lote embarque"
                            error={!!error?.message}
                            helperText={error?.message ? error?.message : ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={onChange}
                            disabled={true}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
};

export default OrdemColetaForm;
