import { Grid } from '@mui/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import InputKG from '../../../../../Components/Input/InputKG';
import { IOrdemColetaLancamento } from '../../../domain/dtos/IOrdemColetaLancamento';
import { useEffect } from 'react';

export function LancarDadosEmbarqueForm() {
    const { control, trigger, setValue, getValues } =
        useFormContext<IOrdemColetaLancamento>();
    const { pesoBruto, tara } = useWatch({ control });
    function calcPesoLiquido() {
        const taraValue = getValues().tara;
        const pesoBrutoValue = getValues().pesoBruto;

        const pesoLiquido = Number(pesoBrutoValue) - Number(taraValue);
        if (!isNaN(pesoLiquido)) {
            setValue('pesoLiquido', pesoLiquido);
        }
        if (taraValue || pesoBrutoValue) {
            trigger('tara');
            trigger('pesoBruto');
            trigger('pesoLiquido');
        }
    }

    useEffect(() => {
        calcPesoLiquido();
    }, [pesoBruto, tara]);

    return (
        <Grid container item spacing={2}>
            <Grid item xs={12} lg={3}>
                <Controller
                    name="tara"
                    control={control}
                    render={({
                        field: { ref, value, ...field },
                        fieldState: { error },
                    }) => {
                        return (
                            <InputKG
                                {...field}
                                margin="normal"
                                required
                                value={value}
                                fullWidth
                                label={'Tara'}
                                error={!!error?.message}
                                helperText={
                                    error?.message ? error?.message : ''
                                }
                                InputLabelProps={{
                                    shrink: !!value,
                                }}
                            />
                        );
                    }}
                />
            </Grid>
            <Grid item xs={12} lg={3}>
                <Controller
                    name="pesoBruto"
                    control={control}
                    render={({
                        field: { ref, value, ...field },
                        fieldState: { error },
                    }) => {
                        return (
                            <InputKG
                                {...field}
                                margin="normal"
                                inputRef={ref}
                                required
                                value={value}
                                fullWidth
                                label={'Peso Bruto'}
                                error={!!error?.message}
                                helperText={
                                    error?.message ? error?.message : ''
                                }
                                InputLabelProps={{
                                    shrink: !!value,
                                }}
                            />
                        );
                    }}
                />
            </Grid>
            <Grid item xs={12} lg={3}>
                <Controller
                    name="pesoLiquido"
                    control={control}
                    render={({
                        field: { ref, value, ...field },
                        fieldState: { error },
                    }) => {
                        return (
                            <InputKG
                                {...field}
                                margin="normal"
                                inputRef={ref}
                                value={value}
                                fullWidth
                                disabled
                                label={'Peso Líquido'}
                                error={!!error?.message}
                                helperText={
                                    error?.message ? error?.message : ''
                                }
                                InputLabelProps={{
                                    shrink: !!value || value === 0,
                                }}
                            />
                        );
                    }}
                />
            </Grid>
        </Grid>
    );
}
