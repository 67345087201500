import {
    Divider,
    List,
    ListItem,
    ListItemText,

} from '@mui/material';
import OrdemCarregamento from '../../Models/OrdemCarregamento';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Embarque from '../../Models/Embarque';
import { styles } from './helpers/Styles';

export default function useAuditoriaCamposOrdem() {

    function getEmbarquesAdicionados(embarquesAnteriores?: Embarque[],
        embarquesAtuais?: Embarque[]): Embarque[] {
        var embarquesA: Embarque[] = []
        if (embarquesAnteriores && embarquesAtuais) {
            embarquesAtuais.forEach(embarqueAtual => {
                if (embarquesAnteriores
                    .filter(embarqueAnterior => embarqueAnterior.id === embarqueAtual.id)
                    .length === 0) {

                    embarquesA.push(embarqueAtual)
                }
            })
        }
        return embarquesA;

    }

    function getEmbarquesCancelados(embarquesAnteriores?: Embarque[],
        embarquesAtuais?: Embarque[]): Embarque[] {
        var embarquesC: Embarque[] = []

        if (embarquesAnteriores && embarquesAtuais) {
            embarquesAnteriores.forEach(embarqueAnterior => {
                if (embarquesAtuais
                    .filter(embarqueAtual => embarqueAnterior.id === embarqueAtual.id &&
                        embarqueAnterior.deletado !== embarqueAtual.deletado)
                    .length === 1) {
                    embarquesC.push(embarqueAnterior)
                }
            })
        }

        return embarquesC;

    }

    const getItensAlterados = (
        ordemCarregamentoAtual: OrdemCarregamento,
        ordemCarregamentoAnterior: OrdemCarregamento
    ) => {

        return (
            <List sx={styles().listAuditoria}>
                {ordemCarregamentoAtual.pesoPlanejado !==
                    ordemCarregamentoAnterior.pesoPlanejado && (
                        <ListItem>
                            <ListItemText
                                primary="Peso planejado"
                                secondary={
                                    <div style={styles().divListItemText}>
                                        {ordemCarregamentoAnterior.pesoPlanejado} KG{' '}
                                        <ArrowForwardIcon
                                            fontSize="small"
                                            style={styles().arrowForwardIcon}
                                        />{' '}
                                        {ordemCarregamentoAtual.pesoPlanejado} KG
                                    </div>
                                }
                            />
                        </ListItem>
                    )}

                {ordemCarregamentoAtual.produto.id !==
                    ordemCarregamentoAnterior.produto.id && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Produto"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {ordemCarregamentoAnterior.produto.id} (
                                            {ordemCarregamentoAnterior.produto.nome}
                                            ){' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {ordemCarregamentoAtual.produto.id} (
                                            {ordemCarregamentoAtual.produto.nome})
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {ordemCarregamentoAnterior.clifor.id !==
                    ordemCarregamentoAtual.clifor.id && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Cliente/Fornecedor"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {ordemCarregamentoAnterior.clifor.nome}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {ordemCarregamentoAtual.clifor.nome}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {ordemCarregamentoAnterior.transportadora_id !==
                    ordemCarregamentoAtual.transportadora_id &&
                    (ordemCarregamentoAnterior.transportadora ||
                        ordemCarregamentoAtual.transportadora) && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Transportadora"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {ordemCarregamentoAnterior.transportadora
                                                ? ordemCarregamentoAnterior
                                                    .transportadora.nome
                                                : ''}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {ordemCarregamentoAtual.transportadora
                                                ? ordemCarregamentoAtual
                                                    .transportadora.nome
                                                : ''}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {ordemCarregamentoAnterior.codigoContrato !==
                    ordemCarregamentoAtual.codigoContrato && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Contrato"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                ordemCarregamentoAnterior.codigoContrato
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {ordemCarregamentoAtual.codigoContrato}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {ordemCarregamentoAnterior.classificador.id !==
                    ordemCarregamentoAtual.classificador.id && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Classificador"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                ordemCarregamentoAnterior
                                                    .classificador.id
                                            }{' '}
                                            (
                                            {
                                                ordemCarregamentoAnterior
                                                    .classificador.nome
                                            }
                                            )
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                ordemCarregamentoAtual.classificador
                                                    .id
                                            }{' '}
                                            (
                                            {
                                                ordemCarregamentoAtual.classificador
                                                    .nome
                                            }
                                            )
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {ordemCarregamentoAnterior.localClassificacao !==
                    ordemCarregamentoAtual.localClassificacao && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Local da classificação"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                ordemCarregamentoAnterior.localClassificacao
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                ordemCarregamentoAtual.localClassificacao
                                            }{' '}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {ordemCarregamentoAnterior.tipoNegociacao !==
                    ordemCarregamentoAtual.tipoNegociacao && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Tipo de negociação"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                ordemCarregamentoAnterior.tipoNegociacao
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {ordemCarregamentoAtual.tipoNegociacao}{' '}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {ordemCarregamentoAnterior.enderecamentoOrigem.bairro !==
                    ordemCarregamentoAtual.enderecamentoOrigem.bairro && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereço origem - Bairro"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                ordemCarregamentoAnterior
                                                    .enderecamentoOrigem.bairro
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                ordemCarregamentoAtual
                                                    .enderecamentoOrigem.bairro
                                            }{' '}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {ordemCarregamentoAnterior.enderecamentoOrigem.cidade !==
                    ordemCarregamentoAtual.enderecamentoOrigem.cidade && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereço origem - Cidade"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                ordemCarregamentoAnterior
                                                    .enderecamentoOrigem.cidade
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                ordemCarregamentoAtual
                                                    .enderecamentoOrigem.cidade
                                            }{' '}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {ordemCarregamentoAnterior.enderecamentoOrigem.cep !==
                    ordemCarregamentoAtual.enderecamentoOrigem.cep && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereço origem - CEP"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                ordemCarregamentoAnterior
                                                    .enderecamentoOrigem.cep
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                ordemCarregamentoAtual
                                                    .enderecamentoOrigem.cep
                                            }{' '}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {ordemCarregamentoAnterior.enderecamentoOrigem.complemento !==
                    ordemCarregamentoAtual.enderecamentoOrigem.complemento && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereço origem - Complemento"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                ordemCarregamentoAnterior
                                                    .enderecamentoOrigem.complemento
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                ordemCarregamentoAtual
                                                    .enderecamentoOrigem.complemento
                                            }{' '}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {ordemCarregamentoAnterior.enderecamentoOrigem.estado !==
                    ordemCarregamentoAtual.enderecamentoOrigem.estado && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereço origem - Estado"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                ordemCarregamentoAnterior
                                                    .enderecamentoOrigem.estado
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                ordemCarregamentoAtual
                                                    .enderecamentoOrigem.estado
                                            }{' '}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {ordemCarregamentoAnterior.enderecamentoOrigem.logradouro !==
                    ordemCarregamentoAtual.enderecamentoOrigem.logradouro && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereço origem - Logradouro"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                ordemCarregamentoAnterior
                                                    .enderecamentoOrigem.logradouro
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                ordemCarregamentoAtual
                                                    .enderecamentoOrigem.logradouro
                                            }{' '}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {ordemCarregamentoAnterior.enderecamentoOrigem.numero !==
                    ordemCarregamentoAtual.enderecamentoOrigem.numero && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereço origem - Número"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                ordemCarregamentoAnterior
                                                    .enderecamentoOrigem.numero
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                ordemCarregamentoAtual
                                                    .enderecamentoOrigem.numero
                                            }{' '}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {ordemCarregamentoAnterior.enderecamentoDestino.bairro !==
                    ordemCarregamentoAtual.enderecamentoDestino.bairro && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereço destino - Bairro"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                ordemCarregamentoAnterior
                                                    .enderecamentoDestino.bairro
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                ordemCarregamentoAtual
                                                    .enderecamentoDestino.bairro
                                            }{' '}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {ordemCarregamentoAnterior.enderecamentoDestino.cidade !==
                    ordemCarregamentoAtual.enderecamentoDestino.cidade && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereço destino - Cidade"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                ordemCarregamentoAnterior
                                                    .enderecamentoDestino.cidade
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                ordemCarregamentoAtual
                                                    .enderecamentoDestino.cidade
                                            }{' '}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {ordemCarregamentoAnterior.enderecamentoDestino.cep !==
                    ordemCarregamentoAtual.enderecamentoDestino.cep && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereço destino - CEP"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                ordemCarregamentoAnterior
                                                    .enderecamentoDestino.cep
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                ordemCarregamentoAtual
                                                    .enderecamentoDestino.cep
                                            }{' '}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {ordemCarregamentoAnterior.enderecamentoDestino.complemento !==
                    ordemCarregamentoAtual.enderecamentoDestino.complemento && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereço destino - Complemento"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                ordemCarregamentoAnterior
                                                    .enderecamentoDestino
                                                    .complemento
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                ordemCarregamentoAtual
                                                    .enderecamentoDestino
                                                    .complemento
                                            }{' '}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {ordemCarregamentoAnterior.enderecamentoDestino.estado !==
                    ordemCarregamentoAtual.enderecamentoDestino.estado && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereço destino - Estado"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                ordemCarregamentoAnterior
                                                    .enderecamentoDestino.estado
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                ordemCarregamentoAtual
                                                    .enderecamentoDestino.estado
                                            }{' '}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {ordemCarregamentoAnterior.enderecamentoDestino.logradouro !==
                    ordemCarregamentoAtual.enderecamentoDestino.logradouro && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereço destino - Logradouro"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                ordemCarregamentoAnterior
                                                    .enderecamentoDestino.logradouro
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                ordemCarregamentoAtual
                                                    .enderecamentoDestino.logradouro
                                            }{' '}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {ordemCarregamentoAnterior.enderecamentoDestino.numero !==
                    ordemCarregamentoAtual.enderecamentoDestino.numero && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereço destino - Número"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                ordemCarregamentoAnterior
                                                    .enderecamentoDestino.numero
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                ordemCarregamentoAtual
                                                    .enderecamentoDestino.numero
                                            }{' '}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {ordemCarregamentoAnterior.status !==
                    ordemCarregamentoAtual.status && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Status da ordem"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                ordemCarregamentoAnterior.status
                                            }{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                ordemCarregamentoAtual.status
                                            }{' '}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {ordemCarregamentoAnterior.transportadora?.id !==
                    ordemCarregamentoAtual.transportadora?.id && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Transportadora"
                                    secondary={
                                        <div style={styles().divListItemText}>
                                            {
                                                ordemCarregamentoAnterior
                                                    .transportadora?.id
                                            }{' '}
                                            (
                                            {
                                                ordemCarregamentoAnterior
                                                    .transportadora?.nome
                                            }
                                            )
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {
                                                ordemCarregamentoAtual.transportadora?.id
                                            }{' '}
                                            (
                                            {
                                                ordemCarregamentoAtual.transportadora?.nome
                                            }
                                            )
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {getEmbarquesAdicionados(ordemCarregamentoAnterior.embarques,
                    ordemCarregamentoAtual.embarques).map(e => {
                        return (
                            <>
                                <Divider variant="inset" component="li" />
                                <ListItem>
                                    <ListItemText primary="Embarque Realizado"
                                        secondary={
                                            <div style={styles().divEmbarques}>
                                                {"Id: " + e.id + ", Motorista:"}
                                                {e.motorista !== null ? e.motorista.nome !== null ? e.motorista.nome : "" : ""}
                                                {", Placa Veículo: "}
                                                {e.caminhao !== null ? e.caminhao.placaVeiculo !== null ? e.caminhao.placaVeiculo : "" : ""}
                                                {"\n"}
                                            </div>
                                        } />
                                </ListItem>
                            </>
                        )
                    })}

                {getEmbarquesCancelados(ordemCarregamentoAnterior.embarques,
                    ordemCarregamentoAtual.embarques).map(e => {
                        return (
                            <>
                                <Divider variant="inset" component="li" />
                                <ListItem>
                                    <ListItemText primary="Embarque Cancelado"
                                        secondary={
                                            <div style={styles().divEmbarques}>
                                                {"Id: " + e.id + ", Motorista:"}
                                                {e.motorista !== null ? e.motorista.nome !== null ? e.motorista.nome : "" : ""}
                                                {", Placa Veículo: "}
                                                {e.caminhao !== null ? e.caminhao.placaVeiculo !== null ? e.caminhao.placaVeiculo : "" : ""}
                                                {"\n"}
                                            </div>
                                        } />
                                </ListItem>
                            </>
                        )
                    })}

            </List >
        );
    };

    return {
        getItensAlterados,
    };
}
