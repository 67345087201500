import { Container, Grid } from '@mui/material';
import { Dispatch, FunctionComponent, useCallback } from 'react';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';

const Upload: FunctionComponent<{
    setFile: Dispatch<any>;
    arquivosSuportados: string;
    mensagemIsDragAtivo: string;
    mensagemPadrao: string;
    onArquivoRejeitado?: () => void;
}> = ({
    setFile,
    arquivosSuportados,
    mensagemIsDragAtivo,
    mensagemPadrao,
    onArquivoRejeitado,
}) => {
    const onDrop = useCallback(
        (acceptedFiles, _: FileRejection[], event: DropEvent) => {
            setFile(acceptedFiles[0]);
        },
        [setFile]
    );

    const { getRootProps, getInputProps, isDragActive, isDragReject } =
        useDropzone({
            onDrop,
            multiple: false,
            accept: arquivosSuportados,
            onDropRejected: onArquivoRejeitado ? onArquivoRejeitado : undefined,
        });
    return (
        <Container
            sx={{
                color: '#B8D7F6',
                bgcolor: '#FAFAFA',
                border: '2px dashed #B8D7F6',
            }}
            {...getRootProps()}
        >
            {isDragActive ? (
                <Grid item xs={12}>
                    <p id="modal-modal-title">{mensagemIsDragAtivo}</p>
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <p id="modal-modal-title">{mensagemPadrao}</p>
                </Grid>
            )}
            {isDragReject ? (
                <Grid item xs={12}>
                    <p id="modal-modal-description">
                        Arquivo não suportado. Arquivos suportados:{' '}
                        {arquivosSuportados}
                    </p>
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <p id="modal-modal-description">
                        Arquivos suportados: {arquivosSuportados}
                    </p>
                </Grid>
            )}
            <input {...getInputProps()} />
        </Container>
    );
};

export default Upload;
