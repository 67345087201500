import TextField, { StandardTextFieldProps } from '@mui/material/TextField';
import { ChangeEvent } from 'react';
import InputMask from 'react-input-mask';

type Props = StandardTextFieldProps;

export const maskMoeda = (value: string): string => {
    if (value == null) return '';

    return value
            .replace(/\D/g, '')
            .replace(/(\d)(\d{2})$/, '$1,$2')
            .replace(/(?=(\d{3})+(\D))\B/g, '.')
            .replace(/(.)/, 'R$ $1');
};

export default function InputMoeda({ label = 'R$ 0,00', ...props }: Props) {
    const {
        onBlur,
        value,
        onChange,
        disabled,
        inputRef,
        ...outrasPropriedades
    } = props;

    return (
        <InputMask
            mask=""
            maskPlaceholder={null}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                if (onChange) {
                    onChange({
                        ...event,
                        target: { value: maskMoeda(event.target.value) },
                    } as ChangeEvent<HTMLInputElement>);
                }
            }}
            value={props?.value as string}
            disabled={disabled}
        >
            <TextField
                label={label}
                inputProps={{ autoComplete: 'off' }}
                {...outrasPropriedades}
            />
        </InputMask>
    );
}
