import { StandardTextFieldProps, TextField } from '@mui/material';
import InputMask from 'react-input-mask';

type Props = StandardTextFieldProps;

export default function InputPlaca({ label = 'CPF', ...props }: Props) {
    const {
        onBlur,
        value,
        onChange,
        disabled,
        inputRef,
        ...outrasPropriedades
    } = props;

    return (
        <InputMask
            mask={[
                /[A-Za-z]/,
                /[A-Za-z]/,
                /[A-Za-z]/,
                /\d/,
                /[A-Za-z\d]/,
                /\d/,
                /\d/,
            ]}
            maskPlaceholder={null}
            onChange={onChange}
            onBlur={onBlur}
            value={value as string}
            disabled={disabled}
        >
            <TextField
                label={label}
                inputProps={{ autoComplete: 'off' }}
                {...outrasPropriedades}
                inputRef={inputRef}
                fullWidth
            />
        </InputMask>
    );
}
