import {
    List,
    ListItem,
    ListItemText,

} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { styles } from './helpers/Styles';

export default function useAuditoriaCamposOrdemColeta() {

    const getItensAlterados = (
        ordemColetaAtual: any,
        ordemColetaAnterior: any
    ) => {

        return (
            <List sx={styles().listAuditoria}>
                {ordemColetaAtual?.cadastradaViaPortal !==
                    ordemColetaAnterior?.cadastradaViaPortal && (
                        <ListItem>
                            <ListItemText
                                primary="Cadastrada via portal"
                                secondary={
                                    <div style={styles().divListItemText}>
                                        {ordemColetaAnterior.cadastradaViaPortal? "Sim": "Nao"}{' '}
                                        <ArrowForwardIcon
                                            fontSize="small"
                                            style={styles().arrowForwardIcon}
                                        />{' '}
                                        {ordemColetaAtual.cadastradaViaPortal? "Sim": "Nao"} 
                                    </div>
                                }
                            />
                        </ListItem>
                    )}
                

                {
                    ordemColetaAtual?.cavalo?.id !== ordemColetaAnterior?.cavalo?.id &&(
                        <ListItem>
                            <ListItemText
                                primary="Cavalo"
                                secondary={
                                    <>
                                        <div style={styles().divListItemText}>
                                            {ordemColetaAnterior?.cavalo?.placa}
                                            {' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {ordemColetaAtual?.cavalo?.placa}
                                        </div>
                                         <div style={styles().divListItemText}>
                                            {ordemColetaAnterior?.cavalo?.proprietarioDocumento}
                                            {' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {ordemColetaAtual?.cavalo?.proprietarioDocumento}
                                        </div>
                                         <div style={styles().divListItemText}>
                                            {ordemColetaAnterior?.cavalo?.proprietarioNome}
                                            {' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {ordemColetaAtual?.cavalo?.proprietarioNome}
                                        </div>
                                         <div style={styles().divListItemText}>
                                            {ordemColetaAnterior?.cavalo?.renavam}
                                            {' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {ordemColetaAtual?.cavalo?.renavam}
                                        </div>
                                        <div style={styles().divListItemText}>
                                            {ordemColetaAnterior?.cavalo?.rntrc}
                                            {' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {ordemColetaAtual?.cavalo?.rntrc}
                                        </div>
                                        <div style={styles().divListItemText}>
                                            {ordemColetaAnterior?.cavalo?.rntrcDataVencimento}
                                            {' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {ordemColetaAtual?.cavalo?.rntrcDataVencimento}
                                        </div>
                                    </>
                                }
                            />
                        </ListItem>
                    )
                }
                


                {
                    ordemColetaAtual?.descricaoItem !== ordemColetaAnterior?.descricaoItem &&(
                           <ListItem>
                            <ListItemText
                                primary="Descrição Item"
                                secondary={ 
                                    <div style={styles().divListItemText}>
                                            {ordemColetaAnterior?.descricaoItem}
                                            {' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {ordemColetaAtual?.descricaoItem}
                                    </div>
                                }/>
                            </ListItem>
                    )
                }

                {
                    ordemColetaAtual?.motorista?.id !== ordemColetaAnterior?.motorista?.id &&(
                        <ListItem>
                            <ListItemText
                                primary="Motorista"
                                secondary={
                                    <>
                                        <div style={styles().divListItemText}>
                                            {ordemColetaAnterior?.motorista?.cnh}
                                            {' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {ordemColetaAtual?.motorista?.cnh}
                                        </div>
                                         <div style={styles().divListItemText}>
                                            {ordemColetaAnterior?.motorista?.cpf}
                                            {' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {ordemColetaAtual?.motorista?.cpf}
                                        </div>
                                         <div style={styles().divListItemText}>
                                            {ordemColetaAnterior?.motorista?.nome}
                                            {' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {ordemColetaAtual?.motorista?.nome}
                                        </div>
                                         <div style={styles().divListItemText}>
                                            {ordemColetaAnterior?.motorista?.uf}
                                            {' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {ordemColetaAtual?.motorista?.uf}
                                        </div>
                                        
                                    </>
                                }
                            />
                        </ListItem>
                    )
                }

                {
                    ordemColetaAtual?.numeroLoteGpe65 !== ordemColetaAnterior?.numeroLoteGpe65 && (
                        <ListItem>
                            <ListItemText
                                primary="Lote GPE65"
                                secondary={
                                    <div style={styles().divListItemText}>
                                            {ordemColetaAnterior?.numeroLoteGpe65}
                                            {' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {ordemColetaAtual?.numeroLoteGpe65}
                                    </div>
                                }
                            />
                        </ListItem>
                    )
                }

                {
                    ordemColetaAtual?.peso !== ordemColetaAnterior?.peso && (
                        <ListItem>
                            <ListItemText
                                primary="Peso"
                                secondary={
                                    <div style={styles().divListItemText}>
                                            {ordemColetaAnterior?.peso}
                                            {' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {ordemColetaAtual?.peso}
                                    </div>
                                }
                            />
                        </ListItem>
                    )
                }

                {
                    ordemColetaAtual?.statusOrdemColeta !== ordemColetaAnterior?.statusOrdemColeta && (
                        <ListItem>
                            <ListItemText
                                primary="Status"
                                secondary={
                                    <div style={styles().divListItemText}>
                                            {ordemColetaAnterior?.statusOrdemColeta}
                                            {' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().arrowForwardIcon}
                                            />{' '}
                                            {ordemColetaAtual?.statusOrdemColeta}
                                    </div>
                                }
                            />
                        </ListItem>
                    )
                }

            </List >
        );
    };

    return {
        getItensAlterados,
    };
}
