import { Container, Divider } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import PageTitle from '../../Components/PageTitle/PageTitle';
import SpinnerLoading from '../../Components/SpinnerLoading/SpinnerLoading';
import { MensagemErroRequisicaoApi } from '../../Config/Api';
import useGenericService from '../../hooks/useGenericService';
import { ApiResources, FrontEndPathResources } from '../../Models/Api';
import OrdemCarregamento from '../../Models/OrdemCarregamento';
import LoginResponse from '../../Models/responses/LoginResponse';
import Usuario, {
    ChavesArmazenamentoStorage,
    StatusRequisicao,
} from '../../Models/Usuario';
import Formulario, { CamposFormularioTratados } from './Formulario';
import { Status } from './Formulario';

type Params = {
    id: string;
};

export default function Alterar() {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [permiteEditarOrdem, setPermiteEditarOrdem] = useState<boolean>(true);

    const { id } = useParams<Params>();
    const [ordem, setOrdem] = useState<OrdemCarregamento>();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const { getService } = useGenericService();

    useEffect(() => {
        let loginResponse = localStorage.getItem(
            ChavesArmazenamentoStorage.LOGIN_RESPONSE
        );

        if (loginResponse) {
            const usuario: Usuario = (
                JSON.parse(loginResponse) as LoginResponse
            ).usuario;

            if (usuario.trocarSenha) {
                window.location.pathname = FrontEndPathResources.AlterarSenha;
            }
        }
    }, []);

    useEffect(() => {
        setIsLoading(true);
        const carregarOrdem = async () => {
            const servico = getService(ApiResources.OrdemCarregamento);

            try {
                const resposta = await servico.api.get('/' + id);

                if (resposta.status === StatusRequisicao.OK) {
                    if (
                        (resposta.data.status &&
                            resposta.data.status !== Status.ABERTO) ||
                        (resposta.data.embarques &&
                            resposta.data.embarques.length > 0)
                    ) {
                        setPermiteEditarOrdem(false);
                    }

                    setOrdem(resposta.data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    enqueueSnackbar('Não foi possível encontrar o registro', {
                        variant: 'error',
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
                }
            } catch (error) {
                setIsLoading(false);
            }
        };

        carregarOrdem();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, enqueueSnackbar]);

    const onSubmit = async (dadosFormulario: CamposFormularioTratados) => {
        setIsLoading(true);
        const servico = getService(ApiResources.OrdemCarregamento);

        try {
            const resultado = await servico.api.put('/' + id, dadosFormulario);

            if (resultado.status === StatusRequisicao.OK) {
                enqueueSnackbar(
                    'Ordem ' +
                        resultado.data.codigoContrato +
                        ' atualizado com sucesso!',
                    {
                        variant: 'success',
                        onClick: () => {
                            closeSnackbar();
                        },
                    }
                );
                navigate(FrontEndPathResources.OrdemCarregamento);
                setIsLoading(false);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            (error as MensagemErroRequisicaoApi[]).forEach((erro) => {
                enqueueSnackbar(erro.atributo + ': ' + erro.mensagem, {
                    variant: 'error',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
            });
        }
    };

    return (
        <SpinnerLoading isLoading={isLoading}>
            <Container>
                <PageTitle title={'Alterando ordem ' + id} />
                <Divider />
                {/* <Formulario
                    ordemCarregamento={ordem}
                    onSubmit={onSubmit}
                    naoPermitirEdicao={!permiteEditarOrdem}
                /> */}
            </Container>
        </SpinnerLoading>
    );
}
