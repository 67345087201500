import { StyleSheet } from "../../../StyleSheet";

export const styles = StyleSheet.create({
    button: {
        marginTop: 25,
        marginBottom: 15,
        backgroundColor: '#465f75',
    },
    grid: {
        marginTop: 20,
        marginBottom: 20,
    },
});
