import { Container, Divider, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import PageTitle from '../../../Components/PageTitle/PageTitle';
import SpinnerLoading from '../../../Components/SpinnerLoading/SpinnerLoading';
import { useFetchUseCase } from '../../../hooks/useFetchUseCase';
import { FindLoteTransportadoraById } from '../domain/usecases/FindLoteTransportadoraById';
import { LotePorTransportadoraDtoResponse } from '../types/LotePorTransportadoraDtoResponse';
import Formulario from './Formulario';

type Params = {
    id: string;
};

interface Props {
    findLoteTransportadoraById: FindLoteTransportadoraById;
}

export default function Ver({ findLoteTransportadoraById }: Props) {
    const { executePromise, loading } = useFetchUseCase();

    const { id } = useParams<Params>();

    const [loteEmbarque, setLoteEmbarque] =
        useState<LotePorTransportadoraDtoResponse>();

    const [mostrarModalAuditoria, setMostrarModalAuditoria] =
        useState<boolean>(false);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        executePromise(
            () => findLoteTransportadoraById.execute(Number(id)),
            (response: LotePorTransportadoraDtoResponse) => {
                setLoteEmbarque(response);
            },
            () => {
                enqueueSnackbar('Não foi possível encontrar o registro', {
                    variant: 'error',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
            }
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, enqueueSnackbar]);

    return (
        <SpinnerLoading isLoading={loading}>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={11}>
                        <PageTitle
                            title={
                                'Visualizando Lote Embarque ' +
                                loteEmbarque?.lote
                            }
                        />
                    </Grid>
                </Grid>
                <Divider />

                <Formulario disabled lote={loteEmbarque} />
            </Container>
        </SpinnerLoading>
    );
}
