import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export default class ViaCepService {
    private requestConfig: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        timeout: 10000,
        timeoutErrorMessage: 'Api não está respondendo.',
        responseType: 'json',
        withCredentials: false,
    };

    private api: AxiosInstance;
    private baseUrl: string;

    constructor() {
        this.baseUrl = 'https://viacep.com.br/ws/';

        this.requestConfig.baseURL = this.baseUrl;
        this.api = axios.create(this.requestConfig);
    }

    buscarEndereco(cep: string): Promise<AxiosResponse> {
        const cepSemSlash = cep.replace(/^\/|\/$/g, '');

        return this.api.get(this.baseUrl + cepSemSlash + '/json');
    }
}
