import {
    Alert,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    useMediaQuery,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import SpinnerLoading from '../../Components/SpinnerLoading/SpinnerLoading';
import { ApiResources } from '../../Models/Api';
import Auditoria, { RevisionType } from '../../Models/Auditoria';
import OrdemCarregamento from '../../Models/OrdemCarregamento';
import { StatusRequisicao } from '../../Models/Usuario';
import useGenericService from '../../hooks/useGenericService';
import { styles } from './helpers/Styles';
import useAuditoriaCamposOrdem from './useAuditoriaCamposOrdem';

interface Props {
    ordemCarregamento: OrdemCarregamento;
    fecharClicado: () => void;
    aberto: boolean;
}

export default function ModalHistoricoAlteracoes({
    ordemCarregamento,
    fecharClicado,
    aberto = false,
}: Props) {
    const [auditorias, setAuditorias] = useState<Auditoria[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { getService } = useGenericService();

    const isMobile = useMediaQuery('(max-width: 600px)');

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { getItensAlterados } = useAuditoriaCamposOrdem();

    useEffect(() => {
        if (aberto) {
            setIsLoading(true);
            const carregarOrdem = async () => {
                const service = getService(ApiResources.OrdemCarregamento);

                try {
                    const resposta = await service.api.get(
                        '/auditoria/' + ordemCarregamento.id
                    );

                    if (resposta.status === StatusRequisicao.OK) {
                        setAuditorias(resposta?.data?.content);
                        setIsLoading(false);
                    } else {
                        enqueueSnackbar(
                            'Não foi possível encontrar o registro',
                            {
                                variant: 'error',
                                onClick: () => {
                                    closeSnackbar();
                                },
                            }
                        );
                        setIsLoading(false);
                    }
                } catch (error) {
                    console.log(error);
                    setIsLoading(false);
                }
            };

            carregarOrdem();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aberto, ordemCarregamento, enqueueSnackbar]);

    const getTypeAuditoria = (revisonType: RevisionType) => {
        switch (revisonType) {
            case RevisionType.Adicionado:
                return 'Criação';
            case RevisionType.Deletado:
                return 'Exclusão';
            case RevisionType.Modificado:
                return 'Modificação';
        }
    };

    const getCorpoAuditorias = () => {
        let elements: React.ReactElement[] = [];

        auditorias.forEach((auditoria, index) => {
            if (
                index > 0 &&
                auditorias[index - 1].revision.revisionType ===
                    RevisionType.Deletado
            ) {
                return;
            } else {
                elements.push(
                    <Card sx={styles(isMobile).cardAlteracoes}>
                        <CardContent>
                            <Alert
                                variant="outlined"
                                severity="info"
                                style={
                                    styles(auditoria.revision.revisionType)
                                        .alertAlteracoes
                                }
                                icon={false}
                            >
                                <div style={{ alignItems: 'center' }}>
                                    <div>
                                        <strong>Data da atualização:</strong>{' '}
                                        {format(
                                            parseISO(
                                                auditoria.revision.revisionDate.toString()
                                            ),
                                            'dd/MM/Y HH:mm',
                                            { locale: ptBR }
                                        ).toString()}{' '}
                                    </div>
                                    <div>
                                        <strong>Tipo de registro: </strong>
                                        {getTypeAuditoria(
                                            auditoria.revision.revisionType
                                        )}
                                    </div>
                                </div>
                                <strong> Usuário responsável:</strong>{' '}
                                {auditoria.revision.usuario.login}
                                {/* {getTypeAuditoria(auditoria.revision.revisionType)} */}
                            </Alert>

                            {index > 0 &&
                                getItensAlterados(
                                    auditoria.entidade as OrdemCarregamento,
                                    auditorias[index - 1]
                                        .entidade as OrdemCarregamento
                                )}
                        </CardContent>
                    </Card>
                );
            }
        });

        return elements;
    };

    return (
        <Dialog
            open={aberto}
            onClose={() => fecharClicado()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Histórico de alterações da ordem
            </DialogTitle>
            <SpinnerLoading isLoading={isLoading}>
                {auditorias.length === 0 ? (
                    <Alert severity="info">Não há alterações registradas</Alert>
                ) : (
                    <DialogContent>{getCorpoAuditorias()}</DialogContent>
                )}
            </SpinnerLoading>
            <DialogActions>
                <Button onClick={() => fecharClicado()} autoFocus>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
