import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import * as React from 'react';
import { StatusRequisicao } from '../../Models/Usuario';
import { UseAutocompleteProps } from '@mui/base';
import { IconButton, StandardTextFieldProps } from '@mui/material';
import { ApiResources } from '../../Models/Api';
import ClienteFornecedor from '../../Models/ClienteFornecedor';
import useGenericService from '../../hooks/useGenericService';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';

type InputProps = Pick<
    StandardTextFieldProps,
    | 'margin'
    | 'error'
    | 'helperText'
    | 'fullWidth'
    | 'required'
    | 'inputRef'
    | 'InputLabelProps'
    | 'label'
    | 'placeholder'
    | 'disabled'
    | 'name'
    | 'FormHelperTextProps'
>;

export interface Props
    extends UseAutocompleteProps<any, boolean, boolean, undefined>,
        InputProps {
    label: string;
    disabled?: boolean;
    required?: boolean;
}

export interface TireSet {
    TireSet: number | null;
    TireSetDescription: string | null;
}

export default function SelectConjuntoPneu({
    margin,
    error,
    helperText,
    fullWidth,
    required,
    InputLabelProps,
    inputRef,
    label,
    placeholder,
    name,
    disabled = false,
    ...outrasPropriedades
}: Props) {
    const [marcas, setMarcas] = React.useState<TireSet[]>([]);

    const { getService } = useGenericService();

    React.useEffect(() => {
        const carregarOpcoes = async () => {
            const servico = getService(ApiResources.Juncao);

            try {
                const resposta = await servico.api.get(
                    '/buscar-conjuntos-pneus-maxys-atac'
                );

                if (resposta.status === StatusRequisicao.OK) {
                    setMarcas(resposta.data);
                }
            } catch (error) {
                console.log('erro', error);
            }
        };

        carregarOpcoes();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Autocomplete
            {...outrasPropriedades}
            fullWidth
            noOptionsText={'Sem opções'}
            options={marcas}
            value={(outrasPropriedades as any).field.value}
            multiple={false}
            disabled={disabled}
            filterSelectedOptions
            getOptionLabel={(option) => option.TireSetDescription}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={name}
                    inputRef={inputRef}
                    label={label}
                    required={required}
                    margin={margin}
                    autoComplete="new-password"
                    disabled={disabled}
                    error={error}
                    helperText={helperText}
                    fullWidth={fullWidth}
                    placeholder={placeholder}
                    InputLabelProps={InputLabelProps}
                />
            )}
        />
    );
}
