import ArticleIcon from '@mui/icons-material/Article';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import BalanceIcon from '@mui/icons-material/Balance';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import ContrastIcon from '@mui/icons-material/Contrast';
import DiscFullIcon from '@mui/icons-material/DiscFull';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import {
    Card,
    CardContent,
    Chip,
    Container,
    Divider,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
    Tooltip,
    Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { PermissionGate } from '../../Components/Navegacao/PermissionGate';
import PageTitle from '../../Components/PageTitle/PageTitle';
import GenericComplexTable, {
    ComplexColumn,
} from '../../Components/Table/GenericComplexTable';
import { ApiResources, FrontEndPathResources } from '../../Models/Api';
import Embarque from '../../Models/Embarque';
import NotaFiscal from '../../Models/NotaFiscal';
import OrdemCarregamento from '../../Models/OrdemCarregamento';
import Usuario, {
    ChavesArmazenamentoStorage,
    RecursosPerfisEnum,
    StatusRequisicao,
} from '../../Models/Usuario';
import LoginResponse from '../../Models/responses/LoginResponse';
import useGenericService from '../../hooks/useGenericService';
import { usePermission } from '../../hooks/usePermission';
import { Status } from './Formulario';
import ModalAnexarNota from './ModalAnexarNota';
import ModalClassificacoes from './ModalClassificacoes';
import ModalConfirmacaoExclusaoEmbarque from './ModalConfirmacaoExclusaoEmbarque';
import ModalEditarPesoPlanejado from './ModalEditarPesoPlanejado';
import ModalHistoricoAlteracoes from './ModalHistoricoAlteracoes';
import ModalOrdensExcluidas from './ModalOrdensExcluidas';
import ModalRomaneio from './ModalRomaneio';
import { styles } from './helpers/Styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableHead: {
            backgroundColor: '#EDF0FE',
            fontWeight: 'bold',
        },
    })
);

export type Arquivo = {
    name: string;
};

export default function Listar() {
    const [mostrarModalAuditoria, setMostrarModalAuditoria] =
        useState<boolean>(false);

    const [mostrarModalExcluirEmbarque, setMostrarModalExcluirEmbarque] =
        useState<boolean>(false);

    const [ordemEditarPeso, setOrdemEditarPeso] = useState<OrdemCarregamento>();

    const [
        mostrarModalAlterarPesoPlanejado,
        setMostrarModalAlterarPesoPlanejado,
    ] = useState<boolean>(false);

    const [ordeMostrarAuditoria, setOrdemMostrarAuditoria] =
        useState<OrdemCarregamento>();

    const classes = useStyles();

    const [mostrarModalClassificacoes, setMostrarModalClassificacoes] =
        useState<boolean>(false);

    const [embarqueMostrar, setEmbarqueMostrar] = useState<Embarque>();

    const [mostrarModalAnexarNota, setMostrarModalAnexarNota] =
        useState<boolean>(false);
    const [embarqueAnexarNota, setEmbarqueAnexarNota] = useState<Embarque>();

    const [embarqueGerarRomaneio, setEmbarqueGerarRomaneio] =
        useState<Embarque>();

    const [ordemGerarRomaneio, setOrdemGerarRomaneio] =
        useState<OrdemCarregamento>();

    const [mostrarModalItensExcluidos, setMostrarModalItensExcluidos] =
        useState<boolean>(false);

    const [ordemModal, setOrdemModal] = useState<OrdemCarregamento>();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [embarqueExcluir, setEmbarqueExcluir] = useState<Embarque>();

    const [mostrarModalGerarRomaneio, setMostrarModalGerarRomaneio] =
        useState<boolean>(false);

    const [ordens, setOrdens] = useState<OrdemCarregamento[]>();

    const [desativarCriarOrdem, setDesativarCriarOrdem] =
        useState<boolean>(false);

    const [isIntegracaoAutomatica, setIsIntegracaoAutomatica] =
        useState<boolean>(false);

    const { isAllowed: isPermitidoExcluir } = usePermission(
        RecursosPerfisEnum.ORDEM_CARREGAMENTO_EXCLUIR
    );

    const { getService } = useGenericService();

    useEffect(() => {
        let loginResponse = localStorage.getItem(
            ChavesArmazenamentoStorage.LOGIN_RESPONSE
        );

        if (loginResponse) {
            const usuario: Usuario = (
                JSON.parse(loginResponse) as LoginResponse
            ).usuario;

            if (usuario.trocarSenha) {
                window.location.pathname = FrontEndPathResources.AlterarSenha;
            }
        }

        const getIntegracaoPortal = async () => {
            const service = getService(ApiResources.ConfigGeral);

            try {
                const resposta = await service.api.get('');
                if (resposta.data !== null) {
                    setDesativarCriarOrdem(
                        resposta?.data?.desativar_ordens_portal
                    );
                    setIsIntegracaoAutomatica(
                        resposta?.data?.integracao_automatica
                    );
                }
            } catch (error) {}
        };

        getIntegracaoPortal();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const desativarProduto = async (url: string) => {
        const service = getService(ApiResources.OrdemCarregamento);

        try {
            const resposta = await service.api.delete(url);

            if (resposta.status === StatusRequisicao.OK) {
                enqueueSnackbar('Ordem de carregamento cancelada com sucesso', {
                    variant: 'success',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
                navigate(FrontEndPathResources.OrdemCarregamento);
                carregar();
            }
        } catch (error) {}
    };

    const carregar = async () => {
        const service = getService(ApiResources.OrdemCarregamento);

        const resposta = await service.api.get('');

        setOrdens(resposta?.data?.content);
    };

    const colunas: ComplexColumn[] = [
        {
            attribute: 'id',
            label: 'ID',
            id: 'id',
            minWidth: 20,
            sortable: true,
        },
        {
            attribute: 'codigoContrato',
            label: 'Contrato',
            id: 'codigoContrato',
            sortable: true,
            minWidth: 70,
        },
        {
            attribute: 'produto.nome',
            label: 'Produto',
            id: 'produto.nome',
            sortable: false,
            format: (row) => row.produto.nome,
        },
        {
            attribute: 'classificador.nome',
            label: 'Classificador',
            id: 'classificador.nome',
            sortable: false,
            format: (row) => row.classificador.nome,
        },
        {
            attribute: 'pesoPlanejado',
            label: 'Peso planejado',
            id: 'pesoPlanejado',
            sortable: true,
            format: (row) =>
                row.pesoPlanejado
                    .toString()
                    .replace('.', ',')
                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' KG',
        },
        {
            attribute: 'status',
            label: 'Status',
            id: 'status',
            sortable: true,
            format: (row) => getStatusOrdemFormatado(row.status),
        },
        {
            attribute: 'nome',
            label: 'Ações',
            id: 'acao',
            minWidth: 200,
            format: (row) => {
                return (
                    <>
                        <Tooltip title="Ver" placement="top" disableInteractive>
                            <IconButton
                                aria-label="ver"
                                onClick={() => {
                                    navigate(
                                        FrontEndPathResources.OrdemCarregamento +
                                            '/ver/' +
                                            row.id
                                    );
                                }}
                            >
                                <RemoveRedEyeIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title={
                                !row.cadastradaPeloPortal
                                    ? 'Ordens criadas por sistemas externos não podem ser alteradas'
                                    : 'Alterar peso planejado'
                            }
                            placement="top"
                            disableInteractive
                        >
                            <PermissionGate
                                recurso={
                                    RecursosPerfisEnum.ORDEM_CARREGAMENTO_ALTERAR
                                }
                                redirect={''}
                            >
                                <IconButton
                                    aria-label="alterar-peso-planejado"
                                    disabled={!row.cadastradaPeloPortal}
                                    onClick={() => {
                                        setOrdemEditarPeso(
                                            row as OrdemCarregamento
                                        );
                                        setMostrarModalAlterarPesoPlanejado(
                                            true
                                        );
                                    }}
                                >
                                    <BalanceIcon />
                                </IconButton>
                            </PermissionGate>
                        </Tooltip>
                        <PermissionGate
                            recurso={
                                RecursosPerfisEnum.ORDEM_CARREGAMENTO_CONSULTAR_HISTORICO
                            }
                            redirect={''}
                        >
                            <Tooltip
                                title="Histórico de alterações"
                                placement="top"
                                disableInteractive
                            >
                                <IconButton
                                    aria-label="ver"
                                    onClick={() => {
                                        setOrdemMostrarAuditoria(
                                            row as OrdemCarregamento
                                        );
                                        setMostrarModalAuditoria(true);
                                    }}
                                >
                                    <ArticleIcon />
                                </IconButton>
                            </Tooltip>
                        </PermissionGate>
                        {row.status === Status.ABERTA && (
                            <>
                                <Tooltip
                                    title={
                                        !row.cadastradaPeloPortal
                                            ? 'Ordens criadas por sistemas externos não podem ser alteradas'
                                            : 'Alterar' || desativarCriarOrdem
                                            ? 'Alterar ordens está desativado'
                                            : 'Alterar'
                                    }
                                    placement="top"
                                    disableInteractive
                                >
                                    <PermissionGate
                                        recurso={
                                            RecursosPerfisEnum.ORDEM_CARREGAMENTO_ALTERAR
                                        }
                                        redirect={''}
                                    >
                                        <IconButton
                                            aria-label="alterar"
                                            disabled={
                                                !row.cadastradaPeloPortal ||
                                                desativarCriarOrdem
                                            }
                                            onClick={() => {
                                                navigate(
                                                    FrontEndPathResources.OrdemCarregamento +
                                                        '/alterar/' +
                                                        row.id
                                                );
                                            }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </PermissionGate>
                                </Tooltip>
                                <Tooltip
                                    title={
                                        !row.cadastradaPeloPortal
                                            ? 'Ordens criadas por sistemas externos não podem ser canceladas'
                                            : 'Cancelar'
                                    }
                                    placement="top"
                                    disableInteractive
                                >
                                    <PermissionGate
                                        recurso={
                                            RecursosPerfisEnum.ORDEM_CARREGAMENTO_EXCLUIR
                                        }
                                        redirect={''}
                                    >
                                        <IconButton
                                            aria-label="delete"
                                            disabled={!row.cadastradaPeloPortal}
                                            onClick={() => {
                                                desativarProduto('/' + row.id);
                                            }}
                                        >
                                            <CancelIcon />
                                        </IconButton>
                                    </PermissionGate>
                                </Tooltip>
                            </>
                        )}
                    </>
                );
            },
        },
    ];

    const pesoEmbarcado = useCallback(
        (ordem: OrdemCarregamento, emb: Embarque) => {
            if (ordem.cadastradaPeloPortal) {
                return Math.abs(
                    emb.pesagem!.pesoSaida! - emb.pesagem!.pesoEntrada!
                );
            } else if (!emb.enviadoERP && !isIntegracaoAutomatica) {
                return Math.abs(
                    emb.pesagem!.pesoSaida! - emb.pesagem!.pesoEntrada!
                );
            } else {
                return emb.pesoAposDescontos;
            }
        },
        [isIntegracaoAutomatica]
    );

    function getRowBodyExpandedRow(
        embarques: Embarque[],
        ordemCarregamento: OrdemCarregamento
    ): JSX.Element[] {
        let elements: JSX.Element[] = [];

        embarques.forEach((embarque) => {
            elements.push(
                <TableRow key={embarque.id}>
                    <TableCell component="th" scope="row">
                        {embarque.id}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {format(
                            new Date(embarque.dataHoraCriacao),
                            'dd/MM/Y HH:mm'
                        ).toString()}
                    </TableCell>
                    <TableCell>{embarque.caminhao.placaVeiculo}</TableCell>
                    <TableCell>{embarque.motorista.nome}</TableCell>
                    <TableCell align="right">
                        {pesoEmbarcado(ordemCarregamento, embarque)
                            .toString()
                            .replace('.', ',')
                            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                        KG
                    </TableCell>
                    <TableCell align="right">
                        <Tooltip
                            title="Baixar"
                            placement="top"
                            disableInteractive
                        >
                            <IconButton
                                aria-label="anexar"
                                onClick={() => {
                                    setOrdemGerarRomaneio(ordemCarregamento);
                                    setEmbarqueGerarRomaneio(embarque);
                                    setMostrarModalGerarRomaneio(true);
                                }}
                            >
                                <PictureAsPdfIcon />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                    <TableCell align="right">
                        {embarque.notaFiscal &&
                        embarque.notaFiscal.notaFiscalEncoded ? (
                            <>
                                {!embarque.enviadoERP && (
                                    <Tooltip
                                        title="Alterar anexo"
                                        placement="top"
                                        disableInteractive
                                    >
                                        <PermissionGate
                                            recurso={
                                                RecursosPerfisEnum.ORDEM_CARREGAMENTO_ANEXAR_NOTA_FISCAL
                                            }
                                            redirect={''}
                                        >
                                            <IconButton
                                                aria-label="anexar"
                                                onClick={() => {
                                                    setEmbarqueAnexarNota(
                                                        embarque
                                                    );
                                                    setMostrarModalAnexarNota(
                                                        true
                                                    );
                                                }}
                                            >
                                                <PublishedWithChangesIcon />
                                            </IconButton>
                                        </PermissionGate>
                                    </Tooltip>
                                )}
                                <Tooltip
                                    title="Baixar nota"
                                    placement="top"
                                    disableInteractive
                                >
                                    <IconButton
                                        aria-label="baixar"
                                        onClick={() => {
                                            const ancoraParaDownload =
                                                document.createElement('a');
                                            ancoraParaDownload.download =
                                                'notaFiscal_embarque_' +
                                                embarque.id +
                                                '.pdf';
                                            ancoraParaDownload.href =
                                                embarque.notaFiscal!.notaFiscalEncoded!;
                                            ancoraParaDownload.click();
                                        }}
                                    >
                                        <DownloadIcon />
                                    </IconButton>
                                </Tooltip>
                            </>
                        ) : (
                            <Tooltip
                                title="Anexar"
                                placement="top"
                                disableInteractive
                            >
                                <PermissionGate
                                    recurso={
                                        RecursosPerfisEnum.ORDEM_CARREGAMENTO_ANEXAR_NOTA_FISCAL
                                    }
                                    redirect={''}
                                >
                                    <IconButton
                                        aria-label="anexar"
                                        onClick={() => {
                                            setEmbarqueAnexarNota(embarque);
                                            setMostrarModalAnexarNota(true);
                                        }}
                                    >
                                        <AttachFileIcon />
                                    </IconButton>
                                </PermissionGate>
                            </Tooltip>
                        )}
                    </TableCell>
                    <TableCell align="center">
                        <Tooltip
                            title="Ver classificações"
                            placement="top"
                            disableInteractive
                        >
                            <IconButton
                                aria-label="Ver classificações"
                                onClick={() => {
                                    setEmbarqueMostrar(embarque);
                                    setMostrarModalClassificacoes(true);
                                    setOrdemModal(ordemCarregamento);
                                }}
                            >
                                <ScienceOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                        {!embarque.enviadoERP && (
                            <Tooltip
                                title="Excluir embarque"
                                placement="top"
                                disableInteractive
                            >
                                <PermissionGate
                                    recurso={
                                        RecursosPerfisEnum.ORDEM_CARREGAMENTO_EXCLUIR
                                    }
                                    redirect={''}
                                >
                                    <IconButton
                                        aria-label="excluir-embarque"
                                        onClick={() => {
                                            setEmbarqueExcluir(embarque);
                                            setMostrarModalExcluirEmbarque(
                                                true
                                            );
                                        }}
                                    >
                                        <CancelIcon />
                                    </IconButton>
                                </PermissionGate>
                            </Tooltip>
                        )}
                    </TableCell>
                </TableRow>
            );
        });

        return elements;
    }

    const getTotalEmbarcado = (ordem: OrdemCarregamento): number => {
        let totalEmbarcado = 0;

        if (ordem.embarques && ordem.embarques.length > 0) {
            totalEmbarcado = ordem.embarques
                .map((emb) => pesoEmbarcado(ordem, emb))
                .reduce((a, b) => a + b, 0);
        }

        return totalEmbarcado;
    };

    const getTotalExcedente = (ordem: OrdemCarregamento): number => {
        let totalEmbarcado = getTotalEmbarcado(ordem);

        let totalExcedido = totalEmbarcado - ordem.pesoPlanejado;

        return totalExcedido > 0 ? totalExcedido : 0;
    };

    function expandedRowContent(row: any) {
        return (
            <Card style={styles().card}>
                <CardContent>
                    <Typography
                        sx={{ fontSize: 20 }}
                        color="text.secondary"
                        gutterBottom
                    >
                        Embarques
                    </Typography>
                    {row.embarques.length > 0 && (
                        <Grid container spacing={2} style={styles().gridListar}>
                            <Grid item xs={12} lg={3}>
                                <Card>
                                    <CardContent>
                                        <Typography
                                            sx={{ fontSize: 14 }}
                                            color="text.secondary"
                                            gutterBottom
                                        >
                                            Total embarcado
                                        </Typography>
                                        <Typography
                                            variant="h5"
                                            component="div"
                                            color="#75DB07"
                                        >
                                            {getTotalEmbarcado(
                                                row as OrdemCarregamento
                                            )
                                                .toString()
                                                .replace('.', ',')
                                                .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    '.'
                                                )}
                                            KG
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <Card>
                                    <CardContent>
                                        <Typography
                                            sx={{ fontSize: 14 }}
                                            color="text.secondary"
                                            gutterBottom
                                        >
                                            Total excedente
                                        </Typography>
                                        <Typography
                                            variant="h5"
                                            component="div"
                                            color="#DBCE07"
                                        >
                                            {getTotalExcedente(
                                                row as OrdemCarregamento
                                            )
                                                .toString()
                                                .replace('.', ',')
                                                .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    '.'
                                                )}{' '}
                                            KG
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    )}

                    {row.embarques.length > 0 ? (
                        <Table size="medium" aria-label="purchases">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        width={50}
                                        className={classes.tableHead}
                                    >
                                        ID
                                    </TableCell>
                                    <TableCell
                                        width={120}
                                        className={classes.tableHead}
                                    >
                                        Data/Hora de saída
                                    </TableCell>
                                    <TableCell
                                        width={200}
                                        className={classes.tableHead}
                                    >
                                        Placa do veículo
                                    </TableCell>
                                    <TableCell
                                        width={120}
                                        className={classes.tableHead}
                                    >
                                        Motorista
                                    </TableCell>
                                    <TableCell
                                        width={120}
                                        className={classes.tableHead}
                                        align="right"
                                    >
                                        Peso embarcado
                                    </TableCell>

                                    <TableCell
                                        width={120}
                                        className={classes.tableHead}
                                        align="right"
                                    >
                                        Romaneio
                                    </TableCell>
                                    <TableCell
                                        width={120}
                                        className={classes.tableHead}
                                        align="right"
                                    >
                                        Nota fiscal
                                    </TableCell>
                                    <TableCell
                                        width={120}
                                        className={classes.tableHead}
                                        align="center"
                                    >
                                        Classificações
                                    </TableCell>
                                    <TableCell
                                        width={120}
                                        className={classes.tableHead}
                                        align="center"
                                    >
                                        Ações
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {getRowBodyExpandedRow(
                                    row.embarques as Embarque[],
                                    row as OrdemCarregamento
                                )}
                            </TableBody>
                        </Table>
                    ) : (
                        'Nenhum embarque foi registrado'
                    )}
                </CardContent>
            </Card>
        );
    }

    const getStatusOrdemFormatado = (statusOrdem: Status) => {
        switch (statusOrdem) {
            case Status.ABERTA:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <ImportContactsIcon
                                    style={styles().iconStyle}
                                />{' '}
                                {Status.ABERTA}
                            </div>
                        }
                        color="primary"
                        variant="outlined"
                    />
                );
            case Status.ATENDIDAPARCIALMENTE:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <ContrastIcon style={styles().iconStyle} />{' '}
                                {Status.ATENDIDAPARCIALMENTE}
                            </div>
                        }
                        color="default"
                        variant="outlined"
                    />
                );
            case Status.FINALIZADA:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <CheckIcon style={styles().iconStyle} />{' '}
                                {Status.FINALIZADA}
                            </div>
                        }
                        color="success"
                        variant="outlined"
                    />
                );
            case Status.EXCEDIDA:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <DiscFullIcon style={styles().iconStyle} />{' '}
                                {Status.EXCEDIDA}
                            </div>
                        }
                        color="warning"
                        variant="outlined"
                    />
                );
        }
    };

    const enviarNotaFiscalApi = async (notaFiscal: NotaFiscal) => {
        try {
            const service = getService(ApiResources.NotaFiscal);

            const resposta = await service.api.post('/portal', notaFiscal);

            if (resposta.status === StatusRequisicao.OK) {
                enqueueSnackbar('Nota enviada com sucesso!', {
                    variant: 'success',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
                carregar();
            } else {
                enqueueSnackbar('Erro ao tentar enviar a nota!', {
                    variant: 'error',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
            }
        } catch (error) {
            enqueueSnackbar('Erro ao tentar enviar a nota!', {
                variant: 'error',
                onClick: () => {
                    closeSnackbar();
                },
            });
        }
    };

    const alterarPesoOrdem = async (pesoPlanejado: number, idOrdem: number) => {
        try {
            const service = getService(ApiResources.OrdemCarregamento);

            const resposta = await service.api.put(
                '/alterar-peso-planejado/' + idOrdem,
                {
                    pesoPlanejado,
                }
            );

            if (resposta.status === StatusRequisicao.OK) {
                enqueueSnackbar(
                    'Peso planejado alterado com sucesso com sucesso!',
                    {
                        variant: 'success',
                        onClick: () => {
                            closeSnackbar();
                        },
                    }
                );
                carregar();
            } else {
                enqueueSnackbar('Erro ao tentar alterar o peso planejado!', {
                    variant: 'error',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
            }
        } catch (error) {
            const erroTratado = error as { atributo: string; mensagem: string };

            enqueueSnackbar(
                erroTratado.atributo + ': ' + erroTratado.mensagem,
                {
                    variant: 'error',
                    onClick: () => {
                        closeSnackbar();
                    },
                }
            );
        }
    };

    const excluirEmbarque = async () => {
        const servico = getService(ApiResources.Embarque);

        try {
            const resultado = await servico.api.delete(
                '/cancelar/' + embarqueExcluir?.id
            );

            if (resultado.status === StatusRequisicao.OK) {
                enqueueSnackbar('Embarque excluido com sucesso', {
                    variant: 'success',
                });
                carregar();
            } else {
                enqueueSnackbar('Embarque não foi excluido!', {
                    variant: 'error',
                });
            }
        } catch (error) {
            enqueueSnackbar(error as string, {
                variant: 'error',
            });
        } finally {
            setMostrarModalExcluirEmbarque(false);
            setEmbarqueExcluir(undefined);
        }

        setMostrarModalExcluirEmbarque(false);
        setEmbarqueExcluir(undefined);
    };

    return (
        <PermissionGate
            recurso={RecursosPerfisEnum.ORDEM_CARREGAMENTO_VER}
            redirect={FrontEndPathResources.Dashboard}
        >
            <Container>
                <PageTitle title="Ordens de carregamento" />

                <Divider />

                {mostrarModalExcluirEmbarque && embarqueExcluir && (
                    <ModalConfirmacaoExclusaoEmbarque
                        aberto={mostrarModalExcluirEmbarque}
                        embarque={embarqueExcluir}
                        fecharClicado={() => {
                            setMostrarModalExcluirEmbarque(false);
                            setEmbarqueExcluir(undefined);
                        }}
                        confirmarClicado={() => {
                            setMostrarModalExcluirEmbarque(false);
                            setEmbarqueExcluir(undefined);
                            excluirEmbarque();
                        }}
                    />
                )}
                <PermissionGate
                    recurso={
                        RecursosPerfisEnum.ORDEM_CARREGAMENTO_ALTERAR_PESO_PLANEJADO
                    }
                    redirect={''}
                >
                    {mostrarModalAlterarPesoPlanejado && ordemEditarPeso && (
                        <ModalEditarPesoPlanejado
                            aberto={mostrarModalAlterarPesoPlanejado}
                            fecharClicado={() => {
                                setOrdemEditarPeso(undefined);
                                setMostrarModalAlterarPesoPlanejado(false);
                            }}
                            gravarClicado={(
                                pesoPlanejado: number,
                                ordemId: number
                            ) => {
                                alterarPesoOrdem(pesoPlanejado, ordemId);
                                setMostrarModalAlterarPesoPlanejado(false);
                                setOrdemEditarPeso(undefined);
                            }}
                            ordem={ordemEditarPeso}
                        />
                    )}
                </PermissionGate>

                <ModalOrdensExcluidas
                    aberto={mostrarModalItensExcluidos}
                    fecharClicado={() => {
                        setMostrarModalItensExcluidos(false);
                    }}
                    restaurarClicado={() => {
                        carregar();
                        setMostrarModalItensExcluidos(false);
                    }}
                />
                <PermissionGate
                    recurso={
                        RecursosPerfisEnum.ORDEM_CARREGAMENTO_CONSULTAR_HISTORICO
                    }
                    redirect={''}
                >
                    {mostrarModalAuditoria && ordeMostrarAuditoria && (
                        <ModalHistoricoAlteracoes
                            ordemCarregamento={ordeMostrarAuditoria}
                            aberto={mostrarModalAuditoria}
                            fecharClicado={() => {
                                setMostrarModalAuditoria(false);
                            }}
                        />
                    )}
                </PermissionGate>

                {embarqueMostrar && ordemModal && (
                    <ModalClassificacoes
                        aberto={mostrarModalClassificacoes}
                        embarque={embarqueMostrar}
                        ordem={ordemModal}
                        fecharClicado={() => {
                            setMostrarModalClassificacoes(false);
                            setEmbarqueMostrar(undefined);
                        }}
                    />
                )}
                <PermissionGate
                    recurso={
                        RecursosPerfisEnum.ORDEM_CARREGAMENTO_ANEXAR_NOTA_FISCAL
                    }
                    redirect={''}
                >
                    {embarqueAnexarNota && mostrarModalAnexarNota && (
                        <ModalAnexarNota
                            aberto={mostrarModalAnexarNota}
                            embarque={embarqueAnexarNota}
                            fecharClicado={() => {
                                setMostrarModalAnexarNota(false);
                                setEmbarqueAnexarNota(undefined);
                            }}
                            enviarAnexoClicado={(notaFiscal: NotaFiscal) => {
                                enviarNotaFiscalApi(notaFiscal);
                                setMostrarModalAnexarNota(false);
                                setEmbarqueAnexarNota(undefined);
                            }}
                        />
                    )}
                </PermissionGate>

                {embarqueGerarRomaneio && ordemGerarRomaneio && (
                    <ModalRomaneio
                        aberto={mostrarModalGerarRomaneio}
                        embarque={embarqueGerarRomaneio}
                        ordem={ordemGerarRomaneio}
                        fecharClicado={() => {
                            setEmbarqueGerarRomaneio(undefined);
                            setOrdemGerarRomaneio(undefined);
                            setMostrarModalGerarRomaneio(false);
                        }}
                    />
                )}

                <GenericComplexTable
                    service={getService(ApiResources.OrdemCarregamento)}
                    columnToSearch={'codigoContrato'}
                    columns={colunas}
                    rows={ordens}
                    naoMostrarBotaoNovo={desativarCriarOrdem}
                    createButtonText="Registrar nova"
                    linkCreateButtonText={
                        FrontEndPathResources.OrdemCarregamento + '/criar'
                    }
                    expandedRows
                    contentExpandedRow={expandedRowContent}
                    mostrarIconeItensExcluidos={isPermitidoExcluir}
                    onIconeItensExcluidosClicado={() => {
                        setMostrarModalItensExcluidos(true);
                    }}
                />
            </Container>
        </PermissionGate>
    );
}
