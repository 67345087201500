import { joiResolver } from '@hookform/resolvers/joi';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
} from '@mui/material';
import Joi from 'joi';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InputTelefone from '../../../Components/Input/InputTelefone';
import { Reference } from '../types/Juncao';

interface Props {
    aberto: boolean;
    referencia?: Reference;
    gravarClicado: (referencia: Reference) => void;
    fecharClicado: () => void;
}

export default function ModalReferencia({
    referencia,
    gravarClicado,
    aberto,
    fecharClicado,
}: Props) {
    const validacao = Joi.object({
        Phone: Joi.string().required().messages({
            'string.base': 'Não pode ficar em branco',
            'string.required': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
        }),
        Name: Joi.string().trim().required().min(3).max(60).messages({
            'string.base': 'O nome deve ser uma string',
            'string.empty': 'O nome não pode ficar em branco',
            'any.required': 'O nome não pode ficar em branco',
            'string.min': 'Deve ter no mínimo 3 caracteres',
            'string.max': 'Permitido até 60 caracteres',
        }),
    });

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<Reference>({
        resolver: joiResolver(validacao),
        criteriaMode: 'all',
    });

    useEffect(() => {
        if (referencia) {
            setValue('Phone', referencia.Phone);
            setValue('Name', referencia.Name);
        }
    }, [referencia, setValue]);

    const tratarDados = (dadosForm: Reference) => {
        gravarClicado({
            Name: dadosForm.Name.toUpperCase().trim(),
            Phone: dadosForm.Phone,
        });
    };

    return (
        <Dialog
            open={aberto}
            onClose={() => fecharClicado()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="lg"
        >
            <DialogTitle id="alert-dialog-title">
                Referência do motorista.
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={7}>
                        <Controller
                            name="Name"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="new-password"
                                        inputProps={{
                                            maxLength: 120,
                                            style: {
                                                textTransform: 'uppercase',
                                            },
                                        }}
                                        fullWidth
                                        onChange={(e) => {
                                            e.target.value =
                                                e.target.value.replace(
                                                    /[^A-Za-z0-9.\s]/g,
                                                    ''
                                                );

                                            field.onChange(e);
                                        }}
                                        label="Nome"
                                        autoFocus
                                        error={!!errors.Name}
                                        helperText={
                                            errors.Name
                                                ? errors.Name.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="Phone"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <InputTelefone
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="new-password"
                                        fullWidth
                                        label="Telefone"
                                        error={!!errors.Phone}
                                        helperText={
                                            errors.Phone
                                                ? errors.Phone.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => fecharClicado()} autoFocus>
                    Fechar
                </Button>
                <Button onClick={() => handleSubmit(tratarDados)()} autoFocus>
                    Gravar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
