import CancelIcon from '@mui/icons-material/Cancel';
import { Button, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import { FunctionComponent, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useAuth } from '../../../../../AuthProvider';
import DividerComp from '../../../../../Components/Divider/DividerComp';
import { IObservacao } from '../../../domain/dtos/IObservacao';
import { IOrdemColetaLancamento } from '../../../domain/dtos/IOrdemColetaLancamento';
import { styles } from '../OrdemColetaForm/Styles';

interface ObservacoesFormProps {}

const ObservacoesForm: FunctionComponent<ObservacoesFormProps> = () => {
    const { control, setValue, reset, getValues } =
        useFormContext<IOrdemColetaLancamento>();

    const { fields, append, prepend, remove, swap, move, insert, replace } =
        useFieldArray({
            control,
            name: 'observacoes',
        });

    const auth = useAuth();

    const [enableAddObservacao, setEnableAddObservacao] = useState(true);

    const [observacao, setObservacao] = useState<IObservacao>({
        id: 0,
        usuario: '',
        descricao: '',
        dataHoraCriacao: '',
    });

    return (
        <>
            <DividerComp texto="Observações" />
            {fields.map((observacao, index) => {
                return (
                    <Grid container item spacing={2} key={index}>
                        <Grid item xs={12} lg={2}>
                            <Controller
                                name={`observacoes.${index}.dataHoraCriacao`}
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: {
                                        ref,
                                        onChange,
                                        onBlur,
                                        value,
                                        ...field
                                    },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        {...field}
                                        margin="normal"
                                        fullWidth
                                        value={value}
                                        label="Hora do registro"
                                        error={!!error?.message}
                                        helperText={
                                            error?.message ? error?.message : ''
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={onChange}
                                        disabled={true}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Controller
                                name={`observacoes.${index}.usuario`}
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: {
                                        ref,
                                        onChange,
                                        onBlur,
                                        value,
                                        ...field
                                    },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        {...field}
                                        margin="normal"
                                        fullWidth
                                        value={value}
                                        label="Usuário"
                                        error={!!error?.message}
                                        helperText={
                                            error?.message ? error?.message : ''
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={onChange}
                                        disabled={true}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Controller
                                name={`observacoes.${index}.descricao`}
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: {
                                        ref,
                                        onChange,
                                        onBlur,
                                        value,
                                        ...field
                                    },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        {...field}
                                        margin="normal"
                                        fullWidth
                                        value={value}
                                        label="Descrição"
                                        multiline
                                        error={!!error?.message}
                                        helperText={
                                            error?.message ? error?.message : ''
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={onChange}
                                        disabled={true}
                                    />
                                )}
                            />
                        </Grid>
                        {!enableAddObservacao && index + 1 == fields.length && (
                            <Grid item xs={12} lg={1} mt={2.2}>
                                <Tooltip
                                    title="Cancelar Observação"
                                    placement="top"
                                    disableInteractive
                                >
                                    <IconButton
                                        aria-label="delete"
                                        onClick={() => {
                                            remove(index);
                                            setEnableAddObservacao(true);
                                        }}
                                        size="large"
                                    >
                                        <CancelIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        )}
                    </Grid>
                );
            })}
            {enableAddObservacao && (
                <Grid container item spacing={2}>
                    <Grid item xs={12} lg={8}>
                        <TextField
                            margin="normal"
                            fullWidth
                            value={observacao.descricao}
                            label="Descrição (máximo 500 caracteres)"
                            multiline
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(event) => {
                                setObservacao({
                                    id: 0,
                                    usuario: auth?.usuarioLogado?.nome
                                        ? auth?.usuarioLogado?.nome
                                        : '',
                                    descricao: event.target.value,
                                    dataHoraCriacao: 
                                    format(
                                        new Date(),
                                        'dd/MM/yyyy'
                                    ),
                                });
                            }}
                            inputProps={{
                                maxLength: 500,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Button
                            onClick={() => {
                                append(observacao);
                                setObservacao({
                                    id: 0,
                                    usuario: auth?.usuarioLogado?.nome
                                        ? auth.usuarioLogado.nome
                                        : '',
                                    descricao: '',
                                    dataHoraCriacao: format(
                                        new Date(),
                                        'dd/MM/yyyy'
                                    ),
                                });
                                setEnableAddObservacao(false);
                            }}
                            variant="contained"
                            style={styles().button}
                            size="large"
                        >
                            Adicionar
                        </Button>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default ObservacoesForm;
