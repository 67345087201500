import AutorenewIcon from '@mui/icons-material/Autorenew';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Tooltip,
} from '@mui/material';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import GenericBasicTable from '../../Components/Table/GenericBasicTable';
import { ComplexColumn } from '../../Components/Table/GenericComplexTable';
import Amostra from '../../Models/Amostra';
import { ApiResources } from '../../Models/Api';
import { StatusRequisicao } from '../../Models/Usuario';
import useGenericService from '../../hooks/useGenericService';
import { styles } from './helpers/Styles';

interface Props {
    aberto: boolean;
    fecharClicado: () => void;
    restaurarClicado: () => void;
}

export default function ModalAmostrasExcluidas({
    aberto,
    fecharClicado,
    restaurarClicado,
}: Props) {
    const [amostras, setAmostras] = useState<Amostra[]>();

    const { getService } = useGenericService();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        const buscarAmostrasDesativadas = async () => {
            const servico = getService(ApiResources.Amostra);

            try {
                const resposta = await servico.api.get('/desativadas');
                if (resposta.status === StatusRequisicao.OK) {
                    setAmostras(resposta?.data?.content);
                }
            } catch (error) {}
        };

        buscarAmostrasDesativadas();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aberto]);

    const restaurarAmostra = async (amostraId: number) => {
        const servico = getService(ApiResources.Amostra);

        try {
            const resposta = await servico.api.put(
                '/restaurar/' + amostraId,
                {}
            );

            if (resposta.status === StatusRequisicao.OK) {
                enqueueSnackbar('Amostra restaurada com sucesso', {
                    variant: 'success',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
                restaurarClicado();
            }
        } catch (error) {}
    };

    const colunas: ComplexColumn[] = [
        {
            attribute: 'id',
            label: 'ID',
            id: 'id',
            align: 'left',
            width: 50,
        },
        {
            attribute: 'nome',
            label: 'Nome',
            id: 'nome',
            align: 'left',
            width: 150,
        },
        {
            attribute: 'dataHoraDeletado',
            label: 'Data da exclusão',
            id: 'dataHoraDeletado',
            align: 'left',
            width: 150,
            format: (row) =>
                format(new Date(row.dataHoraDeletado), 'dd/MM/Y').toString(),
        },
        {
            attribute: 'nome',
            label: 'Ações',
            id: 'acao',
            width: 50,
            format: (row) => {
                return (
                    <>
                        <Tooltip
                            title="Restaurar"
                            placement="top"
                            disableInteractive
                        >
                            <IconButton
                                aria-label="delete"
                                onClick={() => {
                                    restaurarAmostra(row.id);
                                }}
                            >
                                <AutorenewIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    return (
        <Dialog
            open={aberto}
            onClose={() => fecharClicado()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
        >
            <DialogTitle id="alert-dialog-title">
                Amostras excluídas
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} style={styles.grid}>
                    <GenericBasicTable columns={colunas} rows={amostras} />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => fecharClicado()} autoFocus>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
