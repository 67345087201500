import { Container, Divider } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import PageTitle from '../../Components/PageTitle/PageTitle';

import { useEffect, useState } from 'react';
import { PermissionGate } from '../../Components/Navegacao/PermissionGate';
import SpinnerLoading from '../../Components/SpinnerLoading/SpinnerLoading';
import { MensagemErroRequisicaoApi } from '../../Config/Api';
import { ApiResources, FrontEndPathResources } from '../../Models/Api';
import Usuario, {
    ChavesArmazenamentoStorage,
    RecursosPerfisEnum,
    StatusRequisicao,
} from '../../Models/Usuario';
import LoginResponse from '../../Models/responses/LoginResponse';
import useGenericService from '../../hooks/useGenericService';
import Formulario, { CamposFormularioTratados } from './Formulario';

export default function Criar() {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { getService } = useGenericService();

    const navigate = useNavigate();

    useEffect(() => {
        let loginResponse = localStorage.getItem(
            ChavesArmazenamentoStorage.LOGIN_RESPONSE
        );

        if (loginResponse) {
            const usuario: Usuario = (
                JSON.parse(loginResponse) as LoginResponse
            ).usuario;

            if (usuario.trocarSenha) {
                window.location.pathname = FrontEndPathResources.AlterarSenha;
            }
        }
    }, []);

    const onSubmit = async (dadosFormulario: CamposFormularioTratados) => {
        setIsLoading(true);
        const servico = getService(ApiResources.Transportadora);

        try {
            const resultado = await servico.api.post('', dadosFormulario);

            if (resultado.status === StatusRequisicao.CREATED) {
                enqueueSnackbar(
                    'Transportadora ' +
                        resultado.data.nome +
                        ' cadastrado com sucesso!',
                    {
                        variant: 'success',
                        onClick: () => {
                            closeSnackbar();
                        },
                    }
                );
                navigate(FrontEndPathResources.Transportadora);
                setIsLoading(false);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            (error as MensagemErroRequisicaoApi[]).forEach((erro) => {
                enqueueSnackbar(erro.atributo + ': ' + erro.mensagem, {
                    variant: 'error',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
            });
        }
    };

    return (
        <PermissionGate
            recurso={RecursosPerfisEnum.TRANSPORTADORAS_CRIAR}
            redirect={FrontEndPathResources.Transportadora}
        >
            <SpinnerLoading isLoading={isLoading}>
                <Container>
                    <PageTitle title={'Cadastrar Transportadora'} />
                    <Divider />
                    <Formulario onSubmit={onSubmit} />
                </Container>
            </SpinnerLoading>
        </PermissionGate>
    );
}
