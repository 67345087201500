import { Grid, TextField } from '@mui/material';
import { FunctionComponent } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { IOrdemColetaLancamento } from '../../../domain/dtos/IOrdemColetaLancamento';
import { styles } from '../OrdemColetaForm/Styles';

const ClassificacaoForm: FunctionComponent = () => {
    const {
        control,
        formState: { errors },
        trigger,
    } = useFormContext<IOrdemColetaLancamento>();

    const { fields } = useFieldArray({
        control,
        name: 'classificacao.classificacaoAmostras',
    });

    const resultErrorMessage = (index: number) =>
        errors.classificacao?.classificacaoAmostras?.[index]?.resultado
            ?.message;

    const handleTrigger = async (index: number) => {
        await trigger(`classificacao.classificacaoAmostras.${index}.resultado`);
    };

    return (
        <>
            {fields.map((item, index) => {
                return (
                    <Grid container item spacing={2} key={item.id}>
                        <Grid item xs={12} lg={4}>
                            <Controller
                                name={`classificacao.classificacaoAmostras.${index}.descricao`}
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: {
                                        ref,
                                        onChange,
                                        onBlur,
                                        value,
                                        ...field
                                    },
                                    fieldState: { error },
                                }) => (
                                    <TextField
                                        {...field}
                                        margin="normal"
                                        fullWidth
                                        value={value}
                                        label="Descrição"
                                        error={!!error?.message}
                                        helperText={
                                            error?.message ? error?.message : ''
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={onChange}
                                        disabled={true}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} lg={1}>
                            <Controller
                                name={`classificacao.classificacaoAmostras.${index}.resultado`}
                                control={control}
                                render={({
                                    field: {
                                        ref,
                                        onChange,
                                        onBlur,
                                        value,
                                        ...field
                                    },
                                }) => (
                                    <TextField
                                        {...field}
                                        margin="normal"
                                        fullWidth
                                        value={value}
                                        label="Resultado"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => {
                                            onChange(e);
                                            handleTrigger(index);
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Tab') {
                                                handleTrigger(index);
                                            }
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        {resultErrorMessage(index) && (
                            <Grid item xs={12} lg={12}>
                                <p style={styles().error}>
                                    {resultErrorMessage(index)}
                                </p>
                            </Grid>
                        )}
                    </Grid>
                );
            })}
        </>
    );
};

export default ClassificacaoForm;
