import { StyleSheet } from '../../../StyleSheet';

export const styles = StyleSheet.create({
    cardStyle: { minWidth: 275, marginTop: 2 },
    cloudIconButton: { marginLeft: 15 },
    deleteIconButton: { marginLeft: 0 },
    tooltipContainer: { display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'space-between' },
    img: { width: '20%', height: '10%' },
    alert: {  display: 'flex', justifyContent: 'space-between',alignItems: 'space-between' },
    button: {
        marginTop: 25,
        marginBottom: 15,
        backgroundColor: '#465f75',
    },
    testFailButton: {
        marginTop: 25,
        marginBottom: 15,
        //backgroundColor: '#465f75',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    testSuccessButton: {
        marginTop: 25,
        marginBottom: 15,
        //backgroundColor: '#465f75',
        //backgroundColor: ' rgba(99, 255, 132, 0.5)',
        backgroundColor: '#00a42f',
    },
    box: {
        backgroundColor: 'white',
        p: 2,
    },
    formControlStyle: { marginTop: 25 },
    //Semáforo de cores usadas para a tela de Integrações
    cardBackground1: {
        backgroundColor: '#607d8f',
        borderRadius: 8,
    },
    cardBackground2: {
        backgroundColor: '#839fad',
        borderRadius: 8,
    },
    cardBackground3: {
        backgroundColor: '#a1bdcb',
        borderRadius: 8,
    },
});
