import { joiResolver } from '@hookform/resolvers/joi';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import {
    CircularProgress,
    Grid,
    IconButton,
    InputAdornment,
    useMediaQuery,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import JoiCpfCnpj from 'cpf-cnpj-validator';
import { default as JoiOriginal } from 'joi';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import { useLocation as useLocationDom } from 'react-router-dom';
import loginImg from '../../Assets/Images/background.jpg';
import { useAuth } from '../../AuthProvider';
import InputCnpj from '../../Components/Input/InputCnpj';
import SelectServers from '../../Components/Select/SelectServers';
import GerenciadorTenancyService from '../../Config/services/GerenciadorTenancyService';
import useLocalhost from '../../hooks/useLocalhost';
import Empresa from '../../Models/Empresa';
import LoginResponse from '../../Models/responses/LoginResponse';
import Server from '../../Models/Server';
import {
    ChavesArmazenamentoStorage,
    StatusRequisicao,
} from '../../Models/Usuario';
import { IdentificadorProduto } from '../../types/IdentificadorProduto';
import { styles } from './helpers/Styles';
import ModalEsqueciMinhaSenha from './ModalEsqueciMinhaSenha';

type CamposFormulario = {
    usuario: string;
    senha: string;
    cnpj: string;
    server: Server | null;
};

const gerenciadorTenancyService = new GerenciadorTenancyService(
    'https://mobile.maxiconsystems.com.br/management/mobile/get'
);

export default function Login() {
    const isMobile = useMediaQuery('(max-width: 789px)');
    const motivoLoginUsuario = new URLSearchParams(useLocationDom().search).get(
        'reason'
    );

    const [mostrarModalEsqueciMinhaSenha, setMostrarModalEsqueciMinhaSenha] =
        useState<boolean>(false);

    const [isLocalhost, setIsLocalhost] = useState<boolean>();

    const getUsuarioLogado = async () => {
        let loginResponse = localStorage.getItem(
            ChavesArmazenamentoStorage.LOGIN_RESPONSE
        );

        if (loginResponse) {
            return (JSON.parse(loginResponse) as LoginResponse).usuario;
        }
    };

    useEffect(() => {
        const urlEnv = process.env.REACT_APP_BACKEND_URL;

        if (urlEnv && urlEnv === 'http://localhost:8089/v1') {
            setIsLocalhost(true);
        } else {
            setIsLocalhost(false);
        }
    }, []);

    const [cnpj, setCnpj] = useState<string>();

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    const [empresaSelecionada, setEmpresaSelecionada] = useState<Empresa>();

    const [isLogando, setIsLogando] = useState<boolean>(false);
    const [isBuscandoBase, setIsBuscandoBase] = useState<boolean>(false);

    const navigate = useNavigate();
    const location = useLocation();
    const auth = useAuth();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { getServer } = useLocalhost();

    let from: string = '/easymbark';
    if (location.state && (location.state as any).from.pathname) {
        from = (location.state as any).from.pathname || '/';
    }

    useEffect(() => {
        if (motivoLoginUsuario) {
            enqueueSnackbar(motivoLoginUsuario, {
                variant: 'info',
                onClick: () => {
                    closeSnackbar();
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [motivoLoginUsuario]);

    const Joi = JoiOriginal.extend(JoiCpfCnpj);

    const validacao = Joi.object({
        usuario: Joi.string().trim().required().messages({
            'string.base': 'O usuário deve ser uma string',
            'string.empty': 'O usuário não pode ficar em branco',
            'any.required': 'O usuário não pode ficar em branco',
        }),
        senha: Joi.string().trim().required().messages({
            'string.base': 'A senha deve ser uma string',
            'string.empty': 'A senha não pode ficar em branco',
            'any.required': 'A senha não pode ficar em branco',
        }),
        cnpj: Joi.document().cnpj().messages({
            'string.empty': 'O CNPJ não pode ficar em branco',
            'any.required': 'O CNPJ não pode ficar em branco',
        }),
        server: Joi.object({
            _id: Joi.string().required().messages({
                'string.base': 'Não pode ficar em branco',
                'string.required': 'Não pode ficar em branco',
            }),
        })
            .unknown()
            .messages({
                'object.base': 'Não pode ficar em branco',
            }),
    });

    const {
        control,
        handleSubmit,
        register,
        setValue,
        watch,
        formState: { errors },
    } = useForm<CamposFormulario>({
        resolver: joiResolver(validacao),
        criteriaMode: 'all',
    });

    const watchServidor = watch('server');

    useEffect(() => {
        const clientString = localStorage.getItem(
            ChavesArmazenamentoStorage.CLIENT
        );

        if (clientString) {
            const clientConvertido = JSON.parse(clientString) as Empresa;

            setCnpj(clientConvertido.cnpj);

            setEmpresaSelecionada(clientConvertido);
        }
    }, [setValue]);

    const buscarBaseEmpresa = async (cnpj: string) => {
        setIsBuscandoBase(true);
        const cnpjValidado = cnpj.replace(/\D/g, '');

        if (cnpjValidado.length !== 14) {
            enqueueSnackbar('CNPJ inválido!', {
                variant: 'info',
                onClick: () => {
                    closeSnackbar();
                },
            });
            setIsBuscandoBase(false);
            return;
        }
        try {
            const resposta = await gerenciadorTenancyService.api.get(
                cnpjValidado,
                {
                    headers: {
                        product: IdentificadorProduto.Easymbark,
                    },
                }
            );

            if (resposta.status === StatusRequisicao.OK) {
                setEmpresaSelecionada(resposta.data);
                setIsBuscandoBase(false);
            }
            setIsBuscandoBase(false);
        } catch (error) {
            setIsBuscandoBase(false);
        }
    };

    function submitFormulario(dadosFormulario: CamposFormulario) {
        setIsLogando(true);

        localStorage.removeItem(ChavesArmazenamentoStorage.HOST);

        localStorage.setItem(
            ChavesArmazenamentoStorage.CLIENT,
            JSON.stringify(empresaSelecionada)
        );

        if (!isLocalhost) {
            localStorage.setItem(
                ChavesArmazenamentoStorage.HOST,
                JSON.stringify(dadosFormulario.server)
            );
        } else {
            localStorage.setItem(
                ChavesArmazenamentoStorage.HOST,
                JSON.stringify(getServer())
            );
        }

        const urlEnviar = isLocalhost
            ? 'http://localhost:8089/v1'
            : dadosFormulario.server?.host +
              ':' +
              dadosFormulario.server?.port +
              '/v1';

        auth.signin(
            dadosFormulario.usuario,
            dadosFormulario.senha,
            urlEnviar,
            (irPara: string) => {
                navigate(irPara, {
                    replace: true,
                    state: {
                        mensagem:
                            'Você recebeu uma senha temporaria. Altere-a para proteger sua conta.',
                    },
                });
                setIsLogando(false);
            },
            (mensagemErro: string) => {
                enqueueSnackbar(mensagemErro, {
                    variant: 'error',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
                setIsLogando(false);
            }
        );
    }

    function onKeyDown(event: any) {
        if (event.key === 'Tab') { 
            buscarBaseEmpresa(cnpj || '');
        }
    }
    return (
        <Grid container>
            <Grid
                item
                xs={7}
                sx={{
                    display: isMobile ? 'none' : 'block',
                    height: '100vh',
                }}
            >
                <img alt="loginImg" src={loginImg} style={styles.img} />
            </Grid>
            <Grid
                item
                xs={5}
                style={{
                    display: isMobile ? 'contents' : 'flex',
                    backgroundColor: '#0E366B',
                    justifyContent: 'center',
                }}
            >
                <Box
                    component="form"
                    onSubmit={handleSubmit(submitFormulario)}
                    noValidate
                    autoComplete="off"
                    sx={[styles.boxForm, { boxShadow: 3 }]}
                >
                    <Grid container spacing={1} columns={10}>
                        <Grid item xs={8}>
                            <InputCnpj
                                value={cnpj}
                                margin="normal"
                                autoComplete="off"
                                fullWidth
                                label="CNPJ"
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    setCnpj(
                                        (event.target as HTMLInputElement).value
                                    );
                                }}
                                helperText={
                                    'Informe o CNPJ da empresa para buscar os servidores disponíveis.'
                                }
                               onKeyDown={onKeyDown}
                                
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => {
                                    buscarBaseEmpresa(cnpj || '');
                                }}
                                disabled={isBuscandoBase}
                                sx={styles.buttonSearch}
                            >
                                {!isBuscandoBase ? (
                                    <Box sx={{ display: 'flex' }}>
                                        <SearchIcon />
                                    </Box>
                                ) : (
                                    <Box sx={{ display: 'flex' }}>
                                        <CircularProgress
                                            size={25}
                                            style={styles.circularProgress}
                                        />
                                    </Box>
                                )}
                            </Button>
                        </Grid>
                    </Grid>

                    <Controller
                        control={control}
                        name="server"
                        defaultValue={null}
                        render={(props) => {
                            return (
                                <SelectServers
                                    {...props}
                                    servers={
                                        empresaSelecionada &&
                                        empresaSelecionada.servers
                                            ? empresaSelecionada.servers
                                            : []
                                    }
                                    options={[]}
                                    inputRef={props.field.ref}
                                    label={'Servidor'}
                                    disabled={!empresaSelecionada}
                                    required
                                    onChange={(_: any, server: any) => {
                                        props.field.onChange(server);
                                    }}
                                    fullWidth
                                    error={!!errors.server}
                                    helperText={
                                        errors.server
                                            ? (errors.server as any).message
                                            : empresaSelecionada
                                            ? 'Servidores da empresa ' +
                                              empresaSelecionada.name
                                            : ''
                                    }
                                    data-id="selectServer"
                                />
                            );
                        }}
                    />

                    <Controller
                        name="usuario"
                        control={control}
                        defaultValue={''}
                        render={(props) => {
                            return (
                                <TextField
                                    {...props}
                                    {...register('usuario')}
                                    margin="normal"
                                    required
                                    fullWidth
                                    style={{ marginBottom: 0 }}
                                    disabled={!watchServidor}
                                    label="Usuário"
                                    autoComplete="username"
                                    autoFocus
                                    error={!!errors.usuario}
                                    helperText={
                                        errors.usuario
                                            ? errors.usuario.message
                                            : ''
                                    }
                                />
                            );
                        }}
                    />

                    <Controller
                        name="senha"
                        control={control}
                        defaultValue={''}
                        render={(props) => {
                            return (
                                <TextField
                                    {...props}
                                    {...register('senha')}
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Senha"
                                    disabled={!watchServidor}
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    autoComplete="current-password"
                                    error={!!errors.senha}
                                    helperText={
                                        errors.senha ? errors.senha.message : ''
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={
                                                        handleClickShowPassword
                                                    }
                                                    onMouseDown={
                                                        handleMouseDownPassword
                                                    }
                                                    edge="end"
                                                >
                                                    {showPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            );
                        }}
                    />
                    {watchServidor && (
                        <div
                            onClick={() =>
                                setMostrarModalEsqueciMinhaSenha(true)
                            }
                            style={styles.esqueciSenha}
                        >
                            Esqueci minha senha
                        </div>
                    )}

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        disabled={isLogando}
                        style={styles.buttonLogin}
                    >
                        {!isLogando ? (
                            'Entrar'
                        ) : (
                            <Box sx={{ display: 'flex' }}>
                                <CircularProgress
                                    size={25}
                                    style={styles.circularProgress}
                                />
                            </Box>
                        )}
                    </Button>

                    {watchServidor && mostrarModalEsqueciMinhaSenha && (
                        <ModalEsqueciMinhaSenha
                            url={
                                isLocalhost
                                    ? 'http://localhost:8089'
                                    : watchServidor.host +
                                      ':' +
                                      watchServidor.port
                            }
                            fecharClicado={() =>
                                setMostrarModalEsqueciMinhaSenha(false)
                            }
                            aberto={mostrarModalEsqueciMinhaSenha}
                        />
                    )}
                </Box>
            </Grid>
        </Grid>
    );
}
