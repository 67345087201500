import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Divider, List, ListItem, ListItemText } from '@mui/material';
import LoteEmbarqueTransportadora from '../../../Models/LoteEmbarqueTransportadora';
import { styles } from '../helpers/Styles';

export default function useAuditoriaCamposLote() {
    const getItensAlterados = (
        ordemCarregamentoAtual: LoteEmbarqueTransportadora,
        ordemCarregamentoAnterior: LoteEmbarqueTransportadora
    ) => {
        return (
            <List sx={styles().listAuditoria}>
                {ordemCarregamentoAtual.pesoPlanejado !==
                    ordemCarregamentoAnterior.pesoPlanejado && (
                    <ListItem>
                        <ListItemText
                            primary="Peso planejado"
                            secondary={
                                <div style={styles().divListItemText}>
                                    {ordemCarregamentoAnterior.pesoPlanejado} KG{' '}
                                    <ArrowForwardIcon
                                        fontSize="small"
                                        style={styles().arrowForwardIcon}
                                    />{' '}
                                    {ordemCarregamentoAtual.pesoPlanejado} KG
                                </div>
                            }
                        />
                    </ListItem>
                )}

                {ordemCarregamentoAnterior.status !==
                    ordemCarregamentoAtual.status && (
                    <>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                            <ListItemText
                                primary="Local da classificação"
                                secondary={
                                    <div style={styles().divListItemText}>
                                        {ordemCarregamentoAnterior.status}{' '}
                                        <ArrowForwardIcon
                                            fontSize="small"
                                            style={styles().arrowForwardIcon}
                                        />{' '}
                                        {ordemCarregamentoAtual.status}{' '}
                                    </div>
                                }
                            />
                        </ListItem>
                    </>
                )}
            </List>
        );
    };

    return {
        getItensAlterados,
    };
}
