import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
    Alert,
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
} from '@mui/material';
import ptBR from 'date-fns/locale/pt-BR';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import DividerComp from '../../../Components/Divider/DividerComp';
import InputCpf from '../../../Components/Input/InputCpf';
import InputNumero from '../../../Components/Input/InputNumero';
import SelectCategoriaCNH from '../../../Components/Select/SelectCategoriaCNH';
import SelectCidades from '../../../Components/Select/SelectCidades';
import SelectUF from '../../../Components/Select/SelectUF';
import SpinnerLoading from '../../../Components/SpinnerLoading/SpinnerLoading';
import { MensagemErroRequisicaoApi } from '../../../Config/Api';
import Util from '../../../Helpers/Util';
import ViaCepService from '../../../Helpers/ViaCepService';
import { ApiResources } from '../../../Models/Api';
import CidadeIBGE from '../../../Models/CidadeIBGE';
import { StatusRequisicao } from '../../../Models/Usuario';
import useGenericService from '../../../hooks/useGenericService';
import { styles } from '../../Configuracao/helpers/Styles';
import { Driver, Juncao, Reference } from '../types/Juncao';
import TableReferenciasMotorista from './TableReferenciasMotorista';
import { stylesTabMotorista } from './helpers/stylesTabMotorista';

export type CamposFormularioMotorista = {
    Document: string;
    Name: string;
    DateOfBirthday: Date | null;
    Document2: string; //RG
    IssueStateDocument2: string;
    IssueDateDocument2: Date | null;
    Document3: string;
    CategoryDocument3: string;
    DueDateDocument3: Date | null;
    EmitterDocument3: string;
    FirstDateDocument3: Date | null;
    FatherName: string;
    MotherName: string;
    cidadeIbge: CidadeIBGE | null; //IssueCityDocument3
    Postcode: string;
    Address: string;
    Number: string;
    County: string;
    cidadeIbgeMotorista: CidadeIBGE | null;
};

interface Props {
    radioTipoMotorista: TipoMotorista;
    useFormMethods: UseFormReturn<CamposFormularioMotorista, object>;
    referenciasAnteriores: Reference[];
    onDocumentChange: (document: string) => void;
    onMotoristaTipoChange: (tipo: TipoMotorista) => void;
    onReferenciasChange: (referencias: Reference[]) => void;
    onSaveButton: (ok: boolean) => void;
    onRadioTipoMotoristaSelecionado: (tipoMotorista: TipoMotorista) => void;
}

export enum TipoMotorista {
    ApenasMotorista = 'ApenasMotorista',
    ProprietarioConjunto = 'ProprietarioConjunto',
    ProprietarioCavalo = 'ProprietarioCavalo',
}

export const getDataFormatadaEnvioApi = (data: Date) => {
    const day = data.getDate().toString().padStart(2, '0');
    const month = (data.getMonth() + 1).toString().padStart(2, '0');
    const year = data.getFullYear().toString();

    return `${day}/${month}/${year}`;
};

export default function TabMotorista({
    onDocumentChange,
    onMotoristaTipoChange,
    useFormMethods,
    referenciasAnteriores,
    radioTipoMotorista,
    onReferenciasChange,
    onSaveButton,
    onRadioTipoMotoristaSelecionado,
}: Props) {
    const [usuarioBuscouCpf, setUsuarioBuscouCpf] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [mensagemErroBuscaMotorista, setMensagemErroBuscaMotorista] =
        useState<string>();

    const [referenciasMotorista, setReferenciasMotorista] = useState<
        Reference[]
    >(referenciasAnteriores);

    const [codeEnderecoMotorista, setCodeEnderecoMotorista] =
        useState<number>();

    const [radioSelecionado, setRadioSelecionado] =
        useState<TipoMotorista>(radioTipoMotorista);

    const [erroReferenciasMotorista, setErroReferenciasMotorista] =
        useState<string>();

    const serviceViaCep = new ViaCepService();
    const { getService } = useGenericService();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [isSalvarClicado, setIsSalvarClicado] = useState<boolean>(false);

    const {
        control,
        handleSubmit,
        setValue,
        reset,
        watch,
        getValues,
        formState: { errors },
    } = useFormMethods;

    useEffect(() => {
        if (getValues('Document') && getValues('Document').length >= 11) {
            setUsuarioBuscouCpf(true);
        }
    }, []);

    const watchDocument = watch('Document');

    useEffect(() => {
        if (watchDocument) {
            onDocumentChange(watchDocument);
        }
    }, [watchDocument, onDocumentChange]);

    const buscarMotorista = async () => {
        const document = getValues('Document');
        if (document) {
            setIsLoading(true);
            try {
                const servico = getService(ApiResources.Juncao);

                const respostaApi = await servico.api.get(
                    '/buscar-motorista-maxys-atac/' +
                        document
                            .replace('.', '')
                            .replace('.', '')
                            .replace('-', '')
                );

                if (respostaApi.status === StatusRequisicao.OK) {
                    setUsuarioBuscouCpf(true);
                    const juncao: Juncao = respostaApi.data;

                    const driver = juncao.Driver;

                    setValue('Document', driver.Document);
                    setValue('Name', driver.Name);
                    setValue(
                        'DateOfBirthday',
                        Util.converterStringParaData(driver.DateOfBirthday)
                    );
                    setValue('Document2', driver.Document2);
                    setValue(
                        'IssueDateDocument2',
                        Util.converterStringParaData(driver.IssueDateDocument2)
                    );

                    setValue('IssueStateDocument2', driver.IssueStateDocument2);

                    setValue('Document3', driver.Document3);
                    setValue('CategoryDocument3', driver.CategoryDocument3);
                    setValue(
                        'DueDateDocument3',
                        Util.converterStringParaData(driver.DueDateDocument3)
                    );
                    setValue('EmitterDocument3', driver.EmitterDocument3);
                    // setValue('IssueCityDocument3', driver.IssueCityDocument3);
                    setValue(
                        'FirstDateDocument3',
                        Util.converterStringParaData(driver.FirstDateDocument3)
                    );
                    setValue('FatherName', driver.FatherName);
                    setValue('MotherName', driver.MotherName);

                    setValue('Postcode', driver.Adresses[0].Postcode);
                    setValue('Address', driver.Adresses[0].Address);
                    setValue('County', driver.Adresses[0].County);
                    setValue('Number', driver.Adresses[0].Number);

                    setReferenciasMotorista(
                        driver.References.filter((r) => r.Name.length > 0)
                    );

                    try {
                        const respostaApi = await servico.api.get(
                            'get-cidade-ibge/' + driver.IssueCityDocument3
                        );

                        if (respostaApi.status === StatusRequisicao.OK) {
                            setValue('EmitterDocument3', respostaApi.data.uf);
                            setValue('cidadeIbge', respostaApi.data);
                        }
                    } catch (error) {
                        setIsLoading(false);
                    }

                    try {
                        const respostaApi2 = await servico.api.get(
                            'get-cidade-ibge/' + driver.Adresses[0].City
                        );

                        if (respostaApi2.status === StatusRequisicao.OK) {
                            setValue('cidadeIbgeMotorista', respostaApi2.data);
                        }
                    } catch (error) {
                        setIsLoading(false);
                    }

                    setCodeEnderecoMotorista(driver.Adresses![0]!.Code!);

                    setMensagemErroBuscaMotorista(undefined);
                } else if (respostaApi.status === 400) {
                    console.log('errors', respostaApi);
                }
            } catch (error) {
                const errorTratado: MensagemErroRequisicaoApi[] =
                    error as MensagemErroRequisicaoApi[];

                if (
                    errorTratado &&
                    errorTratado.length &&
                    errorTratado.length > 0
                ) {
                    enqueueSnackbar(errorTratado[0].mensagem, {
                        variant: 'error',
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
                } else {
                    setMensagemErroBuscaMotorista(
                        'Documento não encontrado, você pode cadastrar um novo.'
                    );
                }

                resetAllFields();

                setValue('Document', document);

                setUsuarioBuscouCpf(true);

                setIsLoading(false);
            }
        }
        setIsLoading(false);
    };

    const resetAllFields = () => {
        reset({
            Name: '',
            Address: undefined,
            CategoryDocument3: '',
            cidadeIbge: null,
            cidadeIbgeMotorista: null,
            County: undefined,
            DateOfBirthday: null,
            Document2: '',
            Document3: '',
            DueDateDocument3: null,
            EmitterDocument3: '',
            FatherName: '',
            FirstDateDocument3: null,
            IssueDateDocument2: null,
            IssueStateDocument2: '',
            MotherName: '',
            Number: undefined,
            Postcode: undefined,
        });
        setReferenciasMotorista([]);
    };

    const tratarDados = async (dadosFormulario: CamposFormularioMotorista) => {
        setIsLoading(true);
        //validar as referências
        if (referenciasMotorista.length === 0) {
            setErroReferenciasMotorista('Nenhuma referência foi adicionada!');
            setIsLoading(false);
            return;
        }

        let dadosEnviarApi: Driver = {
            Adresses: [
                {
                    Code: codeEnderecoMotorista,
                    Address: dadosFormulario.Address.toUpperCase().trim(),
                    City: Number(
                        dadosFormulario.cidadeIbgeMotorista!.idIbge
                    ).toString(),
                    County: dadosFormulario.County.toUpperCase().trim(),
                    Number: dadosFormulario.Number,
                    Postcode: dadosFormulario.Postcode,
                },
            ],
            CategoryDocument3: dadosFormulario.CategoryDocument3,
            DateOfBirthday: getDataFormatadaEnvioApi(
                dadosFormulario.DateOfBirthday!
            ),
            Document: dadosFormulario.Document.replace('.', '')
                .replace('.', '')
                .replace('-', ''),
            Document2: dadosFormulario.Document2,
            DueDateDocument3: getDataFormatadaEnvioApi(
                dadosFormulario.DueDateDocument3!
            ),
            EmitterDocument3: dadosFormulario.EmitterDocument3,
            Document3: dadosFormulario.Document3,
            FatherName: dadosFormulario.FatherName.toUpperCase().trim(),
            FirstDateDocument3: getDataFormatadaEnvioApi(
                dadosFormulario.FirstDateDocument3!
            ),
            IssueCityDocument3: Number(dadosFormulario.cidadeIbge!.idIbge),
            IssueDateDocument2: getDataFormatadaEnvioApi(
                dadosFormulario.IssueDateDocument2!
            ),
            IssueStateDocument2: dadosFormulario.IssueStateDocument2,
            MotherName: dadosFormulario.MotherName.toUpperCase().trim(),
            Name: dadosFormulario.Name.toUpperCase().trim(),
            References: referenciasMotorista,
        };

        const servico = getService(ApiResources.Juncao);

        try {
            const resultado = await servico.api.post('', dadosEnviarApi);

            if (resultado.status === StatusRequisicao.OK) {
                enqueueSnackbar('Motorista registrado com sucesso!', {
                    variant: 'success',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
                // setTimeout(() => {}, 5000);

                setIsLoading(false);
                onSaveButton(true);
            } else {
                if (resultado.status === StatusRequisicao.BAD_REQUEST) {
                    enqueueSnackbar('resultado.', {
                        variant: 'error',
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
                }
                setIsLoading(false);
            }
        } catch (error) {
            console.error(error);

            setIsLoading(false);
            (error as MensagemErroRequisicaoApi[]).forEach((erro) => {
                enqueueSnackbar(erro.atributo + ': ' + erro.mensagem, {
                    variant: 'error',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
            });
        }
    };

    useEffect(() => {
        onMotoristaTipoChange(radioSelecionado);
    }, [radioSelecionado, onMotoristaTipoChange]);

    useEffect(() => {
        if (referenciasMotorista.length > 0) {
            setErroReferenciasMotorista(undefined);
            onReferenciasChange(referenciasMotorista);
        } else {
            onReferenciasChange([]);
        }
    }, [referenciasMotorista]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    // const tratarAbaErros = () => {
    //     if (
    //         errors['Document'] ||
    //         errors['Name'] ||
    //         errors['DateOfBirthday'] ||
    //         errors['Document2'] ||
    //         errors['IssueStateDocument2'] ||
    //         errors['IssueDateDocument2'] ||
    //         errors['Document3'] ||
    //         errors['CategoryDocument3'] ||
    //         errors['DueDateDocument3'] ||
    //         errors['EmitterDocument3'] ||
    //         errors['FirstDateDocument3'] ||
    //         errors['FatherName'] ||
    //         errors['MotherName'] ||
    //         errors['cidadeIbge']
    //     ) {
    //         setTabValue('1');
    //         setIsSalvarClicado(false);
    //     } else if (
    //         (errors['Postcode'] ||
    //             errors['Address'] ||
    //             errors['Number'] ||
    //             errors['County'] ||
    //             errors['cidadeIbgeMotorista']) &&
    //         isSalvarClicado
    //     ) {
    //         setTabValue('2');
    //         setIsSalvarClicado(false);
    //     }
    // };

    // useEffect(() => {

    //     if (errors) {
    //         tratarAbaErros();
    //     }
    // }, [errors, tratarAbaErros]);

    const buscarCep = async (cep: string) => {
        try {
            const resposta = await serviceViaCep.buscarEndereco(cep);

            if (
                resposta.status === StatusRequisicao.OK &&
                !resposta?.data?.erro
            ) {
                setValue('Address', resposta?.data?.logradouro);
                setValue('County', resposta?.data?.bairro);

                const servico = getService(ApiResources.Juncao);

                try {
                    const respostaApi = await servico.api.get(
                        'get-cidade-ibge/' + resposta?.data?.ibge
                    );

                    if (respostaApi.status === StatusRequisicao.OK) {
                        setValue('cidadeIbgeMotorista', respostaApi.data);
                    }
                } catch (error) {}
            }
        } catch (error) {}
    };

    const handleTabPress = (event: any) => {
        if (event.key === 'Tab') {
            event.preventDefault(); // Impede o comportamento padrão do Tab

            // Encontra o próximo input usando o atributo `tabIndex`
            const inputs = document.getElementsByTagName('input');
            const index = Array.from(inputs).indexOf(event.target);
            const nextInput = inputs[index + 1];

            // Foca no próximo input, se existir
            if (nextInput) {
                nextInput.focus();
            }
        }
    };

    return (
        <SpinnerLoading isLoading={isLoading}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <form
                    noValidate
                    autoComplete="off"
                    //onSubmit={handleSubmit(tratarDados)}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={12}>
                            <FormControl>
                                <RadioGroup
                                    value={radioSelecionado}
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                >
                                    <FormControlLabel
                                        value={TipoMotorista.ApenasMotorista}
                                        onClick={() => {
                                            setRadioSelecionado(
                                                TipoMotorista.ApenasMotorista
                                            );
                                            onRadioTipoMotoristaSelecionado(
                                                TipoMotorista.ApenasMotorista
                                            );
                                        }}
                                        control={<Radio />}
                                        label="Apenas motorista"
                                    />
                                    <FormControlLabel
                                        value={
                                            TipoMotorista.ProprietarioConjunto
                                        }
                                        onClick={() => {
                                            setRadioSelecionado(
                                                TipoMotorista.ProprietarioConjunto
                                            );
                                            onRadioTipoMotoristaSelecionado(
                                                TipoMotorista.ProprietarioConjunto
                                            );
                                        }}
                                        control={<Radio />}
                                        label="Motorista é proprietário do conjunto"
                                    />
                                    <FormControlLabel
                                        value={TipoMotorista.ProprietarioCavalo}
                                        onClick={() => {
                                            setRadioSelecionado(
                                                TipoMotorista.ProprietarioCavalo
                                            );
                                            onRadioTipoMotoristaSelecionado(
                                                TipoMotorista.ProprietarioCavalo
                                            );
                                        }}
                                        control={<Radio />}
                                        label="Motorista é proprietário do cavalo"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={3}>
                            <Controller
                                name="Document"
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: { ref, ...field },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <InputCpf
                                            {...field}
                                            inputRef={ref}
                                            required
                                            value={field.value}
                                            // onBlur={(event) => {
                                            //     buscarMotorista();
                                            // }}
                                            margin="normal"
                                            autoComplete="new-password"
                                            fullWidth
                                            label="CPF"
                                            error={!!errors.Document}
                                            helperText={
                                                errors.Document
                                                    ? errors.Document.message
                                                    : 'Digite o CPF e busque o motorista'
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={5}>
                            <Button
                                type="button"
                                variant="contained"
                                style={stylesTabMotorista().button}
                                size="large"
                                onClick={buscarMotorista}
                            >
                                Buscar
                            </Button>
                        </Grid>
                    </Grid>
                    {mensagemErroBuscaMotorista && (
                        <Grid container spacing={2}>
                            <Grid
                                item
                                xs={12}
                                lg={12}
                                style={{ marginTop: '16px' }}
                            >
                                <Alert
                                    variant="outlined"
                                    severity="error"
                                    style={styles.alert}
                                >
                                    {mensagemErroBuscaMotorista}
                                </Alert>
                            </Grid>
                        </Grid>
                    )}

                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6}>
                            <Controller
                                name="Name"
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: { ref, ...field },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <TextField
                                            {...field}
                                            inputRef={ref}
                                            margin="normal"
                                            disabled={!usuarioBuscouCpf}
                                            required
                                            autoComplete="new-password"
                                            fullWidth
                                            onChange={(e) => {
                                                e.target.value =
                                                    e.target.value.replace(
                                                        /['",\\]/g,
                                                        ''
                                                    );

                                                field.onChange(e);
                                            }}
                                            inputProps={{
                                                maxLength: 150,
                                                style: {
                                                    textTransform: 'uppercase',
                                                },
                                            }}
                                            label="Nome"
                                            error={!!errors.Name}
                                            helperText={
                                                errors.Name
                                                    ? errors.Name.message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Controller
                                name="Document2"
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: { ref, ...field },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <InputNumero
                                            {...field}
                                            inputRef={ref}
                                            margin="normal"
                                            autoComplete="new-password"
                                            required
                                            disabled={!usuarioBuscouCpf}
                                            maxLength={20}
                                            fullWidth
                                            inputProps={{
                                                maxLength: 20,
                                            }}
                                            label="RG"
                                            error={!!errors.Document2}
                                            helperText={
                                                errors.Document2
                                                    ? errors.Document2.message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3} style={{ marginTop: '16px' }}>
                            <LocalizationProvider
                                locale={ptBR}
                                dateAdapter={AdapterDateFns}
                            >
                                <Controller
                                    name="DateOfBirthday"
                                    control={control}
                                    defaultValue={null}
                                    render={({
                                        field: { ref, ...field },
                                        fieldState: { error },
                                    }) => {
                                        return (
                                            <DatePicker
                                                inputFormat="dd/MM/yyyy"
                                                value={field.value}
                                                disabled={!usuarioBuscouCpf}
                                                onChange={field.onChange}
                                                allowSameDateSelection
                                                autoFocus={false}
                                                renderInput={(params: any) => (
                                                    <TextField
                                                        {...params}
                                                        label="Data de nascimento"
                                                        placeholder=""
                                                        style={{
                                                            color: 'blue',
                                                        }}
                                                        onKeyDown={
                                                            handleTabPress
                                                        }
                                                        error={
                                                            !!errors.DateOfBirthday
                                                        }
                                                        helperText={
                                                            errors.DateOfBirthday
                                                                ? errors
                                                                      .DateOfBirthday
                                                                      .message
                                                                : ''
                                                        }
                                                        autoComplete="new-password"
                                                        fullWidth
                                                        required
                                                    />
                                                )}
                                            />
                                        );
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={3} style={{ marginTop: '16px' }}>
                            <LocalizationProvider
                                locale={ptBR}
                                dateAdapter={AdapterDateFns}
                            >
                                <Controller
                                    name="IssueDateDocument2"
                                    control={control}
                                    defaultValue={null}
                                    render={({
                                        field: { ref, ...field },
                                        fieldState: { error },
                                    }) => {
                                        return (
                                            <DatePicker
                                                inputFormat="dd/MM/yyyy"
                                                value={field.value}
                                                disabled={!usuarioBuscouCpf}
                                                onChange={field.onChange}
                                                allowSameDateSelection
                                                renderInput={(params: any) => (
                                                    <TextField
                                                        {...params}
                                                        label="Data de emissão do RG"
                                                        placeholder=""
                                                        onKeyDown={
                                                            handleTabPress
                                                        }
                                                        error={
                                                            !!errors.IssueDateDocument2
                                                        }
                                                        helperText={
                                                            errors.IssueDateDocument2
                                                                ? errors
                                                                      .IssueDateDocument2
                                                                      .message
                                                                : ''
                                                        }
                                                        autoComplete="new-password"
                                                        fullWidth
                                                        required
                                                    />
                                                )}
                                            />
                                        );
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} lg={3} style={{ marginTop: '16px' }}>
                            <Controller
                                control={control}
                                name="IssueStateDocument2"
                                defaultValue=""
                                render={(props) => {
                                    return (
                                        <SelectUF
                                            {...props}
                                            options={[]}
                                            inputRef={props.field.ref}
                                            label={'UF de emissão do RG'}
                                            required
                                            disabled={!usuarioBuscouCpf}
                                            onChange={(
                                                _,
                                                IssueStateDocument2
                                            ) => {
                                                props.field.onChange(
                                                    IssueStateDocument2
                                                );
                                            }}
                                            fullWidth
                                            error={!!errors.IssueStateDocument2}
                                            helperText={
                                                errors.IssueStateDocument2
                                                    ? (
                                                          errors.IssueStateDocument2 as any
                                                      ).message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Controller
                                name="Document3"
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: { ref, ...field },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <InputNumero
                                            {...field}
                                            inputRef={ref}
                                            margin="normal"
                                            required
                                            fullWidth
                                            autoComplete="new-password"
                                            disabled={!usuarioBuscouCpf}
                                            maxLength={15}
                                            inputProps={{
                                                maxLength: 20,
                                            }}
                                            label="CNH"
                                            error={!!errors.Document3}
                                            helperText={
                                                errors.Document3
                                                    ? errors.Document3.message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={2} style={{ marginTop: '16px' }}>
                            <Controller
                                control={control}
                                name="CategoryDocument3"
                                defaultValue=""
                                render={(props) => {
                                    return (
                                        <SelectCategoriaCNH
                                            {...props}
                                            options={[]}
                                            inputRef={props.field.ref}
                                            label={'Categoria CNH'}
                                            required
                                            disabled={!usuarioBuscouCpf}
                                            onChange={(
                                                _,
                                                CategoryDocument3
                                            ) => {
                                                props.field.onChange(
                                                    CategoryDocument3
                                                );
                                            }}
                                            fullWidth
                                            error={!!errors.CategoryDocument3}
                                            helperText={
                                                errors.CategoryDocument3
                                                    ? (
                                                          errors.CategoryDocument3 as any
                                                      ).message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={3} style={{ marginTop: '16px' }}>
                            <LocalizationProvider
                                locale={ptBR}
                                dateAdapter={AdapterDateFns}
                            >
                                <Controller
                                    name="DueDateDocument3"
                                    control={control}
                                    defaultValue={null}
                                    render={({
                                        field: { ref, ...field },
                                        fieldState: { error },
                                    }) => {
                                        return (
                                            <DatePicker
                                                inputFormat="dd/MM/yyyy"
                                                value={field.value}
                                                disabled={!usuarioBuscouCpf}
                                                onChange={field.onChange}
                                                allowSameDateSelection
                                                renderInput={(params: any) => (
                                                    <TextField
                                                        {...params}
                                                        label="Data de vencimento CNH"
                                                        placeholder=""
                                                        onKeyDown={
                                                            handleTabPress
                                                        }
                                                        error={
                                                            !!errors.DueDateDocument3
                                                        }
                                                        helperText={
                                                            errors.DueDateDocument3
                                                                ? errors
                                                                      .DueDateDocument3
                                                                      .message
                                                                : ''
                                                        }
                                                        autoComplete="new-password"
                                                        fullWidth
                                                        required
                                                    />
                                                )}
                                            />
                                        );
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} lg={3} style={{ marginTop: '16px' }}>
                            <Controller
                                control={control}
                                name="EmitterDocument3"
                                defaultValue=""
                                render={(props) => {
                                    return (
                                        <SelectUF
                                            {...props}
                                            options={[]}
                                            inputRef={props.field.ref}
                                            disabled={!usuarioBuscouCpf}
                                            label={'UF de emissão da CNH'}
                                            required
                                            onChange={(_, EmitterDocument3) => {
                                                props.field.onChange(
                                                    EmitterDocument3
                                                );
                                            }}
                                            fullWidth
                                            error={!!errors.EmitterDocument3}
                                            helperText={
                                                errors.EmitterDocument3
                                                    ? (
                                                          errors.EmitterDocument3 as any
                                                      ).message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3} style={{ marginTop: '16px' }}>
                            <LocalizationProvider
                                locale={ptBR}
                                dateAdapter={AdapterDateFns}
                            >
                                <Controller
                                    name="FirstDateDocument3"
                                    control={control}
                                    defaultValue={null}
                                    render={({
                                        field: { ref, ...field },
                                        fieldState: { error },
                                    }) => {
                                        return (
                                            <DatePicker
                                                inputFormat="dd/MM/yyyy"
                                                value={field.value}
                                                disabled={!usuarioBuscouCpf}
                                                onChange={field.onChange}
                                                allowSameDateSelection
                                                renderInput={(params: any) => (
                                                    <TextField
                                                        {...params}
                                                        label="Data de emissão da 1ª CNH"
                                                        placeholder=""
                                                        onKeyDown={
                                                            handleTabPress
                                                        }
                                                        error={
                                                            !!errors.FirstDateDocument3
                                                        }
                                                        helperText={
                                                            errors.FirstDateDocument3
                                                                ? errors
                                                                      .FirstDateDocument3
                                                                      .message
                                                                : ''
                                                        }
                                                        autoComplete="new-password"
                                                        fullWidth
                                                        required
                                                    />
                                                )}
                                            />
                                        );
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6}>
                            <Controller
                                name="FatherName"
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: { ref, ...field },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <TextField
                                            {...field}
                                            inputRef={ref}
                                            margin="normal"
                                            required
                                            autoComplete="new-password"
                                            disabled={!usuarioBuscouCpf}
                                            fullWidth
                                            inputProps={{
                                                maxLength: 150,
                                                style: {
                                                    textTransform: 'uppercase',
                                                },
                                            }}
                                            onChange={(e) => {
                                                e.target.value =
                                                    e.target.value.replace(
                                                        /['",\\]/g,
                                                        ''
                                                    );

                                                field.onChange(e);
                                            }}
                                            label="Nome do pai"
                                            error={!!errors.FatherName}
                                            helperText={
                                                errors.FatherName
                                                    ? errors.FatherName.message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Controller
                                name="MotherName"
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: { ref, ...field },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <TextField
                                            {...field}
                                            inputRef={ref}
                                            margin="normal"
                                            disabled={!usuarioBuscouCpf}
                                            required
                                            autoComplete="new-password"
                                            fullWidth
                                            inputProps={{
                                                maxLength: 150,
                                                style: {
                                                    textTransform: 'uppercase',
                                                },
                                            }}
                                            onChange={(e) => {
                                                e.target.value =
                                                    e.target.value.replace(
                                                        /['",\\]/g,
                                                        ''
                                                    );

                                                field.onChange(e);
                                            }}
                                            label="Nome da mãe"
                                            error={!!errors.MotherName}
                                            helperText={
                                                errors.MotherName
                                                    ? errors.MotherName.message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6} style={{ marginTop: '16px' }}>
                            <Controller
                                control={control}
                                name="cidadeIbge"
                                defaultValue={null}
                                render={(props) => {
                                    return (
                                        <SelectCidades
                                            {...props}
                                            options={[]}
                                            disabled={!usuarioBuscouCpf}
                                            inputRef={props.field.ref}
                                            label={'Cidade de emissão da CNH'}
                                            required
                                            onChange={(_, cidadeIbge) => {
                                                props.field.onChange(
                                                    cidadeIbge
                                                );
                                            }}
                                            fullWidth
                                            error={!!errors.cidadeIbge}
                                            helperText={
                                                errors.cidadeIbge
                                                    ? (errors.cidadeIbge as any)
                                                          .message
                                                    : 'Pesquise pelo nome da cidade (min 3 letras)'
                                            }
                                            data-id="selectProduto"
                                        />
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>

                    <DividerComp texto="Endereço" />

                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={3}>
                            <Controller
                                name="Postcode"
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: { ref, ...field },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <TextField
                                            {...field}
                                            inputRef={ref}
                                            margin="normal"
                                            disabled={!usuarioBuscouCpf}
                                            autoComplete="new-password"
                                            fullWidth
                                            required
                                            label="CEP"
                                            inputProps={{
                                                maxLength: 8,
                                            }}
                                            onChange={(
                                                event: React.ChangeEvent<
                                                    | HTMLTextAreaElement
                                                    | HTMLInputElement
                                                >
                                            ) => {
                                                event.currentTarget.value =
                                                    event.currentTarget.value =
                                                        event.currentTarget.value.replace(
                                                            /[^0-9]/g,
                                                            ''
                                                        );

                                                field.onChange(event);
                                            }}
                                            onBlur={(event) => {
                                                buscarCep(event.target.value);
                                            }}
                                            error={!!errors.Postcode}
                                            helperText={
                                                errors.Postcode
                                                    ? errors.Postcode.message
                                                    : ''
                                            }
                                            data-id="campoCepOrigem"
                                        />
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={5}>
                            <Controller
                                name="Address"
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: { ref, ...field },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <TextField
                                            {...field}
                                            inputRef={ref}
                                            disabled={!usuarioBuscouCpf}
                                            margin="normal"
                                            autoComplete="new-password"
                                            fullWidth
                                            required
                                            inputProps={{
                                                style: {
                                                    textTransform: 'uppercase',
                                                },
                                            }}
                                            onChange={(e) => {
                                                e.target.value =
                                                    e.target.value.replace(
                                                        /['",\\]/g,
                                                        ''
                                                    );

                                                field.onChange(e);
                                            }}
                                            label="Logradouro"
                                            error={!!errors.Address}
                                            helperText={
                                                errors.Address
                                                    ? errors.Address.message
                                                    : ''
                                            }
                                            data-id="campoLogradouroOrigem"
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Controller
                                name="County"
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: { ref, ...field },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <TextField
                                            {...field}
                                            inputRef={ref}
                                            margin="normal"
                                            autoComplete="new-password"
                                            fullWidth
                                            inputProps={{
                                                style: {
                                                    textTransform: 'uppercase',
                                                },
                                            }}
                                            disabled={!usuarioBuscouCpf}
                                            label="Bairro"
                                            required
                                            onChange={(e) => {
                                                e.target.value =
                                                    e.target.value.replace(
                                                        /['",\\]/g,
                                                        ''
                                                    );

                                                field.onChange(e);
                                            }}
                                            error={!!errors.County}
                                            helperText={
                                                errors.County
                                                    ? errors.County.message
                                                    : ''
                                            }
                                            data-id="campoBairroOrigem"
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} lg={2}>
                            <Controller
                                name="Number"
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: { ref, ...field },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <InputNumero
                                            {...field}
                                            margin="normal"
                                            inputRef={ref}
                                            autoComplete="new-password"
                                            maxLength={7}
                                            disabled={!usuarioBuscouCpf}
                                            fullWidth
                                            required
                                            label="Número"
                                            error={!!errors.Number}
                                            helperText={
                                                errors.Number
                                                    ? errors.Number.message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={4} style={{ marginTop: '16px' }}>
                            <Controller
                                control={control}
                                name="cidadeIbgeMotorista"
                                defaultValue={null}
                                render={(props) => {
                                    return (
                                        <SelectCidades
                                            {...props}
                                            options={[]}
                                            inputRef={props.field.ref}
                                            label={'Cidade'}
                                            required
                                            disabled={!usuarioBuscouCpf}
                                            onChange={(
                                                _,
                                                cidadeIbgeMotorista
                                            ) => {
                                                props.field.onChange(
                                                    cidadeIbgeMotorista
                                                );
                                            }}
                                            fullWidth
                                            error={!!errors.cidadeIbgeMotorista}
                                            helperText={
                                                errors.cidadeIbgeMotorista
                                                    ? (
                                                          errors.cidadeIbgeMotorista as any
                                                      ).message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={12}
                            lg={4}
                            style={{ marginTop: '16px' }}
                        ></Grid>
                    </Grid>

                    <TableReferenciasMotorista
                        disabled={!usuarioBuscouCpf}
                        referenciasForm={referenciasMotorista}
                        referenciasAlteradas={(referencias) => {
                            setReferenciasMotorista(referencias);
                        }}
                    />
                    {erroReferenciasMotorista && (
                        <Grid container spacing={2}>
                            <Grid
                                item
                                xs={12}
                                lg={12}
                                style={{ marginTop: '16px' }}
                            >
                                <Alert
                                    variant="outlined"
                                    severity="error"
                                    style={styles.alert}
                                >
                                    {erroReferenciasMotorista}
                                </Alert>
                            </Grid>
                        </Grid>
                    )}

                    <Button
                        type="button"
                        variant="contained"
                        style={stylesTabMotorista().button}
                        size="large"
                        disabled={!usuarioBuscouCpf}
                        onClick={() => {
                            setIsSalvarClicado(true);
                            handleSubmit(tratarDados)();
                        }}
                    >
                        Continuar
                    </Button>
                </form>
            </Box>
        </SpinnerLoading>
    );
}
