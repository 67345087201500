import { joiResolver } from '@hookform/resolvers/joi';
import { Button, Container, Divider, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';
import { maskCPForCNPJ } from '../../../Components/Input/InputCpfCnpj';
import PageTitle from '../../../Components/PageTitle/PageTitle';
import SpinnerLoading from '../../../Components/SpinnerLoading/SpinnerLoading';
import { unmaskCpfCnpj } from '../../../Helpers/Util';
import { useFetchUseCase } from '../../../hooks/useFetchUseCase';
import { FrontEndPathResources } from '../../../Models/Api';
import { IOrdemColetaRequestDto } from '../domain/dtos/IOrdemColetaRequestDto';
import { FindJuncaoByFilter } from '../domain/usecases/FindJuncaoByFilter';
import { GetOrdemColetaById } from '../domain/usecases/GetOrdemColetaById';
import { GetSaldoLoteEmbarqueTransportadoraById } from '../domain/usecases/GetSaldoLoteEmbarqueTransportadoraById';
import { PutOrdemColeta } from '../domain/usecases/PutOrdemColeta';
import { OrdemColetaForm } from './components/OrdemColetaForm';
import { validacao } from './components/OrdemColetaForm/JoiValidators';
import { styles } from './components/OrdemColetaForm/Styles';
import { EStatusOrdemColeta } from '../domain/entities/EStatusOrdemColeta';

interface Props {
    putOrdemColeta: PutOrdemColeta;
    getOrdemColetaById: GetOrdemColetaById;
    getSaldoLoteEmbarqueTransportadoraById: GetSaldoLoteEmbarqueTransportadoraById;
    findJuncaoByFilter: FindJuncaoByFilter;
}

export default function Alterar({
    putOrdemColeta,
    getOrdemColetaById,
    getSaldoLoteEmbarqueTransportadoraById,
    findJuncaoByFilter,
}: Props) {
    const navigate = useNavigate();
    const location = useLocation();
    const { executePromise, loading } = useFetchUseCase();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { id: ordemColetaId } = useParams<{ id: string }>();
    console.log('idOrdemColeta = ', ordemColetaId);
    const [state] = useState(location.state as any);
    const { status, lote, produto, id: loteEmbarqueTransportadoraId } = state;
    console.log('idLoteEmbarque = ', loteEmbarqueTransportadoraId);

    const [pesoDisponivel, setPesoDisponivel] = useState<number>(0);

    const ordemColetaForm = useForm<IOrdemColetaRequestDto>({
        criteriaMode: 'all',
        //resolver: joiResolver(validacao(pesoDisponivel)),
    });

    const { handleSubmit, reset } = ordemColetaForm;

    const onSubmit = handleSubmit(async (data: IOrdemColetaRequestDto) => {
        console.log(`aqui - onSubmit`);
        const isArrayCarretaNotEmpty = data.carretas?.some(
            (carreta) => carreta.placa !== ''
        );

        if (isArrayCarretaNotEmpty) {
            const carretas = data.carretas?.filter(
                (carreta) => carreta.placa !== ''
            );
            data.carretas = carretas;
        } else {
            data.carretas = [];
        }

        const dataTratada: IOrdemColetaRequestDto = {
            ...data,
            statusOrdemColeta: data.statusOrdemColeta,
            motorista: {
                ...data.motorista,
                cpf: unmaskCpfCnpj(data.motorista.cpf),
            },
            cavalo: {
                ...data.cavalo,
                proprietarioDocumento: unmaskCpfCnpj(
                    data.cavalo.proprietarioDocumento
                ),
            },
            dataHoraColeta:
                data.dataHoraColeta &&
                new Date(data.dataHoraColeta).toISOString(),
            carretas: [
                ...(data.carretas != null
                    ? data.carretas.map((carreta) => ({
                          ...carreta,
                          proprietarioDocumento: unmaskCpfCnpj(
                              carreta.proprietarioDocumento
                          ),
                      }))
                    : []),
            ],
        };

        executePromise(
            () => putOrdemColeta.execute(dataTratada),
            () => {
                enqueueSnackbar('Alterado com sucesso!', {
                    variant: 'success',
                    onClick: () => {
                        closeSnackbar();
                        //navigate(FrontEndPathResources.OrdemColeta);
                    },
                });
                navigate(FrontEndPathResources.LoteEmbarquePorTransportadora);
            },
            (errorResponse) => {
                const erroNoEnvioProErpMaxys = errorResponse.find(
                    (error: { atributo: string; mensagem: string }) =>
                        error.atributo === 'EnvioMaxysERP' &&
                        error.mensagem.includes(
                            'OrdemColeta não integrada com o Maxys. Necessário reprocessar mais tarde.'
                        )
                );
                if (erroNoEnvioProErpMaxys) {
                    enqueueSnackbar('Registro alterado com sucesso!', {
                        variant: 'success',
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
                    enqueueSnackbar(
                        'Atenção: ' + erroNoEnvioProErpMaxys.mensagem,
                        {
                            variant: 'warning',
                            onClick: () => {
                                closeSnackbar();
                            },
                        }
                    );
                    navigate(
                        FrontEndPathResources.LoteEmbarquePorTransportadora
                    );
                } else {
                    const outroErroGenerico = errorResponse
                        .map(
                            (error: { atributo: string; mensagem: string }) =>
                                error.mensagem
                        )
                        .join(' | ');
                    enqueueSnackbar('Erro: ' + outroErroGenerico, {
                        variant: 'error',
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
                }
            }
        );
    });

    useEffect(() => {
        executePromise(
            () => getOrdemColetaById.execute(Number(ordemColetaId)),
            (response: IOrdemColetaRequestDto) => {
                reset({
                    ...response,
                    motorista: {
                        ...response.motorista,
                        cpf: maskCPForCNPJ(response.motorista.cpf),
                    },
                    cavalo: {
                        ...response.cavalo,
                        proprietarioDocumento: maskCPForCNPJ(
                            response.cavalo.proprietarioDocumento
                        ),
                    },
                });
            },
            () => {
                enqueueSnackbar('Não foi possível encontrar o registro', {
                    variant: 'error',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
            }
        );

        executePromise(
            () =>
                getSaldoLoteEmbarqueTransportadoraById.execute(
                    loteEmbarqueTransportadoraId
                ),
            (response) => {
                setPesoDisponivel(response);
            },
            () => {
                enqueueSnackbar(
                    'Saldo de peso para o Lote Embarque Transpordora não encontrado',
                    {
                        variant: 'error',
                        onClick: () => {
                            closeSnackbar();
                        },
                    }
                );
            }
        );

        reset({
            loteEmbarqueId: lote,
            statusOrdemColeta: status,
            descricaoItem: produto?.nome,
            loteEmbarqueTransportadoraId: loteEmbarqueTransportadoraId,
            cadastradaViaPortal: true,
        });
    }, [
        status,
        lote,
        produto,
        reset,
        ordemColetaId,
        executePromise,
        getOrdemColetaById,
        getSaldoLoteEmbarqueTransportadoraById,
        closeSnackbar,
    ]);

    return (
        <SpinnerLoading isLoading={loading}>
            <Container>
                <FormProvider {...ordemColetaForm}>
                    <PageTitle
                        title={'Alterando ordem de coleta ' + ordemColetaId}
                    />
                    <Divider />
                    <OrdemColetaForm
                        disabled={false}
                        pesoDisponivel={pesoDisponivel}
                        findJuncaoByFilter={findJuncaoByFilter}
                        isAlterar
                    />
                    <Grid container spacing={2} justifyContent="end">
                        <Button
                            onClick={() => {
                                console.log(`aqui - onClick`);
                                onSubmit();
                            }}
                            variant="contained"
                            style={styles().button}
                            size="large"
                        >
                            Salvar
                        </Button>
                    </Grid>
                </FormProvider>
            </Container>
        </SpinnerLoading>
    );
}
