import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Container, Divider, IconButton, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { PermissionGate } from '../../Components/Navegacao/PermissionGate';
import PageTitle from '../../Components/PageTitle/PageTitle';
import GenericComplexTable, {
    ComplexColumn,
} from '../../Components/Table/GenericComplexTable';
import Amostra from '../../Models/Amostra';
import { ApiResources, FrontEndPathResources } from '../../Models/Api';
import Usuario, {
    ChavesArmazenamentoStorage,
    RecursosPerfisEnum,
    StatusRequisicao,
} from '../../Models/Usuario';
import LoginResponse from '../../Models/responses/LoginResponse';
import useGenericService from '../../hooks/useGenericService';
import { usePermission } from '../../hooks/usePermission';
import ModalAmostrasExcluidas from './ModalAmostrasExcluidas';

export default function Listar() {
    useEffect(() => {
        let loginResponse = localStorage.getItem(
            ChavesArmazenamentoStorage.LOGIN_RESPONSE
        );

        if (loginResponse) {
            const usuario: Usuario = (
                JSON.parse(loginResponse) as LoginResponse
            ).usuario;

            if (usuario.trocarSenha) {
                window.location.pathname = FrontEndPathResources.AlterarSenha;
            }
        }
    }, []);

    const [mostrarModalItensExcluidos, setMostrarModalItensExcluidos] =
        useState<boolean>(false);

    const [amostras, setAmostras] = useState<Amostra[]>();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const { isAllowed: isPermitidoExcluir } = usePermission(
        RecursosPerfisEnum.AMOSTRAS_EXCLUIR
    );
    const { getService } = useGenericService();

    const desativarProduto = async (url: string) => {
        const servico = getService(ApiResources.Amostra);

        try {
            const resposta = await servico.api.delete(url);

            if (resposta.status === StatusRequisicao.OK) {
                enqueueSnackbar('Amostra desativado com sucesso', {
                    variant: 'success',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
                navigate(FrontEndPathResources.Amostra);
                carregar();
            }
        } catch (error) {}
    };

    const carregar = async () => {
        const servico = getService(ApiResources.Amostra);

        const resposta = await servico.api.get('');

        setAmostras(resposta?.data?.content);
    };

    const colunas: ComplexColumn[] = [
        {
            attribute: 'id',
            label: 'ID',
            id: 'id',
            sortable: true,
        },
        {
            attribute: 'nome',
            label: 'Nome',
            id: 'nome',
            sortable: true,
            minWidth: 80,
        },
        {
            attribute: 'percentualLimiteMaximo',
            label: 'Limite de classificação (%)',
            id: 'percentualLimiteMaximo',
            sortable: true,
            align: 'center',
        },
        {
            attribute: 'nome',
            label: 'Ações',
            id: 'acao',
            width: 200,
            format: (row) => {
                return (
                    <>
                        <Tooltip title="Ver" placement="top" disableInteractive>
                            <IconButton
                                aria-label="view"
                                onClick={() => {
                                    navigate(
                                        FrontEndPathResources.Amostra +
                                            '/ver/' +
                                            row.id
                                    );
                                }}
                            >
                                <RemoveRedEyeIcon />
                            </IconButton>
                        </Tooltip>
                        <PermissionGate
                            recurso={RecursosPerfisEnum.AMOSTRAS_ALTERAR}
                            redirect={''}
                        >
                            <Tooltip
                                title="Alterar"
                                placement="top"
                                disableInteractive
                            >
                                <IconButton
                                    aria-label="edit"
                                    onClick={() => {
                                        navigate(
                                            FrontEndPathResources.Amostra +
                                                '/alterar/' +
                                                row.id
                                        );
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </PermissionGate>
                        <PermissionGate
                            recurso={RecursosPerfisEnum.AMOSTRAS_EXCLUIR}
                            redirect={''}
                        >
                            <Tooltip
                                title="Desativar"
                                placement="top"
                                disableInteractive
                            >
                                <IconButton
                                    aria-label="delete"
                                    onClick={() => {
                                        desativarProduto('/' + row.id);
                                    }}
                                >
                                    <CancelIcon />
                                </IconButton>
                            </Tooltip>
                        </PermissionGate>
                    </>
                );
            },
        },
    ];

    return (
        <PermissionGate
            recurso={RecursosPerfisEnum.AMOSTRAS_VER}
            redirect={FrontEndPathResources.Dashboard}
        >
            <Container>
                <PageTitle title="Amostras" />

                <Divider />

                <ModalAmostrasExcluidas
                    aberto={mostrarModalItensExcluidos}
                    fecharClicado={() => {
                        setMostrarModalItensExcluidos(false);
                    }}
                    restaurarClicado={() => {
                        carregar();
                        setMostrarModalItensExcluidos(false);
                    }}
                />

                <GenericComplexTable
                    columnToSearch={'nome'}
                    service={getService(ApiResources.Amostra)}
                    rows={amostras}
                    columns={colunas}
                    createButtonText="Cadastrar nova"
                    linkCreateButtonText={
                        FrontEndPathResources.Amostra + '/criar'
                    }
                    mostrarIconeItensExcluidos={isPermitidoExcluir}
                    onIconeItensExcluidosClicado={() => {
                        setMostrarModalItensExcluidos(true);
                    }}
                />
            </Container>
        </PermissionGate>
    );
}
