import Server from '../Models/Server';

export default function useLocalhost() {
    const getServer = () => {
        return new Server(
            'teste',
            8089,
            9,
            0,
            'localhost',
            'http://localhost',
            'testes localhost',
            true
        );
    };

    return {
        getServer,
    };
}
