import { LocalizationProvider } from '@mui/lab';
import AuthProvider from './AuthProvider';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ptBR from 'date-fns/locale/pt-BR';
import Router from './routes';

export default function App() {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
            <AuthProvider>
                <Router />
            </AuthProvider>
        </LocalizationProvider>
    );
}
