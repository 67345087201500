import { StandardTextFieldProps, TextField } from '@mui/material';

export default function InputKG({
    onChange,
    disabled,
    inputRef,
    label,
    ...props
}: StandardTextFieldProps) {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = e.target;
        const regex = /^[0-9]*,?[0-9]{0,4}$/;
        const lastChar = value.charAt(value.length - 1);
        if (lastChar === ',' && value.length === 1) {
            value = value + '0000';
        }
        if (value.charAt(0) === ',') {
            value = '0' + value;
        }
        if (!regex.test(value)) {
            value = value.substring(0, value.length - 1);
        }
        e.target.value = value;
        if (onChange) onChange(e);
    };

    return (
        <TextField
            {...props}
            label={label}
            inputRef={inputRef}
            fullWidth
            disabled={disabled}
            InputProps={{
                endAdornment: 'KG',
                autoComplete: 'off',
            }}
            onChange={handleChange}
        />
    );
}
