import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import {
    Button,
    Card,
    CardContent,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React, { FunctionComponent, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import DividerComp from '../../../Components/Divider/DividerComp';
import InputMoeda from '../../../Components/Input/InputMoeda';
import { inputMoneyMask } from '../../../Helpers/Util';
import { EUnidadesMedida } from '../../OrdemColeta/domain/entities/EUnidadesMedida';
import { styles } from '../../PerfilUsuario/helpers/Styles';
import { IDoubleCheck } from '../entities/IDoubleCheck';
import ContratosModal from './ContratosModal';

interface NotaFiscalFormProps {}

const NotaFiscalForm: FunctionComponent<NotaFiscalFormProps> = () => {
    const { control, setValue, getValues, reset } =
        useFormContext<IDoubleCheck>();

    const { fields } = useFieldArray({
        control,
        name: 'notas',
    });

    const [modalContratos, setModalContratos] = useState(false);

    const [modalVinculoContratoIndex, setModalVinculoContratoIndex] =
        useState(0);

    return (
        <>
            {fields.map((nota, index) => {
                return (
                    <React.Fragment key={index}>
                        <DividerComp
                            texto={`Nota ${
                                nota.numeroNfe ? nota.numeroNfe : nota.numeroNf
                            }`}
                        />
                        <Grid container item spacing={2}>
                            {nota.numeroNfe && (
                                <Grid item xs={12} lg={4}>
                                    <Controller
                                        name={`notas.${index}.chaveNota`}
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: {
                                                ref,
                                                onChange,
                                                onBlur,
                                                value,
                                                ...field
                                            },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                                {...field}
                                                margin="normal"
                                                fullWidth
                                                value={value}
                                                label="Chave de acesso"
                                                error={!!error?.message}
                                                helperText={
                                                    error?.message
                                                        ? error?.message
                                                        : ''
                                                }
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={onChange}
                                                disabled={true}
                                            />
                                        )}
                                    />
                                </Grid>
                            )}
                            {nota.numeroNf && (
                                <Grid item xs={12} lg={4}>
                                    <Controller
                                        name={`notas.${index}.numeroNf`}
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: {
                                                ref,
                                                onChange,
                                                onBlur,
                                                value,
                                                ...field
                                            },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                                {...field}
                                                margin="normal"
                                                fullWidth
                                                value={value}
                                                label="Nota fiscal"
                                                error={!!error?.message}
                                                helperText={
                                                    error?.message
                                                        ? error?.message
                                                        : ''
                                                }
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(event) => {
                                                    let { value } =
                                                        event.target;
                                                    value = value.slice(0, 9);
                                                    const onlyNums =
                                                        value.replace(
                                                            /[^0-9]/g,
                                                            ''
                                                        );
                                                    onChange(onlyNums);
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                            )}
                            {nota.numeroNfe && (
                                <Grid item xs={12} lg={4}>
                                    <Controller
                                        name={`notas.${index}.numeroNfe`}
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: {
                                                ref,
                                                onChange,
                                                onBlur,
                                                value,
                                                ...field
                                            },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                                {...field}
                                                margin="normal"
                                                fullWidth
                                                value={value}
                                                label="Nota fiscal eletrônica"
                                                error={!!error?.message}
                                                helperText={
                                                    error?.message
                                                        ? error?.message
                                                        : ''
                                                }
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(event) => {
                                                    let { value } =
                                                        event.target;
                                                    value = value.slice(0, 9);
                                                    const onlyNums =
                                                        value.replace(
                                                            /[^0-9]/g,
                                                            ''
                                                        );
                                                    onChange(onlyNums);
                                                }}
                                                disabled={true}
                                            />
                                        )}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12} lg={1}>
                                <Controller
                                    name={`notas.${index}.serie`}
                                    control={control}
                                    defaultValue={''}
                                    render={({
                                        field: {
                                            ref,
                                            onChange,
                                            onBlur,
                                            value,
                                            ...field
                                        },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            {...field}
                                            margin="normal"
                                            fullWidth
                                            value={value}
                                            label="Serie"
                                            error={!!error?.message}
                                            helperText={
                                                error?.message
                                                    ? error?.message
                                                    : ''
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => {
                                                let { value } = event.target;
                                                value = value.slice(0, 6);
                                                const onlyNums = value.replace(
                                                    /[^0-9]/g,
                                                    ''
                                                );
                                                onChange(onlyNums);
                                            }}
                                            disabled={nota.numeroNf == null}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} lg={2}>
                                <Controller
                                    name={`notas.${index}.dataEmissao`}
                                    control={control}
                                    defaultValue={''}
                                    render={({
                                        field: {
                                            ref,
                                            onChange,
                                            onBlur,
                                            value,
                                            ...field
                                        },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            {...field}
                                            margin="normal"
                                            fullWidth
                                            value={
                                                value
                                                    ? format(
                                                          new Date(value),
                                                          'dd/MM/yyyy'
                                                      )
                                                    : ''
                                            }
                                            label="Data"
                                            error={!!error?.message}
                                            helperText={
                                                error?.message
                                                    ? error?.message
                                                    : ''
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={onChange}
                                            disabled={nota.numeroNf == null}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} lg={2}>
                                <Controller
                                    name={`notas.${index}.peso`}
                                    control={control}
                                    defaultValue={0}
                                    render={({
                                        field: {
                                            ref,
                                            onChange,
                                            onBlur,
                                            value,
                                            ...field
                                        },
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            {...field}
                                            margin="normal"
                                            fullWidth
                                            value={value}
                                            label="Quantidade"
                                            error={!!error?.message}
                                            helperText={
                                                error?.message
                                                    ? error?.message
                                                    : ''
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(event) => {
                                                let { value } = event.target;
                                                value = value.slice(0, 15);
                                                const onlyNums = value.replace(
                                                    /[^0-9]/g,
                                                    ''
                                                );
                                                onChange(onlyNums);
                                            }}
                                            disabled={nota.numeroNf == null}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item lg={1} mt={2}>
                                <Controller
                                    name={`notas.${index}.unidadeMedida`}
                                    control={control}
                                    defaultValue={EUnidadesMedida.KG}
                                    render={({
                                        field: {
                                            ref,
                                            value,
                                            onChange,
                                            ...field
                                        },
                                        fieldState: { error },
                                    }) => (
                                        <FormControl fullWidth>
                                            <InputLabel id="Unidade" shrink>
                                                Unidade
                                            </InputLabel>
                                            <Select
                                                value={value}
                                                label="Unidade"
                                                onChange={onChange}
                                                placeholder="Unidade"
                                                disabled={nota.numeroNf == null}
                                            >
                                                {Object.values(
                                                    EUnidadesMedida
                                                ).map((unidadeMedida) => (
                                                    <MenuItem
                                                        key={unidadeMedida}
                                                        value={unidadeMedida}
                                                    >
                                                        {unidadeMedida}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {error ? (
                                                <span
                                                    style={
                                                        styles()
                                                            .mensagemErrorStyle
                                                    }
                                                >
                                                    {error}
                                                </span>
                                            ) : (
                                                <></>
                                            )}
                                        </FormControl>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} lg={2}>
                                <Controller
                                    name={`notas.${index}.valorTotal`}
                                    control={control}
                                    defaultValue={0}
                                    render={({
                                        field: {
                                            ref,
                                            onChange,
                                            onBlur,
                                            value,
                                            ...field
                                        },
                                        fieldState: { error },
                                    }) => (
                                        <InputMoeda
                                            {...field}
                                            margin="normal"
                                            fullWidth
                                            value={inputMoneyMask(value)}
                                            label="Valor total"
                                            error={!!error?.message}
                                            helperText={
                                                error?.message
                                                    ? error?.message
                                                    : ''
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={onChange}
                                            disabled={true}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item container xs={12} lg={12}>
                                <Grid item xs={12} lg={3}>
                                    <Controller
                                        name={`notas.${index}.danfe`}
                                        control={control}
                                        render={({
                                            field: {
                                                ref,
                                                value,
                                                onChange,
                                                ...field
                                            },
                                            fieldState: { error },
                                        }) => (
                                            <TextField
                                                {...field}
                                                margin="normal"
                                                disabled={true}
                                                fullWidth
                                                value={value ? value.nome : ''}
                                                onChange={onChange}
                                                label="Danfe"
                                                error={!!error?.message}
                                                helperText={
                                                    error?.message
                                                        ? error?.message
                                                        : ''
                                                }
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    lg={0.5}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Tooltip
                                        title="Baixar"
                                        placement="top"
                                        disableInteractive
                                    >
                                        <IconButton
                                            aria-label="baixar"
                                            onClick={() => {
                                                if (
                                                    getValues(
                                                        `notas.${index}.danfe`
                                                    )
                                                ) {
                                                    const ancoraParaDownload =
                                                        document.createElement(
                                                            'a'
                                                        );
                                                    ancoraParaDownload.download =
                                                        'danfe_' +
                                                        getValues(
                                                            `notas.${index}.danfe.nome`
                                                        );
                                                    ancoraParaDownload.href =
                                                        getValues(
                                                            `notas.${index}.danfe.stringBase64`
                                                        );
                                                    ancoraParaDownload.click();
                                                }
                                            }}
                                        >
                                            <DownloadIcon fontSize="medium" />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <Button
                                        onClick={() => {
                                            setValue(
                                                `notas.${index}.danfe`,
                                                null
                                            );
                                            reset({ ...getValues() });
                                        }}
                                        variant="contained"
                                        style={styles().button}
                                        size="large"
                                    >
                                        Excluir DANFe
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={2}>
                            <Grid item xs={12} lg={3}>
                                <Button
                                    onClick={() => {
                                        setModalVinculoContratoIndex(index);
                                        setModalContratos(true);
                                    }}
                                    variant="contained"
                                    style={styles().button}
                                    size="large"
                                >
                                    Vincular contrato(s)
                                </Button>
                            </Grid>
                        </Grid>
                        {modalContratos && (
                            <>
                                <ContratosModal
                                    open={modalContratos}
                                    onClose={() => setModalContratos(false)}
                                    nestedIndex={modalVinculoContratoIndex}
                                />
                            </>
                        )}
                        <DividerComp texto={`Contrato(s) vinculados a nota`} />
                        <Grid container item spacing={2}>
                            {nota.contratos &&
                                nota.contratos.map((contrato, i) => {
                                    return (
                                        <Grid item xs={12} lg={1.3} key={i}>
                                            <Card key={i}>
                                                <CardContent
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'space-around',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Typography>
                                                        Contrato:{' '}
                                                        {
                                                            contrato.numeroContrato
                                                        }
                                                    </Typography>
                                                    <IconButton
                                                        onClick={() => {
                                                            nota?.contratos?.splice(
                                                                i
                                                            );
                                                            reset({
                                                                ...getValues(),
                                                            });
                                                        }}
                                                        aria-label="delete"
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    );
                                })}
                        </Grid>
                    </React.Fragment>
                );
            })}
        </>
    );
};

export default NotaFiscalForm;
