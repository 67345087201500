import Formulario, { CamposFormulario } from './Formulario';

import { Container, Divider } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { PermissionGate } from '../../Components/Navegacao/PermissionGate';
import PageTitle from '../../Components/PageTitle/PageTitle';
import SpinnerLoading from '../../Components/SpinnerLoading/SpinnerLoading';
import { MensagemErroRequisicaoApi } from '../../Config/Api';
import { ApiResources, FrontEndPathResources } from '../../Models/Api';
import Usuario, {
    ChavesArmazenamentoStorage,
    RecursosPerfisEnum,
    StatusRequisicao,
} from '../../Models/Usuario';
import LoginResponse from '../../Models/responses/LoginResponse';
import useGenericService from '../../hooks/useGenericService';

export default function Criar() {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { getService } = useGenericService();

    const navigate = useNavigate();

    useEffect(() => {
        let loginResponse = localStorage.getItem(
            ChavesArmazenamentoStorage.LOGIN_RESPONSE
        );

        if (loginResponse) {
            const usuario: Usuario = (
                JSON.parse(loginResponse) as LoginResponse
            ).usuario;

            if (usuario.trocarSenha) {
                window.location.pathname = FrontEndPathResources.AlterarSenha;
            }
        }
    }, []);

    const onSubmit = async (dadosFormulario: CamposFormulario) => {
        setIsLoading(true);
        const servico = getService(ApiResources.Usuario);

        try {
            const resultado = await servico.api.post('', {
                ...dadosFormulario,
                cpf:
                    dadosFormulario.cpfCnpj?.length === 11
                        ? dadosFormulario.cpfCnpj
                        : null,
                cnpj:
                    dadosFormulario.cpfCnpj?.length === 14
                        ? dadosFormulario.cpfCnpj
                        : null,
            });

            if (resultado.status === StatusRequisicao.CREATED) {
                enqueueSnackbar('Usuário cadastrado com sucesso!', {
                    variant: 'success',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
                navigate(FrontEndPathResources.CadastroUsuario);
                setIsLoading(false);
            } else {
                setIsLoading(false);
                if (resultado.status === StatusRequisicao.BAD_REQUEST) {
                    enqueueSnackbar('resultado.', {
                        variant: 'error',
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
                }
            }
        } catch (error) {
            setIsLoading(false);
            (error as MensagemErroRequisicaoApi[]).forEach((erro) => {
                enqueueSnackbar(erro.atributo + ': ' + erro.mensagem, {
                    variant: 'error',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
            });
        }
    };

    return (
        <PermissionGate
            recurso={RecursosPerfisEnum.CADASTRO_USUARIOS_CRIAR}
            redirect={FrontEndPathResources.CadastroUsuario}
        >
            <SpinnerLoading isLoading={isLoading}>
                <Container>
                    <PageTitle title={'Cadastrar Usuário'} />
                    <Divider />
                    <Formulario onSubmit={onSubmit} isCriar />
                </Container>
            </SpinnerLoading>
        </PermissionGate>
    );
}
