import AutorenewIcon from '@mui/icons-material/Autorenew';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Tooltip,
} from '@mui/material';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import GenericBasicTable, {
    ComplexColumn,
} from '../../Components/Table/GenericBasicTable';
import { ApiResources } from '../../Models/Api';
import OrdemCarregamento from '../../Models/OrdemCarregamento';
import { StatusRequisicao } from '../../Models/Usuario';
import useGenericService from '../../hooks/useGenericService';
import { styles } from './helpers/Styles';

interface Props {
    aberto: boolean;
    fecharClicado: () => void;
    restaurarClicado: () => void;
}

export default function ModalOrdensExcluidas({
    aberto,
    fecharClicado,
    restaurarClicado,
}: Props) {
    const [ordens, setOrdens] = useState<OrdemCarregamento[]>();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { getService } = useGenericService();

    useEffect(() => {
        const buscarOrdensDesativadas = async () => {
            const service = getService(ApiResources.OrdemCarregamento);

            try {
                const resposta = await service.api.get('/desativadas');
                if (resposta.status === StatusRequisicao.OK) {
                    setOrdens(resposta?.data?.content);
                }
            } catch (error) {}
        };

        buscarOrdensDesativadas();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aberto]);

    const restaurarOrdem = async (ordemId: number) => {
        const service = getService(ApiResources.OrdemCarregamento);

        try {
            const resposta = await service.api.put('/restaurar/' + ordemId, {});

            if (resposta.status === StatusRequisicao.OK) {
                enqueueSnackbar('Ordem restaurada com sucesso', {
                    variant: 'success',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
                restaurarClicado();
            }
        } catch (error) {}
    };

    const colunas: ComplexColumn[] = [
        {
            attribute: 'id',
            label: 'ID',
            id: 'id',
            align: 'left',
            width: 50,
        },
        {
            attribute: 'codigoContrato',
            label: 'Contrato',
            id: 'codigoContrato',
            sortable: true,
        },
        {
            attribute: 'produto.nome',
            label: 'Produto',
            id: 'produto.nome',
            sortable: true,
            format: (row) => row.produto.nome,
        },
        {
            attribute: 'classificador.nome',
            label: 'Classificador',
            id: 'classificador.nome',
            sortable: true,
            format: (row) => row.classificador.nome,
        },
        {
            attribute: 'pesoPlanejado',
            label: 'Peso planejado',
            id: 'pesoPlanejado',
            sortable: true,
            format: (row) => row.pesoPlanejado + ' KG',
        },
        {
            attribute: 'dataHoraDeletado',
            label: 'Data da exclusão',
            id: 'dataHoraDeletado',
            align: 'left',
            width: 150,
            format: (row) =>
                format(new Date(row.dataHoraDeletado), 'dd/MM/Y').toString(),
        },
        {
            attribute: 'nome',
            label: 'Ações',
            id: 'acao',
            width: 50,
            format: (row) => {
                return (
                    <>
                        {row.cadastradaPeloPortal && (
                            <Tooltip
                                title="Restaurar"
                                placement="top"
                                disableInteractive
                            >
                                <IconButton
                                    aria-label="delete"
                                    onClick={() => {
                                        restaurarOrdem(row.id);
                                    }}
                                >
                                    <AutorenewIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                );
            },
        },
    ];

    return (
        <Dialog
            open={aberto}
            onClose={() => fecharClicado()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
        >
            <DialogTitle id="alert-dialog-title">Ordens excluídas</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} style={styles().gridExcluidas}>
                    <GenericBasicTable columns={colunas} rows={ordens} />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => fecharClicado()} autoFocus>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
