import { StyleSheet } from '../../../StyleSheet';

export const styles = StyleSheet.create({
    boxFooter: {
        position: 'fixed',
        bottom: 0,
    },
    linkFooter: {
        color: '#6C6C80',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        borderTop: '1px #BDBDBD solid',
        paddingBlock: '5px',
        marginTop: '2px',
        background: '#FFFFFF',
    },
    typographyLink: {
        paddingInline: '2%',
        fontSize: '70%',
    },
    imgLink: { width: '6%', height: '6%' },
});
