import { joiResolver } from '@hookform/resolvers/joi';
import {
    Button,
    Container,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Tooltip,
} from '@mui/material';
import Joi from 'joi';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import DividerComp from '../../Components/Divider/DividerComp';
import SelectClassificador from '../../Components/Select/SelectClassificador';
import SelectClienteFornecedor from '../../Components/Select/SelectClienteFornecedor';
import SelectProduto from '../../Components/Select/SelectProduto';
import SelectTransportadora from '../../Components/Select/SelectTransportadora';
import ViaCepService from '../../Helpers/ViaCepService';
import { FrontEndPathResources } from '../../Models/Api';
import Classificador from '../../Models/Classificador';
import ClienteFornecedor from '../../Models/ClienteFornecedor';
import Endereco from '../../Models/Endereco';
import OrdemCarregamento from '../../Models/OrdemCarregamento';
import Produto from '../../Models/Produto';
import Transportadora from '../../Models/Transportadora';
import { StatusRequisicao } from '../../Models/Usuario';
import { styles } from './helpers/Styles';

export enum TipoOrdem {
    Venda = 'Venda',
    Compra = 'Compra',
}

export enum LocalClassificacao {
    origem = 'ORIGEM',
    destino = 'DESTINO',
}

export enum Status {
    ABERTA = 'Aberta',
    ATENDIDAPARCIALMENTE = 'Atendida Parcialmente',
    EXCEDIDA = 'Excedida',
    FINALIZADA = 'Finalizada',
    CANCELADA = 'Cancelada',
}

type CamposFormulario = {
    codigoContrato: string;
    cliforCadastrado: ClienteFornecedor | null;
    produtoCadastrado: Produto | null;
    pesoPlanejado: string;
    classificadorCadastrado: Classificador | null;
    enderecamentoOrigem: Endereco;
    enderecamentoDestino: Endereco;
    transportadoraCadastrada?: Transportadora | null;
};

export interface CamposFormularioTratados
    extends Omit<
        CamposFormulario,
        | 'cliforCadastrado'
        | 'produtoCadastrado'
        | 'classificadorCadastrado'
        | 'transportadoraCadastrada'
    > {
    tipoNegociacao: TipoOrdem;
    localClassificacao: LocalClassificacao;
    clifor_id: number;
    produto_id: number;
    classificador_id: number;
    transportadora_id: number | null;
}

interface Props {
    disabled?: boolean;
    onSubmit?: (dadosFormulario: CamposFormularioTratados) => {};
    ordemCarregamento?: OrdemCarregamento;
    naoPermitirEdicao?: boolean;
}

const serviceViaCep = new ViaCepService();

export default function Formulario({
    disabled = false,
    onSubmit,
    ordemCarregamento,
    naoPermitirEdicao = false,
}: Props) {
    const [tipoOrdem, setTipoOrdem] = useState<TipoOrdem>(TipoOrdem.Venda);
    const [localClassificacao, setLocalClassificacao] =
        useState<LocalClassificacao>(LocalClassificacao.origem);

    const navigate = useNavigate();

    const validacao = Joi.object({
        codigoContrato: Joi.string().max(30).required().messages({
            'string.base': 'Não pode ficar em branco',
            'string.max': 'Não deve ser maior do que 30 caracteres',
            'string.required': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
        }),
        cliforCadastrado: Joi.object({
            id: Joi.number().required().messages({
                'number.base': 'Não pode ficar em branco',
                'number.required': 'Não pode ficar em branco',
            }),
        })
            .unknown()
            .messages({
                'object.base': 'Não pode ficar em branco',
            }),
        produtoCadastrado: Joi.object({
            id: Joi.number().required().messages({
                'number.base': 'Não pode ficar em branco',
                'number.required': 'Não pode ficar em branco',
            }),
        })
            .unknown()
            .messages({
                'object.base': 'Não pode ficar em branco',
            }),
        pesoPlanejado: Joi.number().required().messages({
            'number.base': 'Não pode ficar em branco',
            'number.required': 'Não pode ficar em branco',
        }),
        classificadorCadastrado: Joi.object({
            id: Joi.number().required().integer().messages({
                'number.base': 'Não pode ficar em branco',
                'number.required': 'Não pode ficar em branco',
            }),
        })
            .unknown()
            .messages({
                'object.base': 'Não pode ficar em branco',
            }),
        transportadoraCadastrada: Joi.optional(),
        enderecamentoOrigem: Joi.object({
            id: Joi.number().optional(),
            cep: Joi.string().max(10).allow('', null).messages({
                'string.max': 'Deve ter no máximo 10 caracteres',
            }),
            logradouro: Joi.string().max(100).allow('', null).messages({
                'string.max': 'Deve ter no máximo 100 caracteres',
            }),
            bairro: Joi.string().max(100).allow('', null).messages({
                'string.max': 'Deve ter no máximo 100 caracteres',
            }),
            cidade: Joi.string().max(150).required().messages({
                'string.base': 'Não pode ficar em branco',
                'string.max': 'Deve ter no máximo 150 caracteres',
                'string.required': 'Não pode ficar em branco',
                'string.empty': 'Não pode ficar em branco',
            }),
            estado: Joi.string().max(2).required().messages({
                'string.base': 'Não pode ficar em branco',
                'string.required': 'Não pode ficar em branco',
                'string.empty': 'Não pode ficar em branco',
                'string.max': 'Deve ter no máximo 1250 caracteres',
            }),
            numero: Joi.string().max(6).required().messages({
                'string.base': 'Não pode ficar em branco',
                'string.required': 'Não pode ficar em branco',
                'string.empty': 'Não pode ficar em branco',
                'string.max': 'Deve ter no máximo 6 caracteres',
            }),
            complemento: Joi.string().allow('', null),
            ibge: Joi.string().allow('', null),
        }),
        enderecamentoDestino: Joi.object({
            id: Joi.number().optional(),
            cep: Joi.string().max(10).allow('', null).messages({
                'string.max': 'Deve ter no máximo 10 caracteres',
            }),
            logradouro: Joi.string().max(100).allow('', null).messages({
                'string.max': 'Deve ter no máximo 100 caracteres',
            }),
            bairro: Joi.string().max(100).allow('', null).messages({
                'string.max': 'Deve ter no máximo 100 caracteres',
            }),
            cidade: Joi.string().max(150).required().messages({
                'string.base': 'Não pode ficar em branco',
                'string.required': 'Não pode ficar em branco',
                'string.empty': 'Não pode ficar em branco',
                'string.max': 'Deve ter no máximo 150 caracteres',
            }),
            estado: Joi.string().max(2).required().messages({
                'string.base': 'Não pode ficar em branco',
                'string.required': 'Não pode ficar em branco',
                'string.empty': 'Não pode ficar em branco',
                'string.max': 'Deve ter no máximo 2 caracteres',
            }),
            numero: Joi.string().max(6).required().messages({
                'string.base': 'Não pode ficar em branco',
                'string.required': 'Não pode ficar em branco',
                'string.empty': 'Não pode ficar em branco',
                'string.max': 'Deve ter no máximo 6 caracteres',
            }),
            complemento: Joi.string().max(100).allow('', null),
            ibge: Joi.string().allow('', null),
        }),
    });

    const {
        control,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm<CamposFormulario>({
        resolver: joiResolver(validacao),
        criteriaMode: 'all',
    });

    const getEnderecamento = (enderecamentoOrdem: any) => {
        if (enderecamentoOrdem.bairro === '' && enderecamentoOrdem.cep === '') {
            const enderecoRetornar: Endereco = {
                cidade: enderecamentoOrdem.cidade,
                estado: enderecamentoOrdem.estado,
                bairro: null,
                cep: null,
                logradouro: null,
                complemento: null,
                numero: null,
                id: enderecamentoOrdem.id,
                ibge: null,
            };
            return enderecoRetornar;
        } else {
            const enderecoRetornar: Endereco = {
                cidade: enderecamentoOrdem.cidade,
                estado: enderecamentoOrdem.estado,
                bairro: enderecamentoOrdem.bairro,
                cep: enderecamentoOrdem.cep,
                logradouro: enderecamentoOrdem.logradouro,
                complemento: enderecamentoOrdem.complemento,
                numero: enderecamentoOrdem.numero,
                id: enderecamentoOrdem.id,
                ibge: enderecamentoOrdem.ibge,
            };
            return enderecoRetornar;
        }
    };

    useEffect(() => {
        const carregarFormulario = (ordem: OrdemCarregamento) => {
            setTipoOrdem(ordem.tipoNegociacao);
            setLocalClassificacao(ordem.localClassificacao);

            reset({
                classificadorCadastrado: ordem.classificador,
                cliforCadastrado: ordem.clifor,
                codigoContrato: ordem.codigoContrato,
                enderecamentoDestino: getEnderecamento(
                    ordem.enderecamentoDestino
                ),
                enderecamentoOrigem: getEnderecamento(
                    ordem.enderecamentoOrigem
                ),
                pesoPlanejado: ordem.pesoPlanejado.toString(),
                produtoCadastrado: ordem.produto,
                transportadoraCadastrada: ordem.transportadora,
            });
        };

        if (ordemCarregamento) {
            carregarFormulario(ordemCarregamento);
        }
    }, [ordemCarregamento, reset]);

    const tratarDados = (dadosFormulario: CamposFormulario) => {
        if (onSubmit) {
            const {
                cliforCadastrado,
                classificadorCadastrado,
                produtoCadastrado,
                enderecamentoDestino,
                enderecamentoOrigem,
                transportadoraCadastrada,
                ...resto
            } = dadosFormulario;

            let enderecamentoDestinoFormat: Endereco =
                getEnderecamento(enderecamentoDestino);
            let enderecamentoOrigemFormat: Endereco =
                getEnderecamento(enderecamentoOrigem);

            const dadosFormTratados: CamposFormularioTratados = {
                ...resto,
                tipoNegociacao: tipoOrdem,
                localClassificacao: localClassificacao,
                clifor_id: cliforCadastrado!.id,
                classificador_id: classificadorCadastrado!.id,
                produto_id: produtoCadastrado!.id,
                enderecamentoDestino: enderecamentoDestinoFormat,
                enderecamentoOrigem: enderecamentoOrigemFormat,
                transportadora_id: transportadoraCadastrada
                    ? transportadoraCadastrada?.id
                    : null,
            };
            onSubmit(dadosFormTratados);
        }
    };

    const buscarCep = async (cep: string, atributo: string) => {
        try {
            const resposta = await serviceViaCep.buscarEndereco(cep);

            if (resposta.status === StatusRequisicao.OK) {
                if (atributo === 'enderecamentoOrigem') {
                    setValue(
                        'enderecamentoOrigem.cidade',
                        resposta?.data?.localidade
                    );
                    setValue('enderecamentoOrigem.estado', resposta?.data?.uf);
                    setValue(
                        'enderecamentoOrigem.logradouro',
                        resposta?.data?.logradouro
                    );
                    setValue(
                        'enderecamentoOrigem.bairro',
                        resposta?.data?.bairro
                    );
                    setValue('enderecamentoOrigem.ibge', resposta?.data?.ibge);
                } else {
                    setValue(
                        'enderecamentoDestino.cidade',
                        resposta?.data?.localidade
                    );
                    setValue('enderecamentoDestino.estado', resposta?.data?.uf);
                    setValue(
                        'enderecamentoDestino.logradouro',
                        resposta?.data?.logradouro
                    );
                    setValue(
                        'enderecamentoDestino.bairro',
                        resposta?.data?.bairro
                    );
                    setValue('enderecamentoDestino.ibge', resposta?.data?.ibge);
                }
            }
        } catch (error) {}
    };

    return (
        <Container>
            <form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(tratarDados)}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={3}>
                        <FormControl
                            style={styles().formularioFormControl}
                            disabled={disabled}
                            data-id="radioTipoNegociacaoOrdem"
                        >
                            <FormLabel id="demo-row-radio-buttons-group-label">
                                Tipo do contrato
                            </FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={tipoOrdem}
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    setTipoOrdem(
                                        (event.target as HTMLInputElement)
                                            .value as TipoOrdem
                                    );
                                }}
                                data-id="radioTipoOrdem"
                            >
                                <FormControlLabel
                                    value={TipoOrdem.Venda}
                                    control={<Radio />}
                                    label="Venda"
                                />
                                <FormControlLabel
                                    value={TipoOrdem.Compra}
                                    control={<Radio />}
                                    label="Compra"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="codigoContrato"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        style={styles().textField}
                                        inputProps={{ maxLength: 30 }}
                                        required
                                        disabled={disabled}
                                        fullWidth
                                        label="Código do contrato"
                                        autoFocus
                                        error={!!errors.codigoContrato}
                                        helperText={
                                            errors.codigoContrato
                                                ? errors.codigoContrato?.message
                                                : ''
                                        }
                                        data-id="campoCodigoContrato"
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            control={control}
                            name="cliforCadastrado"
                            defaultValue={null}
                            render={(props) => {
                                return (
                                    <SelectClienteFornecedor
                                        {...props}
                                        options={[]}
                                        disabled={disabled}
                                        inputRef={props.field.ref}
                                        label={'Cliente/Fornecedor'}
                                        required
                                        onChange={(_, cliforCadastrado) => {
                                            props.field.onChange(
                                                cliforCadastrado
                                            );
                                        }}
                                        fullWidth
                                        margin="normal"
                                        error={!!errors.cliforCadastrado}
                                        helperText={
                                            errors.cliforCadastrado
                                                ? (
                                                      errors.cliforCadastrado as any
                                                  ).message
                                                : ''
                                        }
                                        data-id="selectClienteFornecedor"
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            control={control}
                            name="produtoCadastrado"
                            defaultValue={null}
                            render={(props) => {
                                return (
                                    <SelectProduto
                                        {...props}
                                        options={[]}
                                        disabled={disabled}
                                        inputRef={props.field.ref}
                                        label={'Produto'}
                                        required
                                        onChange={(_, produtoCadastrado) => {
                                            props.field.onChange(
                                                produtoCadastrado
                                            );
                                        }}
                                        fullWidth
                                        error={!!errors.produtoCadastrado}
                                        helperText={
                                            errors.produtoCadastrado
                                                ? (
                                                      errors.produtoCadastrado as any
                                                  ).message
                                                : ''
                                        }
                                        data-id="selectProduto"
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={3}>
                        <Controller
                            name="pesoPlanejado"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        required
                                        disabled={disabled}
                                        fullWidth
                                        label={'Peso planejado'}
                                        onChange={(
                                            event: React.ChangeEvent<
                                                | HTMLTextAreaElement
                                                | HTMLInputElement
                                            >
                                        ) => {
                                            event.currentTarget.value =
                                                event.currentTarget.value =
                                                    event.currentTarget.value.replace(
                                                        /[^.0-9]/g,
                                                        ''
                                                    );

                                            field.onChange(event);
                                        }}
                                        InputProps={{
                                            endAdornment: 'KG',
                                        }}
                                        error={!!errors.pesoPlanejado}
                                        helperText={
                                            errors.pesoPlanejado
                                                ? errors.pesoPlanejado.message
                                                : ''
                                        }
                                        data-id="campoPesoPlanejado"
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>

                <DividerComp texto="Classificação" />

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={3}>
                        <FormControl
                            style={styles().localClassificacaoControl}
                            disabled={disabled}
                            data-id="radioLocalClassificacao"
                        >
                            <FormLabel id="demo-row-radio-buttons-group-label">
                                Local da Classificação
                            </FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={localClassificacao}
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    setLocalClassificacao(
                                        (event.target as HTMLInputElement)
                                            .value as LocalClassificacao
                                    );
                                }}
                                data-test-id="radioLocalClassificacao"
                            >
                                <FormControlLabel
                                    value={LocalClassificacao.origem}
                                    control={<Radio />}
                                    label="Origem"
                                />
                                <FormControlLabel
                                    value={LocalClassificacao.destino}
                                    control={<Radio />}
                                    label="Destino"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Controller
                            control={control}
                            name="classificadorCadastrado"
                            defaultValue={null}
                            render={(props) => {
                                return (
                                    <SelectClassificador
                                        {...props}
                                        options={[]}
                                        disabled={disabled}
                                        inputRef={props.field.ref}
                                        label={'Classificador'}
                                        required
                                        onChange={(
                                            _,
                                            classificadorCadastrado
                                        ) => {
                                            props.field.onChange(
                                                classificadorCadastrado
                                            );
                                        }}
                                        fullWidth
                                        error={!!errors.classificadorCadastrado}
                                        helperText={
                                            errors.classificadorCadastrado
                                                ? (
                                                      errors.classificadorCadastrado as any
                                                  ).message
                                                : ''
                                        }
                                        data-id="selectClassificador"
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <Controller
                            control={control}
                            name="transportadoraCadastrada"
                            defaultValue={null}
                            render={(props) => {
                                return (
                                    <SelectTransportadora
                                        {...props}
                                        options={[]}
                                        disabled={disabled}
                                        inputRef={props.field.ref}
                                        label={'Transportadora'}
                                        onChange={(
                                            _,
                                            transportadoraCadastrada
                                        ) => {
                                            props.field.onChange(
                                                transportadoraCadastrada
                                            );
                                        }}
                                        fullWidth
                                        error={
                                            !!errors.transportadoraCadastrada
                                        }
                                        helperText={
                                            errors.transportadoraCadastrada
                                                ? (
                                                      errors.transportadoraCadastrada as any
                                                  ).message
                                                : ''
                                        }
                                        data-id="selectClassificador"
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>

                <DividerComp texto="Endereço de origem" />

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={3}>
                        <Controller
                            name="enderecamentoOrigem.cep"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        label="CEP"
                                        onChange={(
                                            event: React.ChangeEvent<
                                                | HTMLTextAreaElement
                                                | HTMLInputElement
                                            >
                                        ) => {
                                            event.currentTarget.value =
                                                event.currentTarget.value =
                                                    event.currentTarget.value.replace(
                                                        /[^0-9]/g,
                                                        ''
                                                    );

                                            field.onChange(event);
                                        }}
                                        onBlur={(event) => {
                                            buscarCep(
                                                event.target.value,
                                                'enderecamentoOrigem'
                                            );
                                        }}
                                        error={
                                            !!(
                                                errors.enderecamentoOrigem &&
                                                errors.enderecamentoOrigem.cep
                                            )
                                        }
                                        helperText={
                                            errors.enderecamentoOrigem &&
                                            errors.enderecamentoOrigem.cep
                                                ? (
                                                      errors.enderecamentoOrigem
                                                          .cep as any
                                                  ).message
                                                : ''
                                        }
                                        data-id="campoCepOrigem"
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="enderecamentoOrigem.cidade"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        required
                                        disabled={disabled}
                                        fullWidth
                                        label="Cidade"
                                        error={
                                            !!(
                                                errors.enderecamentoOrigem &&
                                                errors.enderecamentoOrigem
                                                    .cidade
                                            )
                                        }
                                        helperText={
                                            errors.enderecamentoOrigem &&
                                            errors.enderecamentoOrigem.cidade
                                                ? (
                                                      errors.enderecamentoOrigem
                                                          .cidade as any
                                                  ).message
                                                : ''
                                        }
                                        data-id="campoCidadeOrigem"
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <Controller
                            name="enderecamentoOrigem.estado"
                            control={control}
                            defaultValue=""
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        required
                                        inputProps={{ maxLength: 2 }}
                                        label="Estado"
                                        error={
                                            !!(
                                                errors.enderecamentoOrigem &&
                                                errors.enderecamentoOrigem
                                                    .estado
                                            )
                                        }
                                        helperText={
                                            errors.enderecamentoOrigem &&
                                            errors.enderecamentoOrigem.estado
                                                ? (
                                                      errors.enderecamentoOrigem
                                                          .estado as any
                                                  ).message
                                                : ''
                                        }
                                        data-id="campoEstadoOrigem"
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="enderecamentoOrigem.logradouro"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        label="Logradouro"
                                        error={
                                            !!(
                                                errors.enderecamentoOrigem &&
                                                errors.enderecamentoOrigem
                                                    .logradouro
                                            )
                                        }
                                        helperText={
                                            errors.enderecamentoOrigem &&
                                            errors.enderecamentoOrigem
                                                .logradouro
                                                ? (
                                                      errors.enderecamentoOrigem
                                                          .logradouro as any
                                                  ).message
                                                : ''
                                        }
                                        data-id="campoLogradouroOrigem"
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Controller
                            name="enderecamentoOrigem.bairro"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        label="Bairro"
                                        error={
                                            !!(
                                                errors.enderecamentoOrigem &&
                                                errors.enderecamentoOrigem
                                                    .bairro
                                            )
                                        }
                                        helperText={
                                            errors.enderecamentoOrigem &&
                                            errors.enderecamentoOrigem.bairro
                                                ? (
                                                      errors.enderecamentoOrigem
                                                          .bairro as any
                                                  ).message
                                                : ''
                                        }
                                        data-id="campoBairroOrigem"
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <Controller
                            name="enderecamentoOrigem.numero"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        margin="normal"
                                        inputRef={ref}
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        required
                                        label="Número"
                                        error={
                                            !!(
                                                errors.enderecamentoOrigem &&
                                                errors.enderecamentoOrigem
                                                    .numero
                                            )
                                        }
                                        helperText={
                                            errors.enderecamentoOrigem &&
                                            errors.enderecamentoOrigem.numero
                                                ? (
                                                      errors.enderecamentoOrigem
                                                          .numero as any
                                                  ).message
                                                : ''
                                        }
                                        data-id="campoNumeroOrigem"
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="enderecamentoOrigem.complemento"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        label="Complemento"
                                        error={
                                            !!(
                                                errors.enderecamentoOrigem &&
                                                errors.enderecamentoOrigem
                                                    .complemento
                                            )
                                        }
                                        helperText={
                                            errors.enderecamentoOrigem &&
                                            errors.enderecamentoOrigem
                                                .complemento
                                                ? (
                                                      errors.enderecamentoOrigem
                                                          .complemento as any
                                                  ).message
                                                : ''
                                        }
                                        data-id="campoComplementoOrigem"
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>

                <DividerComp texto="Endereço de destino" />

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={3}>
                        <Controller
                            name="enderecamentoDestino.cep"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        label="CEP"
                                        onChange={(
                                            event: React.ChangeEvent<
                                                | HTMLTextAreaElement
                                                | HTMLInputElement
                                            >
                                        ) => {
                                            event.currentTarget.value =
                                                event.currentTarget.value =
                                                    event.currentTarget.value.replace(
                                                        /[^0-9]/g,
                                                        ''
                                                    );

                                            field.onChange(event);
                                        }}
                                        onBlur={(event) => {
                                            buscarCep(
                                                event.target.value,
                                                'enderecamentoDestino'
                                            );
                                        }}
                                        error={
                                            !!(
                                                errors.enderecamentoDestino &&
                                                errors.enderecamentoDestino.cep
                                            )
                                        }
                                        helperText={
                                            errors.enderecamentoDestino &&
                                            errors.enderecamentoDestino.cep
                                                ? (
                                                      errors
                                                          .enderecamentoDestino
                                                          .cep as any
                                                  ).message
                                                : ''
                                        }
                                        data-id="campoCepDestino"
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="enderecamentoDestino.cidade"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        required
                                        label="Cidade"
                                        error={
                                            !!(
                                                errors.enderecamentoDestino &&
                                                errors.enderecamentoDestino
                                                    .cidade
                                            )
                                        }
                                        helperText={
                                            errors.enderecamentoDestino &&
                                            errors.enderecamentoDestino.cidade
                                                ? (
                                                      errors
                                                          .enderecamentoDestino
                                                          .cidade as any
                                                  ).message
                                                : ''
                                        }
                                        data-id="campoCidadeDestino"
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <Controller
                            name="enderecamentoDestino.estado"
                            control={control}
                            defaultValue=""
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        required
                                        inputProps={{ maxLength: 2 }}
                                        label="Estado"
                                        error={
                                            !!(
                                                errors.enderecamentoDestino &&
                                                errors.enderecamentoDestino
                                                    .estado
                                            )
                                        }
                                        helperText={
                                            errors.enderecamentoDestino &&
                                            errors.enderecamentoDestino.estado
                                                ? (
                                                      errors
                                                          .enderecamentoDestino
                                                          .estado as any
                                                  ).message
                                                : ''
                                        }
                                        data-id="campoEstadoDestino"
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="enderecamentoDestino.logradouro"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        label="Logradouro"
                                        error={
                                            !!(
                                                errors.enderecamentoDestino &&
                                                errors.enderecamentoDestino
                                                    .logradouro
                                            )
                                        }
                                        helperText={
                                            errors.enderecamentoDestino &&
                                            errors.enderecamentoDestino
                                                .logradouro
                                                ? (
                                                      errors
                                                          .enderecamentoDestino
                                                          .logradouro as any
                                                  ).message
                                                : ''
                                        }
                                        data-id="campoLogradouroDestino"
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Controller
                            name="enderecamentoDestino.bairro"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        label="Bairro"
                                        error={
                                            !!(
                                                errors.enderecamentoDestino &&
                                                errors.enderecamentoDestino
                                                    .bairro
                                            )
                                        }
                                        helperText={
                                            errors.enderecamentoDestino &&
                                            errors.enderecamentoDestino.bairro
                                                ? (
                                                      errors
                                                          .enderecamentoDestino
                                                          .bairro as any
                                                  ).message
                                                : ''
                                        }
                                        data-id="campoBairroDestino"
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <Controller
                            name="enderecamentoDestino.numero"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        margin="normal"
                                        inputRef={ref}
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        required
                                        label="Número"
                                        error={
                                            !!(
                                                errors.enderecamentoDestino &&
                                                errors.enderecamentoDestino
                                                    .numero
                                            )
                                        }
                                        helperText={
                                            errors.enderecamentoDestino &&
                                            errors.enderecamentoDestino.numero
                                                ? (
                                                      errors
                                                          .enderecamentoDestino
                                                          .numero as any
                                                  ).message
                                                : ''
                                        }
                                        data-id="campoNumeroDestino"
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="enderecamentoDestino.complemento"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        label="Complemento"
                                        error={
                                            !!(
                                                errors.enderecamentoDestino &&
                                                errors.enderecamentoDestino
                                                    .complemento
                                            )
                                        }
                                        helperText={
                                            errors.enderecamentoDestino &&
                                            errors.enderecamentoDestino
                                                .complemento
                                                ? (
                                                      errors
                                                          .enderecamentoDestino
                                                          .complemento as any
                                                  ).message
                                                : ''
                                        }
                                        data-id="campoComplementoDestino"
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2} justifyContent="end">
                    {!disabled ? (
                        <Button
                            type="submit"
                            variant="contained"
                            style={styles().button}
                            size="large"
                            data-id="botaoSalvar"
                        >
                            Salvar
                        </Button>
                    ) : (
                        ordemCarregamento && (
                            <Tooltip
                                title={
                                    ordemCarregamento.status !== Status.ABERTA
                                        ? "A ordem não está com o status 'Aberta'"
                                        : !ordemCarregamento.cadastradaPeloPortal
                                        ? 'Ordens criadas por sistemas externos não podem ser alteradas'
                                        : 'Alterar'
                                }
                                placement="top"
                                disableInteractive
                            >
                                <span>
                                    <Button
                                        onClick={() => {
                                            navigate(
                                                FrontEndPathResources.OrdemCarregamento +
                                                    '/alterar/' +
                                                    ordemCarregamento?.id
                                            );
                                        }}
                                        variant="contained"
                                        style={styles().button}
                                        disabled={
                                            naoPermitirEdicao ||
                                            ordemCarregamento.status !==
                                                Status.ABERTA ||
                                            !ordemCarregamento.cadastradaPeloPortal
                                        }
                                        size="large"
                                    >
                                        Alterar
                                    </Button>
                                </span>
                            </Tooltip>
                        )
                    )}
                </Grid>
            </form>
        </Container>
    );
}
