import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {
    FormHelperText,
    Grow,
    IconButton,
    TextField,
    Theme,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { ChangeEvent, KeyboardEvent, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            width: '100%',
            maxWidth: '600px',
            minWidth: '300px',
        },
        searchText: {
            flex: '0.8 0',
        },
        clearIcon: {
            '&:hover': {
                color: 'green',
            },
        },
        searchIcon: {
            '&:hover': {
                color: 'blue',
            },
        },
    })
);

interface Props {
    onSearch: (searchText: string) => void;
    helperText?: string;
}

export default function Search({ onSearch, helperText }: Props) {
    const [searchText, setSearchText] = useState('');

    const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const clearSearch = () => {
        setSearchText('');
        onSearch('');
    };

    const search = () => {
        onSearch(searchText);
    };

    const onKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            clearSearch();
        }

        if (event.key === 'Enter') {
            search();
        }
    };

    const classes = useStyles();

    return (
        <Grow appear in={true}>
            <div style={{ flex: 1 }}>
                <TextField
                    value={searchText}
                    onChange={handleTextChange}
                    onKeyDown={onKeyDown}
                    fullWidth={true}
                    label="Pesquisar"
                    InputProps={{
                        endAdornment: (
                            <div style={{ display: 'flex' }}>
                                <IconButton
                                    className={classes.searchIcon}
                                    onClick={search}
                                >
                                    <SearchIcon />
                                </IconButton>
                                <IconButton
                                    className={classes.clearIcon}
                                    onClick={clearSearch}
                                >
                                    <ClearIcon />
                                </IconButton>
                            </div>
                        ),
                    }}
                    onBlur={() => search()}
                />

                <FormHelperText>{helperText}</FormHelperText>
            </div>
        </Grow>
    );
}
