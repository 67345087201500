import { IBaseUseCase } from '../../../../types/interfaces/IBaseUseCase';
import { IAnexosDocumentosDto } from '../dtos/IAnexosDocumentosDto';

import { IOrdemColetaRepository } from '../repositories/IOrdemColetaRepository';

export class FindAnexosDocumentosByOrdemColetaId
    implements IBaseUseCase<number, IAnexosDocumentosDto>
{
    constructor(private readonly repository: IOrdemColetaRepository) {}

    execute(ordemColetaId: number): Promise<IAnexosDocumentosDto> {
        return this.repository.findAnexosDocumentosByOrdemColetaId(
            ordemColetaId
        );
    }
}
