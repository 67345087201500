import { Box } from "@mui/material";
import LogoEasymberk from '../../Assets/Images/logoPreta.svg';
import { styles } from "./helpers/Styles";

export default function NotFound() {
    return (
        <Box sx={styles.box} >
            <img
                src={LogoEasymberk}
                alt="Easymbark"
                style={styles.img}
            />
            <h1> 404, Página não encontrada</h1>
        </Box>
    )
}
