import { RevisionType } from '../../../Models/Auditoria';
import { StyleSheet } from '../../../StyleSheet';

const getBorderColorRevisionType = (revisionType: any) => {
    switch (revisionType) {
        case RevisionType.Adicionado:
            return '#3AB4F2';
        case RevisionType.Modificado:
            return '#FEB139';
        case RevisionType.Deletado:
            return '#D61C4E';
    }
};

export const styles = (props?: any) => {
    return StyleSheet.create({
        formularioFormControl: { marginTop: 20 },
        textField: { marginBottom: 20 },
        localClassificacaoControl: { marginBottom: 20 },
        card: { width: '100%' },
        gridListar: { marginBottom: 10 },
        chipStatus: { margin: 5 },
        iconStyle: { width: 13, height: 13 },
        button: {
            marginTop: 25,
            marginBottom: 15,
            backgroundColor: '#465f75',
        },
        divChip: {
            justifyContent: 'center',
            alignItems: 'center',
        },
        cardAlteracoes: { marginTop: 2, width: props ? 280 : 500 },
        alertAlteracoes: {
            marginTop: 15,
            borderColor: getBorderColorRevisionType(props),
        },
        gridExcluidas: { marginTop: 20, marginBottom: 20 },
        dialogRomaneio: {
            width: 870,
            height: 800,
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        divListItemText: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        listAuditoria: {
            width: '100%',
            maxWidth: 500,
            bgcolor: 'background.paper',
        },
        arrowForwardIcon: {
            marginLeft: 10,
            marginRight: 10,
        },
        divEmbarques: {
            justifyContent: 'center',
            alignItems: 'center',
        },
    });
};
