import {
    Container,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import DividerComp from '../../../Components/Divider/DividerComp';
import Amostra from '../../../Models/Amostra';
import { TipoEmbarque } from '../../LoteEmbarque/Formulario';
import { styles } from '../../Ordem/helpers/Styles';
import { LotePorTransportadoraDtoResponse } from '../types/LotePorTransportadoraDtoResponse';

export type CamposFormulario = {
    nome: string;
    amostras: Amostra[];
};

interface Props {
    disabled?: boolean;
    lote?: LotePorTransportadoraDtoResponse;
}

export default function Formulario({ disabled = true, lote }: Props) {
    const navigate = useNavigate();

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<CamposFormulario>({
        criteriaMode: 'all',
    });

    const [tipoEmbarque, setTipoEmbarque] = useState('');

    useEffect(() => {
        if (lote) setTipoEmbarque(lote.tipoEmbarque);
    }, [lote]);

    return (
        <Container>
            <form noValidate autoComplete="off">
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={8}>
                        <FormControl
                            style={styles().formularioFormControl}
                            disabled={disabled}
                            data-id="radioTipoNegociacaoOrdem"
                        >
                            <FormLabel id="demo-row-radio-buttons-group-label">
                                Tipo de embarque
                            </FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={tipoEmbarque}
                                data-id="radioTipoOrdem"
                            >
                                <FormControlLabel
                                    value={TipoEmbarque.Troca}
                                    control={<Radio />}
                                    label="Troca de nota"
                                />
                                <FormControlLabel
                                    value={TipoEmbarque.Armazem}
                                    control={<Radio />}
                                    label="Armaz./Indust."
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <TextField
                            margin="normal"
                            style={styles().textField}
                            inputProps={{ maxLength: 30 }}
                            InputLabelProps={{ shrink: true }}
                            required
                            value={lote?.codigoContrato}
                            disabled={disabled}
                            fullWidth
                            label="Código do contrato"
                            autoFocus
                            data-id="campoCodigoContrato"
                        />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <TextField
                            margin="normal"
                            required
                            disabled={disabled}
                            fullWidth
                            label={'Peso planejado para a transportadora'}
                            InputProps={{
                                endAdornment: 'KG',
                            }}
                            InputLabelProps={{ shrink: true }}
                            value={lote?.pesoPlanejadoTransportadora}
                            data-id="campoPesoPlanejado"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <TextField
                            margin="normal"
                            required
                            disabled={disabled}
                            fullWidth
                            label={'Produto'}
                            InputLabelProps={{ shrink: true }}
                            value={lote?.produto?.nome}
                            data-id="campoPesoPlanejado"
                        />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <TextField
                            margin="normal"
                            required
                            disabled={disabled}
                            fullWidth
                            label={'Status'}
                            InputLabelProps={{ shrink: true }}
                            value={lote?.status}
                            data-id="campoPesoPlanejado"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={4}>
                        <TextField
                            margin="normal"
                            required
                            disabled={disabled}
                            fullWidth
                            label={'Clifor/Fornecedor'}
                            InputLabelProps={{ shrink: true }}
                            value={lote?.loteEmbarque.clifor.nome}
                            data-id="campoPesoPlanejado"
                        />
                    </Grid>
                </Grid>

                <DividerComp texto="Endereço transportadora" />

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={3}>
                        <TextField
                            margin="normal"
                            autoComplete="off"
                            disabled={disabled}
                            fullWidth
                            label="CEP"
                            value={lote?.endereco.cep}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={5}>
                        <TextField
                            value={lote?.endereco.cidade}
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                            autoComplete="off"
                            required
                            disabled={disabled}
                            fullWidth
                            label="Cidade"
                            data-id="campoCidadeOrigem"
                        />
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <TextField
                            value={lote?.endereco.estado}
                            margin="normal"
                            autoComplete="off"
                            InputLabelProps={{ shrink: true }}
                            disabled={disabled}
                            fullWidth
                            required
                            inputProps={{ maxLength: 2 }}
                            label="Estado"
                            data-id="campoEstadoOrigem"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <TextField
                            value={lote?.endereco.logradouro}
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                            autoComplete="off"
                            disabled={disabled}
                            fullWidth
                            label="Logradouro"
                            data-id="campoLogradouroOrigem"
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <TextField
                            value={lote?.endereco.bairro}
                            margin="normal"
                            autoComplete="off"
                            disabled={disabled}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label="Bairro"
                            data-id="campoBairroOrigem"
                        />
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <TextField
                            value={lote?.endereco.numero}
                            margin="normal"
                            autoComplete="off"
                            disabled={disabled}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            required
                            label="Número"
                            data-id="campoNumeroOrigem"
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <TextField
                            value={lote?.endereco.complemento}
                            margin="normal"
                            autoComplete="off"
                            InputLabelProps={{ shrink: true }}
                            disabled={disabled}
                            fullWidth
                            label="Complemento"
                            data-id="campoComplementoOrigem"
                        />
                    </Grid>
                </Grid>

                <DividerComp texto="Endereço de origem" />

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={3}>
                        <TextField
                            value={lote?.loteEmbarque.localEmbarque.cep}
                            margin="normal"
                            autoComplete="off"
                            InputLabelProps={{ shrink: true }}
                            disabled={disabled}
                            fullWidth
                            label="CEP"
                            data-id="campoCepOrigem"
                        />
                    </Grid>
                    <Grid item xs={12} lg={5}>
                        <TextField
                            value={lote?.loteEmbarque.localEmbarque.cidade}
                            margin="normal"
                            autoComplete="off"
                            InputLabelProps={{ shrink: true }}
                            required
                            disabled={disabled}
                            fullWidth
                            label="Cidade"
                            data-id="campoCidadeOrigem"
                        />
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <TextField
                            value={lote?.loteEmbarque.localEmbarque.estado}
                            margin="normal"
                            autoComplete="off"
                            InputLabelProps={{ shrink: true }}
                            disabled={disabled}
                            fullWidth
                            required
                            inputProps={{ maxLength: 2 }}
                            label="Estado"
                            data-id="campoEstadoOrigem"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <TextField
                            value={lote?.loteEmbarque.localEmbarque.logradouro}
                            margin="normal"
                            autoComplete="off"
                            InputLabelProps={{ shrink: true }}
                            disabled={disabled}
                            fullWidth
                            label="Logradouro"
                            data-id="campoLogradouroOrigem"
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <TextField
                            value={lote?.loteEmbarque.localEmbarque.bairro}
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                            autoComplete="off"
                            disabled={disabled}
                            fullWidth
                            label="Bairro"
                            data-id="campoBairroOrigem"
                        />
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <TextField
                            value={lote?.loteEmbarque.localEmbarque.numero}
                            margin="normal"
                            autoComplete="off"
                            InputLabelProps={{ shrink: true }}
                            disabled={disabled}
                            fullWidth
                            required
                            label="Número"
                            data-id="campoNumeroOrigem"
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <TextField
                            value={lote?.loteEmbarque.localEmbarque.complemento}
                            margin="normal"
                            autoComplete="off"
                            InputLabelProps={{ shrink: true }}
                            disabled={disabled}
                            fullWidth
                            label="Complemento"
                            data-id="campoComplementoOrigem"
                        />
                    </Grid>
                </Grid>

                <DividerComp texto="Endereço de destino" />

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={3}>
                        <TextField
                            value={lote?.loteEmbarque.localDestino.cep}
                            margin="normal"
                            autoComplete="off"
                            InputLabelProps={{ shrink: true }}
                            disabled={disabled}
                            fullWidth
                            label="CEP"
                            data-id="campoCepDestino"
                        />
                    </Grid>
                    <Grid item xs={12} lg={5}>
                        <TextField
                            value={lote?.loteEmbarque.localDestino.cidade}
                            margin="normal"
                            autoComplete="off"
                            disabled={disabled}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            required
                            label="Cidade"
                            data-id="campoCidadeDestino"
                        />
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <TextField
                            margin="normal"
                            value={lote?.loteEmbarque.localDestino.estado}
                            autoComplete="off"
                            InputLabelProps={{ shrink: true }}
                            disabled={disabled}
                            fullWidth
                            required
                            inputProps={{ maxLength: 2 }}
                            label="Estado"
                            data-id="campoEstadoDestino"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <TextField
                            value={lote?.loteEmbarque.localDestino.logradouro}
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                            autoComplete="off"
                            disabled={disabled}
                            fullWidth
                            label="Logradouro"
                            data-id="campoLogradouroDestino"
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <TextField
                            value={lote?.loteEmbarque.localDestino.bairro}
                            margin="normal"
                            autoComplete="off"
                            disabled={disabled}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            label="Bairro"
                            data-id="campoBairroDestino"
                        />
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <TextField
                            margin="normal"
                            value={lote?.loteEmbarque.localDestino.numero}
                            autoComplete="off"
                            disabled={disabled}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            required
                            label="Número"
                            data-id="campoNumeroDestino"
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <TextField
                            value={lote?.loteEmbarque.localDestino.complemento}
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                            autoComplete="off"
                            disabled={disabled}
                            fullWidth
                            label="Complemento"
                            data-id="campoComplementoDestino"
                        />
                    </Grid>
                </Grid>
            </form>
        </Container>
    );
}
