import { StandardTextFieldProps, TextField } from '@mui/material';
import InputMask from 'react-input-mask';

type Props = StandardTextFieldProps;

export default function InputCnpj({ label = 'CNPJ', ...props }: Props) {
    const { onBlur, value, onChange, disabled, inputRef, ...outrasPropriedades } =
        props;

    return (
        <InputMask
            mask="99.999.999/9999-99"
            maskPlaceholder={null}
            onChange={onChange}
            onBlur={onBlur}
            value={value as string}
            disabled={disabled}
        >
            <TextField
                label={label}
                inputProps={{ autoComplete: 'off' }}
                {...outrasPropriedades}
                inputRef={inputRef}
                fullWidth
            />
        </InputMask>
    );
}
