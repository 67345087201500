import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import Embarque from '../../Models/Embarque';
import OrdemCarregamento from '../../Models/OrdemCarregamento';
import Romaneio from '../../Config/Pdf/Romaneio';
import { PDFViewer } from '@react-pdf/renderer';
import { styles } from './helpers/Styles';

interface Props {
    embarque: Embarque;
    ordem: OrdemCarregamento;
    fecharClicado: () => void;
    aberto: boolean;
}

export default function ModalRomaneio({
    fecharClicado,
    aberto = false,
    ordem,
    embarque,
}: Props) {

    return (
        <Dialog
            open={aberto}
            onClose={() => fecharClicado()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="lg"
        >
            <DialogTitle id="alert-dialog-title">Romaneio</DialogTitle>
            <DialogContent style={styles().dialogRomaneio}>
                <PDFViewer width={800} height={550}>
                    <Romaneio
                        embarque={embarque}
                        ordemCarregamento={ordem}
                    />
                </PDFViewer>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => fecharClicado()} autoFocus>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
