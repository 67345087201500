import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';
import Upload from '../../Components/Upload/Upload';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { styles } from './helpers/Styles';

interface Props {
    fecharClicado: () => void;
    aberto: boolean;
    enviarAnexoClicado: (imagemBase64: string) => void;
}

export default function ModalAnexarLogoEmpresa({
    fecharClicado,
    aberto = false,
    enviarAnexoClicado,
}: Props) {
    const [arquivoSelecionado, setArquivoSelecionado] = useState<any>();
    const [imagemBase64, setImagemBase64] = useState<string>();

    const [arquivoDimensaoErrada, setArquivoDimensao] =
        useState<boolean>(false);

    const LARGURA_MAXIMA_PERMITIDA = 600;
    const ALTURA_MAXIMA_PERMITIDA = 600;

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const tratarDados = () => {
        if (imagemBase64) {
            enviarAnexoClicado(imagemBase64);
        } else {
            enqueueSnackbar('Selecione um arquivo!', {
                variant: 'info',
                onClick: () => {
                    closeSnackbar();
                },
            });
        }
    };

    const removerArquivoSelecionado = () => {
        setArquivoSelecionado(undefined);
        setImagemBase64(undefined);
    };

    return (
        <Dialog
            open={aberto}
            onClose={() => fecharClicado()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Anexar imagem</DialogTitle>
            <DialogContent>
                <Box sx={styles.box}>
                    <Upload
                        setFile={(file) => {
                            const image = new Image();
                            image.addEventListener('load', () => {
                                if (
                                    image.width > LARGURA_MAXIMA_PERMITIDA ||
                                    image.height > ALTURA_MAXIMA_PERMITIDA
                                ) {
                                    enqueueSnackbar(
                                        'Selecione um arquivo com dimenção máxima de: ' +
                                        LARGURA_MAXIMA_PERMITIDA +
                                        'x' +
                                        ALTURA_MAXIMA_PERMITIDA,
                                        {
                                            variant: 'error',
                                            onClick: () => {
                                                closeSnackbar();
                                            },
                                        }
                                    );

                                    removerArquivoSelecionado();
                                    return;
                                } else {
                                }
                            });
                            image.src = URL.createObjectURL(file);

                            setArquivoSelecionado(file);

                            let fileToLoad = file;
                            let fileReader = new FileReader();

                            fileReader.onload = function (fileLoadedEvent) {
                                file = fileLoadedEvent!.target!.result;

                                setImagemBase64(
                                    fileLoadedEvent!.target!.result as string
                                );
                            };
                            fileReader.readAsDataURL(fileToLoad);
                        }}
                        arquivosSuportados={'.png, .jpn, .jpeg'}
                        mensagemIsDragAtivo="Arraste o arquivo aqui."
                        mensagemPadrao={
                            'Araste o arquivo aqui ou clique para selecionar do computador. ' +
                            'Selecione um arquivo com dimenção máxima de: ' +
                            LARGURA_MAXIMA_PERMITIDA +
                            'x' +
                            ALTURA_MAXIMA_PERMITIDA
                        }
                    />
                    {arquivoSelecionado ? (
                        <List>
                            <ListItem key={arquivoSelecionado.name}>
                                <ListItemText
                                    primary={`${arquivoSelecionado.name}`}
                                />
                            </ListItem>
                        </List>
                    ) : (
                        <></>
                    )}

                    <Button
                        style={styles.button}
                        variant="contained"
                        type="submit"
                        onClick={() => {
                            tratarDados();
                        }}
                    >
                        Enviar
                    </Button>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => fecharClicado()} autoFocus>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
