export enum ApiResources {
    Classificador = '/v1/classificadores',
    Amostra = '/v1/amostras',
    ClienteFornecedor = '/v1/clifors',
    Produto = '/v1/produtos',
    Administrador = '/v1/administradores',
    Transportadora = '/v1/transportadoras',
    Contratante = '/v1/contratantes',
    OrdemCarregamento = '/v1/ordens',
    Usuario = '/v1/usuarios',
    Relatorio = '/v1/relatorios',
    NotaFiscal = '/v1/notas',
    Token = '/v1/tokenerp',
    Embarque = '/v1/embarques',
    Empty = '',
    Juncao = '/v1/juncao',
    IntegracaoMaxys = '/v1/integracao-maxys',
    PerfilUsuario = '/v1/perfis-usuarios',
    RecursoPerfilUsuario = '/v1/recursos-perfis',
    CadastroUsuario = '/v1/cadastro-usuario',
    ConfigGeral = '/v1/config-geral',
    ConfigEmail = '/v1/config-email',
    LoteEmbarque = '/v1/loteembarque',
    OrdemColeta = '/v1/ordem-coleta',
    LoteEmbarquePorTransportadora = '/v1/lotes-por-transportadora',
    OrdemColetaLancamento = '/v1/ordem-coleta-lancamento',
    IntegracaoMaxysXML = '/v1/integracao-maxysXML',
    OrdemColetaValidacaoDescarga = '/v1/ordem-coleta-validacao-descarga',
    OrdemColetaMessageERP= '/v1/ordem-coleta-message-erp',
    LoteEmbarqueContrato = '/v1/lote-embarque-contrato',
    OrdemColetaDoubleCheck = '/v1/ordem-coleta/todas-double-check'
}

export enum FrontEndPathResources {
    Classificador = '/easymbark/classificadores',
    ClienteFornecedor = '/easymbark/clientes-fornecedores',
    Amostra = '/easymbark/amostras',
    Produto = '/easymbark/produtos',
    Administrador = '/easymbark/administradores',
    Transportadora = '/easymbark/transportadoras',
    Contratante = '/easymbark/contratantes',
    OrdemCarregamento = '/easymbark/ordens',
    Configuracao = '/easymbark/configuracoes',
    AlterarSenha = '/easymbark/alterar-senha',
    Dashboard = '/easymbark',
    Juncao = '/easymbark/registro-juncao',
    IntegracaoMaxys = '/easymbark/integracao-maxys',
    PerfilUsuario = '/easymbark/perfil-usuario',
    CadastroUsuario = '/easymbark/cadastro-usuario',
    LoteEmbarque = '/easymbark/loteembarque',
    OrdemColeta = '/easymbark/ordem-coleta',
    LoteEmbarquePorTransportadora = '/easymbark/lotes-transportadora',
    DoubleCheck = '/easymbark/double-check'
}

export default class Api {}
