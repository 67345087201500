import ArticleIcon from '@mui/icons-material/Article';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Container, Divider, IconButton, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import PageTitle from '../../Components/PageTitle/PageTitle';
import GenericComplexTable, {
    ComplexColumn,
} from '../../Components/Table/GenericComplexTable';
import { ApiResources, FrontEndPathResources } from '../../Models/Api';
import Usuario, {
    ChavesArmazenamentoStorage,
    RecursosPerfisEnum,
    StatusRequisicao,
} from '../../Models/Usuario';
import LoginResponse from '../../Models/responses/LoginResponse';

import { PermissionGate } from '../../Components/Navegacao/PermissionGate';
import useGenericService from '../../hooks/useGenericService';
import { usePermission } from '../../hooks/usePermission';
import ModalHistoricoAlteracoes from './ModalHistoricoAlteracoes';
import ModalUsuarioExcluido from './ModalUsuarioExcluido';

export default function Listar() {
    const [usuarioMostrarAuditoria, setUsuarioMostrarAuditoria] =
        useState<Usuario>();

    const [mostrarModalAuditoria, setMostrarModalAuditoria] =
        useState<boolean>(false);

    const [mostrarModalItensExcluidos, setMostrarModalItensExcluidos] =
        useState<boolean>(false);

    const { isAllowed: isPermitidoExcluir } = usePermission(
        RecursosPerfisEnum.CADASTRO_USUARIOS_EXCLUIR
    );

    const navigate = useNavigate();

    const { getService } = useGenericService();

    const [usuarios, setUsuarios] = useState<Usuario[]>();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        let loginResponse = localStorage.getItem(
            ChavesArmazenamentoStorage.LOGIN_RESPONSE
        );

        if (loginResponse) {
            const usuario: Usuario = (
                JSON.parse(loginResponse) as LoginResponse
            ).usuario;

            if (usuario.trocarSenha) {
                window.location.pathname = FrontEndPathResources.AlterarSenha;
            }
        }
    }, []);

    const desativarUsuario = async (url: string) => {
        const servico = getService(ApiResources.Usuario);

        try {
            const resposta = await servico.api.delete(url);

            if (resposta.status === StatusRequisicao.OK) {
                enqueueSnackbar('Usuário desativado com sucesso', {
                    variant: 'success',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
                navigate(FrontEndPathResources.CadastroUsuario);
                carregar();
            }
        } catch (error) {}
    };

    const carregar = async () => {
        const servico = getService(ApiResources.Usuario);

        const resposta = await servico.api.get('');

        setUsuarios(resposta?.data?.content);
    };

    const colunas: ComplexColumn[] = [
        {
            attribute: 'id',
            label: 'ID',
            id: 'id',
            sortable: true,
        },
        {
            attribute: 'nome',
            label: 'Nome',
            id: 'nome',
            sortable: true,
        },
        {
            attribute: 'id',
            label: 'Ações',
            id: 'acao',
            width: 200,
            format: (row) => {
                return (
                    <>
                        <Tooltip title="Ver" placement="top" disableInteractive>
                            <IconButton
                                aria-label="view"
                                onClick={() => {
                                    navigate(
                                        FrontEndPathResources.CadastroUsuario +
                                            '/ver/' +
                                            row.id
                                    );
                                }}
                            >
                                <RemoveRedEyeIcon />
                            </IconButton>
                        </Tooltip>
                        <PermissionGate
                            recurso={
                                RecursosPerfisEnum.CADASTRO_USUARIOS_ALTERAR
                            }
                            redirect={''}
                        >
                            <Tooltip
                                title="Alterar"
                                placement="top"
                                disableInteractive
                            >
                                <IconButton
                                    aria-label="edit"
                                    onClick={() => {
                                        navigate(
                                            FrontEndPathResources.CadastroUsuario +
                                                '/alterar/' +
                                                row.id
                                        );
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </PermissionGate>
                        <PermissionGate
                            recurso={
                                RecursosPerfisEnum.CADASTRO_USUARIOS_EXCLUIR
                            }
                            redirect={''}
                        >
                            <Tooltip
                                title="Desativar"
                                placement="top"
                                disableInteractive
                            >
                                <IconButton
                                    aria-label="delete"
                                    onClick={() => {
                                        desativarUsuario('/' + row.id);
                                    }}
                                >
                                    <CancelIcon />
                                </IconButton>
                            </Tooltip>
                        </PermissionGate>
                        <Tooltip
                            title="Histórico de alterações"
                            placement="top"
                            disableInteractive
                        >
                            <IconButton
                                aria-label="ver"
                                onClick={() => {
                                    setUsuarioMostrarAuditoria(row as Usuario);
                                    setMostrarModalAuditoria(true);
                                }}
                            >
                                <ArticleIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    return (
        <PermissionGate
            recurso={RecursosPerfisEnum.CADASTRO_USUARIOS_VER}
            redirect={FrontEndPathResources.Dashboard}
        >
            <Container>
                <PageTitle title="Usuários" />

                <Divider />

                <ModalUsuarioExcluido
                    aberto={mostrarModalItensExcluidos}
                    fecharClicado={() => {
                        setMostrarModalItensExcluidos(false);
                    }}
                    restaurarClicado={() => {
                        carregar();
                        setMostrarModalItensExcluidos(false);
                    }}
                />

                {mostrarModalAuditoria && usuarioMostrarAuditoria && (
                    <ModalHistoricoAlteracoes
                        key={'historico_alteracoes'}
                        usuario={usuarioMostrarAuditoria}
                        aberto={mostrarModalAuditoria}
                        fecharClicado={() => {
                            setMostrarModalAuditoria(false);
                        }}
                    />
                )}

                <GenericComplexTable
                    service={getService(ApiResources.Usuario)}
                    columnToSearch={'nome'}
                    rows={usuarios}
                    columns={colunas}
                    createButtonText="Cadastrar novo"
                    linkCreateButtonText={
                        FrontEndPathResources.CadastroUsuario + '/criar'
                    }
                    mostrarIconeItensExcluidos={isPermitidoExcluir}
                    onIconeItensExcluidosClicado={() => {
                        setMostrarModalItensExcluidos(true);
                    }}
                />
            </Container>
        </PermissionGate>
    );
}
