import { Container, Divider } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { PermissionGate } from '../../Components/Navegacao/PermissionGate';
import PageTitle from '../../Components/PageTitle/PageTitle';
import SpinnerLoading from '../../Components/SpinnerLoading/SpinnerLoading';
import { MensagemErroRequisicaoApi } from '../../Config/Api';
import { ApiResources, FrontEndPathResources } from '../../Models/Api';
import ClienteFornecedor from '../../Models/ClienteFornecedor';
import Usuario, {
    ChavesArmazenamentoStorage,
    RecursosPerfisEnum,
    StatusRequisicao,
} from '../../Models/Usuario';
import LoginResponse from '../../Models/responses/LoginResponse';
import useGenericService from '../../hooks/useGenericService';
import Formulario, { CamposFormularioTratados } from './Formulario';

type Params = {
    id: string;
};

export default function Ver() {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { id } = useParams<Params>();
    const [clienteFornecedor, setClienteFornecedor] =
        useState<ClienteFornecedor>();

    const { getService } = useGenericService();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const navigate = useNavigate();

    useEffect(() => {
        let loginResponse = localStorage.getItem(
            ChavesArmazenamentoStorage.LOGIN_RESPONSE
        );

        if (loginResponse) {
            const usuario: Usuario = (
                JSON.parse(loginResponse) as LoginResponse
            ).usuario;

            if (usuario.trocarSenha) {
                window.location.pathname = FrontEndPathResources.AlterarSenha;
            }
        }
    }, []);

    useEffect(() => {
        setIsLoading(true);
        const carregarObjeto = async () => {
            const servico = getService(ApiResources.ClienteFornecedor);

            try {
                const resposta = await servico.api.get('/' + id);

                if (resposta.status === StatusRequisicao.OK) {
                    setClienteFornecedor(resposta.data);
                    setIsLoading(false);
                } else {
                    enqueueSnackbar('Não foi possível encontrar o registro', {
                        variant: 'error',
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
                    setIsLoading(false);
                }
            } catch (error) {
                setIsLoading(false);
            }
        };

        carregarObjeto();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, enqueueSnackbar]);

    const onSubmit = async (dadosFormulario: CamposFormularioTratados) => {
        setIsLoading(true);
        const servico = getService(ApiResources.ClienteFornecedor);

        try {
            const resultado = await servico.api.put('/' + id, dadosFormulario);

            if (resultado.status === StatusRequisicao.OK) {
                enqueueSnackbar(
                    'Cliente/Fornecedor ' +
                        resultado.data.nome +
                        ' atualizado com sucesso!',
                    {
                        variant: 'success',
                        onClick: () => {
                            closeSnackbar();
                        },
                    }
                );
                navigate(FrontEndPathResources.ClienteFornecedor);
                setIsLoading(false);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            (error as MensagemErroRequisicaoApi[]).forEach((erro) => {
                enqueueSnackbar(erro.atributo + ': ' + erro.mensagem, {
                    variant: 'error',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
            });
        }
    };

    return (
        <PermissionGate
            recurso={RecursosPerfisEnum.CLIENTES_FORNECEDORES_ALTERAR}
            redirect={FrontEndPathResources.ClienteFornecedor}
        >
            <SpinnerLoading isLoading={isLoading}>
                <Container>
                    <PageTitle title={'Alterando Cliente/Fornecedor ' + id} />
                    <Divider />
                    <Formulario
                        clienteFornecedor={clienteFornecedor}
                        onSubmit={onSubmit}
                        display={true}
                    />
                </Container>
            </SpinnerLoading>
        </PermissionGate>
    );
}
