import { Button, Container, Grid } from '@mui/material';
import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import DividerComp from '../../Components/Divider/DividerComp';
import PageTitle from '../../Components/PageTitle/PageTitle';
import SpinnerLoading from '../../Components/SpinnerLoading/SpinnerLoading';
import { ApiResources, FrontEndPathResources } from '../../Models/Api';
import useGenericService from '../../hooks/useGenericService';
import { LotePorTransportadoraDtoResponse } from '../LotesPorTransportadora/types/LotePorTransportadoraDtoResponse';
import { IOrdemColetaResponseDto } from '../OrdemColeta/domain/dtos/IOrdemColetaResponseDto';
import { styles } from '../PerfilUsuario/helpers/Styles';
import CavaloCarretaForm from './components/CavaloCarretaForm';
import ClassificacaoForm from './components/ClassificacaoForm';
import ConfirmacaoModal from './components/ConfirmacaoModal';
import EmbarqueForm from './components/EmbarqueForm';
import MotoristaForm from './components/MotoristaForm';
import NotaFiscalForm from './components/NotaFiscalForm';
import ObservacoesForm from './components/ObservacoesForm';
import OrdemColetaForm from './components/OrdemColetaForm';
import StatusForm from './components/StatusForm';
import UsuarioResponsavelForm from './components/UsuarioResponsavelForm';
import { IDoubleCheck, IUsuario } from './entities/IDoubleCheck';
import { ILoteEmbarqueContratos } from './entities/INotaComContratos';
import { IOrdemColetaLancamentoDoubleCheck } from './entities/IOrdemColetaLancamentoDoubleCheck';

type FormProps = {
    disabled?: boolean;
};

export function DoubleCheck({ disabled = false }: FormProps) {
    const formDoubleCheck = useForm<IDoubleCheck>({
        criteriaMode: 'all',
    });

    const { id } = useParams<{ id: string }>();

    const { setValue, getValues, watch } = formDoubleCheck;

    const { getService } = useGenericService();

    const [loading, setLoading] = useState(true);

    const [openModalConfirmacao, setOpenModalConfirmacao] = useState(false);

    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    const servicoOcl = getService(ApiResources.OrdemColetaLancamento);
    const servicoOc = getService(ApiResources.OrdemColeta);
    const servicoLet = getService(ApiResources.LoteEmbarquePorTransportadora);
    const servicoContrato = getService(ApiResources.LoteEmbarqueContrato);
    const servicoUsuario = getService(ApiResources.Usuario);

    useEffect(() => {
        const fetchData = async () => {
            const responseOc: AxiosResponse<IOrdemColetaResponseDto> =
                await servicoOc.api.get(`/buscar/${id}`);

            const responseLet: AxiosResponse<LotePorTransportadoraDtoResponse> =
                await servicoLet.api.get(
                    `/buscar-lote-transportadora/${responseOc.data.loteEmbarqueTransportadoraId}`
                );

            const responseContrato: AxiosResponse<ILoteEmbarqueContratos[]> =
                await servicoContrato.api.get(
                    `/listar/por-lote-embarque/${responseLet.data.loteEmbarque.id}`
                );

            const responseUsuarios: AxiosResponse =
                await servicoUsuario.api.get(
                    '/buscar-usuarios-responsaveis-double-check'
                );

            // Motorista e transportadora
            setValue('motorista', {
                transportadora: responseLet.data.transportadora.nome,
                ...responseOc.data.motorista,
            });

            // Cavalo
            setValue('cavalo', responseOc.data.cavalo);

            // Carretas
            if (responseOc.data.carretas)
                setValue('carretas', responseOc.data.carretas);

            // Ordem coleta
            setValue('coleta', {
                id: responseOc.data.id,
                peso: Number(responseOc.data.peso),
                produto: responseLet.data.produto.nome,
                lote: responseLet.data.lote,
            });

            // Classificação amostras
            if (responseOc.data.classificacaoAmostras)
                setValue(
                    'classificacaoAmostras',
                    responseOc.data.classificacaoAmostras
                );

            // Ticket
            if (responseOc.data.ordemColetaLancamento)
                setValue(
                    'ticket',
                    responseOc.data.ordemColetaLancamento
                        .ticketCaminhaoCarregado
                );

            // Laudo
            if (responseOc.data.ordemColetaLancamento)
                setValue('laudo', responseOc.data.ordemColetaLancamento.laudo);

            // Lista de contratos
            responseContrato.data.map(contrato => {
                contrato.permiteEditarTransgenia = contrato.transgenia == null
            })
            if (responseContrato)
                setValue('listaContratos', responseContrato.data);

            if (
                responseOc.data.ordemColetaLancamento &&
                responseOc.data.ordemColetaLancamento.notasAnteriores
            )
                setValue(
                    'notas',
                    responseOc.data.ordemColetaLancamento.notasAnteriores
                );

            if (responseOc.data.ordemColetaLancamento) {
                setValue(
                    'observacoes',
                    responseOc.data.ordemColetaLancamento.observacoes
                        ? responseOc.data.ordemColetaLancamento.observacoes.map(
                              (obs) => {
                                  if (obs.dataHoraCriacao)
                                      obs.dataHoraCriacao = format(
                                          new Date(obs.dataHoraCriacao),
                                          'dd/MM/yyyy HH:mm'
                                      );
                                  return obs;
                              }
                          )
                        : []
                );

                setValue('pesagem', {
                    tara: responseOc.data.ordemColetaLancamento.tara,
                    pesoBruto: responseOc.data.ordemColetaLancamento.pesoBruto,
                    pesoLiquido:
                        responseOc.data.ordemColetaLancamento.pesoLiquido,
                });

                setValue(
                    'ordemColetaLancamentoId',
                    responseOc.data.ordemColetaLancamento.id!.toString()
                );

                if (responseOc.data.ordemColetaLancamento.statusDoubleCheck)
                    setValue(
                        'status',
                        responseOc.data.ordemColetaLancamento.statusDoubleCheck
                    );
            }

            let usuarios: IUsuario[] = [];
            responseUsuarios.data.map((usuario: any) =>
                usuarios.push({ login: usuario.login, usuario: usuario.nome })
            );

            setValue('listaUsuarios', usuarios);

            setLoading(false);
        };

        fetchData();

        // eslint-disable-next-line
    }, []);

    const salvarDoubleCheck = async () => {
        setLoading(true);

        const o = getValues('observacoes').map((obs) => {
            obs.dataHoraCriacao = null;
            return obs;
        });

        const notas = getValues('notas').map((nota) => {
            nota.dataEmissao = format(new Date(nota.dataEmissao), 't');
            return nota;
        });

        const ocl: IOrdemColetaLancamentoDoubleCheck = {
            ordemColetaLancamentoId: parseInt(
                getValues('ordemColetaLancamentoId')
            ),
            statusDoubleCheck: getValues('status'),
            observacoes: o,
            classificacaoAmostras: getValues('classificacaoAmostras'),
            notasAnteriores: notas,
            laudo: getValues('laudo'),
            ticketCaminhaoCarregado: getValues('ticket'),
        };

        try {
            const response = await servicoOcl.api.put(
                `/double-check/${ocl.ordemColetaLancamentoId}`,
                ocl
            );

            if (response.status === 200) {
                enqueueSnackbar('Double check salvo com sucesso.', {
                    variant: 'success',
                });

                navigate(FrontEndPathResources.DoubleCheck);
            } else {
                enqueueSnackbar('Erro ao salvar double check.', {
                    variant: 'error',
                });
            }

            setLoading(false);
        } catch (e) {
            enqueueSnackbar('Erro ao salvar double check.', {
                variant: 'error',
            });

            setLoading(false);
        }
    };

    return (
        <SpinnerLoading isLoading={loading}>
            <Container maxWidth={false}>
                <FormProvider {...formDoubleCheck}>
                    <PageTitle title={'Double Check'} />
                    <DividerComp texto="Alterar Usuário Responsável" />
                    <UsuarioResponsavelForm />
                    <DividerComp texto="Motorista" />
                    <MotoristaForm />
                    <DividerComp texto="Cavalo" />
                    <CavaloCarretaForm />
                    <DividerComp texto="Ordem Coleta" />
                    <OrdemColetaForm />
                    <DividerComp texto="Embarque" />
                    <EmbarqueForm />
                    <DividerComp texto="Classificação" />
                    <ClassificacaoForm />
                    {watch('notas')?.length > 0 && (
                        <DividerComp texto="Nota(s) Fiscal(s)" />
                    )}
                    <NotaFiscalForm />
                    <DividerComp texto="Observações" />
                    <ObservacoesForm />
                    <DividerComp texto="Status" />
                    <StatusForm />
                    <Grid container item spacing={2}>
                        <Grid item xs={12} lg={3}>
                            <Button
                                onClick={() => {
                                    setOpenModalConfirmacao(true);
                                }}
                                variant="contained"
                                style={styles().button}
                                size="large"
                            >
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                    <ConfirmacaoModal
                        open={openModalConfirmacao}
                        setModalOpen={setOpenModalConfirmacao}
                        saveDoubleCheck={salvarDoubleCheck}
                    />
                </FormProvider>
            </Container>
        </SpinnerLoading>
    );
}
