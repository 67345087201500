import { PropsWithChildren, useEffect } from 'react';

import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { FrontEndPathResources } from '../../Models/Api';
import { RecursosPerfisEnum } from '../../Models/Usuario';
import { usePermission } from '../../hooks/usePermission';

interface IPermissionGateProps {
    recurso: RecursosPerfisEnum;
    redirect?: true | string;
}

export function PermissionGate({
    recurso,
    redirect,
    children,
}: PropsWithChildren<IPermissionGateProps>): JSX.Element {
    const { isAllowed } = usePermission(recurso);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const navigate = useNavigate();
    useEffect(() => {
        if (!isAllowed && !!redirect) {
            enqueueSnackbar(
                'Você não tem permissão para acessar este recurso',
                {
                    variant: 'warning',
                    onClick: () => {
                        closeSnackbar();
                    },
                }
            );
            const replaceUrl =
                typeof redirect === 'string'
                    ? redirect
                    : FrontEndPathResources.Dashboard;
            navigate(replaceUrl);
        }
    }, [isAllowed, redirect, enqueueSnackbar, closeSnackbar, navigate]);

    if (isAllowed) return <>{children}</>;
    return <></>;
}
