import React, { useContext, useEffect, useState } from 'react';
import GerenciadorTenancyService from './Config/services/GerenciadorTenancyService';
import { FrontEndPathResources } from './Models/Api';
import Server from './Models/Server';
import Usuario, {
    ChavesArmazenamentoStorage,
    EnumAutorizacao,
    StatusRequisicao,
} from './Models/Usuario';
import LoginResponse from './Models/responses/LoginResponse';

export default function AuthProvider({
    children,
}: {
    children: React.ReactNode;
}) {
    const [usuarioLogado, setUsuarioLogado] = useState<Usuario | null>(null);

    const localStorageCredentials = localStorage.getItem(
        ChavesArmazenamentoStorage.LOGIN_RESPONSE
    );

    const parsedCredentials = localStorageCredentials
        ? JSON.parse(localStorageCredentials)
        : null;

    let signin = async (
        usuario: string,
        senha: string,
        host: string,
        callback: (irPara: string) => void,
        callBackErrorOperation: (mensagemErro: string) => void
    ) => {
        const servicoLogin = new GerenciadorTenancyService(host);

        const login = usuario;

        try {
            const resultado = await servicoLogin.api.login(
                '/auth',
                login,
                senha
            );

            if (resultado.status === StatusRequisicao.OK) {
                const loginResponse: LoginResponse =
                    resultado.data as LoginResponse;

                if (
                    loginResponse.usuario.autorizacoes[0]?.tipoAcesso ===
                    EnumAutorizacao.Classificador
                ) {
                    throw Error;
                }

                localStorage.setItem(
                    ChavesArmazenamentoStorage.LOGIN_RESPONSE,
                    JSON.stringify(loginResponse)
                );

                // localStorage.setItem(
                //     ChavesArmazenamentoStorage.Usuario,
                //     JSON.stringify(user)
                // );

                // localStorage.setItem(
                //     ChavesArmazenamentoStorage.Token,
                //     JSON.stringify(resultado.data.token)
                // );

                setUsuarioLogado(loginResponse.usuario);
                if (
                    loginResponse.usuario.login === 'TRATOLOG' ||
                    loginResponse.usuario.login === 'VIDAL_LOG' ||
                    loginResponse.usuario.login === 'D_GRANEL' ||
                    loginResponse.usuario.login === 'SAFRA_LOG'
                ) {
                    callback(
                        loginResponse.usuario.trocarSenha
                            ? '/easymbark/alterar-senha'
                            : FrontEndPathResources.Juncao
                    );
                } else {
                    callback(
                        loginResponse.usuario.trocarSenha
                            ? '/easymbark/alterar-senha'
                            : '/easymbark'
                    );
                }
            } else {
                callBackErrorOperation('Usuário e/ou senha inválidos!');
                return;
            }
        } catch (error: any) {
            callBackErrorOperation(
                error[0]?.mensagem || 'Usuário e/ou senha inválidos!'
            );
        }
    };

    let signout = (callback: VoidFunction) => {
        setUsuarioLogado(null);
        localStorage.removeItem(ChavesArmazenamentoStorage.LOGIN_RESPONSE);
        localStorage.removeItem(ChavesArmazenamentoStorage.HOST);
        callback();
    };

    useEffect(() => {
        const urlEnv = process.env.REACT_APP_BACKEND_URL;

        if (urlEnv && urlEnv === 'http://localhost:8089/v1') {
            localStorage.removeItem(ChavesArmazenamentoStorage.CLIENT);
            localStorage.removeItem(ChavesArmazenamentoStorage.HOST);

            const server = new Server(
                'teste',
                8089,
                9,
                0,
                'localhost',
                'http://localhost',
                'testes localhost',
                true
            );

            localStorage.setItem(
                ChavesArmazenamentoStorage.HOST,
                JSON.stringify(server)
            );
        }
    }, []);

    let value;
    if (parsedCredentials?.usuario && parsedCredentials?.token) {
        value = { usuarioLogado: parsedCredentials.usuario, signin, signout };
    } else {
        value = { usuarioLogado, signin, signout };
    }

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
}

interface AuthContextType {
    usuarioLogado: Usuario | null;
    signin: (
        usuario: string,
        senha: string,
        host: string,
        callback: (irPara: string) => void,
        callBackErrorMessage: (mensagemError: string) => void
    ) => void;
    signout: (callback: VoidFunction) => void;
}

let AuthContext = React.createContext<AuthContextType>({} as AuthContextType);

export function useAuth() {
    return useContext(AuthContext);
}
