import { Container, Divider } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { PermissionGate } from '../../Components/Navegacao/PermissionGate';
import PageTitle from '../../Components/PageTitle/PageTitle';
import SpinnerLoading from '../../Components/SpinnerLoading/SpinnerLoading';
import Amostra from '../../Models/Amostra';
import { ApiResources, FrontEndPathResources } from '../../Models/Api';
import Usuario, {
    ChavesArmazenamentoStorage,
    RecursosPerfisEnum,
    StatusRequisicao,
} from '../../Models/Usuario';
import LoginResponse from '../../Models/responses/LoginResponse';
import useGenericService from '../../hooks/useGenericService';
import Formulario from './Formulario';

type Params = {
    id: string;
};

export default function Ver() {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { id } = useParams<Params>();

    const [amostra, setAmostra] = useState<Amostra>();

    const { getService } = useGenericService();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        let loginResponse = localStorage.getItem(
            ChavesArmazenamentoStorage.LOGIN_RESPONSE
        );

        if (loginResponse) {
            const usuario: Usuario = (
                JSON.parse(loginResponse) as LoginResponse
            ).usuario;

            if (usuario.trocarSenha) {
                window.location.pathname = FrontEndPathResources.AlterarSenha;
            }
        }
    }, []);

    useEffect(() => {
        setIsLoading(true);
        const carregarProduto = async () => {
            const servico = getService(ApiResources.Amostra);

            try {
                const resposta = await servico.api.get('/' + id);

                if (resposta.status === StatusRequisicao.OK) {
                    setAmostra(resposta.data);
                    setIsLoading(false);
                } else {
                    enqueueSnackbar('Não foi possível encontrar o registro', {
                        variant: 'error',
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
                    setIsLoading(false);
                }
            } catch (error) {
                setIsLoading(false);
            }
        };

        carregarProduto();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, enqueueSnackbar]);

    return (
        <PermissionGate
            recurso={RecursosPerfisEnum.AMOSTRAS_VER}
            redirect={FrontEndPathResources.Amostra}
        >
            <SpinnerLoading isLoading={isLoading}>
                <Container>
                    <PageTitle title={'Visualizando amostra ' + id} />
                    <Divider />
                    <Formulario disabled amostra={amostra} />
                </Container>
            </SpinnerLoading>
        </PermissionGate>
    );
}
