import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { IBaseUseCase } from '../types/interfaces/IBaseUseCase';

interface IUseFetchOptions<T> {
    data: T;
    loading: boolean;
    errorMessage: string;
    setErrorMessage: Dispatch<SetStateAction<string>>;
    execute: <params, response>(
        usecase: IBaseUseCase<params, response>,
        param: params,
        onSuccessCallback?: (resp: response) => void,
        onErrorCallback?: () => void
    ) => Promise<response>;
    executePromise: <response>(
        promiseCallback: () => Promise<response>,
        onSuccessCallback?: (resp: response) => void,
        onErrorCallback?: (error: any) => void,
        showLoading?: boolean
    ) => Promise<response>;
}

type ValueType<T> = T extends Promise<infer U> ? U : T;

export function useFetchUseCase<P extends any[]>(
    promisesFactory?: () => Promise<ValueType<P[number]>>[]
): IUseFetchOptions<P> {
    const [data, setData] = useState<P>(null as unknown as P);
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState('');

    const execute = useCallback(
        async <Params, Response>(
            usecase: IBaseUseCase<Params, Response>,
            param: Params,
            onSuccessCallback?: (resp: Response) => void,
            onErrorCallback?: () => void
        ): Promise<Response> => {
            try {
                setLoading(true);
                const response = await usecase.execute(param);
                if (onSuccessCallback) onSuccessCallback(response);
                return response;
            } catch (error: any) {
                setErrorMessage(error.message);
                if (onErrorCallback) onErrorCallback();
                return Promise.reject(error);
            } finally {
                setLoading(false);
            }
        },
        []
    );

    const executePromise = useCallback(
        async <response,>(
            promiseCallback: () => Promise<response>,
            onSuccessCallback?: (resp: response) => void,
            onErrorCallback?: (error: any) => void,
            showLoading = true
        ): Promise<response> => {
            try {
                if (showLoading) setLoading(true);
                const response = await promiseCallback();
                if (onSuccessCallback) onSuccessCallback(response);
                return response;
            } catch (error: any) {
                setErrorMessage(error.message);
                if (onErrorCallback) onErrorCallback(error);
                return Promise.reject(error);
            } finally {
                setLoading(false);
            }
        },
        []
    );

    useEffect(() => {
        if (!promisesFactory) return;
        setLoading(true);
        Promise.all(promisesFactory())
            .then((response) => {
                setData(response as P);
            })
            .catch((error: any) => {
                setErrorMessage(error?.message);
            })
            .finally(() => {
                setLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        data,
        loading,
        errorMessage,
        setErrorMessage,
        execute,
        executePromise,
    };
}
