import ConfigGeral from './ConfigGeral';
import ConfigEmail from './ConfigEmail';
import Contato from './Contato';
import Endereco from './Endereco';
import Usuario from './Usuario';

export enum TipoInscricao {
    Isento = 'isento',
}

export default class Contratante {
    constructor(
        public id: number,
        public nome: string,
        public nomeFantasia: string,
        public cnpj: string,
        public contato: Contato,
        public usuario: Usuario,
        public endereco?: Endereco,
        public configGeral?: ConfigGeral,
        public configEmail?: ConfigEmail,
        public urlEmissaoNota?: string //TODO: revisar essa parte de urlEmissaoNota //public integracao?: CamposFormulario //import { CamposFormulario } from '../Screens/Configuracao/Configuracao';
    ) {}
}
