import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { StatusRequisicao } from '../../Models/Usuario';
import { UseAutocompleteProps } from '@mui/base';
import { StandardTextFieldProps } from '@mui/material';
import { ApiResources } from '../../Models/Api';
import useGenericService from '../../hooks/useGenericService';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import { useState } from 'react';
import CidadeIBGE from '../../Models/CidadeIBGE';

type InputProps = Pick<
    StandardTextFieldProps,
    | 'margin'
    | 'error'
    | 'helperText'
    | 'fullWidth'
    | 'required'
    | 'inputRef'
    | 'InputLabelProps'
    | 'label'
    | 'placeholder'
    | 'disabled'
    | 'name'
    | 'FormHelperTextProps'
>;

export interface Props
    extends UseAutocompleteProps<any, boolean, boolean, undefined>,
        InputProps {
    label: string;
    disabled?: boolean;
    required?: boolean;
}

export default function SelectCidades({
    margin,
    error,
    helperText,
    fullWidth,
    required,
    InputLabelProps,
    inputRef,
    label,
    placeholder,
    name,
    disabled = false,
    ...outrasPropriedades
}: Props) {
    const [cidades, setCidades] = useState<CidadeIBGE[]>([]);

    const { getService } = useGenericService();

    const carregaCidades = async (value: string) => {
        const servico = getService(ApiResources.Juncao);

        try {
            const resposta = await servico.api.get('get-cidades-ibge', {
                params: { cidade: value },
            });

            if (resposta.status === StatusRequisicao.OK) {
                setCidades(resposta.data);
            }
        } catch (error) {
            console.log('erro-select-classificador', error);
        }
    };

    const handleInputChange = (event: any, value: any) => {
        // Carrega cidades apenas se houver pelo menos 2 caracteres no input
        if (value.length >= 3) {
            carregaCidades(value);
        }
    };

    return (
        <Autocomplete
            {...outrasPropriedades}
            fullWidth
            noOptionsText={'Sem opções'}
            options={cidades}
            value={(outrasPropriedades as any).field.value}
            multiple={false}
            disabled={disabled}
            onInputChange={handleInputChange}
            getOptionLabel={(option) => option.cidade + ' - ' + option.uf}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={name}
                    inputRef={inputRef}
                    label={label}
                    required={required}
                    autoComplete="new-password"
                    margin={margin}
                    disabled={disabled}
                    error={error}
                    helperText={helperText}
                    fullWidth={fullWidth}
                    placeholder={placeholder}
                    InputLabelProps={InputLabelProps}
                />
            )}
        />
    );
}
