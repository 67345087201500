import { IBaseUseCase } from '../../../../types/interfaces/IBaseUseCase';
import { IOrdemColetaLancamento } from '../dtos/IOrdemColetaLancamento';
import { IOrdemColetaRepository } from '../repositories/IOrdemColetaRepository';

export class GetLancarDadosEmbarqueById
    implements IBaseUseCase<number, IOrdemColetaLancamento>
{
    constructor(private readonly repository: IOrdemColetaRepository) {}

    execute(id: number): Promise<IOrdemColetaLancamento> {
        return this.repository.getLancarDadosEmbarqueById(id);
    }
}
