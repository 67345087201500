import { PermissionGate } from '../../../Components/Navegacao/PermissionGate';
import { FrontEndPathResources } from '../../../Models/Api';
import LoteEmbarque from '../../../Models/LoteEmbarque';
import { RecursosPerfisEnum } from '../../../Models/Usuario';
import { FindAnexosDocumentosByOrdemColetaId } from '../../OrdemColeta/domain/usecases/FindAnexosDocumentosByOrdemColetaId';
import { PostOrdemColeta } from '../../OrdemColeta/domain/usecases/PostOrdemColeta';
import { PutArquivoOrdemColeta } from '../../OrdemColeta/domain/usecases/PutArquivoOrdemColeta';
import { OrdemColetaRepository } from '../../OrdemColeta/infra/OrdemColetaRepository';
import { OrdemColeta } from '../OrdemColeta';

interface Props {
    row: LoteEmbarque;
}

export const OrdemColetaFactory: React.FC<Props> = ({ row }) => {
    const ordemColetaRepository = new OrdemColetaRepository();

    const postOrdemColeta = new PostOrdemColeta(ordemColetaRepository);

    const putArquivoOrdemColeta = new PutArquivoOrdemColeta(
        ordemColetaRepository
    );

    const findAnexosDocumentosByOrdemColetaId =
        new FindAnexosDocumentosByOrdemColetaId(ordemColetaRepository);

    return (
        <PermissionGate
            recurso={RecursosPerfisEnum.LOTE_EMBARQUE_POR_TRANSPORTADORA}
            redirect={FrontEndPathResources.Dashboard}
        >
            <OrdemColeta
                row={row}
                postOrdemColeta={postOrdemColeta}
                putArquivoOrdemColeta={putArquivoOrdemColeta}
                findAnexosDocumentosByOrdemColetaId={
                    findAnexosDocumentosByOrdemColetaId
                }
            />
        </PermissionGate>
    );
};
