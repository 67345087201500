import { IBaseUseCase } from '../../../../types/interfaces/IBaseUseCase';
import { IOrdemColetaResponseDto } from '../dtos/IOrdemColetaResponseDto';
import { IOrdemColetaRepository } from '../repositories/IOrdemColetaRepository';

export class GetOrdemColetaById
    implements IBaseUseCase<number, IOrdemColetaResponseDto>
{
    constructor(private readonly repository: IOrdemColetaRepository) {}

    execute(id: number): Promise<IOrdemColetaResponseDto> {
        return this.repository.getOrdemColetaById(id);
    }
}
