import { joiResolver } from '@hookform/resolvers/joi';
import {
    Button,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    Radio,
    RadioGroup,
    TextField,
} from '@mui/material';
import JoiCpfCnpj from 'cpf-cnpj-validator';
import { default as JoiOriginal } from 'joi';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import DividerComp from '../../Components/Divider/DividerComp';
import InputCelular from '../../Components/Input/InputCelular';
import InputCnpj from '../../Components/Input/InputCnpj';
import InputCpf from '../../Components/Input/InputCpf';
import InputTelefone from '../../Components/Input/InputTelefone';
import Util from '../../Helpers/Util';
import { FrontEndPathResources } from '../../Models/Api';
import Classificador from '../../Models/Classificador';
import Contato from '../../Models/Contato';
import { TipoPessoa } from '../../Models/Pessoa';
import { UsuarioTratado } from '../../Models/Usuario';
import { styles } from './helpers/Styles';
import { VisibilityOff, Visibility } from '@mui/icons-material';

export type CamposFormulario = {
    nome: string;
    telefone: string;
    celular: string;
    email: string;
    login: string;
    senha: string;
    cpf?: string;
    cnpj?: string;
};

export interface CamposFormularioTratados
    extends Omit<
        CamposFormulario,
        'login' | 'senha' | 'email' | 'telefone' | 'celular'
    > {
    usuario: UsuarioTratado;
    contato: Contato | null;
}

interface Props {
    disabled?: boolean;
    classificador?: Classificador;
    permitirSenhaVazia?: boolean;
    onSubmit?: (dadosFormulario: CamposFormularioTratados) => {};
}

export default function Formulario({
    disabled = false,
    classificador,
    permitirSenhaVazia = false,
    onSubmit,
}: Props) {
    const [tipoPessoa, setTipoPessoa] = useState<TipoPessoa>(
        TipoPessoa.Juridica
    );
    const [trocarSenhaUsuario, setTrocarSenhaUsuario] =
        useState<boolean>(false);

    const [mensagemErroPadraoSenha, setMensagemErroPadraoSenha] =
        useState<string>();

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    
    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    const navigate = useNavigate();

    const Joi = JoiOriginal.extend(JoiCpfCnpj);

    let validacaoComCpf = Joi.object({
        nome: Joi.string().trim().required().min(3).max(150).messages({
            'string.base': 'O nome deve ser uma string',
            'string.empty': 'O nome não pode ficar em branco',
            'any.required': 'O nome não pode ficar em branco',
            'string.min': 'Deve ter no mínimo 3 caracteres',
            'string.max': 'Permitido até 150 caracteres',
        }),
        cpf: Joi.document().cpf().messages({
            'string.base': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
            'any.required': 'Não pode ficar em branco',
        }),
        cnpj: Joi.string().allow('', null).optional(),
        login: Joi.string().max(70).trim().required().messages({
            'string.base': 'O login deve ser uma string',
            'string.max': 'Deve ter no máximo 70 caracteres',
            'string.empty': 'O login não pode ficar em branco',
            'any.required': 'O login não pode ficar em branco',
        }),
        senha: permitirSenhaVazia
            ? Joi.string().allow('', null)
            : Joi.string().trim().required().min(8).messages({
                  'string.base': 'A senha deve ser uma string',
                  'string.empty': 'A senha não pode ficar em branco',
                  'any.required': 'A senha não pode ficar em branco',
                  'string.min': 'A senha deve ter no mínimo 8 caracteres',
              }),
        email: Joi.string()
            .email({ tlds: { allow: false } })
            .allow('', null)
            .messages({
                'string.email': 'Deve ser um e-mail válido',
            }),
        telefone: Joi.string().allow('', null).optional(),
        celular: Joi.string().allow('', null).optional(),
    });

    let validacaoComCnpj = Joi.object({
        nome: Joi.string().trim().required().min(3).max(120).messages({
            'string.base': 'O nome deve ser uma string',
            'string.empty': 'O nome não pode ficar em branco',
            'any.required': 'O nome não pode ficar em branco',
            'string.min': 'Deve ter no mínimo 3 caracteres',
            'string.max': 'Permitido até 120 caracteres',
        }),
        cpf: Joi.string().allow('', null).optional(),
        cnpj: Joi.document().cnpj().messages({
            'string.empty': 'O CNPJ não pode ficar em branco',
            'any.required': 'O CNPJ não pode ficar em branco',
        }),
        login: Joi.string().max(70).trim().required().messages({
            'string.base': 'O login deve ser uma string',
            'string.max': 'Deve ter no máximo 70 caracteres',
            'string.empty': 'O login não pode ficar em branco',
            'any.required': 'O login não pode ficar em branco',
        }),
        senha: permitirSenhaVazia
            ? Joi.string().allow('', null)
            : Joi.string().trim().required().min(8).messages({
                  'string.base': 'A senha deve ser uma string',
                  'string.empty': 'A senha não pode ficar em branco',
                  'any.required': 'A senha não pode ficar em branco',
                  'string.min': 'A senha deve ter no mínimo 8 caracteres',
              }),
        email: Joi.string()
            .max(150)
            .email({ tlds: { allow: false } })
            .allow('', null)
            .messages({
                'string.email': 'Deve ser um e-mail válido',
                'string.max': 'Deve ter no máximo 150 caracteres',
            }),
        telefone: Joi.string().allow('', null).optional(),
        celular: Joi.string().allow('', null).optional(),
    });

    const {
        control,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm<CamposFormulario>({
        resolver: joiResolver(
            tipoPessoa === TipoPessoa.Fisica
                ? validacaoComCpf
                : validacaoComCnpj
        ),
        criteriaMode: 'all',
    });

    const getTipoPessoa = (cnpj?: string) => {
        if (cnpj === null) return TipoPessoa.Fisica;
        else return TipoPessoa.Juridica;
    };

    useEffect(() => {
        const preencherFormulario = (classificador: Classificador) => {
            setTipoPessoa(getTipoPessoa(classificador.cnpj));
            setTrocarSenhaUsuario(classificador.usuario?.trocarSenha!);

            reset({
                nome: classificador.nome,
                cpf: classificador ? classificador.cpf : '',
                cnpj: classificador ? classificador.cnpj : '',
                login: classificador.usuario?.login,
                celular: classificador.contato
                    ? classificador.contato.celular
                    : undefined,
                email: classificador.contato
                    ? classificador.contato.email
                    : undefined,
                telefone: classificador.contato
                    ? classificador.contato.telefone
                    : undefined,
            });
        };
        if (classificador) {
            preencherFormulario(classificador);
        }
    }, [reset, classificador]);

    const getContato = (email: string, telefone: string, celular: string) => {
        if (email === '' && telefone === '' && celular === '') {
            return null;
        }

        return {
            email,
            telefone,
            celular,
        };
    };

    const tratarDados = (dadosFormulario: CamposFormulario) => {
        if (dadosFormulario.senha) {
            if (
                !Util.temNumeroECaracteresEspeciais(dadosFormulario.senha) ||
                !Util.temLetraMaiuscula(dadosFormulario.senha)
            ) {
                setMensagemErroPadraoSenha(
                    'A senha deve conter ao menos 1 número, 1 caractere especial e 1 letra maiúscula.'
                );
                return;
            }
        }

        if (onSubmit) {
            const { login, senha, email, telefone, celular, ...resto } =
                dadosFormulario;

            let contato: Contato | null = getContato(email, telefone, celular);

            const dadosFormTratados: CamposFormularioTratados = {
                ...resto,
                usuario: {
                    login,
                    senha: !senha && senha === '' ? null : senha,
                    trocarSenha: trocarSenhaUsuario,
                },
                contato: contato,
            };
            onSubmit(dadosFormTratados);
        }
    };

    return (
        <Container>
            <form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(tratarDados)}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <FormControl
                            style={styles().formControlStyle}
                            disabled={disabled}
                        >
                            <FormLabel id="demo-row-radio-buttons-group-label">
                                Tipo de Pessoa
                            </FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    setTipoPessoa(
                                        (event.target as HTMLInputElement)
                                            .value as TipoPessoa
                                    );
                                    if (tipoPessoa === 'pessoa_juridica') {
                                        setValue('cnpj', undefined, {
                                            shouldValidate: false,
                                        });
                                    } else {
                                        setValue('cpf', undefined, {
                                            shouldValidate: false,
                                        });
                                    }
                                }}
                                value={tipoPessoa}
                            >
                                <FormControlLabel
                                    value={TipoPessoa.Juridica}
                                    control={<Radio />}
                                    label="Jurídica"
                                />

                                <FormControlLabel
                                    value={TipoPessoa.Fisica}
                                    control={<Radio />}
                                    label="Física"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="nome"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        inputProps={{ maxLength: 120 }}
                                        fullWidth
                                        label="Nome"
                                        autoFocus
                                        error={!!errors.nome}
                                        helperText={
                                            errors.nome
                                                ? errors.nome.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>

                    {tipoPessoa === TipoPessoa.Fisica ? (
                        <Grid item xs={12} lg={5}>
                            <Controller
                                name="cpf"
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: { ref, ...field },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <InputCpf
                                            {...field}
                                            inputRef={ref}
                                            value={classificador?.cpf}
                                            margin="normal"
                                            autoComplete="off"
                                            disabled={disabled}
                                            fullWidth
                                            label="CPF"
                                            error={!!errors.cpf}
                                            helperText={
                                                errors.cpf
                                                    ? errors.cpf.message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                    ) : (
                        <Grid item xs={12} lg={5}>
                            <Controller
                                name="cnpj"
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: { ref, ...field },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <InputCnpj
                                            {...field}
                                            inputRef={ref}
                                            margin="normal"
                                            autoComplete="off"
                                            disabled={disabled}
                                            fullWidth
                                            label="CNPJ"
                                            error={!!errors.cnpj}
                                            helperText={
                                                errors.cnpj
                                                    ? errors.cnpj.message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                    )}
                </Grid>

                <DividerComp texto="Login" />

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="login"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        fullWidth
                                        inputProps={{ maxLength: 70 }}
                                        disabled={disabled}
                                        label="Usuário"
                                        error={!!errors.login}
                                        helperText={
                                            errors.login
                                                ? errors.login.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="senha"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        fullWidth
                                        label="Senha"
                                        disabled={disabled}
                                        type={showPassword ? 'text' : 'password'}
                                        id="password"
                                        autoComplete="current-password"
                                        error={!!errors.senha}
                                        helperText={
                                            errors.senha
                                                ? errors.senha.message
                                                : ''
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={
                                                            handleClickShowPassword
                                                        }
                                                        onMouseDown={
                                                            handleMouseDownPassword
                                                        }
                                                        edge="end"
                                                    >
                                                        {showPassword ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>

                {mensagemErroPadraoSenha && (
                    <p style={styles().mensagemErrorStyle}>
                        {mensagemErroPadraoSenha}
                    </p>
                )}

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <FormGroup>
                            <FormControlLabel
                                disabled={disabled}
                                control={
                                    <Checkbox
                                        checked={trocarSenhaUsuario}
                                        onClick={() =>
                                            setTrocarSenhaUsuario(
                                                !trocarSenhaUsuario
                                            )
                                        }
                                    />
                                }
                                label="Solicitar a alteração de senha no primeiro acesso"
                            />
                        </FormGroup>
                    </Grid>
                </Grid>

                <DividerComp texto="Contato" />

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        inputProps={{ maxLength: 150 }}
                                        label="Email"
                                        error={!!errors.email}
                                        helperText={
                                            errors.email
                                                ? errors.email.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="telefone"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <InputTelefone
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        label="Telefone"
                                        error={!!errors.telefone}
                                        helperText={
                                            errors.telefone
                                                ? errors.telefone.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="celular"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <InputCelular
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        label="Celular"
                                        error={!!errors.celular}
                                        helperText={
                                            errors.celular
                                                ? errors.celular.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2} justifyContent="end">
                    {!disabled ? (
                        <Button
                            type="submit"
                            variant="contained"
                            style={styles().button}
                            size="large"
                        >
                            Salvar
                        </Button>
                    ) : (
                        <Button
                            onClick={() => {
                                navigate(
                                    FrontEndPathResources.Classificador +
                                        '/alterar/' +
                                        classificador?.id
                                );
                            }}
                            variant="contained"
                            style={styles().button}
                            size="large"
                        >
                            Alterar
                        </Button>
                    )}
                </Grid>
            </form>
        </Container>
    );
}
