import JoiCpfCnpj from 'cpf-cnpj-validator';
import Joi, { default as JoiOriginal } from 'joi';
import { TipoPessoa } from '../../../../Models/Pessoa';

export default function useVeiculoValidation(tipoPessoa: TipoPessoa) {
    const Joi = JoiOriginal.extend(JoiCpfCnpj);

    const validacao = Joi.object({
        Plate: Joi.string().trim().required().min(7).max(7).messages({
            'string.base': 'O nome deve ser uma string',
            'string.empty': 'O nome não pode ficar em branco',
            'any.required': 'O nome não pode ficar em branco',
            'string.min': 'Placa inválida',
            'string.max': 'Placa inválida',
        }),
        Name: Joi.string().trim().required().min(3).max(60).messages({
            'string.base': 'O nome deve ser uma string',
            'string.empty': 'O nome não pode ficar em branco',
            'any.required': 'O nome não pode ficar em branco',
            'string.min': 'Deve ter no mínimo 3 caracteres',
            'string.max': 'Permitido até 60 caracteres',
        }),

        Document: Joi.string()
            .required()
            .custom((value: string, helpers: Joi.CustomHelpers<any>) => {
                if (!validateCNPJ(value) && !validateCPF(value)) {
                    return helpers.error('any.invalid');
                }
                return value;
            }, 'custom validation')
            .messages({
                'string.base': 'Não pode ficar em branco',
                'string.empty': 'Não pode ficar em branco',
                'any.required': 'Não pode ficar em branco',
                'any.invalid': 'Documento inválido',
            }),
        Postcode: Joi.string().min(8).max(8).required().messages({
            'string.max': 'Deve ter 8 caracteres',
            'string.min': 'Deve ter 8 caracteres',
            'any.required': 'Não pode ficar em branco',
            'string.required': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
        }),
        Address: Joi.string().max(60).required().messages({
            'string.max': 'Deve ter no máximo 60 caracteres',
            'any.required': 'Não pode ficar em branco',
            'string.required': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
        }),
        Number: Joi.string().max(7).required().messages({
            'string.max': 'Deve ter no máximo 7 caracteres',
            'any.required': 'Não pode ficar em branco',
            'string.required': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
        }),
        County: Joi.string().max(100).required().messages({
            'string.max': 'Deve ter no máximo 100 caracteres',
            'any.required': 'Não pode ficar em branco',
            'string.required': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
        }),
        cidadeIbge: Joi.object({
            id: Joi.number().required().messages({
                'number.base': 'Não pode ficar em branco',
                'number.required': 'Não pode ficar em branco',
            }),
        })
            .unknown()
            .messages({
                'object.base': 'Não pode ficar em branco',
            }),
        cidadeIbgePlate: Joi.object({
            id: Joi.number().required().messages({
                'number.base': 'Não pode ficar em branco',
                'number.required': 'Não pode ficar em branco',
            }),
        })
            .unknown()
            .messages({
                'object.base': 'Não pode ficar em branco',
            }),
        Document3: Joi.string().min(11).max(11).required().messages({
            'string.base': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
            'string.max': 'deve ter no máximo 11 caracteres',
            'string.min': 'deve ter no mínimo 11 caracteres',
            'any.required': 'Não pode ficar em branco',
        }),
        Document2: Joi.string().min(9).max(12).required().messages({
            'string.base': 'não pode ficar em branco',
            'string.empty': 'não pode ficar em branco',
            'string.max': 'deve ter no máximo 12 caracteres',
            'string.min': 'deve ter no mínimo 9 caracteres',
            'any.required': 'não pode ficar em branco',
        }),
        DeviceBrand: Joi.object({
            DeviceBrand: Joi.number().required().messages({
                'number.base': 'Não pode ficar em branco',
                'number.required': 'Não pode ficar em branco',
            }),
        })
            .unknown()
            .messages({
                'object.base': 'Não pode ficar em branco',
            }),
        TipoCarroceria: Joi.object({
            codigo: Joi.number().required().messages({
                'number.base': 'Não pode ficar em branco',
                'number.required': 'Não pode ficar em branco',
            }),
        })
            .unknown()
            .messages({
                'object.base': 'Não pode ficar em branco',
            }),
        TireSet: Joi.object({
            TireSet: Joi.number().required().messages({
                'number.base': 'Não pode ficar em branco',
                'number.required': 'Não pode ficar em branco',
            }),
        })
            .unknown()
            .messages({
                'object.base': 'Não pode ficar em branco',
            }),
        Capacity: Joi.string().required().messages({
            'string.base': 'não pode ficar em branco',
            'string.empty': 'não pode ficar em branco',
            'any.required': 'não pode ficar em branco',
        }),
        DeviceModel: Joi.object({
            DeviceModel: Joi.number().required().messages({
                'number.base': 'Não pode ficar em branco',
                'number.required': 'Não pode ficar em branco',
            }),
        })
            .unknown()
            .messages({
                'object.base': 'Não pode ficar em branco',
            }),
        DeviceType: Joi.object({
            DeviceType: Joi.number().required().messages({
                'number.base': 'Não pode ficar em branco',
                'number.required': 'Não pode ficar em branco',
            }),
        })
            .unknown()
            .messages({
                'object.base': 'Não pode ficar em branco',
            }),
        Document4: Joi.string().max(30).required().messages({
            'string.base': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
            'string.max': 'deve ter no máximo 30 caracteres',
            'any.required': 'Não pode ficar em branco',
        }),
        Year: Joi.string().max(4).min(4).required().messages({
            'string.base': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
            'string.max': 'deve ter no máximo 4 caracteres',
            'string.min': 'deve ter no mínimo 4 caracteres',
            'any.required': 'Não pode ficar em branco',
        }),
        DueDocument2: Joi.date().required().messages({
            'date.base': 'Data inválida',
            'date.required': 'Não pode ficar em branco',
        }),

        Document5: Joi.string()
            .pattern(/^.{14}$|^.{18}$/)
            .allow('', null)
            .optional()
            .messages({
                'string.pattern.base': 'Documento inválido',
            }),
        Postcode5: Joi.string().allow('', null).optional(),
        NameDocument2: Joi.string().allow('', null).optional(),
        Address5: Joi.string().allow('', null).optional(),
        Number5: Joi.string().allow('', null).optional(),
        Complement5: Joi.string().allow('', null).optional(),
        County5: Joi.string().allow('', null).optional(),
        cidadeIbge5: Joi.object().allow('', null).optional(),
    });

    // Função para validar CPF
    function validateCPF(cpf: string): boolean {
        cpf = cpf.replace(/[^\d]+/g, '');

        if (cpf === '' || cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
            return false;
        }

        return true;
    }

    // Função para validar CNPJ
    function validateCNPJ(cnpj: string): boolean {
        cnpj = cnpj.replace(/[^\d]+/g, '');

        if (cnpj === '' || cnpj.length !== 14 || /^(\d)\1{13}$/.test(cnpj)) {
            return false;
        }

        return true;
    }

    const validacaoComCnpj = Joi.object({
        Plate: Joi.string().trim().required().min(7).max(7).messages({
            'string.base': 'O nome deve ser uma string',
            'string.empty': 'O nome não pode ficar em branco',
            'any.required': 'O nome não pode ficar em branco',
            'string.min': 'Placa inválida',
            'string.max': 'Placa inválida',
        }),
        Name: Joi.string().trim().required().min(3).max(60).messages({
            'string.base': 'O nome deve ser uma string',
            'string.empty': 'O nome não pode ficar em branco',
            'any.required': 'O nome não pode ficar em branco',
            'string.min': 'Deve ter no mínimo 3 caracteres',
            'string.max': 'Permitido até 60 caracteres',
        }),

        Document: Joi.string()
            .required()
            .custom((value: string, helpers: Joi.CustomHelpers<any>) => {
                if (!validateCNPJ(value) && !validateCPF(value)) {
                    return helpers.error('any.invalid');
                }
                return value;
            }, 'custom validation')
            .messages({
                'string.base': 'Não pode ficar em branco',
                'string.empty': 'Não pode ficar em branco',
                'any.required': 'Não pode ficar em branco',
                'any.invalid': 'Documento inválido',
            }),
        Postcode: Joi.string().min(8).max(8).required().messages({
            'string.max': 'Deve ter 8 caracteres',
            'string.min': 'Deve ter 8 caracteres',
            'any.required': 'Não pode ficar em branco',
            'string.required': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
        }),
        Address: Joi.string().max(60).required().messages({
            'string.max': 'Deve ter no máximo 60 caracteres',
            'any.required': 'Não pode ficar em branco',
            'string.required': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
        }),
        Number: Joi.string().max(7).required().messages({
            'string.max': 'Deve ter no máximo 7 caracteres',
            'any.required': 'Não pode ficar em branco',
            'string.required': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
        }),
        County: Joi.string().max(100).required().messages({
            'string.max': 'Deve ter no máximo 100 caracteres',
            'any.required': 'Não pode ficar em branco',
            'string.required': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
        }),
        cidadeIbge: Joi.object({
            id: Joi.number().required().messages({
                'number.base': 'Não pode ficar em branco',
                'number.required': 'Não pode ficar em branco',
            }),
        })
            .unknown()
            .messages({
                'object.base': 'Não pode ficar em branco',
            }),
        cidadeIbgePlate: Joi.object({
            id: Joi.number().required().messages({
                'number.base': 'Não pode ficar em branco',
                'number.required': 'Não pode ficar em branco',
            }),
        })
            .unknown()
            .messages({
                'object.base': 'Não pode ficar em branco',
            }),
        Document3: Joi.string().min(11).max(11).required().messages({
            'string.base': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
            'string.max': 'deve ter no máximo 11 caracteres',
            'string.min': 'deve ter no mínimo 11 caracteres',
            'any.required': 'Não pode ficar em branco',
        }),
        Document2: Joi.string().min(9).max(12).required().messages({
            'string.base': 'não pode ficar em branco',
            'string.empty': 'não pode ficar em branco',
            'string.max': 'deve ter no máximo 12 caracteres',
            'string.min': 'deve ter no mínimo 9 caracteres',
            'any.required': 'não pode ficar em branco',
        }),
        TipoCarroceria: Joi.object({
            codigo: Joi.number().required().messages({
                'number.base': 'Não pode ficar em branco',
                'number.required': 'Não pode ficar em branco',
            }),
        })
            .unknown()
            .messages({
                'object.base': 'Não pode ficar em branco',
            }),
        TireSet: Joi.object({
            TireSet: Joi.number().required().messages({
                'number.base': 'Não pode ficar em branco',
                'number.required': 'Não pode ficar em branco',
            }),
        })
            .unknown()
            .messages({
                'object.base': 'Não pode ficar em branco',
            }),
        Capacity: Joi.string().required().messages({
            'string.base': 'não pode ficar em branco',
            'string.empty': 'não pode ficar em branco',
            'any.required': 'não pode ficar em branco',
        }),
        DeviceBrand: Joi.object({
            DeviceBrand: Joi.number().required().messages({
                'number.base': 'Não pode ficar em branco',
                'number.required': 'Não pode ficar em branco',
            }),
        })
            .unknown()
            .messages({
                'object.base': 'Não pode ficar em branco',
            }),
        DeviceModel: Joi.object({
            DeviceModel: Joi.number().required().messages({
                'number.base': 'Não pode ficar em branco',
                'number.required': 'Não pode ficar em branco',
            }),
        })
            .unknown()
            .messages({
                'object.base': 'Não pode ficar em branco',
            }),
        DeviceType: Joi.object({
            DeviceType: Joi.number().required().messages({
                'number.base': 'Não pode ficar em branco',
                'number.required': 'Não pode ficar em branco',
            }),
        })
            .unknown()
            .messages({
                'object.base': 'Não pode ficar em branco',
            }),
        Document4: Joi.string().max(30).required().messages({
            'string.base': 'não pode ficar em branco',
            'string.empty': 'não pode ficar em branco',
            'string.max': 'deve ter no máximo 30 caracteres',
            'any.required': 'não pode ficar em branco',
        }),
        Year: Joi.string().max(4).min(4).required().messages({
            'string.base': 'não pode ficar em branco',
            'string.empty': 'não pode ficar em branco',
            'string.max': 'deve ter no máximo 4 caracteres',
            'string.min': 'deve ter no mínimo 4 caracteres',
            'any.required': 'não pode ficar em branco',
        }),
        DueDocument2: Joi.date().required().messages({
            'date.base': 'Data inválida',
            'date.required': 'Não pode ficar em branco',
        }),

        Document5: Joi.string()
            .pattern(/^.{14}$|^.{18}$/)
            .allow('', null)
            .optional()
            .messages({
                'string.pattern.base': 'Documento inválido',
            }),
        Postcode5: Joi.string().allow('', null).optional(),
        NameDocument2: Joi.string().allow('', null).optional(),
        Address5: Joi.string().allow('', null).optional(),
        Number5: Joi.string().allow('', null).optional(),
        Complement5: Joi.string().allow('', null).optional(),
        County5: Joi.string().allow('', null).optional(),
        cidadeIbge5: Joi.object().allow('', null).optional(),
    });

    return { validacao, validacaoComCnpj };
}
