import Joi from 'joi';

export const validacaoClassificacao = Joi.object({
    amostra_id: Joi.number().allow('', null).optional(),
    produto_id: Joi.number().allow('', null).optional(),
    idTest: Joi.string().allow('', null).optional(),
    descricao: Joi.string().required().messages({
        'string.base': 'O campo não pode ficar em branco',
        'string.empty': 'O campo não pode ficar em branco',
        'any.required': 'O campo não pode ficar em branco',
    }),
    resultadoMaximo: Joi.number().optional().allow(null),
    resultado: Joi.number()
        .min(0)
        .precision(2)
        .required()
        .when('resultadoMaximo', {
            is: Joi.exist(),
            then: Joi.when('resultadoMaximo', {
                is: Joi.number().greater(0),
                then: Joi.number().max(Joi.ref('resultadoMaximo')).messages({
                    'number.max': `O resultado não pode ser maior que o resultado máximo de {resultadoMaximo}`,
                }),
                otherwise: Joi.number().max(100).messages({
                    'number.max': `O resultado não pode ser maior que 100`,
                }),
            }),
        })
        .messages({
            'any.empty': 'O campo resultado não pode ficar em branco',
            'any.required': 'O campo resultado não pode ficar em branco',
            'any.base': 'O campo resultado deve conter um número.',
            'any.invalid': 'O campo resultado deve conter um número.',
            'number.base': 'O campo resultado deve conter um número.',
            'number.unsafe': 'O campo resultado deve conter um número.',
            'number.precision':
                'O campo resultado deve conter apenas 2 casas de precisão',
            'number.min': 'O valor mínimo do campo resultado é 0,00',
            'number.positive': 'O valor do campo resultado deve ser positivo',
        }),
});
