import { CSSProperties } from 'react';
import { MoonLoader } from 'react-spinners';

const override: CSSProperties = {
    position: 'absolute',
    left: '50%',
    top: '50%',
};

interface Props {
    isLoading: boolean;
    children: React.ReactNode;
}

export default function SpinnerLoading({ children, isLoading = false }: Props) {
    const color = '#465f75';

    return (
        <>
            <MoonLoader
                color={color}
                loading={isLoading}
                cssOverride={override}
                size={50}
            />
            <div
                className="sweet-loading"
                style={{
                    width: '100%',
                    height: '100%',
                    alignItems: 'center',
                    justifySelf: 'center',
                    contentVisibility: 'unset',
                    opacity: isLoading ? 0.5 : 1,
                    pointerEvents: isLoading ? 'none' : 'auto',
                }}
            >
                {children}
            </div>
        </>
    );
}
