export default class Util {
    static onlyNumbers(valor: string) {
        return valor.replace(/[^0-9]/g, '');
    }

    static generateRGBColor(): string {
        return (
            'rgb(' +
            (Math.random() * (255 - 0) + 0) +
            ', ' +
            (Math.random() * (255 - 0) + 0) +
            ', ' +
            (Math.random() * (255 - 0) + 0) +
            ')'
        );
    }

    static generateHSLColor(): string {
        return (
            'hsl(' +
            (Math.random() * (255 - 0) + 0) +
            ', ' +
            (Math.random() * (100 - 0) + 0) +
            '%' +
            ', ' +
            (Math.random() * (100 - 0) + 0) +
            '%' +
            ')'
        );
    }

    static temNumeroECaracteresEspeciais(str: string) {
        return (
            /\d/.test(str) &&
            /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(str)
        );
    }

    static temLetraMaiuscula(str: string) {
        return /[A-Z]/.test(str);
    }

    static converterStringParaData(dataString: string) {
        const partes = dataString.split('/');
        const ano = parseInt(partes[2], 10);
        const mes = parseInt(partes[1], 10) - 1;
        const dia = parseInt(partes[0], 10);
        const data = new Date(ano, mes, dia);

        return data;
    }

    static formatNumberWithKG = (number: number) => {
        if (number === null || number === undefined) return '';
        return new Intl.NumberFormat('pt-BR').format(number);
    };
}

export const unmaskCpfCnpj = (cpfCnpj: string) => {
    return cpfCnpj.replace(/[^\d]+/g, '');
};

export const inputMoneyMask = (value: string | number): string => {
    if (typeof value === 'string') {
        return value
            .replace(/\D/g, '')
            .replace(/(\d)(\d{2})$/, '$1,$2')
            .replace(/(?=(\d{3})+(\D))\B/g, '.')
            .replace(/(.)/, 'R$ $1');
    }

    return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    }).format(value);
};

export const removeMoedaSpecialChars = (value: string | number): number => {

    if (typeof value === 'number') {
        return value;
    }

    const result = Number(
        value
            .replace(/[A-Z]\s/gi, '') // Remove letras e espaços
            .replace(/r\$/gi, '') // Remove 'R$'
            .replace(/\./g, '') // Remove pontos
            .replace(/,/g, '.') // Substitui vírgulas por pontos
    );
    return result;
};
