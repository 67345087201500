
export default class Server {
    constructor(
        public _id: string,
        public port: number,
        public product: number,
        public order: number,
        public name: string,
        public host: string,
        public description: string,
        public active: boolean,
    ) {}
}
