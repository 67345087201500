import { Container, Divider } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageTitle from '../../Components/PageTitle/PageTitle';
import SpinnerLoading from '../../Components/SpinnerLoading/SpinnerLoading';
import { MensagemErroRequisicaoApi } from '../../Config/Api';
import useGenericService from '../../hooks/useGenericService';
import { ApiResources, FrontEndPathResources } from '../../Models/Api';
import LoginResponse from '../../Models/responses/LoginResponse';
import Usuario, {
    ChavesArmazenamentoStorage,
    StatusRequisicao,
} from '../../Models/Usuario';

import Formulario, { CamposFormularioTratados } from './Formulario';

export default function Criar() {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const { getService } = useGenericService();

    useEffect(() => {
        let loginResponse = localStorage.getItem(
            ChavesArmazenamentoStorage.LOGIN_RESPONSE
        );

        if (loginResponse) {
            const usuario: Usuario = (
                JSON.parse(loginResponse) as LoginResponse
            ).usuario;

            if (usuario.trocarSenha) {
                window.location.pathname = FrontEndPathResources.AlterarSenha;
            }
        }
    }, []);

    const onSubmit = async (dadosFormulario: CamposFormularioTratados) => {
        setIsLoading(true);
        const servico = getService(ApiResources.Contratante);

        try {
            const resultado = await servico.api.post('', dadosFormulario);

            if (resultado.status === StatusRequisicao.CREATED) {
                enqueueSnackbar('Contratante cadastrado com sucesso!', {
                    variant: 'success',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
                navigate(FrontEndPathResources.Contratante);
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);

            (error as MensagemErroRequisicaoApi[]).forEach((erro) => {
                enqueueSnackbar(erro.atributo + ': ' + erro.mensagem, {
                    variant: 'error',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
            });
        }
    };

    return (
        <SpinnerLoading isLoading={isLoading}>
            <Container>
                <PageTitle title={'Cadastrar Contratante'} />
                <Divider />
                <Formulario onSubmit={onSubmit} />
            </Container>
        </SpinnerLoading>
    );
}
