import { joiResolver } from '@hookform/resolvers/joi';
import {
    Button,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    TextField,
} from '@mui/material';
import JoiCpfCnpj from 'cpf-cnpj-validator';
import { default as JoiOriginal } from 'joi';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import DividerComp from '../../Components/Divider/DividerComp';
import InputCelular from '../../Components/Input/InputCelular';
import InputCnpj from '../../Components/Input/InputCnpj';
import InputTelefone from '../../Components/Input/InputTelefone';
import ViaCepService from '../../Helpers/ViaCepService';
import { FrontEndPathResources } from '../../Models/Api';
import Contato from '../../Models/Contato';
import Endereco from '../../Models/Endereco';
import Transportadora, { TipoInscricao } from '../../Models/Transportadora';
import { StatusRequisicao } from '../../Models/Usuario';
import { styles } from './helpers/Styles';

export type CamposFormulario = {
    nome: string;
    nomeFantasia: string;
    cnpj: string;
    cep: string | null;
    cidade: string | null;
    estado: string | null;
    logradouro: string | null;
    bairro: string | null;
    numero: string | null;
    complemento: string | null;
    ibge: string | null;
    email: string;
    telefone: string;
    celular: string;
    inscricaoEstadual?: string;
    inscricaoMunicipal?: string;
};

export interface CamposFormularioTratados
    extends Omit<
        CamposFormulario,
        | 'cep'
        | 'cidade'
        | 'estado'
        | 'logradouro'
        | 'bairro'
        | 'numero'
        | 'complemento'
        | 'ibge'
        | 'email'
        | 'telefone'
        | 'celular'
        | 'inscricaoEstadual'
        | 'inscricaoMunicipal'
    > {
    endereco: Endereco | null;
    contato: Contato;
}

interface Props {
    disabled?: boolean;
    transportadora?: Transportadora;
    onSubmit?: (dadosFormulario: CamposFormularioTratados) => {};
}

const serviceViaCep = new ViaCepService();

export default function Formulario({
    disabled = false,
    transportadora,
    onSubmit,
}: Props) {
    const navigate = useNavigate();
    let refInputNumero = useRef<HTMLInputElement | null>(null);

    const [isIsento, setIsIsento] = useState<boolean>(false);

    const Joi = JoiOriginal.extend(JoiCpfCnpj);

    const validacao = Joi.object({
        nome: Joi.string().min(3).max(120).trim().required().messages({
            'string.base': 'O nome deve ser uma string',
            'string.empty': 'O nome não pode ficar em branco',
            'any.required': 'O nome não pode ficar em branco',
            'string.min': 'Deve ter no mínimo 3 caracteres',
            'string.max': 'Permitido até 120 caracteres',
        }),
        nomeFantasia: Joi.string().min(3).max(120).trim().required().messages({
            'string.base': 'O nome fantasia deve ser uma string',
            'string.empty': 'O nome fantasia não pode ficar em branco',
            'any.required': 'O nome fantasia não pode ficar em branco',
            'string.min': 'Deve ter no mínimo 3 caracteres',
            'string.max': 'Permitido até 120 caracteres',
        }),
        cnpj: Joi.document().cnpj().messages({
            'string.empty': 'O CNPJ não pode ficar em branco',
            'any.required': 'O CNPJ não pode ficar em branco',
        }),
        inscricaoMunicipal: Joi.string()
            .max(20)
            .allow('', null)
            .optional()
            .messages({
                'string.max': 'Deve ter no máximo 20 caracteres',
            }),
        inscricaoEstadual: Joi.string()
            .max(20)
            .allow('', null)
            .optional()
            .messages({
                'string.max': 'Deve ter no máximo 20 caracteres',
            }),
        cep: Joi.string().max(10).allow('', null).optional().messages({
            'string.max': 'Deve ter no máximo 10 caracteres',
        }),
        logradouro: Joi.string().max(100).allow('', null).optional().messages({
            'string.max': 'Deve ter no máximo 100 caracteres',
        }),
        bairro: Joi.string().max(100).allow('', null).optional().messages({
            'string.max': 'Deve ter no máximo 100 caracteres',
        }),
        cidade: Joi.string().max(150).allow('', null).optional().messages({
            'string.max': 'Deve ter no máximo 150 caracteres',
        }),
        estado: Joi.string().max(2).allow('', null).optional().messages({
            'string.max': 'Deve ter no máximo 2 caracteres',
        }),
        complemento: Joi.string().max(100).allow('', null).optional().messages({
            'string.max': 'Deve ter no máximo 100 caracteres',
        }),
        numero: Joi.string().max(6).allow('', null).optional().messages({
            'string.max': 'Deve ter no máximo 6 caracteres',
        }),
        ibge: Joi.string().max(15).allow('', null).optional().messages({
            'string.max': 'Deve ter no máximo 15 caracteres',
        }),
        email: Joi.string()
            .max(150)
            .email({ tlds: { allow: false } })
            .allow('', null)
            .messages({
                'string.email': 'Deve ser um e-mail válido',
                'string.max': 'Deve ter no máximo 150 caracteres',
            }),
        telefone: Joi.string().allow('', null).optional(),
        celular: Joi.string().allow('', null).optional(),
    });

    const {
        control,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm<CamposFormulario>({
        resolver: joiResolver(validacao),
        criteriaMode: 'all',
    });

    useEffect(() => {
        const preencherFormulario = (transportadora: Transportadora) => {
            if (
                transportadora.endereco.inscricaoEstadual?.toLocaleLowerCase() ===
                    'isento' ||
                transportadora.endereco.inscricaoMunicipal?.toLocaleLowerCase() ===
                    'isento' ||
                transportadora.endereco.inscricaoMunicipal === null ||
                transportadora.endereco.inscricaoEstadual === null
            ) {
                setIsIsento(true);
            }

            reset({
                nome: transportadora.nome,
                cnpj: transportadora.cnpj,
                nomeFantasia: transportadora
                    ? transportadora.nomeFantasia
                    : undefined,
                celular: transportadora.contato
                    ? transportadora.contato.celular
                    : undefined,
                email: transportadora.contato
                    ? transportadora.contato.email
                    : undefined,
                telefone: transportadora.contato
                    ? transportadora.contato.telefone
                    : undefined,
                cep: transportadora.endereco
                    ? transportadora.endereco.cep
                    : undefined,
                estado: transportadora.endereco
                    ? transportadora.endereco.estado
                    : undefined,
                cidade: transportadora.endereco
                    ? transportadora.endereco.cidade
                    : undefined,
                bairro: transportadora.endereco
                    ? transportadora.endereco.bairro
                    : undefined,
                logradouro: transportadora.endereco
                    ? transportadora.endereco.logradouro
                    : undefined,
                complemento: transportadora.endereco
                    ? transportadora.endereco.complemento
                    : undefined,
                numero: transportadora.endereco
                    ? transportadora.endereco.numero
                    : undefined,
                ibge: transportadora.endereco
                    ? transportadora.endereco.ibge
                    : undefined,
                inscricaoEstadual: transportadora.endereco
                    ? transportadora.endereco.inscricaoEstadual
                    : undefined,
                inscricaoMunicipal: transportadora.endereco
                    ? transportadora.endereco.inscricaoMunicipal
                    : undefined,
            });
        };

        if (transportadora) {
            preencherFormulario(transportadora);
        }
    }, [reset, transportadora, disabled]);

    const tratarDados = (dadosFormulario: CamposFormulario) => {
        if (onSubmit) {
            const {
                cep,
                cidade,
                estado,
                logradouro,
                bairro,
                numero,
                complemento,
                ibge,
                inscricaoEstadual,
                inscricaoMunicipal,
                email,
                telefone,
                celular,
                ...resto
            } = dadosFormulario;

            let enderecoFormat: Endereco | null = getEndereco(
                cep,
                cidade,
                estado,
                logradouro,
                bairro,
                numero,
                complemento,
                ibge,
                inscricaoEstadual,
                inscricaoMunicipal
            );

            const dadosFormTratados: CamposFormularioTratados = {
                ...resto,
                endereco: enderecoFormat,
                contato: {
                    email,
                    telefone,
                    celular,
                },
            };
            onSubmit(dadosFormTratados);
        }
    };

    const getEndereco = (
        cep: string | null,
        cidade: string | null,
        estado: string | null,
        logradouro: string | null,
        bairro: string | null,
        numero: string | null,
        complemento: string | null,
        ibge: string | null,
        inscricaoEstadual?: string,
        inscricaoMunicipal?: string,
        id?: number
    ) => {
        if (
            bairro === '' &&
            cep === '' &&
            logradouro === '' &&
            complemento === '' &&
            numero === '' &&
            cidade === '' &&
            estado === '' &&
            inscricaoEstadual === '' &&
            inscricaoMunicipal === ''
        ) {
            return null;
        } else {
            const enderecoRetornar: Endereco = {
                cidade: cidade,
                estado: estado,
                bairro: bairro,
                cep: cep,
                logradouro: logradouro,
                complemento: complemento,
                numero: numero,
                ibge: ibge,
                id: id,
                inscricaoEstadual: inscricaoEstadual,
                inscricaoMunicipal: inscricaoMunicipal,
            };
            return enderecoRetornar;
        }
    };

    const buscarCep = async (cep: string) => {
        try {
            const resposta = await serviceViaCep.buscarEndereco(cep);

            if (resposta.status === StatusRequisicao.OK) {
                setValue('cidade', resposta?.data?.localidade);
                setValue('estado', resposta?.data?.uf);
                setValue('logradouro', resposta?.data?.logradouro);
                setValue('bairro', resposta?.data?.bairro);
                setValue('ibge', resposta?.data?.ibge);

                if (
                    refInputNumero !== null &&
                    refInputNumero.current !== null
                ) {
                    refInputNumero.current.focus();
                }
            }
        } catch (error) {}
    };

    return (
        <Container>
            <form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(tratarDados)}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="nome"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        inputProps={{ maxLength: 120 }}
                                        label="Nome"
                                        autoFocus
                                        error={!!errors.nome}
                                        helperText={
                                            errors.nome
                                                ? errors.nome.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="cnpj"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <InputCnpj
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        required
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        label="CNPJ"
                                        error={!!errors.cnpj}
                                        helperText={
                                            errors.cnpj
                                                ? errors.cnpj.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="nomeFantasia"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        required
                                        disabled={disabled}
                                        fullWidth
                                        inputProps={{ maxLength: 120 }}
                                        label="Nome fantasia"
                                        error={!!errors.nomeFantasia}
                                        helperText={
                                            errors.nomeFantasia
                                                ? errors.nomeFantasia.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>

                <DividerComp texto="Inscrições" />

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <FormControl>
                            <FormGroup>
                                <FormControlLabel
                                    id="smart-check-box"
                                    value={TipoInscricao.Isento}
                                    control={
                                        <Checkbox
                                            value={TipoInscricao.Isento}
                                            inputProps={{
                                                'aria-label':
                                                    TipoInscricao.Isento,
                                            }}
                                            checked={isIsento}
                                            onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>
                                            ) => {
                                                setIsIsento(
                                                    event.target.checked
                                                );
                                                if (isIsento === true) {
                                                    setValue(
                                                        'inscricaoMunicipal',
                                                        ''
                                                    );
                                                    setValue(
                                                        'inscricaoEstadual',
                                                        ''
                                                    );
                                                } else {
                                                    setValue(
                                                        'inscricaoMunicipal',
                                                        'isento'
                                                    );
                                                    setValue(
                                                        'inscricaoEstadual',
                                                        'isento'
                                                    );
                                                }
                                            }}
                                        />
                                    }
                                    label="Isento"
                                />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="inscricaoMunicipal"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        disabled={isIsento || disabled}
                                        fullWidth
                                        label="Inscrição municipal"
                                        inputProps={{ maxLength: 20 }}
                                        error={!!errors.inscricaoMunicipal}
                                        helperText={
                                            errors.inscricaoMunicipal
                                                ? errors.inscricaoMunicipal
                                                      .message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="inscricaoEstadual"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        disabled={isIsento || disabled}
                                        fullWidth
                                        inputProps={{ maxLength: 20 }}
                                        label="Inscrição estadual"
                                        error={!!errors.inscricaoEstadual}
                                        helperText={
                                            errors.inscricaoEstadual
                                                ? errors.inscricaoEstadual
                                                      .message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>

                <DividerComp texto="Endereço" />

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={3}>
                        <Controller
                            name="cep"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        label="CEP"
                                        inputProps={{ maxLength: 10 }}
                                        onChange={(
                                            event: React.ChangeEvent<
                                                | HTMLTextAreaElement
                                                | HTMLInputElement
                                            >
                                        ) => {
                                            event.currentTarget.value =
                                                event.currentTarget.value =
                                                    event.currentTarget.value.replace(
                                                        /[^0-9]/g,
                                                        ''
                                                    );

                                            field.onChange(event);
                                        }}
                                        onBlur={(event) => {
                                            buscarCep(event.target.value);
                                        }}
                                        error={!!errors.cep}
                                        helperText={
                                            errors.cep ? errors.cep.message : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="cidade"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        label="Cidade"
                                        inputProps={{ maxLength: 150 }}
                                        error={!!errors.cidade}
                                        helperText={
                                            errors.cidade
                                                ? errors.cidade.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <Controller
                            name="estado"
                            control={control}
                            defaultValue=""
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        inputProps={{ maxLength: 2 }}
                                        disabled={disabled}
                                        fullWidth
                                        label="Estado"
                                        error={!!errors.estado}
                                        helperText={
                                            errors.estado
                                                ? errors.estado.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="logradouro"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        inputProps={{ maxLength: 100 }}
                                        label="Logradouro"
                                        error={!!errors.logradouro}
                                        helperText={
                                            errors.logradouro
                                                ? errors.logradouro.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Controller
                            name="bairro"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        inputProps={{ maxLength: 100 }}
                                        label="Bairro"
                                        error={!!errors.bairro}
                                        helperText={
                                            errors.bairro
                                                ? errors.bairro.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <Controller
                            name="numero"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        margin="normal"
                                        inputRef={refInputNumero}
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        inputProps={{ maxLength: 6 }}
                                        label="Número"
                                        error={!!errors.numero}
                                        helperText={
                                            errors.numero
                                                ? errors.numero.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={10}>
                        <Controller
                            name="complemento"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        inputProps={{ maxLength: 100 }}
                                        label="Complemento"
                                        error={!!errors.complemento}
                                        helperText={
                                            errors.complemento
                                                ? errors.complemento.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>

                <DividerComp texto="Contato" />

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        inputProps={{ maxLength: 150 }}
                                        fullWidth
                                        label="Email"
                                        error={!!errors.email}
                                        helperText={
                                            errors.email
                                                ? errors.email.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="telefone"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <InputTelefone
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        label="Telefone"
                                        error={!!errors.telefone}
                                        helperText={
                                            errors.telefone
                                                ? errors.telefone.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="celular"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <InputCelular
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        label="Celular"
                                        error={!!errors.celular}
                                        helperText={
                                            errors.celular
                                                ? errors.celular.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2} justifyContent="end">
                    {!disabled ? (
                        <Button
                            type="submit"
                            variant="contained"
                            style={styles().button}
                            size="large"
                        >
                            Salvar
                        </Button>
                    ) : (
                        <Button
                            onClick={() => {
                                navigate(
                                    FrontEndPathResources.Transportadora +
                                        '/alterar/' +
                                        transportadora?.id
                                );
                            }}
                            variant="contained"
                            style={styles().button}
                            size="large"
                        >
                            Alterar
                        </Button>
                    )}
                </Grid>
            </form>
        </Container>
    );
}
