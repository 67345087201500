import { UseAutocompleteProps } from '@mui/base';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import { StandardTextFieldProps } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { ApiResources } from '../../Models/Api';
import ClienteFornecedor from '../../Models/ClienteFornecedor';
import { StatusRequisicao } from '../../Models/Usuario';
import useGenericService from '../../hooks/useGenericService';

type InputProps = Pick<
    StandardTextFieldProps,
    | 'margin'
    | 'error'
    | 'helperText'
    | 'fullWidth'
    | 'required'
    | 'inputRef'
    | 'InputLabelProps'
    | 'label'
    | 'placeholder'
    | 'disabled'
    | 'name'
    | 'FormHelperTextProps'
>;

export interface Props
    extends UseAutocompleteProps<any, boolean, boolean, undefined>,
        InputProps {
    label: string;
    disabled?: boolean;
    required?: boolean;
}

export default function SelectProduto({
    margin,
    error,
    helperText,
    fullWidth,
    required,
    InputLabelProps,
    inputRef,
    label,
    placeholder,
    name,
    disabled = false,
    ...outrasPropriedades
}: Props) {
    const [produtos, setProdutos] = React.useState<ClienteFornecedor[]>([]);

    const { getService } = useGenericService();

    React.useEffect(() => {
        const carregarOpcoes = async () => {
            const servico = getService(ApiResources.Produto);

            try {
                const resposta = await servico.api.get('');

                if (resposta.status === StatusRequisicao.OK) {
                    setProdutos(resposta?.data?.content);
                }
            } catch (error) {
                console.log('erro', error);
            }
        };

        carregarOpcoes();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Autocomplete
            {...outrasPropriedades}
            fullWidth
            options={produtos}
            value={(outrasPropriedades as any).field.value}
            multiple={outrasPropriedades.multiple}
            disabled={disabled}
            filterSelectedOptions
            getOptionLabel={(option) => option.nome}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={name}
                    inputRef={inputRef}
                    label={label}
                    required={required}
                    margin={margin}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <CategoryOutlinedIcon
                                style={{ color: '#757575' }}
                            />
                        ),
                    }}
                    disabled={disabled}
                    error={error}
                    helperText={helperText}
                    fullWidth={fullWidth}
                    placeholder={placeholder}
                    InputLabelProps={InputLabelProps}
                />
            )}
        />
    );
}
