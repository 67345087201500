import { StyleSheet } from '../StyleSheet';

export const styles = (props?: any) => {
    return StyleSheet.create({
        boxAuth: { display: 'flex' },
        appBar: { backgroundColor: '#465f75' },
        toolbar: { justifyContent: props ? 'right' : 'space-between' },
        drawer: {
            width: props,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                width: props,
                boxSizing: 'border-box',
            },
        },
        divHeader: {
            margin: '0 auto',
            marginTop: 8,
            display: 'flex',
            flexDirection: 'row',
            textAlign: 'center',
            alignItems: 'inherit',
            justifyContent: 'strech',
            width: 200,
            height: 50,
            objectFit: 'cover',
        },
        img: { width: '35%', height: '30%' },
    });
};
