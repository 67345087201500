import { IBaseUseCase } from '../../../../types/interfaces/IBaseUseCase';
import { IOrdemColetaRepository } from '../repositories/IOrdemColetaRepository';

export class DeleteOrdemColetaById implements IBaseUseCase<number, void> {
    constructor(private readonly repository: IOrdemColetaRepository) {}

    execute(id: number): Promise<void> {
        return this.repository.deleteOrdemColetaById(id);
    }
}
