import { Divider, List, ListItem, ListItemText } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Contratante from '../../Models/Contratante';
import { styles } from './helpers/Styles';

export default function useAuditoriaCamposContratante() {
    const getItensAlterados = (
        contranteAtual: Contratante,
        contranteAnterior: Contratante
    ) => {
        return (
            <List sx={styles().listStyle}>
                {contranteAtual.nome !== contranteAnterior.nome && (
                    <ListItem>
                        <ListItemText
                            primary="Nome"
                            secondary={
                                <div style={styles().listItemText}>
                                    {contranteAnterior.nome}
                                    <ArrowForwardIcon
                                        fontSize="small"
                                        style={styles().arrowForwardIcon}
                                    />{' '}
                                    {contranteAtual.nome}
                                </div>
                            }
                        />
                    </ListItem>
                )}

                {contranteAtual.nomeFantasia !==
                    contranteAnterior.nomeFantasia && (
                    <ListItem>
                        <ListItemText
                            primary="Nome Fantasia"
                            secondary={
                                <div style={styles().listItemText}>
                                    {contranteAnterior.nomeFantasia}
                                    <ArrowForwardIcon
                                        fontSize="small"
                                        style={styles().arrowForwardIcon}
                                    />{' '}
                                    {contranteAtual.nomeFantasia}
                                </div>
                            }
                        />
                    </ListItem>
                )}

                {contranteAtual.cnpj !== contranteAnterior.cnpj && (
                    <>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                            <ListItemText
                                primary="CNPJ"
                                secondary={
                                    <div style={styles().listItemText}>
                                        {contranteAnterior.cnpj}{' '}
                                        <ArrowForwardIcon
                                            fontSize="small"
                                            style={styles().arrowForwardIcon}
                                        />{' '}
                                        {contranteAtual.cnpj}
                                    </div>
                                }
                            />
                        </ListItem>
                    </>
                )}

                {contranteAnterior.contato?.email !==
                    contranteAtual.contato?.email && (
                    <>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                            <ListItemText
                                primary="Contato - Email"
                                secondary={
                                    <div style={styles().listItemText}>
                                        {contranteAnterior.contato?.email}
                                        <ArrowForwardIcon
                                            fontSize="small"
                                            style={styles().arrowForwardIcon}
                                        />{' '}
                                        {contranteAtual.contato?.email}
                                    </div>
                                }
                            />
                        </ListItem>
                    </>
                )}

                {contranteAnterior.contato?.telefone !==
                    contranteAtual.contato?.telefone && (
                    <>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                            <ListItemText
                                primary="Contato - Telefone"
                                secondary={
                                    <div style={styles().listItemText}>
                                        {contranteAnterior.contato?.telefone}
                                        <ArrowForwardIcon
                                            fontSize="small"
                                            style={styles().arrowForwardIcon}
                                        />{' '}
                                        {contranteAtual.contato?.telefone}
                                    </div>
                                }
                            />
                        </ListItem>
                    </>
                )}

                {contranteAnterior.contato?.celular !==
                    contranteAtual.contato?.celular && (
                    <>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                            <ListItemText
                                primary="Contato - Celular"
                                secondary={
                                    <div style={styles().listItemText}>
                                        {contranteAnterior.contato?.celular}
                                        <ArrowForwardIcon
                                            fontSize="small"
                                            style={styles().arrowForwardIcon}
                                        />{' '}
                                        {contranteAtual.contato?.celular}
                                    </div>
                                }
                            />
                        </ListItem>
                    </>
                )}

                {contranteAnterior.usuario &&
                    contranteAnterior.usuario.login &&
                    contranteAnterior.usuario.login !==
                        contranteAtual.usuario.login && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Usuário - Login"
                                    secondary={
                                        <div style={styles().listItemText}>
                                            {contranteAnterior.usuario.login}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={
                                                    styles().arrowForwardIcon
                                                }
                                            />{' '}
                                            {contranteAtual.usuario.login}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {contranteAnterior.usuario &&
                    contranteAnterior.usuario.senha &&
                    contranteAnterior.usuario.senha !==
                        contranteAtual.usuario.senha && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Usuário - Senha"
                                    secondary={
                                        <div style={styles().listItemText}>
                                            {contranteAnterior.usuario.senha}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={
                                                    styles().arrowForwardIcon
                                                }
                                            />{' '}
                                            {contranteAtual.usuario.senha}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {contranteAnterior.endereco?.cep !==
                    contranteAtual.endereco?.cep && (
                    <>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                            <ListItemText
                                primary="Endereco - CEP"
                                secondary={
                                    <div style={styles().listItemText}>
                                        {contranteAnterior.endereco?.cep}
                                        <ArrowForwardIcon
                                            fontSize="small"
                                            style={styles().arrowForwardIcon}
                                        />{' '}
                                        {contranteAtual.endereco?.cep}
                                    </div>
                                }
                            />
                        </ListItem>
                    </>
                )}

                {contranteAnterior.endereco?.logradouro !==
                    contranteAtual.endereco?.logradouro && (
                    <>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                            <ListItemText
                                primary="Endereco - Logradouro"
                                secondary={
                                    <div style={styles().listItemText}>
                                        {contranteAnterior.endereco?.logradouro}
                                        <ArrowForwardIcon
                                            fontSize="small"
                                            style={styles().arrowForwardIcon}
                                        />{' '}
                                        {contranteAtual.endereco?.logradouro}
                                    </div>
                                }
                            />
                        </ListItem>
                    </>
                )}

                {contranteAnterior.endereco?.bairro !==
                    contranteAtual.endereco?.bairro && (
                    <>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                            <ListItemText
                                primary="Endereco - Bairro"
                                secondary={
                                    <div style={styles().listItemText}>
                                        {contranteAnterior.endereco?.bairro}
                                        <ArrowForwardIcon
                                            fontSize="small"
                                            style={styles().arrowForwardIcon}
                                        />{' '}
                                        {contranteAtual.endereco?.bairro}
                                    </div>
                                }
                            />
                        </ListItem>
                    </>
                )}

                {contranteAnterior.endereco?.numero !==
                    contranteAtual.endereco?.numero && (
                    <>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                            <ListItemText
                                primary="Endereco - Numero"
                                secondary={
                                    <div style={styles().listItemText}>
                                        {contranteAnterior.endereco?.numero}
                                        <ArrowForwardIcon
                                            fontSize="small"
                                            style={styles().arrowForwardIcon}
                                        />{' '}
                                        {contranteAtual.endereco?.numero}
                                    </div>
                                }
                            />
                        </ListItem>
                    </>
                )}

                {contranteAnterior.endereco?.complemento !==
                    contranteAtual.endereco?.complemento && (
                    <>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                            <ListItemText
                                primary="Endereco - Complemento"
                                secondary={
                                    <div style={styles().listItemText}>
                                        {
                                            contranteAnterior.endereco
                                                ?.complemento
                                        }
                                        <ArrowForwardIcon
                                            fontSize="small"
                                            style={styles().arrowForwardIcon}
                                        />{' '}
                                        {contranteAtual.endereco?.complemento}
                                    </div>
                                }
                            />
                        </ListItem>
                    </>
                )}

                {contranteAnterior.endereco?.cidade !==
                    contranteAtual.endereco?.cidade && (
                    <>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                            <ListItemText
                                primary="Endereco - Cidade"
                                secondary={
                                    <div style={styles().listItemText}>
                                        {contranteAnterior.endereco?.cidade}
                                        <ArrowForwardIcon
                                            fontSize="small"
                                            style={styles().arrowForwardIcon}
                                        />{' '}
                                        {contranteAtual.endereco?.cidade}
                                    </div>
                                }
                            />
                        </ListItem>
                    </>
                )}

                {contranteAnterior.endereco?.estado !==
                    contranteAtual.endereco?.estado && (
                    <>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                            <ListItemText
                                primary="Endereco - Estado"
                                secondary={
                                    <div style={styles().listItemText}>
                                        {contranteAnterior.endereco?.estado}
                                        <ArrowForwardIcon
                                            fontSize="small"
                                            style={styles().arrowForwardIcon}
                                        />{' '}
                                        {contranteAtual.endereco?.estado}
                                    </div>
                                }
                            />
                        </ListItem>
                    </>
                )}

                {contranteAnterior.endereco?.ibge !==
                    contranteAtual.endereco?.ibge && (
                    <>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                            <ListItemText
                                primary="Endereco - IBGE"
                                secondary={
                                    <div style={styles().listItemText}>
                                        {contranteAnterior.endereco?.ibge}
                                        <ArrowForwardIcon
                                            fontSize="small"
                                            style={styles().arrowForwardIcon}
                                        />{' '}
                                        {contranteAtual.endereco?.ibge}
                                    </div>
                                }
                            />
                        </ListItem>
                    </>
                )}

                {contranteAnterior.endereco?.inscricaoMunicipal !==
                    contranteAtual.endereco?.inscricaoMunicipal && (
                    <>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                            <ListItemText
                                primary="Inscrição Municipal"
                                secondary={
                                    <div style={styles().listItemText}>
                                        {
                                            contranteAnterior.endereco
                                                ?.inscricaoMunicipal
                                        }
                                        <ArrowForwardIcon
                                            fontSize="small"
                                            style={styles().arrowForwardIcon}
                                        />{' '}
                                        {
                                            contranteAtual.endereco
                                                ?.inscricaoMunicipal
                                        }
                                    </div>
                                }
                            />
                        </ListItem>
                    </>
                )}

                {contranteAnterior.endereco?.inscricaoEstadual !==
                    contranteAtual.endereco?.inscricaoEstadual && (
                    <>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                            <ListItemText
                                primary="Inscrição Estadual"
                                secondary={
                                    <div style={styles().listItemText}>
                                        {
                                            contranteAnterior.endereco
                                                ?.inscricaoEstadual
                                        }
                                        <ArrowForwardIcon
                                            fontSize="small"
                                            style={styles().arrowForwardIcon}
                                        />{' '}
                                        {
                                            contranteAtual.endereco
                                                ?.inscricaoEstadual
                                        }
                                    </div>
                                }
                            />
                        </ListItem>
                    </>
                )}

                {contranteAnterior.configEmail &&
                    contranteAtual.configEmail &&
                    contranteAnterior.configEmail?.email_envio !==
                        contranteAtual.configEmail?.email_envio && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Integração - Email Envio"
                                    secondary={
                                        <div style={styles().listItemText}>
                                            {
                                                contranteAnterior.configEmail
                                                    ?.email_envio
                                            }
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={
                                                    styles().arrowForwardIcon
                                                }
                                            />{' '}
                                            {
                                                contranteAtual.configEmail
                                                    ?.email_envio
                                            }
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {contranteAnterior.configEmail &&
                    contranteAtual.configEmail &&
                    contranteAnterior.configEmail
                        ?.provedor_email_contratante !==
                        contranteAtual.configEmail
                            ?.provedor_email_contratante && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Integração - Provedor Email Contratante"
                                    secondary={
                                        <div style={styles().listItemText}>
                                            {
                                                contranteAnterior.configEmail
                                                    ?.provedor_email_contratante
                                            }
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={
                                                    styles().arrowForwardIcon
                                                }
                                            />{' '}
                                            {
                                                contranteAtual.configEmail
                                                    ?.provedor_email_contratante
                                            }
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}
            </List>
        );
    };

    return {
        getItensAlterados,
    };
}
