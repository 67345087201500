import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import JoiCpfCnpj from 'cpf-cnpj-validator';
import SpinnerLoading from '../../Components/SpinnerLoading/SpinnerLoading';
import GerenciadorTenancyService from '../../Config/services/GerenciadorTenancyService';
import { StatusRequisicao } from '../../Models/Usuario';
import { joiResolver } from '@hookform/resolvers/joi';
import { default as JoiOriginal } from 'joi';
import InputCnpj from '../../Components/Input/InputCnpj';
import InputCpf from '../../Components/Input/InputCpf';
import { Controller, useForm } from 'react-hook-form';
import { MensagemErroRequisicaoApi } from '../../Config/Api';
import { styles } from './helpers/Styles';

interface Props {
    url: string;
    fecharClicado: () => void;
    aberto: boolean;
}

type CamposFormulario = {
    login: string;
    cpf: string;
    cnpj: string;
};

enum CpfOuCnpj {
    Cpf = 'CPF',
    Cnpj = 'CNPJ',
}

export default function ModalEsqueciMinhaSenha({
    fecharClicado,
    url,
    aberto = false,
}: Props) {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [tipoPessoa, setTipoPessoa] = useState<CpfOuCnpj>(CpfOuCnpj.Cpf);

    const Joi = JoiOriginal.extend(JoiCpfCnpj);

    const cpfOrCnpjRegexExp =
    /(\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2})|(\d{3}\.\d{3}\.\d{3}-\d{2})/;

    const validacaoComCnpj = Joi.object({
        login: Joi.string().trim().required().messages({
            'string.base': 'O usuário deve ser uma string',
            'string.empty': 'O usuário não pode ficar em branco',
            'any.required': 'O usuário não pode ficar em branco',
        }),
        cpf: Joi.string().allow('', null).optional(),
        cnpj: Joi.string().required().pattern(cpfOrCnpjRegexExp).messages({
            'string.pattern.base': 'Deve ser um CNPJ válido',
            'string.empty': 'Não pode ficar em branco',
            'any.required': 'Não pode ficar em branco',
        }),
    });

    const validacaoComCpf = Joi.object({
        login: Joi.string().trim().required().messages({
            'string.base': 'O usuário deve ser uma string',
            'string.empty': 'O usuário não pode ficar em branco',
            'any.required': 'O usuário não pode ficar em branco',
        }),
        cpf: Joi.string().required().pattern(cpfOrCnpjRegexExp).messages({
            'string.pattern.base': 'Deve ser um CPF válido',
            'string.empty': 'Não pode ficar em branco',
            'any.required': 'Não pode ficar em branco',
        }),
        cnpj: Joi.string().allow('', null).optional(),
    });

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const {
        control,
        handleSubmit,
        register,
        formState: { errors },
    } = useForm<CamposFormulario>({
        resolver: joiResolver(
            tipoPessoa === CpfOuCnpj.Cpf ? validacaoComCpf : validacaoComCnpj
        ),
        criteriaMode: 'all',
    });

    const tratarDados = (dadosFormulario: CamposFormulario) => {
        setIsLoading(true);
        enviarEmailRecuperacaoSenha(
            dadosFormulario.login,
            dadosFormulario.cpf,
            dadosFormulario.cnpj
        );
    };

    const enviarEmailRecuperacaoSenha = async (
        login: string,
        cpf: string | undefined,
        cnpj: string | undefined
    ) => {
        const servico = new GerenciadorTenancyService(url);

        try {
            const resposta = await servico.api.post('/v1/esqueci-minha-senha', {
                login,
                cpf:
                    tipoPessoa === CpfOuCnpj.Cpf && cpf
                        ? cpf.replace(/[^0-9]/g, '')
                        : null,
                cnpj:
                    tipoPessoa === CpfOuCnpj.Cnpj && cnpj
                        ? cnpj.replace(/[^0-9]/g, '')
                        : null,
            });

            if (resposta.status === StatusRequisicao.OK) {
                enqueueSnackbar('Uma nova senha foi enviado ao seu e-mail.', {
                    variant: 'success',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
                setIsLoading(false);
                fecharClicado();
            } else {
                enqueueSnackbar('Erro ao enviar e-mail.', {
                    variant: 'error',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
                setIsLoading(false);
            }
        } catch (error: any) {
            (error as MensagemErroRequisicaoApi[]).forEach((e) =>
                enqueueSnackbar(e.atributo + ':' + e.mensagem, {
                    variant: 'error',
                    onClick: () => {
                        closeSnackbar();
                    },
                })
            );
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog
            open={aberto}
            onClose={() => fecharClicado()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Informe o usuário para redefinir a senha.
            </DialogTitle>
            <DialogContent>
                <Container>
                    <SpinnerLoading isLoading={isLoading}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={12}>
                                <FormControl style={styles.formControl}>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        style={{ flexDirection: 'row' }}
                                        onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                            setTipoPessoa(
                                                (
                                                    event.target as HTMLInputElement
                                                ).value as CpfOuCnpj
                                            );
                                        }}
                                        value={tipoPessoa}
                                    >
                                        <FormControlLabel
                                            value={CpfOuCnpj.Cpf}
                                            control={<Radio />}
                                            label="CPF"
                                        />

                                        <FormControlLabel
                                            value={CpfOuCnpj.Cnpj}
                                            control={<Radio />}
                                            label="CNPJ"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            {tipoPessoa === CpfOuCnpj.Cpf ? (
                                <Grid item xs={12} lg={12}>
                                    <Controller
                                        name="cpf"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <InputCpf
                                                    {...field}
                                                    inputRef={ref}
                                                    margin="normal"
                                                    autoComplete="off"
                                                    fullWidth
                                                    label="CPF"
                                                    error={!!errors.cpf}
                                                    helperText={
                                                        errors.cpf
                                                            ? errors.cpf.message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                            ) : (
                                <Grid item xs={12} lg={12}>
                                    <Controller
                                        name="cnpj"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <InputCnpj
                                                    {...field}
                                                    inputRef={ref}
                                                    margin="normal"
                                                    autoComplete="off"
                                                    fullWidth
                                                    label="CNPJ"
                                                    error={!!errors.cnpj}
                                                    helperText={
                                                        errors.cnpj
                                                            ? errors.cnpj
                                                                .message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        <Controller
                            name="login"
                            control={control}
                            defaultValue={''}
                            render={(props) => {
                                return (
                                    <TextField
                                        {...props}
                                        {...register('login')}
                                        margin="normal"
                                        required
                                        fullWidth
                                        label="Usuário"
                                        autoFocus
                                        error={!!errors.login}
                                        helperText={
                                            errors.login
                                                ? errors.login.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </SpinnerLoading>
                </Container>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => fecharClicado()}>Fechar</Button>
                <Button onClick={() => handleSubmit(tratarDados)()}>
                    Enviar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
