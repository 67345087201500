import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import React from 'react';

interface ModalConfirmarEnvioMaxysProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

const ModalConfirmarEnvioMaxys: React.FC<ModalConfirmarEnvioMaxysProps> = ({
    open,
    onClose,
    onConfirm,
}) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Confirmar Envio</DialogTitle>
            <DialogContent>
                <p>
                    Atenção! Após salvar não será possível alterar. Deseja
                    salvar e enviar para faturamento?
                </p>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={onConfirm} color="primary" autoFocus>
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalConfirmarEnvioMaxys;
