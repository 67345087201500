import { StandardTextFieldProps, TextField } from '@mui/material';
import InputMask from 'react-input-mask';

type Props = StandardTextFieldProps;

export default function InputCelular({ label = 'Celular', ...props }: Props) {
    const { onBlur, value, onChange, disabled, inputRef, ...remainingProps } =
        props;

    return (
        <InputMask
            mask="(99) 99999-9999"
            maskPlaceholder={null}
            onChange={onChange}
            onBlur={onBlur}
            value={value as string}
            disabled={disabled}
        >
            <TextField
                label={label}
                inputProps={{ autoComplete: 'off' }}
                {...remainingProps}
                inputRef={inputRef}
                fullWidth
            />
        </InputMask>
    );
}
