export enum EUnidadesMedida {
  CX = 'CX',
  GRAMAS = 'GRAMAS',
  KG = 'KG',
  LITRO = 'LITRO',
  M = 'M',
  M2 = 'M2',
  M3 = 'M3',
  MILHEI = 'MILHEI',
  ML = 'ML',
  TON = 'TON',
  UNID = 'UNID'
}
