import AutorenewIcon from '@mui/icons-material/Autorenew';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Tooltip,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import GenericBasicTable, {
    ComplexColumn,
} from '../../Components/Table/GenericBasicTable';
import { ApiResources } from '../../Models/Api';
import Classificador from '../../Models/Classificador';
import { StatusRequisicao } from '../../Models/Usuario';
import useGenericService from '../../hooks/useGenericService';
import { styles } from './helpers/Styles';

interface Props {
    aberto: boolean;
    fecharClicado: () => void;
    restaurarClicado: () => void;
}

export default function ModalClassificadorExcluido({
    aberto,
    fecharClicado,
    restaurarClicado,
}: Props) {
    const [classificadores, setClassificadores] = useState<Classificador[]>();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { getService } = useGenericService();

    useEffect(() => {
        const buscarClassificadoresDesativados = async () => {
            const servico = getService(ApiResources.Classificador);

            try {
                const resposta = await servico.api.get('/desativadas');
                if (resposta.status === StatusRequisicao.OK) {
                    setClassificadores(resposta?.data?.content);
                }
            } catch (error) {}
        };

        buscarClassificadoresDesativados();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aberto]);

    const restaurarClassificador = async (classificadorId: number) => {
        const servico = getService(ApiResources.Classificador);

        try {
            const resposta = await servico.api.put(
                '/restaurar/' + classificadorId,
                {}
            );

            if (resposta.status === StatusRequisicao.OK) {
                enqueueSnackbar('Classificador restaurado com sucesso', {
                    variant: 'success',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
                restaurarClicado();
            }
        } catch (error) {}
    };

    const colunas: ComplexColumn[] = [
        {
            attribute: 'id',
            label: 'ID',
            id: 'id',
            sortable: true,
        },
        {
            attribute: 'nome',
            label: 'Nome',
            id: 'nome',
            sortable: true,
        },
        {
            attribute: 'cnpj',
            label: 'CNPJ/CPF',
            id: 'cnpj',
            width: 200,
            sortable: false,
            format: (value: any) =>
                value.cnpj
                    ? value.cnpj.replace(
                          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                          '$1.$2.$3/$4.$5'
                      )
                    : value.cpf.replace(
                          /(\d{3})(\d{3})(\d{3})(\d{2})/,
                          '$1.$2.$3-$4'
                      ),
        },
        {
            attribute: 'contato.telefone',
            label: 'Telefone',
            id: 'telefone',
            sortable: false,
            format: (row) =>
                row.contato && row.contato.telefone ? row.contato.telefone : '',
        },
        {
            attribute: 'contato.celular',
            label: 'Celular',
            id: 'celular',
            sortable: false,
            format: (row) =>
                row.contato && row.contato.celular ? row.contato.celular : '',
        },
        {
            attribute: 'nome',
            label: 'Ações',
            id: 'acao',
            width: 50,
            format: (row) => {
                return (
                    <>
                        <Tooltip
                            title="Restaurar"
                            placement="top"
                            disableInteractive
                        >
                            <IconButton
                                aria-label="delete"
                                onClick={() => {
                                    restaurarClassificador(row.id);
                                }}
                            >
                                <AutorenewIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    return (
        <Dialog
            open={aberto}
            onClose={() => fecharClicado()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
        >
            <DialogTitle id="alert-dialog-title">
                Classificadores excluídos
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} style={styles().grid}>
                    <GenericBasicTable
                        columns={colunas}
                        rows={classificadores}
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => fecharClicado()} autoFocus>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
