import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import Embarque from '../../Models/Embarque';
import OrdemCarregamento from '../../Models/OrdemCarregamento';

interface Props {
    embarque: Embarque;
    ordem: OrdemCarregamento;
    fecharClicado: () => void;
    aberto: boolean;
}

export default function ModalClassificacoes({
    embarque,
    ordem,
    fecharClicado,
    aberto = false,
}: Props) {
    const getAmostraNome = (amostraId: number) => {
        const amostra = ordem.produto.amostras.find(
            (element) => element.id === amostraId
        );

        return amostra?.nome;
    };

    return (
        <Dialog
            open={aberto}
            onClose={() => fecharClicado()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Classificações realizadas para esse embarque.
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {embarque.classificacao.classificacaoAmostras.map((ca) => {
                        return (
                            <>
                                <Chip
                                    style={{ margin: 3 }}
                                    label={
                                        (ca.descricao
                                            ? ca.descricao.toUpperCase()
                                            : getAmostraNome(ca.amostra_id)) +
                                        ': ' +
                                        ca.resultado +
                                        '%'
                                    }
                                />
                            </>
                        );
                    })}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => fecharClicado()} autoFocus>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
