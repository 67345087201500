import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Container, Divider, Grid, TextField } from '@mui/material';
import {
    ArcElement,
    Chart as ChartJS,
    Legend,
    RadialLinearScale,
    Tooltip,
} from 'chart.js';
import { addDays, isDate, subDays } from 'date-fns';
import { useEffect, useState } from 'react';
import { PermissionGate } from '../../Components/Navegacao/PermissionGate';
import PageTitle from '../../Components/PageTitle/PageTitle';
import { FrontEndPathResources } from '../../Models/Api';
import Usuario, {
    ChavesArmazenamentoStorage,
    EnumAutorizacao,
    RecursosPerfisEnum,
} from '../../Models/Usuario';
import LoginResponse from '../../Models/responses/LoginResponse';
import RelatorioEmbarquesPorClassificador from './Relatorios/RelatorioEmbarquesPorClassificador';
import RelatorioOrdensPorClifor from './Relatorios/RelatorioOrdensPorClifor';
import RelatorioPesoPorMes from './Relatorios/RelatorioPesoPorMes';

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

export type DataGrafico = {
    labels: string[];
    datasets: {
        label: string;
        data: number[];
        backgroundColor: string[];
        borderColor?: string[];
        borderWidth: number;
    }[];
};

export default function Home() {
    const [usuarioLogado, setUsuarioLogado] = useState<Usuario>();
    const [fazerRequisicao, setFazerRequisicao] = useState<boolean>(false);

    const [dataInicio, setDataInicio] = useState<Date>(subDays(new Date(), 10));
    const [dataFim, setDataFim] = useState<Date>(addDays(new Date(), 10));

    useEffect(() => {
        usuarioLogado &&
        usuarioLogado.autorizacoes[0]?.tipoAcesso === EnumAutorizacao.Suporte
            ? setFazerRequisicao(false)
            : setFazerRequisicao(true);
    }, [usuarioLogado]);

    useEffect(() => {
        let loginResponse = localStorage.getItem(
            ChavesArmazenamentoStorage.LOGIN_RESPONSE
        );

        if (loginResponse) {
            const usuario: Usuario = (
                JSON.parse(loginResponse) as LoginResponse
            ).usuario;

            if (usuario.trocarSenha) {
                window.location.pathname = FrontEndPathResources.AlterarSenha;
            }
        }
    }, []);

    useEffect(() => {
        const loginResponse = localStorage.getItem(
            ChavesArmazenamentoStorage.LOGIN_RESPONSE
        );

        if (loginResponse) {
            setUsuarioLogado(
                (JSON.parse(loginResponse) as LoginResponse).usuario
            );
        }
    }, []);

    return (
        <PermissionGate
            recurso={RecursosPerfisEnum.DASHBOARD_VER}
            redirect={''}
        >
            <Container>
                <PageTitle title="Dashboard" />

                <Divider style={{ marginBottom: 20 }} />

                {usuarioLogado &&
                usuarioLogado.autorizacoes[0]?.tipoAcesso ===
                    EnumAutorizacao.Suporte ? (
                    <div> Dashboard suporte</div>
                ) : (
                    <>
                        <Grid
                            container
                            spacing={2}
                            style={{ marginBottom: 20 }}
                        >
                            <Grid item xs={12} lg={3}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                >
                                    <DatePicker
                                        label="Data início"
                                        inputFormat="dd/MM/yyyy"
                                        value={dataInicio}
                                        onChange={(newValue: any) => {
                                            if (isDate(newValue)) {
                                                setDataInicio(newValue);
                                            }
                                        }}
                                        renderInput={(params: any) => (
                                            <TextField {...params} />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                >
                                    <DatePicker
                                        label="Data fim"
                                        inputFormat="dd/MM/yyyy"
                                        value={dataFim}
                                        onChange={(newValue: any) => {
                                            if (isDate(newValue)) {
                                                setDataFim(newValue);
                                            }
                                        }}
                                        renderInput={(params: any) => (
                                            <TextField {...params} />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={6}>
                                <RelatorioEmbarquesPorClassificador
                                    fazerRequisicao={fazerRequisicao}
                                    dataInicio={dataInicio}
                                    dataFim={dataFim}
                                />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <RelatorioOrdensPorClifor
                                    fazerRequisicao={fazerRequisicao}
                                    dataInicio={dataInicio}
                                    dataFim={dataFim}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={12}>
                                <RelatorioPesoPorMes
                                    fazerRequisicao={fazerRequisicao}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
            </Container>
        </PermissionGate>
    );
}
