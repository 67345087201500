import { PermissionGate } from '../../../Components/Navegacao/PermissionGate';
import { FrontEndPathResources } from '../../../Models/Api';
import { RecursosPerfisEnum } from '../../../Models/Usuario';
import { DeleteOrdemColetaById } from '../../OrdemColeta/domain/usecases/DeleteOrdemColetaById';
import { FindAnexosDocumentosByOrdemColetaId } from '../../OrdemColeta/domain/usecases/FindAnexosDocumentosByOrdemColetaId';
import { GetSaldoLoteEmbarqueTransportadoraById } from '../../OrdemColeta/domain/usecases/GetSaldoLoteEmbarqueTransportadoraById';
import { PostReprocessarOrdemColeta } from '../../OrdemColeta/domain/usecases/PostReprocessarOrdemColeta';
import { PutArquivoOrdemColeta } from '../../OrdemColeta/domain/usecases/PutArquivoOrdemColeta';
import { OrdemColetaRepository } from '../../OrdemColeta/infra/OrdemColetaRepository';
import { FindLotesEmbarqueByDocumentoTransportadora } from '../domain/usecases/FindLotesEmbarqueByDocumentoTransportadora';
import { LotesPorTransportadoraRepository } from '../infra/LotesPorTransportadoraRepository';
import Listar from '../pages/Listar';

export const LotesPorTransportadoraListar: React.FC = () => {
    const lotesPorTransportadoraRepository =
        new LotesPorTransportadoraRepository();

    const ordemColetaRepository = new OrdemColetaRepository();

    const findLotesEmbarqueByDocumentoTransportadora =
        new FindLotesEmbarqueByDocumentoTransportadora(
            lotesPorTransportadoraRepository
        );

    const getSaldoLoteEmbarqueTransportadoraById =
        new GetSaldoLoteEmbarqueTransportadoraById(ordemColetaRepository);

    const deleteOrdemColetaById = new DeleteOrdemColetaById(
        ordemColetaRepository
    );

    const postReprocessarOrdemColeta = new PostReprocessarOrdemColeta(
        ordemColetaRepository
    );

    const putArquivoOrdemColeta = new PutArquivoOrdemColeta(
        ordemColetaRepository
    );

    const findAnexosDocumentosByOrdemColetaId =
        new FindAnexosDocumentosByOrdemColetaId(ordemColetaRepository);

    return (
        <PermissionGate
            recurso={RecursosPerfisEnum.LOTE_EMBARQUE_POR_TRANSPORTADORA}
            redirect={FrontEndPathResources.Dashboard}
        >
            <Listar
                findLotesEmbarqueByDocumentoTransportadora={
                    findLotesEmbarqueByDocumentoTransportadora
                }
                getSaldoLoteEmbarqueTransportadoraById={
                    getSaldoLoteEmbarqueTransportadoraById
                }
                deleteOrdemColetaById={deleteOrdemColetaById}
                putArquivoOrdemColeta={putArquivoOrdemColeta}
                findAnexosDocumentosByOrdemColetaId={
                    findAnexosDocumentosByOrdemColetaId
                }
                postReprocessarOrdemColeta={postReprocessarOrdemColeta}
            />
        </PermissionGate>
    );
};
