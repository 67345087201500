import CheckIcon from '@mui/icons-material/Check';
import DiscFullIcon from '@mui/icons-material/DiscFull';
import EditIcon from '@mui/icons-material/Edit';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {
    Button,
    Chip,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    Tooltip,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useAuth } from '../../AuthProvider';
import { PermissionGate } from '../../Components/Navegacao/PermissionGate';
import PageTitle from '../../Components/PageTitle/PageTitle';
import GenericComplexTable, {
    ComplexColumn,
} from '../../Components/Table/GenericComplexTable';
import { ApiResources, FrontEndPathResources } from '../../Models/Api';
import { RecursosPerfisEnum } from '../../Models/Usuario';
import useGenericService from '../../hooks/useGenericService';
import { IOrdemColetaResponseDto } from '../OrdemColeta/domain/dtos/IOrdemColetaResponseDto';

import ArticleIcon from '@mui/icons-material/Article';
import { MensagemErroRequisicaoApi } from '../../Config/Api';
import Util from '../../Helpers/Util';
import { styles } from '../LotesPorTransportadora/helpers/Styles';
import { IOrdemColeta } from '../OrdemColeta/domain/entities/IOrdemColeta';
import { FindAnexosDocumentosByOrdemColetaId } from '../OrdemColeta/domain/usecases/FindAnexosDocumentosByOrdemColetaId';
import { PutArquivoOrdemColeta } from '../OrdemColeta/domain/usecases/PutArquivoOrdemColeta';
import ModalHistoricoAlteracoes from './ModalHistoricoAlteracoes';
import ModalAnexosDocumentos from './components/ModalAnexosDocumentos';
import { EStatusDoubleCheck } from './entities/EStatusDoubleCheck';

export enum Status {
    LIBERADO = 'Liberado',
    ANDAMENTO = 'Em Andamento',
    AGUARDANDO = 'Aguardando Processamento',
    ENCERRADO = 'Encerrado',
}

interface Props {
    putArquivoOrdemColeta: PutArquivoOrdemColeta;
    findAnexosDocumentosByOrdemColetaId: FindAnexosDocumentosByOrdemColetaId;
}

export default function ListarDoubleCheck({
    putArquivoOrdemColeta,
    findAnexosDocumentosByOrdemColetaId,
}: Props) {
    const { getService } = useGenericService();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [ordemColeta, setOrdemColeta] = useState<IOrdemColeta[]>();
    const [ordemColetaId, setOrdemColetaId] = useState<number>();

    const [
        openDoubleCheckConfirmationModal,
        setOpenDoubleCheckConfirmationModal,
    ] = useState(false);

    const auth = useAuth();

    const [ordemColetaSelecionada, setOrdemColetaSelecionada] =
        useState<IOrdemColetaResponseDto>();

    const [mostrarModalAuditoria, setMostrarModalAuditoria] =
        useState<boolean>(false);

    const desabilitaDoubleCheck = (row: any): boolean => {
        const responsavelDoubleCheck =
            row.ordemColetaLancamento?.responsavelDoubleCheck;
        const usuarioLogado = auth.usuarioLogado?.login;
        const status = row.ordemColetaLancamento?.statusDoubleCheck;

        if (
            status == EStatusDoubleCheck.PENDENTE ||
            status == EStatusDoubleCheck.LIBERADO
        ) {
            return true;
        }

        if (
            responsavelDoubleCheck === usuarioLogado ||
            !responsavelDoubleCheck
        ) {
            return false;
        }

        return true;
    };

    const getStatusDoubleCheck = (statusOrdem: EStatusDoubleCheck) => {
        switch (statusOrdem) {
            case EStatusDoubleCheck.NOVO:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <ImportContactsIcon
                                    style={styles().iconStyle}
                                />{' '}
                                {EStatusDoubleCheck.NOVO}
                            </div>
                        }
                        color="primary"
                        variant="outlined"
                    />
                );
            case EStatusDoubleCheck.RESPONDIDO:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <CheckIcon style={styles().iconStyle} />{' '}
                                {EStatusDoubleCheck.RESPONDIDO}
                            </div>
                        }
                        color="primary"
                        variant="outlined"
                    />
                );
            case EStatusDoubleCheck.PENDENTE:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <DiscFullIcon style={styles().iconStyle} />{' '}
                                {EStatusDoubleCheck.PENDENTE}
                            </div>
                        }
                        color="warning"
                        variant="outlined"
                    />
                );
            case EStatusDoubleCheck.ATENDIMENTO:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <DiscFullIcon style={styles().iconStyle} />{' '}
                                {EStatusDoubleCheck.ATENDIMENTO}
                            </div>
                        }
                        color="secondary"
                        variant="outlined"
                    />
                );
            case EStatusDoubleCheck.ERRO:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <DiscFullIcon style={styles().iconStyle} />{' '}
                                {EStatusDoubleCheck.ERRO}
                            </div>
                        }
                        color="error"
                        variant="outlined"
                    />
                );
            case EStatusDoubleCheck.LIBERADO:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <CheckIcon style={styles().iconStyle} />{' '}
                                {EStatusDoubleCheck.LIBERADO}
                            </div>
                        }
                        color="success"
                        variant="outlined"
                    />
                );
        }
    };

    const colunas: ComplexColumn[] = [
        {
            attribute: 'id',
            label: 'Nº Ordem',
            id: 'id',
            minWidth: 40,
        },
        {
            attribute: 'cavalo.placa',
            label: 'Placa',
            id: 'cavalo.placa',
            minWidth: 40,
            format: (row) => row.cavalo.placa,
        },
        {
            attribute: 'localEmbarque',
            label: 'Embarque',
            id: 'localEmbarque',
            format: (row) =>
                row?.loteEmbarque?.localEmbarque?.descricao
                    ? row?.loteEmbarque?.localEmbarque?.descricao
                    : row?.loteEmbarque?.localEmbarque?.cidade,
            minWidth: 40,
        },
        {
            attribute: 'localDestino',
            label: 'Destino',
            id: 'localDestino',
            format: (row) =>
                row?.loteEmbarque?.localDestino?.descricao
                    ? row?.loteEmbarque?.localDestino?.descricao
                    : row?.loteEmbarque?.localDestino?.cidade,
            minWidth: 40,
        },
        {
            attribute: 'descricaoItem',
            label: 'Produto',
            id: 'descricaoItem',
            minWidth: 40,
        },
        {
            attribute: 'peso',
            label: 'Peso Planejado',
            id: 'peso',
            minWidth: 40,
            format: (row) => Util.formatNumberWithKG(row.peso) + ' KG',
        },
        {
            attribute: 'status',
            label: 'Status',
            id: 'status',
            minWidth: 40,
            format: (row) =>
                getStatusDoubleCheck(
                    row.ordemColetaLancamento?.statusDoubleCheck
                        ? row.ordemColetaLancamento.statusDoubleCheck
                        : 'Novo'
                ),
        },
        {
            attribute: 'tipoLoteEmbarque',
            label: 'Tipo',
            id: 'tipoLoteEmbarque',
            minWidth: 40,
            format: (row) =>
                row.ordemColetaLancamento?.tipoLoteEmbarque
                    ? row.ordemColetaLancamento.tipoLoteEmbarque
                    : '',
        },
        {
            attribute: 'documentos',
            label: 'Anexos e documentos',
            id: 'documentos',
            align: 'center',
            format: (row) => {
                return (
                    <Tooltip
                        title="Abrir anexos e documentos"
                        placement="top"
                        disableInteractive
                    >
                        <IconButton
                            aria-label="abrir"
                            onClick={() => {
                                setOrdemColetaId(row.id);
                            }}
                        >
                            <PictureAsPdfIcon />
                        </IconButton>
                    </Tooltip>
                );
            },
        },
        {
            attribute: 'id',
            label: 'Ações',
            id: 'acao',
            align: 'center',
            width: 200,
            format: (row) => {
                return (
                    <>
                        <PermissionGate
                            recurso={RecursosPerfisEnum.ORDEM_COLETA_VER}
                            redirect={''}
                        >
                            <Tooltip
                                title="Histórico de alterações"
                                placement="top"
                                disableInteractive
                            >
                                <IconButton
                                    aria-label="ver histórico de alterações"
                                    onClick={() => {
                                        setOrdemColetaSelecionada(row);
                                        setMostrarModalAuditoria(true);
                                    }}
                                >
                                    <ArticleIcon />
                                </IconButton>
                            </Tooltip>
                        </PermissionGate>
                        <PermissionGate
                            recurso={RecursosPerfisEnum.DOUBLE_CHECK_ALTERAR}
                            redirect={''}
                        >
                            <Tooltip
                                title="Double check"
                                placement="top"
                                disableInteractive
                            >
                                <IconButton
                                    aria-label="Double check"
                                    onClick={() => {
                                        setOrdemColetaSelecionada(row);
                                        setOpenDoubleCheckConfirmationModal(
                                            true
                                        );
                                    }}
                                    disabled={desabilitaDoubleCheck(row)}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </PermissionGate>
                    </>
                );
            },
        },
    ];

    return (
        <Container maxWidth={false}>
            <PageTitle title="Faturamento" />

            <Divider />

            <GenericComplexTable
                columnToSearch={'id'}
                service={getService(ApiResources.OrdemColetaDoubleCheck)}
                rows={ordemColeta}
                columns={colunas}
                naoMostrarBotaoNovo
                createButtonText="Cadastrar nova"
                linkCreateButtonText={
                    FrontEndPathResources.OrdemColeta + '/criar'
                }
                filtroCustomAttribute={'status'}
                filtroCustomOptions={Object.values(EStatusDoubleCheck)}
            />

            {mostrarModalAuditoria && ordemColetaSelecionada && (
                <ModalHistoricoAlteracoes
                    ordemColeta={ordemColetaSelecionada}
                    aberto={mostrarModalAuditoria}
                    fecharClicado={() => {
                        setMostrarModalAuditoria(false);
                    }}
                />
            )}

            <Dialog
                open={openDoubleCheckConfirmationModal}
                onClose={() => setOpenDoubleCheckConfirmationModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {ordemColetaSelecionada?.ordemColetaLancamento
                        ?.responsavelDoubleCheck == null
                        ? 'Deseja iniciar processo de double check?'
                        : 'Você é o responsável pelo double check'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {ordemColetaSelecionada?.ordemColetaLancamento
                            ?.responsavelDoubleCheck == null
                            ? 'Você será atribuído como usuário responsável.'
                            : 'Deseja continuar?'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={async () => {
                            try {
                                const servico = getService(
                                    ApiResources.OrdemColetaLancamento
                                );

                                await servico.api.post(
                                    `/alterar-responsavel-double-check/${
                                        ordemColetaSelecionada
                                            ?.ordemColetaLancamento?.id
                                    }/${auth.usuarioLogado!.login}`
                                );

                                navigate(
                                    FrontEndPathResources.DoubleCheck +
                                        '/' +
                                        ordemColetaSelecionada?.id
                                );
                            } catch (error) {
                                (error as MensagemErroRequisicaoApi[]).forEach(
                                    (erro) => {
                                        enqueueSnackbar(
                                            erro.atributo +
                                                ': ' +
                                                erro.mensagem,
                                            {
                                                variant: 'error',
                                                onClick: () => {
                                                    closeSnackbar();
                                                },
                                            }
                                        );
                                    }
                                );
                            } finally {
                                setOpenDoubleCheckConfirmationModal(false);
                            }
                        }}
                    >
                        Continuar
                    </Button>
                    <Button
                        onClick={() =>
                            setOpenDoubleCheckConfirmationModal(false)
                        }
                    >
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
            {ordemColetaId && (
                <ModalAnexosDocumentos
                    open={!!ordemColetaId}
                    onClose={() => setOrdemColetaId(undefined)}
                    ordemColetaId={ordemColetaId}
                    putArquivoOrdemColeta={putArquivoOrdemColeta}
                    findAnexosDocumentosByOrdemColetaId={
                        findAnexosDocumentosByOrdemColetaId
                    }
                />
            )}
        </Container>
    );
}
