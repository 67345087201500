import { Page, Text, View, Document, Font, Image } from '@react-pdf/renderer';
import OrdemCarregamento from '../../Models/OrdemCarregamento';
import Embarque from '../../Models/Embarque';
import arial from '../../Assets/ARIAL.ttf';
import arialBold from '../../Assets/ARIAL-BOLD.ttf';
import { format, parseISO } from 'date-fns';
import Endereco from '../../Models/Endereco';
import ptBR from 'date-fns/locale/pt-BR';
import { useEffect, useState } from 'react';
import LoginResponse from '../../Models/responses/LoginResponse';
import { ChavesArmazenamentoStorage } from '../../Models/Usuario';
import { styles } from './helpers/Style';

Font.register({
    family: 'Arial',
    fonts: [
        {
            src: arial,
        },
        {
            src: arialBold,
            fontWeight: 'bold',
        },
    ],
});
interface Props {
    ordemCarregamento: OrdemCarregamento;
    embarque: Embarque;
}

export default function Romaneio({ embarque, ordemCarregamento }: Props) {
    const [loginResponse, setLoginResponse] = useState<LoginResponse | null>();

    useEffect(() => {
        let loginResponse = localStorage.getItem(
            ChavesArmazenamentoStorage.LOGIN_RESPONSE
        );

        if (loginResponse) {
            setLoginResponse(JSON.parse(loginResponse) as LoginResponse);
        }
    }, []);

    function getEnderecoFormatado(endereco: Endereco) {
        let enderecoFormatado = '';

        for (const [key, value] of Object.entries(endereco)) {
            if (value) {
                if (
                    key !== 'id' &&
                    key !== 'inscricaoMunicipal' &&
                    key !== 'inscricaoEstadual' &&
                    key !== 'ibge'
                )
                    enderecoFormatado += value + ', ';
            }
        }
        return enderecoFormatado;
    }

    return (
        <Document title="romaneio">
            <Page size="A4" style={styles.page} wrap orientation="portrait">
                <View style={styles.section}>
                    <View
                        style={[
                            loginResponse && loginResponse.imagemBase64
                                ? {
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                  }
                                : {},
                        ]}
                    >
                        <View>
                            {loginResponse && loginResponse.imagemBase64 && (
                                <Image
                                    src={loginResponse?.imagemBase64}
                                    style={styles.img}
                                />
                            )}
                        </View>
                        <View>
                            <Text style={styles.title}>
                                ROMANEIO DE{' '}
                                {ordemCarregamento.tipoNegociacao.toUpperCase()}{' '}
                                DE{' '}
                                {ordemCarregamento.produto.nome.toUpperCase()}
                            </Text>
                            {/* <View style={styles.contratanteSection}> */}
                            <View style={styles.inLineText}>
                                <Text>
                                    {ordemCarregamento.contratante?.nome}
                                </Text>
                                <Text>
                                    CNPJ:{' '}
                                    {ordemCarregamento.contratante?.cnpj.replace(
                                        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                                        '$1.$2.$3/$4.$5'
                                    )}
                                </Text>
                                <Text>
                                    IE:
                                    {ordemCarregamento.contratante &&
                                        ordemCarregamento.contratante
                                            .endereco &&
                                        ordemCarregamento.contratante.endereco
                                            .inscricaoEstadual}
                                </Text>
                            </View>
                            <View style={styles.inLineText}>
                                <Text>
                                    End:{' '}
                                    {ordemCarregamento.contratante?.endereco
                                        ? getEnderecoFormatado(
                                              ordemCarregamento.contratante
                                                  ?.endereco
                                          )
                                        : ''}
                                </Text>
                            </View>
                            <View
                                style={[
                                    styles.inLineText,
                                    {
                                        justifyContent: 'flex-end',
                                        alignItems: 'flex-end',
                                    },
                                ]}
                            >
                                <Text style={styles.contratanteIdRomaneioText}>
                                    Romaneio: {ordemCarregamento.codigoContrato}
                                    .{embarque.id}
                                </Text>
                                <Text style={styles.contratanteIdRomaneioText}>
                                    Safra: 2022
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.divider}></View>
                    <View style={styles.inLineText}>
                        <Text>
                            Transp:{' '}
                            {ordemCarregamento.transportadora &&
                                ordemCarregamento.transportadora.nome}
                        </Text>
                        <Text>Motorista: {embarque.motorista.nome}</Text>
                        <Text>Placa: {embarque.caminhao.placaVeiculo}</Text>
                    </View>
                    <View style={styles.containerFornecedor}>
                        <View style={styles.divider}></View>
                        <View
                            style={[
                                styles.inColumText,
                                styles.title,
                                {
                                    fontWeight: 'bold',
                                    alignItems: 'center',
                                },
                            ]}
                        >
                            <Text>Fornecedor(es)</Text>
                        </View>
                        <View
                            style={[
                                styles.inLineText,
                                {
                                    justifyContent: 'flex-start',
                                    padding: 0,
                                },
                            ]}
                        >
                            <Text style={styles.cliforTex}>
                                Clifor: {ordemCarregamento.clifor.nome}
                            </Text>
                        </View>
                        {ordemCarregamento.clifor.enderecos.map((e) => {
                            return (
                                <View
                                    style={[
                                        styles.inLineText,
                                        {
                                            justifyContent: 'flex-start',
                                            padding: 0,
                                        },
                                    ]}
                                >
                                    <Text style={styles.cliforTex}>
                                        {' '}
                                        End: {getEnderecoFormatado(e)}
                                    </Text>
                                </View>
                            );
                        })}
                        <View
                            style={[
                                styles.inLineText,
                                {
                                    justifyContent: 'flex-start',
                                    padding: 0,
                                },
                            ]}
                        >
                            <Text style={styles.cliforTex}>Nota Fiscal: </Text>
                            <Text style={styles.cliforTex}>
                                Peso:
                                {Math.abs(
                                    embarque.pesagem.pesoEntrada -
                                        embarque.pesagem.pesoSaida
                                )}{' '}
                                kg
                            </Text>
                        </View>
                        <View
                            style={[
                                styles.inLineText,
                                {
                                    justifyContent: 'flex-start',
                                    padding: 0,
                                },
                            ]}
                        >
                            <Text style={styles.cliforTex}>
                                Contrato: {ordemCarregamento.codigoContrato}
                            </Text>
                        </View>
                    </View>
                    <View style={styles.divider}></View>
                    <View style={styles.inLineText}>
                        <View style={styles.inColumText}>
                            <Text style={styles.commonText}> </Text>
                            <Text style={styles.commonText}>Peso Entrada:</Text>
                            <Text style={styles.commonText}>Peso Saída:</Text>
                        </View>
                        <View style={styles.inColumText}>
                            <Text style={styles.commonText}> </Text>
                            <Text style={styles.commonText}>
                                {embarque.pesagem.pesoEntrada} kg
                            </Text>
                            <Text style={styles.commonText}>
                                {embarque.pesagem.pesoSaida} kg
                            </Text>
                        </View>
                        <View
                            style={[
                                styles.inColumText,
                                { alignItems: 'center' },
                            ]}
                        >
                            <Text style={styles.commonText}>Data</Text>
                            <Text style={styles.commonText}>
                                {format(
                                    parseISO(
                                        embarque.dataHoraCriacao!.toString()
                                    ),
                                    'dd/MM/Y',
                                    { locale: ptBR }
                                ).toString()}
                            </Text>
                            <Text style={styles.commonText}>
                                {format(
                                    parseISO(
                                        embarque.dataHoraCriacao!.toString()
                                    ),
                                    'dd/MM/Y',
                                    { locale: ptBR }
                                ).toString()}
                            </Text>
                        </View>
                        <View
                            style={[
                                styles.inColumText,
                                { alignItems: 'center' },
                            ]}
                        >
                            <Text style={styles.commonText}>Hora</Text>
                            <Text style={styles.commonText}>
                                {format(
                                    parseISO(
                                        embarque.dataHoraCriacao!.toString()
                                    ),
                                    'HH:mm',
                                    { locale: ptBR }
                                ).toString()}
                            </Text>
                            <Text style={styles.commonText}>
                                {format(
                                    parseISO(
                                        embarque.dataHoraCriacao!.toString()
                                    ),
                                    'HH:mm',
                                    { locale: ptBR }
                                ).toString()}
                            </Text>
                        </View>
                        <View style={styles.inColumText}>
                            <Text style={styles.commonText}>Classificador</Text>
                            <Text style={styles.commonText}>
                                {ordemCarregamento.classificador.nome}
                            </Text>
                            <Text style={styles.commonText}>
                                {ordemCarregamento.classificador.nome}
                            </Text>
                        </View>
                    </View>
                    <View style={[styles.divider, { marginTop: '20%' }]}></View>
                    <View
                        style={[
                            styles.inColumText,
                            styles.title,
                            { fontSize: 10, alignItems: 'center' },
                        ]}
                    >
                        <Text>Classificação</Text>
                    </View>
                    <View style={styles.inColumText}>
                        <View
                            style={[styles.inLineText, { marginBottom: '1%' }]}
                        >
                            <Text style={styles.commonText}>Amostras</Text>
                            <Text style={styles.commonText}>Analisado (%)</Text>
                        </View>
                        {embarque.classificacao.classificacaoAmostras.map(
                            function amostaMap(classificacao, i) {
                                return (
                                    <View
                                        style={[
                                            styles.inLineText,
                                            { padding: '0px 10px 0px 8px' },
                                        ]}
                                    >
                                        <Text style={styles.commonText}>
                                            {' '}
                                            {classificacao.descricao}
                                        </Text>
                                        <Text style={styles.commonText}>
                                            {embarque.classificacao
                                                .classificacaoAmostras[i]
                                                ?.resultado
                                                ? String(
                                                      embarque.classificacao
                                                          .classificacaoAmostras[
                                                          i
                                                      ].resultado
                                                  ).replace('.', ',')
                                                : '0'}
                                        </Text>
                                    </View>
                                );
                            }
                        )}
                        <View style={styles.inLineText}>
                            <Text style={styles.commonText}>
                                TESTE DE TRANSGENIA
                            </Text>
                            {embarque.classificacao.transgenia ? (
                                <Text style={styles.commonText}>
                                    {embarque.classificacao.transgenia}
                                </Text>
                            ) : (
                                <Text style={styles.commonText}>
                                    NÃO POSSUI
                                </Text>
                            )}
                        </View>
                    </View>
                    <View style={styles.divider}></View>
                    <View
                        style={[
                            styles.inLineText,
                            {
                                justifyContent: 'space-evenly',
                                marginTop: '10%',
                            },
                        ]}
                    >
                        <View
                            style={[
                                styles.inColumText,
                                { alignItems: 'center' },
                            ]}
                        >
                            <Text
                                style={[styles.divider, { width: '200px' }]}
                            ></Text>
                            <Text style={styles.commonText}>
                                {ordemCarregamento.classificador.nome}
                            </Text>
                            <Text style={styles.commonText}>Classificador</Text>
                        </View>
                        <View
                            style={[
                                styles.inColumText,
                                { alignItems: 'center' },
                            ]}
                        >
                            <Text
                                style={[styles.divider, { width: '200px' }]}
                            ></Text>
                            <Text style={styles.commonText}>
                                {embarque.motorista.nome}
                            </Text>
                            <Text style={styles.commonText}>Motorista</Text>
                        </View>
                    </View>
                    {/* </View> */}
                </View>
            </Page>
        </Document>
    );
}
