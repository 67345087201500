import { PermissionGate } from '../../../Components/Navegacao/PermissionGate';
import { FrontEndPathResources } from '../../../Models/Api';
import { RecursosPerfisEnum } from '../../../Models/Usuario';
import { FindJuncaoByFilter } from '../domain/usecases/FindJuncaoByFilter';
import { GetOrdemColetaById } from '../domain/usecases/GetOrdemColetaById';
import { GetSaldoLoteEmbarqueTransportadoraById } from '../domain/usecases/GetSaldoLoteEmbarqueTransportadoraById';
import { OrdemColetaRepository } from '../infra/OrdemColetaRepository';
import Ver from '../pages/Ver';

export const OrdemColetaVer: React.FC = () => {
    const ordemColetaRepository = new OrdemColetaRepository();

    const getOrdemColetaById = new GetOrdemColetaById(ordemColetaRepository);
    const getSaldoLoteEmbarqueTransportadoraById =
        new GetSaldoLoteEmbarqueTransportadoraById(ordemColetaRepository);

    const findJuncaoByFilter = new FindJuncaoByFilter(ordemColetaRepository);

    return (
        <PermissionGate
            recurso={RecursosPerfisEnum.ORDEM_COLETA_VER}
            redirect={FrontEndPathResources.OrdemColeta}
        >
            <Ver
                getOrdemColetaById={getOrdemColetaById}
                getSaldoLoteEmbarqueTransportadoraById={
                    getSaldoLoteEmbarqueTransportadoraById
                }
                findJuncaoByFilter={findJuncaoByFilter}
            />
        </PermissionGate>
    );
};
