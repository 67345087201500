import { Button, Grid, TextField } from '@mui/material';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { styles } from '../OrdemColetaForm/Styles';
import ModalAnexarLaudo from './components/ModalAnexarLaudo';

type FormProps = {
    disabled?: boolean;
};

export function EnviarLaudoInput({ disabled = false }: FormProps) {
    const { control, watch, formState: { errors } } = useFormContext();

    const [mostrarModalAnexarLaudo, setMostrarModalAnexarLaudo] =
        useState<boolean>(false);

    const laudoName = watch('laudo.nome');


    const errorMessages = {
    '"laudo" must be of type object': 'campo obrigatório',
    };

    type ErrorKeysProps = keyof typeof errorMessages;

    const translateError = (errorKey: ErrorKeysProps) => {
        console.log("errorKey")
        console.log(errorKey)
    return errorMessages[errorKey];
    };

    return (
        <Grid container item spacing={2}>
            <Grid item xs={12} lg={3}>
                <Controller
                    name="laudo"
                    control={control}
                    defaultValue={''}
                    render={({
                        field: { ref, ...field },
                        fieldState: { error },
                    }) => (
                        <TextField
                            {...field}
                            margin="normal"
                            disabled={true}
                            required
                            fullWidth
                            value={laudoName}
                            label="Laudo"
                            error={!!errors?.laudo}
                            helperText={errors?.laudo ? translateError(errors?.laudo?.message) : ''}
                            InputLabelProps={{
                                shrink: !!laudoName,
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} lg={3}>
                <Button
                    onClick={() => {
                        setMostrarModalAnexarLaudo(true);
                    }}
                    disabled={disabled}
                    variant="contained"
                    style={styles().button}
                    size="large"
                >
                    Anexar Laudo
                </Button>
            </Grid>
            {mostrarModalAnexarLaudo && (
                <>
                    <ModalAnexarLaudo
                        open={mostrarModalAnexarLaudo}
                        onClose={() => setMostrarModalAnexarLaudo(false)}
                    />
                </>
            )}
        </Grid>
    );
}
