import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { StatusRequisicao } from '../../Models/Usuario';
import { UseAutocompleteProps } from '@mui/base';
import { IconButton, StandardTextFieldProps } from '@mui/material';
import { ApiResources } from '../../Models/Api';
import ClienteFornecedor from '../../Models/ClienteFornecedor';
import useGenericService from '../../hooks/useGenericService';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import { useEffect, useState } from 'react';

type InputProps = Pick<
    StandardTextFieldProps,
    | 'margin'
    | 'error'
    | 'helperText'
    | 'fullWidth'
    | 'required'
    | 'inputRef'
    | 'InputLabelProps'
    | 'label'
    | 'placeholder'
    | 'disabled'
    | 'name'
    | 'FormHelperTextProps'
>;

export interface Props
    extends UseAutocompleteProps<any, boolean, boolean, undefined>,
        InputProps {
    label: string;
    disabled?: boolean;
    required?: boolean;
    marca?: number;
}

export interface DeviceModel {
    DeviceModel: number;
    DeviceModelDescription: string;
}

export default function SelectModelosMaxys({
    margin,
    error,
    helperText,
    fullWidth,
    required,
    InputLabelProps,
    inputRef,
    label,
    placeholder,
    name,
    marca,
    disabled = false,
    ...outrasPropriedades
}: Props) {
    const [modelos, setModelos] = useState<DeviceModel[]>([]);

    const { getService } = useGenericService();

    const carregarOpcoes = async (marca: number) => {
        const servico = getService(ApiResources.Juncao);

        try {
            const resposta = await servico.api.get(
                '/buscar-modelos-maxys-atac/' + marca
            );

            if (resposta.status === StatusRequisicao.OK) {
                setModelos(resposta.data);
            }
        } catch (error) {
            console.log('erro', error);
        }
    };

    useEffect(() => {
        if (marca) {
            carregarOpcoes(marca);
        }
    }, [marca]);

    return (
        <Autocomplete
            {...outrasPropriedades}
            fullWidth
            noOptionsText={'Sem opções'}
            options={modelos}
            value={(outrasPropriedades as any).field.value}
            multiple={false}
            disabled={disabled}
            filterSelectedOptions
            getOptionLabel={(option) => option.DeviceModelDescription}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={name}
                    inputRef={inputRef}
                    label={label}
                    required={required}
                    autoComplete="new-password"
                    margin={margin}
                    disabled={disabled}
                    error={error}
                    helperText={helperText}
                    fullWidth={fullWidth}
                    placeholder={placeholder}
                    InputLabelProps={InputLabelProps}
                />
            )}
        />
    );
}
