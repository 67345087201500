import useGenericService from '../../../hooks/useGenericService';
import { ApiResources } from '../../../Models/Api';
import { IFindLotesEmbarqueByFilter } from '../domain/dtos/IFindLotesEmbarqueByFilter';
import { ILotesPorTransportadoraRepository } from '../domain/repositories/ILotesPorTransportadoraRepository';
import { LotePorTransportadoraDtoResponse } from '../types/LotePorTransportadoraDtoResponse';

export class LotesPorTransportadoraRepository
    implements ILotesPorTransportadoraRepository
{
    private service = useGenericService().getService(
        ApiResources.LoteEmbarquePorTransportadora
    );

    async findLotesEmbarqueByDocumentoTransportadora(
        param: IFindLotesEmbarqueByFilter
    ): Promise<LotePorTransportadoraDtoResponse[]> {
        let endpoint = `${param.documento}`;

        endpoint += '?';

        if (param.lote.trim().length > 0) {
            endpoint += `lote=${param.lote}&`;
        }
        if (param.status.trim().length > 0) {
            endpoint += `status=${param.status}&`;
        }
        endpoint += 'sort=id,';
        if (param.sort.trim().length > 0) {
            endpoint += `${param.sort}&`;
        } else {
            endpoint += 'desc&';
        }
        if (param.page) {
            endpoint += `page=${param.page}&`;
        }
        if (param.size) {
            endpoint += `size=${param.size}&`;
        }

        const resultado = await this.service.api.get(endpoint);

        param.setTotal(resultado.data.totalElements);

        return resultado.data.content;
    }

    async findLoteTransportadoraById(
        id: number
    ): Promise<LotePorTransportadoraDtoResponse> {
        const resultado = await this.service.api.get(
            `/buscar-lote-transportadora/${id}`
        );

        return resultado.data;
    }
}
