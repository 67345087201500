import { IArquivoEncoded } from '../entities/IArquivoEncoded';

export interface IPutArquivoOrdemColetaLancamento {
    id?: number;
    ordemColetaId: number;
    arquivo: IArquivoEncoded;
    tipo: ETipoArquivoOrdemColetaLancamento;
}

export enum ETipoArquivoOrdemColetaLancamento {
    cte = 'cte',
    mdfe = 'mdfe',
}
