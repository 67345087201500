import { Divider } from '@mui/material';

interface Props {
    texto: string;
}

export default function DividerComp({ texto }: Props) {
    return (
        <Divider textAlign="left" style={{ marginTop: 20, marginBottom: 15 }}>
            {texto}
        </Divider>
    );
}
