import { Button, Grid, IconButton, Tooltip } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { ComplexColumn } from '../../../Components/Table/GenericComplexTable';
import GenericBasicTable from '../../../Components/Table/GenericBasicTable';
import ModalReferencia from './ModalReferencia';
import { styles } from '../../ClienteFornecedor/helpers/Styles';
import { Reference } from '../types/Juncao';
import { useSnackbar } from 'notistack';

interface Props {
    referenciasForm?: Reference[];
    disabled?: boolean;
    referenciasAlteradas: (referencias: Reference[]) => void;
}

export default function TableReferenciasMotorista({
    referenciasForm,
    disabled = false,
    referenciasAlteradas,
}: Props) {
    const [referenciaAlterar, setReferenciaAlterar] = useState<Reference>();

    const [mostrarModalReferencia, setMostrarModalReferencia] =
        useState<boolean>(false);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [referencias, setReferencias] = useState<Reference[]>([]);

    useEffect(() => {
        if (referenciasForm && referenciasForm.length > 0) {
            setReferencias(referenciasForm);
        }
    }, [referenciasForm]);

    const colunas: ComplexColumn[] = [
        {
            attribute: 'Name',
            label: 'Nome',
            id: 'name',
        },
        {
            attribute: 'Phone',
            label: 'Telefone',
            id: 'phone',
        },
        {
            attribute: 'id',
            label: 'Ações',
            id: 'acao',
            width: 200,
            format: (row: any) => {
                return (
                    <>
                        <Tooltip
                            title="Alterar"
                            placement="top"
                            disableInteractive
                        >
                            <IconButton
                                disabled={disabled}
                                aria-label="alterar"
                                onClick={() => {
                                    setReferenciaAlterar(row as Reference);
                                    setMostrarModalReferencia(true);
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title="Desativar"
                            placement="top"
                            disableInteractive
                        >
                            <IconButton
                                disabled={disabled}
                                aria-label="delete"
                                onClick={() => {
                                    removerReferencia(row as Reference);
                                }}
                            >
                                <CancelIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    const adicionarReferencia = (referencia: Reference) => {
        if (referenciaAlterar) {
            let referenciasAtualizadas = referencias.filter(
                (e) =>
                    !(
                        e.Phone === referenciaAlterar.Phone &&
                        e.Name === referenciaAlterar.Name
                    )
            );

            setReferencias([...referenciasAtualizadas, referencia]);

            setReferenciaAlterar(undefined);
        } else {
            let isRepetido = false;

            referencias.forEach((r) => {
                if (
                    r.Phone.replace(/[^\d]+/g, '') ===
                    referencia.Phone.replace(/[^\d]+/g, '')
                ) {
                    isRepetido = true;
                }
            });

            if (!isRepetido) {
                setReferencias([...referencias, referencia]);
            } else {
                enqueueSnackbar('Telefone já foi adicionado.', {
                    variant: 'info',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
            }
        }
    };

    const removerReferencia = (referencia: Reference) => {
        let referenciasAtualizadas = referencias.filter(
            (e) => !(e.Phone === referencia.Phone && e.Name === referencia.Name)
        );

        setReferencias(referenciasAtualizadas);
    };

    useEffect(() => {
        referenciasAlteradas(referencias);
    }, [referencias, referenciasAlteradas]);

    return (
        <>
            {mostrarModalReferencia && (
                <ModalReferencia
                    aberto={mostrarModalReferencia}
                    referencia={referenciaAlterar}
                    fecharClicado={() => {
                        setMostrarModalReferencia(false);
                        setReferenciaAlterar(undefined);
                    }}
                    gravarClicado={(endereco: Reference) => {
                        setMostrarModalReferencia(false);
                        adicionarReferencia(endereco);
                    }}
                />
            )}
            <Button
                variant="outlined"
                startIcon={<AddIcon />}
                disabled={disabled}
                onClick={() => setMostrarModalReferencia(true)}
            >
                Adicionar referência
            </Button>
            <Grid container spacing={2} style={styles().grid}>
                <Grid item xs={12} lg={8}>
                    <GenericBasicTable columns={colunas} rows={referencias} />
                </Grid>
            </Grid>
        </>
    );
}
