import { compareAsc, format, parse } from 'date-fns';
import Joi from 'joi';
import { removeMoedaSpecialChars } from '../../../../../Helpers/Util';

const method = (value: any, helpers: any) => {
    const date = parse(value, 'dd/MM/yyyy', new Date());
    const todayDate = new Date();

    const resultAsc = compareAsc(date, todayDate);

    if (resultAsc === -1) {
        return format(date, 'T');
    } else {
        return helpers.error('date.max');
    }
};

export const validacao = {
    serie: Joi.string()
        .max(6)
        .when('chaveNota', { is: Joi.exist(), then: Joi.required() })
        .messages({
            'string.base': 'O campo não pode ficar em branco',
            'string.empty': 'O campo não pode ficar em branco',
            'any.required': 'O campo não pode ficar em branco',
            'string.max': 'O campo deve conter no máximo 6 caracteres',
        }),
    dataEmissao: Joi.any()
        .when('chaveNota', { is: Joi.exist(), then: Joi.required() })
        .custom(method, 'custom validation')
        .messages({
            'any.required': 'O campo não pode ficar em branco',
            'date.max': 'Não deve ser maior que a data atual',
        }),
    peso: Joi.number().greater(0).messages({
        'number.greater': 'Número precisa ser maior que 0',
    }),
    unidadeMedida: Joi.string().required().messages({
        'string.base': 'O campo não pode ficar em branco',
        'string.empty': 'O campo não pode ficar em branco',
        'any.required': 'O campo não pode ficar em branco',
    }),
    valorTotal: Joi.custom((value, helpers) => {
        const numeroLimpo = removeMoedaSpecialChars(value);
        if (isNaN(numeroLimpo)) {
            return helpers.error('number.base');
        }
        return numeroLimpo;
    }).required().messages({
        'number.base': 'O campo não pode ficar em branco',
        'number.empty': 'O campo não pode ficar em branco',
        'any.required': 'O campo não pode ficar em branco',
    }),
    danfe: Joi.any()
        .when('chaveNota', { is: Joi.exist(), then: Joi.required() })
        .messages({
            'any.required': 'O campo não pode ficar em branco',
        }),
};

export const validacaoNfe = Joi.object({
    ...validacao,
    chaveNota: Joi.string()
        .when('numeroNfe', { is: !Joi.exist(), then: Joi.required() })
        .messages({
            'string.base': 'O campo não pode ficar em branco',
            'string.empty': 'O campo não pode ficar em branco',
            'any.required': 'O campo não pode ficar em branco',
        }),
    numeroNfe: Joi.string().messages({
        'string.base': 'O campo não pode ficar em branco',
        'string.empty': 'O campo não pode ficar em branco',
        'any.required': 'O campo não pode ficar em branco',
    }),
    numeroNf: Joi.string().optional().allow('', null),
    valorTotal: Joi.custom((value, helpers) => {
        const numeroLimpo = removeMoedaSpecialChars(value);
        if (isNaN(numeroLimpo)) {
            return helpers.error('number.base');
        }
        return numeroLimpo;
    }).required().messages({
        'number.base': 'O campo não pode ficar em branco',
        'number.empty': 'O campo não pode ficar em branco',
        'any.required': 'O campo não pode ficar em branco',
    }),
});

export const validacaoNf = Joi.object({
    ...validacao,
    chaveNota: Joi.string().optional().allow('', null),
    numeroNfe: Joi.string().optional().allow('', null),
    numeroNf: Joi.string().messages({
        'string.base': 'O campo não pode ficar em branco',
        'string.empty': 'O campo não pode ficar em branco',
        'any.required': 'O campo não pode ficar em branco',
    }),
    valorTotal: Joi.custom((value, helpers) => {
        const numeroLimpo = removeMoedaSpecialChars(value);
        if (isNaN(numeroLimpo)) {
            return helpers.error('number.base');
        }
        return numeroLimpo;
    }).required().messages({
        'number.base': 'O campo não pode ficar em branco',
        'number.empty': 'O campo não pode ficar em branco',
        'any.required': 'O campo não pode ficar em branco',
    }),
});
