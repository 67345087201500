import { FunctionComponent, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IDoubleCheck } from '../entities/IDoubleCheck';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { EUnidadesMedida } from '../../OrdemColeta/domain/entities/EUnidadesMedida';
import { styles } from '../../PerfilUsuario/helpers/Styles';
import { EStatusDoubleCheck } from '../entities/EStatusDoubleCheck';

interface StatusFormProps {}

const StatusForm: FunctionComponent<StatusFormProps> = () => {
    const { control, setValue, reset, getValues, watch } =
        useFormContext<IDoubleCheck>();

    const watchStatus = watch('status');

    return (
        <>
            <Grid item lg={2} mt={2}>
                <Controller
                    name={'status'}
                    control={control}
                    defaultValue={
                        watchStatus ? watchStatus : EStatusDoubleCheck.ATENDIMENTO
                    }
                    render={({
                        field: { ref, value, onChange, ...field },
                        fieldState: { error },
                    }) => (
                        <FormControl fullWidth>
                            <InputLabel id="Status" shrink>
                                Status
                            </InputLabel>
                            <Select
                                value={value}
                                label="Status"
                                onChange={onChange}
                                placeholder="Status"
                                defaultValue={
                                    watchStatus
                                        ? watchStatus
                                        : EStatusDoubleCheck.ATENDIMENTO
                                }
                                // disabled={true}
                            >
                                {Object.values(EStatusDoubleCheck).map(
                                    (status) => {
                                        if (
                                            status != EStatusDoubleCheck.NOVO &&
                                            status !=
                                                EStatusDoubleCheck.RESPONDIDO &&
                                            status != EStatusDoubleCheck.ERRO &&
                                            status != EStatusDoubleCheck.ENVIADO_MAXYS
                                        )
                                            return (
                                                <MenuItem
                                                    key={status}
                                                    value={status}
                                                >
                                                    {status}
                                                </MenuItem>
                                            );
                                    }
                                )}
                            </Select>
                            {error ? (
                                <span style={styles().mensagemErrorStyle}>
                                    {error}
                                </span>
                            ) : (
                                <></>
                            )}
                        </FormControl>
                    )}
                />
            </Grid>
        </>
    );
};

export default StatusForm;
