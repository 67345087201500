import { IBaseUseCase } from '../../../../types/interfaces/IBaseUseCase';
import { LotePorTransportadoraDtoResponse } from '../../types/LotePorTransportadoraDtoResponse';

import { ILotesPorTransportadoraRepository } from '../repositories/ILotesPorTransportadoraRepository';

export class FindLoteTransportadoraById
    implements IBaseUseCase<number, LotePorTransportadoraDtoResponse>
{
    constructor(
        private readonly repository: ILotesPorTransportadoraRepository
    ) {}

    execute(id: number): Promise<LotePorTransportadoraDtoResponse> {
        return this.repository.findLoteTransportadoraById(id);
    }
}
