import { Card, CardContent, Grid, Typography } from '@mui/material';
import { styles } from './helpers/Styles';

export function EmbarquesTotais(row: any) {
    function getPesoPlanejado(row: any): number {
        return row?.row?.pesoPlanejado ? row?.row?.pesoPlanejado : 0;
    }
    function getVolumeTotal(row: any): number {
        const sumOfPesoAgendado = row?.row?.ordemColetas.reduce(
            (acc: number, item: any) => {
                acc += item?.peso;
                return acc;
            },
            0
        );
        return sumOfPesoAgendado;
    }

    function getSaldo(row: any): number {
        const pesoPlanejado = getPesoPlanejado(row);
        return pesoPlanejado - getVolumeTotal(row);
    }

    return (
        <Card style={styles().card}>
            <CardContent>
                <Typography
                    sx={{ fontSize: 20 }}
                    color="text.secondary"
                    gutterBottom
                >
                    Embarques
                </Typography>
                <Grid container spacing={2} marginBottom={2}>
                    <Grid item xs={12} lg={3}>
                        <Card>
                            <CardContent>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color="text.secondary"
                                    gutterBottom
                                >
                                    Volume total
                                </Typography>
                                <Typography
                                    variant="h5"
                                    component="div"
                                    color="#75DB07"
                                >
                                    {getPesoPlanejado(row)
                                        ?.toString()
                                        .replace('.', ',')
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, '.') +
                                        ' KG'}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Card>
                            <CardContent>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color="text.secondary"
                                    gutterBottom
                                >
                                    Volume agendado
                                </Typography>
                                <Typography
                                    variant="h5"
                                    component="div"
                                    color="#DBCE07"
                                >
                                    {getVolumeTotal(row)
                                        ?.toString()
                                        .replace('.', ',')
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, '.') +
                                        ' KG'}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Card>
                            <CardContent>
                                <Typography
                                    sx={{ fontSize: 14 }}
                                    color="text.secondary"
                                    gutterBottom
                                >
                                    Saldo
                                </Typography>
                                <Typography
                                    variant="h5"
                                    component="div"
                                    color="#DBCE07"
                                >
                                    {getSaldo(row)
                                        ?.toString()
                                        .replace('.', ',')
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, '.') +
                                        ' KG'}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
