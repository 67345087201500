import AttachFileIcon from '@mui/icons-material/AttachFile';
import DownloadIcon from '@mui/icons-material/Download';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';

import { useFetchUseCase } from '../../../../../hooks/useFetchUseCase';
import { IAnexosDocumentosDto } from '../../../../OrdemColeta/domain/dtos/IAnexosDocumentosDto';
import { ETipoArquivoOrdemColetaLancamento } from '../../../../OrdemColeta/domain/dtos/IPutArquivoOrdemColetaLancamento';
import { IArquivoEncoded } from '../../../../OrdemColeta/domain/entities/IArquivoEncoded';
import { FindAnexosDocumentosByOrdemColetaId } from '../../../../OrdemColeta/domain/usecases/FindAnexosDocumentosByOrdemColetaId';
import { PutArquivoOrdemColeta } from '../../../../OrdemColeta/domain/usecases/PutArquivoOrdemColeta';
import ModalAnexarDocumento from './components/ModalAnexarCte';

interface ModalAnexosDocumentosProps {
    open: boolean;
    onClose: () => void;
    ordemColetaId: number;
    putArquivoOrdemColeta: PutArquivoOrdemColeta;
    findAnexosDocumentosByOrdemColetaId: FindAnexosDocumentosByOrdemColetaId;
}

const ModalAnexosDocumentos: React.FC<ModalAnexosDocumentosProps> = ({
    open,
    onClose,
    ordemColetaId,
    putArquivoOrdemColeta,
    findAnexosDocumentosByOrdemColetaId,
}) => {
    const { executePromise } = useFetchUseCase();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [mostrarModalAnexarDocumento, setMostrarModalAnexarDocumento] =
        useState(false);

    const [tipoDocumento, setTipoDocumento] =
        useState<ETipoArquivoOrdemColetaLancamento>(
            ETipoArquivoOrdemColetaLancamento.cte
        );

    const [anexosDocumentos, setAnexosDocumentos] =
        useState<IAnexosDocumentosDto>();

    const atualizarDocumentoOrdemColeta = (arquivo: IArquivoEncoded) => {
        const documentoAtualizado: IAnexosDocumentosDto = {
            ...anexosDocumentos!,
            [tipoDocumento]: arquivo,
        };

        setAnexosDocumentos(documentoAtualizado);
    };

    const enviarDocumentoSelecionado = (arquivo: IArquivoEncoded) => {
        executePromise(
            () =>
                putArquivoOrdemColeta.execute({
                    arquivo: arquivo,
                    ordemColetaId: ordemColetaId,
                    tipo: tipoDocumento,
                }),
            () => {
                atualizarDocumentoOrdemColeta(arquivo);
                setMostrarModalAnexarDocumento(false);
                enqueueSnackbar('Documento anexado com sucesso', {
                    variant: 'success',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
            },
            () => {
                enqueueSnackbar('Erro ao anexar documento', {
                    variant: 'error',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
            }
        );
    };

    const renderRowsIntegracaoMaxys = () => {
        const rows: JSX.Element[] = [];

        // Processa as NFES
        anexosDocumentos?.nfes?.forEach((nfe, index) => {
            const isNfe = !!nfe.numeroNfe;

            rows.push(
                <TableRow key={`nfe-${index}`}>
                    <TableCell align="center">{isNfe ? 'NFE' : 'NF'}</TableCell>
                    <TableCell align="center">{nfe?.danfe?.nome}</TableCell>
                    <TableCell align="center">
                        <Tooltip
                            title="Baixar"
                            placement="top"
                            disableInteractive
                        >
                            <IconButton
                                aria-label="baixar"
                                onClick={() => {
                                    const ancoraParaDownload =
                                        document.createElement('a');
                                    ancoraParaDownload.download =
                                        'nfe_' + nfe?.danfe?.nome;
                                    ancoraParaDownload.href =
                                        nfe.danfe!.stringBase64!;
                                    ancoraParaDownload.click();
                                }}
                            >
                                <DownloadIcon />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                </TableRow>
            );
        });

        // Processa cte e mdfe
        const fieldsToProcess = ['cte', 'mdfe'] as const;
        fieldsToProcess.forEach((field) => {
            const isTransportadoraTerceiroAndCadastroViaPortal =
                anexosDocumentos?.veiculoTerceiro &&
                anexosDocumentos?.cadastradaViaPortal;

            const isFieldValid = !!anexosDocumentos?.[field]?.stringBase64;

            const mostrarRol = isTransportadoraTerceiroAndCadastroViaPortal
                ? true
                : isFieldValid;

            mostrarRol ? (
                rows.push(
                    <TableRow key={field}>
                        <TableCell align="center">
                            {field?.toUpperCase()}
                        </TableCell>
                        <TableCell align="center">
                            {anexosDocumentos?.[field]?.nome}
                        </TableCell>
                        <TableCell align="center">
                            {isTransportadoraTerceiroAndCadastroViaPortal && (
                                <Tooltip
                                    title="Anexar"
                                    placement="top"
                                    disableInteractive
                                >
                                    <IconButton
                                        aria-label="anexar"
                                        onClick={() => {
                                            setMostrarModalAnexarDocumento(
                                                true
                                            );
                                            setTipoDocumento(
                                                ETipoArquivoOrdemColetaLancamento[
                                                    field
                                                ]
                                            );
                                        }}
                                    >
                                        <AttachFileIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {anexosDocumentos?.[field]?.stringBase64 && (
                                <Tooltip
                                    title="Baixar"
                                    placement="top"
                                    disableInteractive
                                >
                                    <IconButton
                                        aria-label="baixar"
                                        onClick={() => {
                                            const ancoraParaDownload =
                                                document.createElement('a');
                                            ancoraParaDownload.download =
                                                field +
                                                '_' +
                                                anexosDocumentos?.[field]?.nome;
                                            ancoraParaDownload.href =
                                                anexosDocumentos?.[
                                                    field
                                                ]!.stringBase64!;
                                            ancoraParaDownload.click();
                                        }}
                                    >
                                        <DownloadIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </TableCell>
                    </TableRow>
                )
            ) : (
                <></>
            );
        });

        // Processa laudo, ticket, danfeDaNotaRetornoMaxys e xmlDaNotaRetornoMaxys
        const otherFields = [
            'laudo',
            'ticket',
            'danfeDaNotaRetornoMaxys',
            'xmlDaNotaRetornoMaxys',
        ] as const;
        otherFields.forEach((field) => {
            anexosDocumentos?.[field] &&
                rows.push(
                    <TableRow key={field}>
                        <TableCell align="center">
                            {field == 'danfeDaNotaRetornoMaxys'
                                ? 'DANFE NFE EMBARQUE'
                                : field == 'xmlDaNotaRetornoMaxys'
                                ? 'XML NFE EMBARQUE'
                                : field?.toUpperCase()}
                        </TableCell>
                        <TableCell align="center">
                            {anexosDocumentos?.[field]?.nome}
                        </TableCell>
                        <TableCell align="center">
                            <Tooltip
                                title="Baixar"
                                placement="top"
                                disableInteractive
                            >
                                <IconButton
                                    aria-label="baixar"
                                    onClick={() => {
                                        const ancoraParaDownload =
                                            document.createElement('a');
                                        ancoraParaDownload.download =
                                            field +
                                            '_' +
                                            anexosDocumentos?.[field]?.nome;
                                        ancoraParaDownload.href =
                                            anexosDocumentos?.[
                                                field
                                            ]!.stringBase64!;
                                        ancoraParaDownload.click();
                                    }}
                                >
                                    <DownloadIcon />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                );
        });

        return rows;
    };

    useEffect(() => {
        executePromise(
            () => findAnexosDocumentosByOrdemColetaId.execute(ordemColetaId),
            (response) => {
                setAnexosDocumentos(response);
            }
        );
    }, [executePromise, findAnexosDocumentosByOrdemColetaId, ordemColetaId]);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Lista de anexos e documentos</DialogTitle>
            <DialogContent>
                <Paper>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        align="center"
                                        style={{
                                            backgroundColor: '#EDF0FE',
                                            fontWeight: 'bold',
                                            width: '200px',
                                        }}
                                    >
                                        Documento
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        style={{
                                            backgroundColor: '#EDF0FE',
                                            fontWeight: 'bold',
                                            width: '200px',
                                        }}
                                    >
                                        Descrição
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        style={{
                                            backgroundColor: '#EDF0FE',
                                            fontWeight: 'bold',
                                            width: '200px',
                                        }}
                                    >
                                        Ações
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{renderRowsIntegracaoMaxys()}</TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Fechar
                </Button>
            </DialogActions>
            <ModalAnexarDocumento
                aberto={mostrarModalAnexarDocumento}
                enviarAnexoClicado={enviarDocumentoSelecionado}
                fecharClicado={() => setMostrarModalAnexarDocumento(false)}
            />
        </Dialog>
    );
};

export default ModalAnexosDocumentos;
