import { StyleSheet } from '../../../StyleSheet';

export const styles = StyleSheet.create({
    cardStyle: { minWidth: 275, marginTop: 2, backgroundColor: '#FBFBFB' },
    cardTitle: { alignItems: 'center', marginBottom: 15 },
    title: { fontSize: '.8rem', fontWeight: 'bold' },
    subtitle: { fontSize: '1rem', fontWeight: 400, color: '#717171' },
    cloudIconButton: { marginLeft: 15 },
    deleteIconButton: { marginLeft: 0 },
    img: { width: '20%', height: '10%' },
    alert: { marginTop: 15 },
    button: {
        marginTop: 25,
        marginBottom: 15,
        backgroundColor: '#465f75',
    },
    box: {
        backgroundColor: 'white',
        p: 2,
    },
    formControlStyle: { marginTop: 25 },
});
