import DeleteIcon from '@mui/icons-material/Delete';
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import { IOrdemColetaLancamento } from '../../../../domain/dtos/IOrdemColetaLancamento';

interface CardNotaFiscalProps {}

const CardNotaFiscal: FunctionComponent<CardNotaFiscalProps> = () => {
    const { watch, setValue } =
        useFormContext<IOrdemColetaLancamento>();

    const watchNota = watch('notasAnteriores');

    const deleteNota = (index: number) => {
        watchNota?.splice(index, 1);

        setValue('notasAnteriores', watchNota);
    };

    const parseNotas = () => {
        return watchNota?.map((nota, index) => (
            <Grid item xs={12} lg={4} key={index}>
                <Card>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <CardContent>
                            {nota.numeroNf && (
                                <Typography variant="subtitle1" component="div">
                                    NF: {nota.numeroNf}
                                </Typography>
                            )}
                            {nota.numeroNfe && (
                                <Typography variant="subtitle1" component="div">
                                    NFe: {nota.numeroNfe}
                                </Typography>
                            )}
                        </CardContent>
                        <CardActions disableSpacing>
                            <IconButton
                                aria-label="delete"
                                onClick={() => deleteNota(index)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </CardActions>
                    </Box>
                </Card>
            </Grid>
        ));
    };

    return (
        <Grid container item spacing={2}>
            {parseNotas()}
        </Grid>
    );
};

export default CardNotaFiscal;
