import DownloadIcon from '@mui/icons-material/Download';
import { Button, Grid, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { styles } from '../../PerfilUsuario/helpers/Styles';
import { IDoubleCheck } from '../entities/IDoubleCheck';
import Util from '../../../Helpers/Util';

interface EmbarqueFormProps {}

const EmbarqueForm: FunctionComponent<EmbarqueFormProps> = () => {
    const { control, setValue, reset, getValues, watch } =
        useFormContext<IDoubleCheck>();

    return (
        <Grid container item spacing={2}>
            <Grid item xs={12} lg={2}>
                <Controller
                    name={`pesagem.tara`}
                    control={control}
                    render={({
                        field: { ref, value, onChange, ...field },
                        fieldState: { error },
                    }) => (
                        <TextField
                            {...field}
                            margin="normal"
                            fullWidth
                            value={Util.formatNumberWithKG(value)}
                            onChange={onChange}
                            label="Tara"
                            error={!!error?.message}
                            helperText={error?.message ? error?.message : ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={true}
                            InputProps={{
                                endAdornment: <InputAdornment disableTypography position="end">KG</InputAdornment>,
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} lg={2}>
                <Controller
                    name={`pesagem.pesoBruto`}
                    control={control}
                    render={({
                        field: { ref, value, onChange, ...field },
                        fieldState: { error },
                    }) => (
                        <TextField
                            {...field}
                            margin="normal"
                            fullWidth
                            value={Util.formatNumberWithKG(value)}
                            onChange={onChange}
                            label="Peso bruto"
                            error={!!error?.message}
                            helperText={error?.message ? error?.message : ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={true}
                            InputProps={{
                                endAdornment: <InputAdornment disableTypography position="end">KG</InputAdornment>,
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} lg={2}>
                <Controller
                    name={`pesagem.pesoLiquido`}
                    control={control}
                    render={({
                        field: { ref, value, onChange, ...field },
                        fieldState: { error },
                    }) => (
                        <TextField
                            {...field}
                            margin="normal"
                            fullWidth
                            value={Util.formatNumberWithKG(value)}
                            onChange={onChange}
                            label="Peso líquido"
                            error={!!error?.message}
                            helperText={error?.message ? error?.message : ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={true}
                            InputProps={{
                                endAdornment: <InputAdornment disableTypography position="end">KG</InputAdornment>,
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid container item spacing={2}>
                <Grid item xs={12} lg={3}>
                    <Controller
                        name={`laudo`}
                        control={control}
                        render={({
                            field: { ref, value, onChange, ...field },
                            fieldState: { error },
                        }) => (
                            <TextField
                                {...field}
                                margin="normal"
                                disabled={true}
                                fullWidth
                                value={value ? value.nome : ''}
                                onChange={onChange}
                                label="Laudo"
                                error={!!error?.message}
                                helperText={
                                    error?.message ? error?.message : ''
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    lg={0.5}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Tooltip title="Baixar" placement="top" disableInteractive>
                        <IconButton
                            aria-label="baixar"
                            onClick={() => {
                                if (getValues('laudo')) {
                                    const ancoraParaDownload =
                                        document.createElement('a');
                                    ancoraParaDownload.download =
                                        'laudo_' + getValues('laudo.nome');
                                    ancoraParaDownload.href =
                                        getValues('laudo.stringBase64');
                                    ancoraParaDownload.click();
                                }
                            }}
                        >
                            <DownloadIcon fontSize="medium" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Button
                        onClick={() => {
                            setValue('laudo', null);
                        }}
                        variant="contained"
                        style={styles().button}
                        size="large"
                    >
                        Excluir Laudo
                    </Button>
                </Grid>
            </Grid>
            <Grid container item spacing={2}>
                <Grid item xs={12} lg={3}>
                    <Controller
                        name={`ticket`}
                        control={control}
                        render={({
                            field: { ref, value, onChange, ...field },
                            fieldState: { error },
                        }) => (
                            <TextField
                                {...field}
                                margin="normal"
                                disabled={true}
                                fullWidth
                                value={value ? value.nome : ''}
                                onChange={onChange}
                                label="Ticket"
                                error={!!error?.message}
                                helperText={
                                    error?.message ? error?.message : ''
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    lg={0.5}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Tooltip title="Baixar" placement="top" disableInteractive>
                        <IconButton
                            aria-label="baixar"
                            onClick={() => {
                                if (getValues('ticket')) {
                                    const ancoraParaDownload =
                                        document.createElement('a');
                                    ancoraParaDownload.download =
                                        'ticket_' + getValues('ticket.nome');
                                    ancoraParaDownload.href = getValues(
                                        'ticket.stringBase64'
                                    );
                                    ancoraParaDownload.click();
                                }
                            }}
                        >
                            <DownloadIcon fontSize="medium" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Button
                        onClick={() => {
                            setValue('ticket', null);
                        }}
                        variant="contained"
                        style={styles().button}
                        size="large"
                    >
                        Excluir Ticket
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default EmbarqueForm;
