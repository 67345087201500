import { joiResolver } from '@hookform/resolvers/joi';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    TextField,
} from '@mui/material';
import Joi from 'joi';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ViaCepService from '../../Helpers/ViaCepService';
import { TipoInscricao } from '../../Models/ClienteFornecedor';
import Endereco from '../../Models/Endereco';
import { StatusRequisicao } from '../../Models/Usuario';
import { styles } from './helpers/Styles';

interface Props {
    aberto: boolean;
    endereco?: Endereco;
    gravarClicado: (endereco: Endereco) => void;
    fecharClicado: () => void;
}

const serviceViaCep = new ViaCepService();

export default function ModalEndereco({
    endereco,
    gravarClicado,
    aberto,
    fecharClicado,
}: Props) {
    let refInputNumero = useRef<HTMLInputElement | null>(null);

    const [isIsento, setIsIsento] = useState<boolean>(false);

    const validacao = Joi.object({
        id: Joi.number().optional(),
        cep: Joi.string().required().messages({
            'string.base': 'Não pode ficar em branco',
            'string.required': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
        }),
        logradouro: Joi.string().required().messages({
            'string.base': 'Não pode ficar em branco',
            'string.required': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
        }),
        bairro: Joi.string().required().messages({
            'string.base': 'Não pode ficar em branco',
            'string.required': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
        }),
        cidade: Joi.string().required().messages({
            'string.base': 'Não pode ficar em branco',
            'string.required': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
        }),
        estado: Joi.string().required().messages({
            'string.base': 'Não pode ficar em branco',
            'string.required': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
        }),
        numero: Joi.string().allow('', null).optional(),
        complemento: Joi.string().allow('', null),
        ibge: Joi.string().allow('', null),
        inscricaoMunicipal: Joi.string().required().messages({
            'string.base': 'Não pode ficar em branco',
            'string.required': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
        }),
        inscricaoEstadual: Joi.string().required().messages({
            'string.base': 'Não pode ficar em branco',
            'string.required': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
        }),
    });

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<Endereco>({
        resolver: joiResolver(validacao),
        criteriaMode: 'all',
    });

    useEffect(() => {
        if (endereco) {
            if (
                endereco.inscricaoEstadual?.toLocaleLowerCase() === 'isento' ||
                endereco.inscricaoMunicipal?.toLocaleLowerCase() === 'isento'
            ) {
                setIsIsento(true);
            }

            setValue('bairro', endereco.bairro);
            setValue('cep', endereco.cep);
            setValue('cidade', endereco.cidade);
            setValue('complemento', endereco.complemento);
            setValue('estado', endereco.estado);
            setValue('id', endereco.id);
            setValue('logradouro', endereco.logradouro);
            setValue('numero', endereco.numero);
            setValue('inscricaoEstadual', endereco.inscricaoEstadual);
            setValue('inscricaoMunicipal', endereco.inscricaoMunicipal);
            setValue('ibge', endereco.ibge);
        }
    }, [endereco, setValue]);

    const buscarCep = async (cep: string) => {
        try {
            const resposta = await serviceViaCep.buscarEndereco(cep);

            if (resposta.status === StatusRequisicao.OK) {
                setValue('ibge', resposta?.data?.ibge);
                setValue('cidade', resposta?.data?.localidade);
                setValue('estado', resposta?.data?.uf);
                setValue('logradouro', resposta?.data?.logradouro);
                setValue('bairro', resposta?.data?.bairro);
            }
        } catch (error) {}
    };

    const tratarDados = (endereco: Endereco) => {
        gravarClicado(endereco);
    };

    return (
        <Dialog
            open={aberto}
            onClose={() => fecharClicado()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
        >
            <DialogTitle id="alert-dialog-title">
                Endereço do cliente/fornecedor.
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={3}>
                        <Controller
                            name="cep"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        fullWidth
                                        onChange={(
                                            event: React.ChangeEvent<
                                                | HTMLTextAreaElement
                                                | HTMLInputElement
                                            >
                                        ) => {
                                            event.currentTarget.value =
                                                event.currentTarget.value =
                                                    event.currentTarget.value.replace(
                                                        /[^0-9]/g,
                                                        ''
                                                    );

                                            field.onChange(event);
                                        }}
                                        label="CEP"
                                        onBlur={(event) => {
                                            buscarCep(event.target.value);
                                        }}
                                        error={!!(errors && errors.cep)}
                                        helperText={
                                            errors && errors.cep
                                                ? (errors.cep as any).message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="cidade"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        fullWidth
                                        label="Cidade"
                                        error={!!(errors && errors.cidade)}
                                        helperText={
                                            errors && errors.cidade
                                                ? (errors.cidade as any).message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Controller
                            name="estado"
                            control={control}
                            defaultValue=""
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        fullWidth
                                        label="Estado"
                                        error={!!(errors && errors.estado)}
                                        helperText={
                                            errors && errors.estado
                                                ? (errors.estado as any).message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="logradouro"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        fullWidth
                                        label="Logradouro"
                                        error={!!(errors && errors.logradouro)}
                                        helperText={
                                            errors && errors.logradouro
                                                ? (errors.logradouro as any)
                                                      .message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Controller
                            name="bairro"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        fullWidth
                                        label="Bairro"
                                        error={!!(errors && errors.bairro)}
                                        helperText={
                                            errors && errors.bairro
                                                ? (errors.bairro as any).message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Controller
                            name="numero"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        margin="normal"
                                        inputRef={refInputNumero}
                                        autoComplete="off"
                                        fullWidth
                                        label="Número"
                                        error={!!(errors && errors.numero)}
                                        helperText={
                                            errors && errors.numero
                                                ? (errors.numero as any).message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="complemento"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        fullWidth
                                        label="Complemento"
                                        error={!!(errors && errors.complemento)}
                                        helperText={
                                            errors && errors.complemento
                                                ? (errors.complemento as any)
                                                      .message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <FormControl style={styles().formControlStyle}>
                            <FormLabel>Inscrições</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    id="smart-check-box"
                                    value={TipoInscricao.Isento}
                                    control={
                                        <Checkbox
                                            value={TipoInscricao.Isento}
                                            inputProps={{
                                                'aria-label':
                                                    TipoInscricao.Isento,
                                            }}
                                            checked={isIsento}
                                            onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>
                                            ) => {
                                                setIsIsento(
                                                    event.target.checked
                                                );
                                                if (isIsento === true) {
                                                    setValue(
                                                        'inscricaoMunicipal',
                                                        ''
                                                    );
                                                    setValue(
                                                        'inscricaoEstadual',
                                                        ''
                                                    );
                                                } else {
                                                    setValue(
                                                        'inscricaoMunicipal',
                                                        'isento'
                                                    );
                                                    setValue(
                                                        'inscricaoEstadual',
                                                        'isento'
                                                    );
                                                }
                                            }}
                                        />
                                    }
                                    label="Isento"
                                />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="inscricaoMunicipal"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        disabled={isIsento}
                                        fullWidth
                                        label="Inscrição municipal"
                                        inputProps={{ maxLength: 15 }}
                                        error={!!errors.inscricaoMunicipal}
                                        helperText={
                                            errors.inscricaoMunicipal
                                                ? errors.inscricaoMunicipal
                                                      .message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="inscricaoEstadual"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        disabled={isIsento}
                                        fullWidth
                                        inputProps={{ maxLength: 15 }}
                                        label="Inscrição estadual"
                                        error={!!errors.inscricaoEstadual}
                                        helperText={
                                            errors.inscricaoEstadual
                                                ? errors.inscricaoEstadual
                                                      .message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => fecharClicado()} autoFocus>
                    Fechar
                </Button>
                <Button onClick={() => handleSubmit(tratarDados)()} autoFocus>
                    Gravar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
