import { Box, Container, Divider, Tab, Tabs, Typography } from '@mui/material';

import PageTitle from '../../Components/PageTitle/PageTitle';
import { useState } from 'react';
import TabMotorista, {
    CamposFormularioMotorista,
    TipoMotorista,
} from './componentes/TabMotorista';
import TabVeiculo, { CamposFormularioVeiculo } from './componentes/TabVeiculo';
import { Reference, VehicleForm } from './types/Juncao';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import useMotoristaValidation from './componentes/hooks/useMotoristaValidation';
import { TipoPessoa } from '../../Models/Pessoa';
import useVeiculoValidation from './componentes/hooks/useVeiculoValidation';

export interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export default function RegistroJuncao() {
    const [tipoPessoaVeiculo, setTipoPessoaVeiculo] = useState<TipoPessoa>(
        TipoPessoa.Juridica
    );

    const [radioTipoMotorista, setRadioTipoMotorista] = useState<TipoMotorista>(
        TipoMotorista.ApenasMotorista
    );

    const [referenciasMotorista, setReferenciasMotorista] = useState<
        Reference[]
    >([]);

    const [carretasAdicionadas, setCarretasAdicionadas] = useState<
        VehicleForm[]
    >([]);

    const [tabVeiculoLiberada, setTabVeiculoLiberada] =
        useState<boolean>(false);

    const [documentMotoristaSelecionado, setDocumentMotoristaSelecionado] =
        useState<string>();
    const [situacaoMotorista, setSituacaoMotorista] = useState<TipoMotorista>();

    const [tabValue, setTabValue] = useState<'1' | '2'>('1');

    const { validacaoMotorista } = useMotoristaValidation();
    const { validacao, validacaoComCnpj } =
        useVeiculoValidation(tipoPessoaVeiculo);

    const onDocumentMotoristaChange = (document: string) => {
        setDocumentMotoristaSelecionado(document);
    };

    const onTipoMotoristaChange = (tipo: TipoMotorista) => {
        setSituacaoMotorista(tipo);
    };

    const handleChange = (event: React.SyntheticEvent, newValue: '1' | '2') => {
        // if (window.confirm('Confirme que salvou o formulário antes de sair.')) {
        setTabValue(newValue);
        // }
    };

    const useFormMethodsMotorista = useForm<CamposFormularioMotorista>({
        resolver: joiResolver(validacaoMotorista),
        criteriaMode: 'all',
        shouldUnregister: false,
    });

    const useFormMethodsVeiculo = useForm<CamposFormularioVeiculo>({
        resolver: joiResolver(validacaoComCnpj),
        criteriaMode: 'all',
        shouldUnregister: false,
    });

    return (
        <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange}>
                    <Tab label="Motorista" value="1" />
                    <Tab
                        label="Veículo"
                        value="2"
                        disabled={!tabVeiculoLiberada}
                    />
                </TabList>
            </Box>
            <TabPanel value="1">
                <TabMotorista
                    onDocumentChange={onDocumentMotoristaChange}
                    onMotoristaTipoChange={onTipoMotoristaChange}
                    useFormMethods={useFormMethodsMotorista}
                    referenciasAnteriores={referenciasMotorista}
                    radioTipoMotorista={radioTipoMotorista}
                    onSaveButton={(ok) => {
                        if (ok) {
                            setTabVeiculoLiberada(true);
                            setTabValue('2');
                        }
                    }}
                    onReferenciasChange={(referencias) => {
                        setReferenciasMotorista(referencias);
                    }}
                    onRadioTipoMotoristaSelecionado={(tipoMotorista) => {
                        setRadioTipoMotorista(tipoMotorista);
                    }}
                />
            </TabPanel>
            <TabPanel value="2">
                <TabVeiculo
                    documentMotorista={documentMotoristaSelecionado}
                    situacaoMotorista={situacaoMotorista}
                    useFormMethods={useFormMethodsVeiculo}
                    carretasAdicionadas={carretasAdicionadas}
                    onCarretasAdicionadasChange={(carretas) => {
                        setCarretasAdicionadas(carretas);
                    }}
                />
            </TabPanel>
        </TabContext>
    );
}
