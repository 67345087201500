import { UseAutocompleteProps } from '@mui/base';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import { StandardTextFieldProps } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import useGenericService from '../../hooks/useGenericService';
import { ApiResources } from '../../Models/Api';
import ClienteFornecedor from '../../Models/ClienteFornecedor';
import Endereco from '../../Models/Endereco';
import { StatusRequisicao } from '../../Models/Usuario';
import { Driver } from '../../Screens/Juncao/types/Juncao';

type InputProps = Pick<
    StandardTextFieldProps,
    | 'margin'
    | 'error'
    | 'helperText'
    | 'fullWidth'
    | 'required'
    | 'inputRef'
    | 'InputLabelProps'
    | 'label'
    | 'placeholder'
    | 'disabled'
    | 'name'
    | 'FormHelperTextProps'
>;

export interface Props
    extends UseAutocompleteProps<any, boolean, boolean, undefined>,
        InputProps {
    label: string;
    disabled?: boolean;
    required?: boolean;
}

interface DriverMaxys {
    Driver: Driver;
    References: [];
}

export default function SelectTransportadoraMaxys({
    margin,
    error,
    helperText,
    fullWidth,
    required,
    InputLabelProps,
    inputRef,
    label,
    placeholder,
    name,
    disabled = false,
    ...outrasPropriedades
}: Props) {
    const [transportadoras, setTransportadoras] = React.useState<
        ClienteFornecedor[]
    >([]);

    const { getService } = useGenericService();

    const handleInputChange = async (
        event: React.ChangeEvent<{}>,
        value: string
    ) => {
        if (value.length > 3) {
            const servico = getService(ApiResources.Juncao);
            try {
                const resposta = await servico.api.get(
                    `buscar-motorista-nome-maxys/${
                        (event.target as HTMLInputElement).value
                    }`
                );

                if (resposta.status === StatusRequisicao.OK) {
                    const ts: ClienteFornecedor[] = [];

                    resposta.data.forEach((driver: DriverMaxys) => {
                        const es: Endereco[] = [];

                        driver.Driver.Adresses.forEach((address) => {
                            const end: Endereco = {
                                idMaxy: address.id,
                                cep: address.Postcode,
                                logradouro: address.Address,
                                bairro: address.County,
                                numero: address.Number,
                                cidade: address.City,
                                estado: null,
                                ibge: null,
                                complemento: null,
                            };

                            es.push(end);
                        });

                        const transp: ClienteFornecedor = {
                            id: parseInt(driver.Driver.Document),
                            nome: driver.Driver.Name,
                            nomeFantasia: driver.Driver.Name,
                            cnpj: driver.Driver.Document,
                            enderecos: es,
                        };

                        ts.push(transp);
                    });

                    setTransportadoras(ts);
                }
            } catch (error) {
                console.log('erro', error);
            }
        }
    };

    return (
        <Autocomplete
            {...outrasPropriedades}
            fullWidth
            options={transportadoras}
            defaultValue={outrasPropriedades.defaultValue}
            value={(outrasPropriedades as any).field.value}
            multiple={outrasPropriedades.multiple}
            disabled={disabled}
            filterSelectedOptions
            getOptionLabel={(option) => option.nome + ' - ' + option.cnpj}
            onInputChange={handleInputChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={name}
                    inputRef={inputRef}
                    label={label}
                    required={required}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <LocalShippingOutlinedIcon
                                style={{ color: '#757575' }}
                            />
                        ),
                    }}
                    margin={margin}
                    disabled={disabled}
                    error={error}
                    helperText={helperText}
                    fullWidth={fullWidth}
                    placeholder={placeholder}
                    InputLabelProps={InputLabelProps}
                    // onKeyDown={handleKeyDown}
                />
            )}
        />
    );
}
