import {
    DatePicker,
    LocalizationProvider,
    TabContext,
    TabList,
    TabPanel,
} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    Tab,
    TextField,
} from '@mui/material';
import ptBR from 'date-fns/locale/pt-BR';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import DividerComp from '../../../Components/Divider/DividerComp';
import InputNumero from '../../../Components/Input/InputNumero';
import InputPlaca from '../../../Components/Input/InputPlaca';
import SelectCidades from '../../../Components/Select/SelectCidades';
import SelectConjuntoPneu, {
    TireSet,
} from '../../../Components/Select/SelectConjuntoPneu';
import SelectMarcasMaxys, {
    DeviceBrand,
} from '../../../Components/Select/SelectMarcasMaxys';
import SelectModelosMaxys, {
    DeviceModel,
} from '../../../Components/Select/SelectModelosMaxys';
import SelectTipoBau, {
    TipoCarroceria,
} from '../../../Components/Select/SelectTipoBau';
import SelectTipoVeiculo, {
    DeviceType,
} from '../../../Components/Select/SelectTipoVeiculo';
import SpinnerLoading from '../../../Components/SpinnerLoading/SpinnerLoading';
import { MensagemErroRequisicaoApi } from '../../../Config/Api';
import ViaCepService from '../../../Helpers/ViaCepService';
import { ApiResources } from '../../../Models/Api';
import CidadeIBGE from '../../../Models/CidadeIBGE';
import { StatusRequisicao } from '../../../Models/Usuario';
import useGenericService from '../../../hooks/useGenericService';
import { DriverWithAddress, Juncao, VehicleForm } from '../types/Juncao';
import { TipoMotorista } from './TabMotorista';
import TabelaCarreta from './TabelaCarreta';
import { stylesTabMotorista } from './helpers/stylesTabMotorista';
import useMarcaTipoModelo from './hooks/useMarcaTipoModelo';

export type CamposFormularioVeiculo = {
    Plate: string;
    Document: string;
    Name: string;
    Postcode: string;
    Address: string;
    Number: string;
    County: string;
    cidadeIbge: CidadeIBGE | null;
    cidadeIbgePlate: CidadeIBGE | null;
    Document3: string;
    DeviceBrand: DeviceBrand | null;
    DeviceModel: DeviceModel | null;
    DeviceType: DeviceType | null;
    Document4: string;
    Year: string;
    Document2: string;
    NameDocument2: string;
    DueDocument2: Date | null;

    Document5: string | null;
    Postcode5: string | null;
    Address5: string | null;
    Number5: string | null;
    Complement5: string | null;
    County5: string | null;
    cidadeIbge5: CidadeIBGE | null;

    TipoCarroceria: TipoCarroceria | null;
    TireSet: TireSet | null;
    Capacity: string | null;
};

export interface CamposFormularioTratados
    extends Omit<
        CamposFormularioVeiculo,
        | 'TipoCarroceria'
        | 'TireSet'
        | 'Capacity'
        | 'cidadeIbge5'
        | 'cidadeIbge'
        | 'cidadeIbgePlate'
        | 'DueDocument2'
        | 'DeviceType'
        | 'DeviceModel'
        | 'DeviceBrand'
        | 'NameDocument2'
    > {
    DeviceBrand: string;
    DeviceModel: string;
    DeviceType: string;
    DueDocument2: string;
    City5: number | null;
    City: number;
    CityPlate: number;
    Capacity: number;
    TireType: number;
    TrunkType: number;
    NameDocument2: string | null;
    TaxType: string;
    TaxType5: string | null;
}

type Props = {
    useFormMethods: UseFormReturn<CamposFormularioVeiculo, object>;
    documentMotorista?: string;
    situacaoMotorista?: TipoMotorista;
    carretasAdicionadas: VehicleForm[];
    onCarretasAdicionadasChange: (carretas: VehicleForm[]) => void;
};

export default function TabVeiculo({
    documentMotorista,
    situacaoMotorista,
    useFormMethods,
    carretasAdicionadas,
    onCarretasAdicionadasChange,
}: Props) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { getService } = useGenericService();

    const [isUsuarioClicouSalvar, setIsUsuarioClicouSalvar] =
        useState<boolean>(false);

    const [regimeTributarioProprietario, setRegimeTributarioProprietario] =
        useState<string>('N');
    const [regimeTributarioLocatario, setRegimeTributarioLocatario] =
        useState<string>('N');

    const [mensagemLocatarioIncompleto, setMensagemLocatarioIncompleto] =
        useState<string>();

    const [
        mostrarModalConfirmarSalvarSemCarretas,
        setMostrarModalConfirmarSalvarSemCarretas,
    ] = useState<boolean>(false);

    const [veiculos, setVeiculos] =
        useState<VehicleForm[]>(carretasAdicionadas);

    const [usuarioBuscouPlaca, setUsuarioBuscouPlaca] =
        useState<boolean>(false);

    const [regimeTributario, setRegimeTributario] = useState<'S' | 'N'>('S');

    const [mensagemErroBuscaVeiculo, setMensagemErroBuscaVeiculo] =
        useState<string>();

    const { getMarcaByCodigo, getModeloByCodigo, getTipoByCodigo } =
        useMarcaTipoModelo();

    const [tabValue, setTabValue] = useState<'1' | '2'>('1');

    useEffect(() => {
        if (veiculos.length > 0) {
            onCarretasAdicionadasChange(veiculos);
        } else {
            onCarretasAdicionadasChange([]);
        }
    }, [veiculos]);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleChange = (event: React.SyntheticEvent, newValue: '1' | '2') => {
        setTabValue(newValue);
    };

    const {
        control,
        handleSubmit,
        reset,
        setValue,
        getValues,
        watch,
        formState: { errors },
    } = useFormMethods;

    const watchMarca = watch('DeviceBrand');
    const watchPlate = watch('Plate');
    const watchDocument = watch('Document');
    const watchDocument5 = watch('Document5');

    const getDataFormatadaEnvioApi = (data: Date) => {
        const day = data.getDate().toString().padStart(2, '0');
        const month = (data.getMonth() + 1).toString().padStart(2, '0');
        const year = data.getFullYear().toString();

        return `${day}/${month}/${year}`;
    };

    const hasErrosLocatario = (dadosForm: CamposFormularioVeiculo) => {
        if (
            dadosForm.Address5 ||
            dadosForm.County5 ||
            dadosForm.Document5 ||
            dadosForm.NameDocument2 ||
            dadosForm.Number5 ||
            dadosForm.Postcode5
        ) {
            if (
                !(
                    dadosForm.Address5 &&
                    dadosForm.County5 &&
                    dadosForm.Document5 &&
                    dadosForm.NameDocument2 &&
                    dadosForm.Number5 &&
                    dadosForm.Postcode5
                )
            ) {
                setMensagemLocatarioIncompleto(
                    'Finalize o preenchimento dos campos do locatário!'
                );

                return true;
            }
        }

        return false;
    };

    const tratarDados = async (dadosFormulario: CamposFormularioVeiculo) => {
        setIsLoading(true);

        if (hasErrosLocatario(dadosFormulario)) {
            setIsLoading(false);
            setTabValue('1');
            return;
        }

        let dadosEnviarApi: CamposFormularioTratados[] = [];

        dadosEnviarApi.push({
            Plate: dadosFormulario.Plate.replace('-', '').toUpperCase(),
            Address: dadosFormulario.Address.toUpperCase().trim(),
            City: Number(dadosFormulario.cidadeIbge!.idIbge),
            Document: dadosFormulario.Document.replace('.', '')
                .replace('.', '')
                .replace('-', '')
                .replace('/', ''),
            Name: dadosFormulario.Name.toUpperCase().trim(),
            Postcode: dadosFormulario.Postcode,
            Number: dadosFormulario.Number,
            County: dadosFormulario.County.toUpperCase().trim(),
            CityPlate: Number(dadosFormulario.cidadeIbgePlate!.idIbge),
            Document3: dadosFormulario.Document3,
            DeviceBrand: dadosFormulario.DeviceBrand!.DeviceBrand.toString(),
            DeviceModel: dadosFormulario.DeviceModel!.DeviceModel.toString(),
            DeviceType: dadosFormulario.DeviceType!.DeviceType.toString(),
            Document4: dadosFormulario.Document4,
            Year: dadosFormulario.Year,
            Document2: dadosFormulario.Document2,
            NameDocument2: dadosFormulario.NameDocument2
                ? dadosFormulario.NameDocument2.toUpperCase().trim()
                : null,
            DueDocument2: getDataFormatadaEnvioApi(
                dadosFormulario.DueDocument2!
            ),
            Address5: dadosFormulario.Address5
                ? dadosFormulario.Address5.toUpperCase().trim()
                : null,
            City5: dadosFormulario.cidadeIbge5
                ? Number(dadosFormulario.cidadeIbge5!.idIbge)
                : null,
            Complement5: dadosFormulario.Complement5
                ? dadosFormulario.Complement5.toUpperCase().trim()
                : null,
            County5: dadosFormulario.County5
                ? dadosFormulario.County5.toUpperCase().trim()
                : null,
            Document5: dadosFormulario.Document5
                ? dadosFormulario.Document5.replace(/\D/g, '')
                : null,
            Number5: dadosFormulario.Number5 ? dadosFormulario.Number5 : null,
            Postcode5: dadosFormulario.Postcode5
                ? dadosFormulario.Postcode5
                : null,
            TaxType: regimeTributarioProprietario!,
            TaxType5: regimeTributarioLocatario
                ? regimeTributarioLocatario
                : null,
            Capacity: Number(dadosFormulario.Capacity),
            TireType: dadosFormulario.TireSet!.TireSet!,
            TrunkType: dadosFormulario.TipoCarroceria!.codigo!,
        });

        veiculos.forEach((vForm) => {
            dadosEnviarApi.push({
                Plate: vForm.Plate.replace('-', '').toUpperCase(),
                Address: vForm.Address.toUpperCase().trim(),
                City: Number(vForm.City),
                Document: vForm.Document.replace('.', '')
                    .replace('.', '')
                    .replace('-', '')
                    .replace('/', ''),
                Name: vForm.Name.toUpperCase().trim(),
                Postcode: vForm.Postcode,
                Number: vForm.Number,
                County: vForm.County.toUpperCase().trim(),
                CityPlate: vForm.CityPlate,
                Document3: vForm.Document3,
                DeviceBrand: vForm.DeviceBrand!.DeviceBrand.toString(),
                DeviceModel: vForm.DeviceModel!.DeviceModel.toString(),
                DeviceType: vForm.DeviceType!.DeviceType.toString(),
                Document4: vForm.Document4,
                Year: vForm.Year,
                Document2: vForm.Document2,
                NameDocument2: vForm.NameDocument2
                    ? vForm.NameDocument2.toUpperCase().trim()
                    : null,
                DueDocument2: vForm.DueDocument2,
                Address5: vForm.Address5 ? vForm.Address5.toUpperCase() : null,
                City5: vForm.City5 ? vForm.City5 : null,
                Complement5: vForm.Complement5
                    ? vForm.Complement5.toUpperCase().trim()
                    : null,
                County5: vForm.County5 ? vForm.County5.toUpperCase() : null,
                Document5: vForm.Document5
                    ? vForm.Document5.replace(/\D/g, '')
                    : null,
                Number5: vForm.Number5 ? vForm.Number5 : null,
                Postcode5: vForm.Postcode5 ? vForm.Postcode5 : null,
                TaxType: vForm.TaxType,
                TaxType5: vForm.TaxType5,
                Capacity: Number(dadosFormulario.Capacity),
                TireType: dadosFormulario.TireSet!.TireSet!,
                TrunkType: dadosFormulario.TipoCarroceria!.codigo!,
            });
        });

        let juncao: Juncao;

        const servico = getService(ApiResources.Juncao);

        try {
            const servico = getService(ApiResources.Juncao);

            const respostaApi = await servico.api.get(
                '/buscar-motorista-maxys-atac/' +
                    documentMotorista!.replace(/\D/g, '')
            );

            if (respostaApi.status === StatusRequisicao.OK) {
                juncao = respostaApi.data;
            }
        } catch (error) {
            setIsLoading(false);
        }

        try {
            let driver: DriverWithAddress = {
                Document: juncao!.Driver.Document,
                Name: juncao!.Driver.Name,
                DateOfBirthday: juncao!.Driver.DateOfBirthday,
                Document2: juncao!.Driver.Document2,
                IssueDateDocument2: juncao!.Driver.IssueDateDocument2,
                IssueStateDocument2: juncao!.Driver.IssueStateDocument2,
                Document3: juncao!.Driver.Document3,
                CategoryDocument3: juncao!.Driver.CategoryDocument3,
                DueDateDocument3: juncao!.Driver.DueDateDocument3,
                EmitterDocument3: juncao!.Driver.EmitterDocument3,
                IssueCityDocument3: juncao!.Driver.IssueCityDocument3,
                FirstDateDocument3: juncao!.Driver.FirstDateDocument3,
                FatherName: juncao!.Driver.FatherName,
                MotherName: juncao!.Driver.MotherName,
                References: juncao!.Driver.References,
                Address: juncao!.Driver.Adresses[0].Address,
                Postcode: juncao!.Driver.Adresses[0].Postcode,
                County: juncao!.Driver.Adresses[0].County,
                Number: juncao!.Driver.Adresses[0].Number,
                City: juncao!.Driver.Adresses[0].City,
                Code: juncao!.Driver.Adresses[0].Code,
            };

            const resultado = await servico.api.post('/registrar-veiculo', {
                DeviceRegisters: dadosEnviarApi,
                Driver: driver,
            });

            if (resultado.status === StatusRequisicao.OK) {
                enqueueSnackbar('Registrado com sucesso!', {
                    variant: 'success',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
                setIsLoading(false);
                window.location.reload();
            } else {
                if (resultado.status === StatusRequisicao.BAD_REQUEST) {
                    enqueueSnackbar('resultado.', {
                        variant: 'error',
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
                }
                setIsLoading(false);
            }
        } catch (error) {
            console.error(error);

            setIsLoading(false);
            (error as MensagemErroRequisicaoApi[]).forEach((erro) => {
                enqueueSnackbar(erro.atributo + ': ' + erro.mensagem, {
                    variant: 'error',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
            });
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (documentMotorista && situacaoMotorista) {
            if (
                situacaoMotorista === TipoMotorista.ProprietarioCavalo ||
                situacaoMotorista === TipoMotorista.ProprietarioConjunto
            ) {
                buscarProprietario(documentMotorista);
            }
        }
    }, [documentMotorista, situacaoMotorista]);

    const buscarCepLocatario = async (cep: string) => {
        try {
            const resposta = await serviceViaCep.buscarEndereco(cep);

            if (
                resposta.status === StatusRequisicao.OK &&
                !resposta?.data?.erro
            ) {
                setValue('Address5', resposta?.data?.logradouro);
                setValue('County5', resposta?.data?.bairro);

                const servico = getService(ApiResources.Juncao);

                try {
                    const respostaApi = await servico.api.get(
                        'get-cidade-ibge/' + resposta?.data?.ibge
                    );

                    if (respostaApi.status === StatusRequisicao.OK) {
                        setValue('cidadeIbge5', respostaApi.data);
                    }
                } catch (error) {}
            }
        } catch (error) {}
    };

    const getClifor = async (document: string): Promise<Juncao | null> => {
        try {
            const servico = getService(ApiResources.Juncao);

            const respostaApi = await servico.api.get(
                '/buscar-motorista-maxys-atac/' + document.replace(/\D/g, '')
            );

            if (respostaApi.status === StatusRequisicao.OK) {
                return respostaApi.data;
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    };

    const getCidade = async (
        ibgeCode: number | string
    ): Promise<CidadeIBGE | null> => {
        try {
            const servico = getService(ApiResources.Juncao);

            const respostaApi = await servico.api.get(
                'get-cidade-ibge/' + ibgeCode
            );

            if (respostaApi.status === StatusRequisicao.OK) {
                return respostaApi.data;
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    };

    const buscarLocatario = async (document: string) => {
        try {
            const resposta: Juncao | null = await getClifor(document);

            if (resposta) {
                if (resposta.Driver) {
                    const driver = resposta.Driver;

                    if (driver.Document.length <= 11) {
                        setValue(
                            'Document5',
                            driver.Document.replace(
                                /(\d{3})(\d{3})(\d{3})(\d{2})/,
                                '$1.$2.$3-$4'
                            )
                        );
                    } else {
                        setValue(
                            'Document5',
                            driver.Document.replace(
                                /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                                '$1.$2.$3/$4-$5'
                            )
                        );

                        setRegimeTributarioLocatario(
                            driver.Adresses[0].TaxType!
                        );
                    }
                    setValue('NameDocument2', driver.Name);
                    setValue('Postcode5', driver.Adresses[0].Postcode);
                    setValue('Address5', driver.Adresses[0].Address);
                    setValue('County5', driver.Adresses[0].County);
                    setValue('Number5', driver.Adresses[0].Number);

                    const respostaCidade: CidadeIBGE | null = await getCidade(
                        driver.Adresses[0].City
                    );

                    if (respostaCidade) {
                        setValue('cidadeIbge5', respostaCidade);
                    }
                } else {
                    limparLocatario();
                }
            } else {
                limparLocatario();
            }
        } catch (e) {
            limparLocatario();
        }
    };

    const buscarProprietario = async (document: string) => {
        try {
            const servico = getService(ApiResources.Juncao);

            const respostaApi = await servico.api.get(
                '/buscar-motorista-maxys-atac/' + document.replace(/\D/g, '')
            );

            if (respostaApi.status === StatusRequisicao.OK) {
                const juncao: Juncao = respostaApi.data;

                const driver = juncao.Driver;

                if (driver.Document.length <= 11) {
                    setValue(
                        'Document',
                        driver.Document.replace(
                            /(\d{3})(\d{3})(\d{3})(\d{2})/,
                            '$1.$2.$3-$4'
                        )
                    );
                } else {
                    setValue(
                        'Document',
                        driver.Document.replace(
                            /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                            '$1.$2.$3/$4-$5'
                        )
                    );

                    setRegimeTributarioProprietario(
                        driver.Adresses[0].TaxType!
                    );
                }
                setValue('Name', driver.Name);
                setValue('Postcode', driver.Adresses[0].Postcode);
                setValue('Address', driver.Adresses[0].Address);
                setValue('County', driver.Adresses[0].County);
                setValue('Number', driver.Adresses[0].Number);

                try {
                    const respostaApi = await servico.api.get(
                        'get-cidade-ibge/' + driver.Adresses[0].City
                    );

                    if (respostaApi.status === StatusRequisicao.OK) {
                        setValue('cidadeIbge', respostaApi.data);
                    }
                } catch (error) {
                    limparProprietario();
                }
            } else {
                limparProprietario();
            }
        } catch (error) {
            limparProprietario();
        }
    };

    const limparProprietario = () => {
        // setValue('Document', '');
        setValue('Name', '');
        setValue('Postcode', '');
        setValue('Address', '');
        setValue('County5', '');
        setValue('County', '');
        setValue('Number', '');
        setValue('cidadeIbge', null);
        setRegimeTributarioProprietario('N');
    };

    const limparLocatario = () => {
        //setValue('Document5', '');
        setValue('NameDocument2', '');
        setValue('Postcode5', '');
        setValue('Address5', '');
        setValue('County5', '');
        setValue('Number5', '');
        setValue('cidadeIbge5', null);
        setRegimeTributarioLocatario('N');
    };

    function converterStringParaData(dataString: string) {
        const partes = dataString.split('/');
        const ano = parseInt(partes[2], 10);
        const mes = parseInt(partes[1], 10) - 1;
        const dia = parseInt(partes[0], 10);
        const data = new Date(ano, mes, dia);

        return data;
    }

    const buscarPlaca = async () => {
        setIsLoading(true);
        let plate = getValues('Plate');

        if (plate) {
            try {
                const servico = getService(ApiResources.Juncao);

                const respostaApi = await servico.api.get(
                    '/buscar-placa-maxys-atac/' + plate.replace('-', '')
                );

                //se trazer o veículo, realiza requisição pra pegar as cidades
                if (respostaApi.status === StatusRequisicao.OK) {
                    setUsuarioBuscouPlaca(true);
                    const veiculo = respostaApi.data.DeviceRegisters;

                    setValue('County', veiculo.County);
                    setValue('Document3', veiculo.Document3);
                    setValue('Document4', veiculo.Document4);
                    setValue('Name', veiculo.Name);
                    setValue('NameDocument2', veiculo.NameDocument2);
                    setValue('Number', veiculo.Number);
                    setValue('Postcode', veiculo.Postcode);
                    setValue('Year', veiculo.Year.toString());

                    setRegimeTributarioLocatario(veiculo.TaxType5);

                    setRegimeTributarioProprietario(veiculo.TaxType);

                    if (veiculo.Document.length <= 11) {
                        setValue(
                            'Document',
                            veiculo.Document.replace(
                                /(\d{3})(\d{3})(\d{3})(\d{2})/,
                                '$1.$2.$3-$4'
                            )
                        );
                    } else {
                        setValue(
                            'Document',
                            veiculo.Document.replace(
                                /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                                '$1.$2.$3/$4-$5'
                            )
                        );
                    }

                    if (veiculo.TireSets && veiculo.TireSets.TireSet) {
                        setValue('TireSet', veiculo.TireSets);
                    }

                    setValue('TipoCarroceria', veiculo.tiposCarroceria);

                    setValue(
                        'Capacity',
                        veiculo.Capacity == null
                            ? ''
                            : veiculo.Capacity.toString()
                    );

                    setValue('Document2', veiculo.Document2);
                    setValue('Address', veiculo.Address);
                    setValue(
                        'DueDocument2',
                        converterStringParaData(veiculo.DueDocument2)
                    );

                    getMarcaByCodigo(veiculo.DeviceBrand).then((retorno) => {
                        setValue('DeviceBrand', retorno);
                    });

                    getModeloByCodigo(
                        veiculo.DeviceModel,
                        veiculo.DeviceBrand
                    ).then((retorno) => {
                        setValue('DeviceModel', retorno);
                    });

                    getTipoByCodigo(veiculo.DeviceType).then((retorno) => {
                        setValue('DeviceType', retorno);
                    });

                    setValue('Document2', veiculo.Document2);

                    setValue('Address5', veiculo.Address5);
                    setValue('Complement5', veiculo.Complement5);
                    setValue('County5', veiculo.County5);
                    setValue('Number5', veiculo.Number5);
                    setValue('Postcode5', veiculo.Postcode5);

                    if (veiculo.City5) {
                        try {
                            const respostaApi2 = await servico.api.get(
                                'get-cidade-ibge/' + veiculo.City5
                            );

                            if (respostaApi2.status === StatusRequisicao.OK) {
                                setValue('cidadeIbge5', respostaApi2.data);
                            }
                        } catch (error) {
                            setIsLoading(false);
                        }
                    }

                    if (veiculo.Document5.length <= 11) {
                        setValue(
                            'Document5',
                            veiculo.Document5.replace(
                                /(\d{3})(\d{3})(\d{3})(\d{2})/,
                                '$1.$2.$3-$4'
                            )
                        );
                    } else {
                        setValue(
                            'Document5',
                            veiculo.Document5.replace(
                                /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                                '$1.$2.$3/$4-$5'
                            )
                        );
                    }

                    try {
                        const respostaApi = await servico.api.get(
                            'get-cidade-ibge/' + veiculo.City
                        );

                        if (respostaApi.status === StatusRequisicao.OK) {
                            setValue('cidadeIbge', respostaApi.data);
                        }
                    } catch (error) {}

                    try {
                        const respostaApi = await servico.api.get(
                            'get-cidade-ibge/' + veiculo.CityPlate
                        );

                        if (respostaApi.status === StatusRequisicao.OK) {
                            setValue('cidadeIbgePlate', respostaApi.data);
                        }
                    } catch (error) {}
                }
                setMensagemErroBuscaVeiculo(undefined);
            } catch (error) {
                setMensagemErroBuscaVeiculo(
                    'Veículo não encontrado, você pode cadastrar um novo.'
                );
                setUsuarioBuscouPlaca(true);
                resetarCampos();
                setValue('Plate', plate);
                setIsLoading(false);
            }
        }
        setIsLoading(false);
    };

    const resetarCampos = () => {
        if (
            situacaoMotorista === TipoMotorista.ProprietarioCavalo ||
            situacaoMotorista === TipoMotorista.ProprietarioConjunto
        ) {
            // reset({});
        } else {
            reset({ Document: '', Document5: '' });
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const tratarAbaErros = () => {
        if (Object.entries(errors).length > 0 && isUsuarioClicouSalvar) {
            setTabValue('1');
            setIsUsuarioClicouSalvar(false);
        }
    };

    useEffect(() => {
        if (errors) {
            tratarAbaErros();
        }
    }, [errors, tratarAbaErros]);

    const serviceViaCep = new ViaCepService();

    const buscarCep = async (cep: string) => {
        try {
            const resposta = await serviceViaCep.buscarEndereco(cep);

            if (
                resposta.status === StatusRequisicao.OK &&
                !resposta?.data?.erro
            ) {
                setValue('Address', resposta?.data?.logradouro);
                setValue('County', resposta?.data?.bairro);

                const servico = getService(ApiResources.Juncao);

                try {
                    const respostaApi = await servico.api.get(
                        'get-cidade-ibge/' + resposta?.data?.ibge
                    );

                    if (respostaApi.status === StatusRequisicao.OK) {
                        setValue('cidadeIbge', respostaApi.data);
                    }
                } catch (error) {}
            }
        } catch (error) {}
    };

    const handleTabPress = (event: any) => {
        if (event.key === 'Tab') {
            event.preventDefault(); // Impede o comportamento padrão do Tab

            // Encontra o próximo input usando o atributo `tabIndex`
            const inputs = document.getElementsByTagName('input');
            const index = Array.from(inputs).indexOf(event.target);
            const nextInput = inputs[index + 1];

            // Foca no próximo input, se existir
            if (nextInput) {
                nextInput.focus();
            }
        }
    };

    return (
        <SpinnerLoading isLoading={isLoading}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <form
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit(tratarDados)}
                >
                    <TabContext value={tabValue}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange}>
                                <Tab label="Cavalo" value="1" />
                                <Tab
                                    label="Carretas"
                                    value="2"
                                    disabled={!usuarioBuscouPlaca}
                                />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={3}>
                                    <Controller
                                        name="Plate"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <InputPlaca
                                                    {...field}
                                                    inputRef={ref}
                                                    required
                                                    value={field.value}
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                    // onBlur={(event) => {
                                                    //     buscarPlaca();
                                                    // }}
                                                    inputProps={{
                                                        style: {
                                                            textTransform:
                                                                'uppercase',
                                                        },
                                                    }}
                                                    fullWidth
                                                    label="Placa"
                                                    error={!!errors.Plate}
                                                    helperText={
                                                        errors.Plate
                                                            ? errors.Plate
                                                                  .message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={5}>
                                    <Button
                                        type="button"
                                        variant="contained"
                                        style={stylesTabMotorista().button}
                                        size="large"
                                        disabled={
                                            !watchPlate || watchPlate.length < 7
                                        }
                                        onClick={() => {
                                            buscarPlaca();
                                        }}
                                    >
                                        Buscar
                                    </Button>
                                </Grid>
                            </Grid>

                            {mensagemErroBuscaVeiculo && (
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        lg={12}
                                        style={{ marginTop: '16px' }}
                                    >
                                        <Alert
                                            variant="outlined"
                                            severity="error"
                                            style={{ marginTop: 15 }}
                                        >
                                            {mensagemErroBuscaVeiculo}
                                        </Alert>
                                    </Grid>
                                </Grid>
                            )}

                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={12}
                                    lg={3}
                                    style={{ marginTop: '16px' }}
                                >
                                    <Controller
                                        name="Document"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                type="text"
                                                label="CPF/CNPJ do proprietário"
                                                onBlur={() => {
                                                    if (
                                                        (watchDocument &&
                                                            watchDocument.length ===
                                                                14) ||
                                                        watchDocument?.length ===
                                                            18
                                                    ) {
                                                        buscarProprietario(
                                                            watchDocument
                                                        );
                                                    }
                                                }}
                                                fullWidth
                                                autoComplete="new-password"
                                                disabled={!usuarioBuscouPlaca}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={!!errors.Document}
                                                helperText={
                                                    errors.Document
                                                        ? (
                                                              errors.Document as any
                                                          ).message
                                                        : ''
                                                }
                                                inputProps={{ maxLength: 18 }}
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    let formattedValue =
                                                        value.replace(
                                                            /\D/g,
                                                            ''
                                                        );
                                                    if (
                                                        formattedValue.length <=
                                                        11
                                                    ) {
                                                        formattedValue =
                                                            formattedValue.replace(
                                                                /(\d{3})(\d{3})(\d{3})(\d{2})/,
                                                                '$1.$2.$3-$4'
                                                            );
                                                    } else {
                                                        formattedValue =
                                                            formattedValue.replace(
                                                                /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                                                                '$1.$2.$3/$4-$5'
                                                            );
                                                    }
                                                    field.onChange(
                                                        formattedValue
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12} lg={6}>
                                    <Controller
                                        name="Name"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <TextField
                                                    {...field}
                                                    inputRef={ref}
                                                    margin="normal"
                                                    required
                                                    autoComplete="new-password"
                                                    fullWidth
                                                    disabled={
                                                        !usuarioBuscouPlaca
                                                    }
                                                    inputProps={{
                                                        maxLength: 150,
                                                        style: {
                                                            textTransform:
                                                                'uppercase',
                                                        },
                                                    }}
                                                    onChange={(e) => {
                                                        e.target.value =
                                                            e.target.value.replace(
                                                                /['",\\]/g,
                                                                ''
                                                            );

                                                        field.onChange(e);
                                                    }}
                                                    label="Nome do proprietário"
                                                    error={!!errors.Name}
                                                    helperText={
                                                        errors.Name
                                                            ? errors.Name
                                                                  .message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <Controller
                                        name="Postcode"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <TextField
                                                    {...field}
                                                    inputRef={ref}
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                    fullWidth
                                                    disabled={
                                                        !usuarioBuscouPlaca
                                                    }
                                                    inputProps={{
                                                        maxLength: 8,
                                                    }}
                                                    label="CEP do proprietário"
                                                    onChange={(
                                                        event: React.ChangeEvent<
                                                            | HTMLTextAreaElement
                                                            | HTMLInputElement
                                                        >
                                                    ) => {
                                                        event.currentTarget.value =
                                                            event.currentTarget.value =
                                                                event.currentTarget.value.replace(
                                                                    /[^0-9]/g,
                                                                    ''
                                                                );

                                                        field.onChange(event);
                                                    }}
                                                    onBlur={(event) => {
                                                        buscarCep(
                                                            event.target.value
                                                        );
                                                    }}
                                                    error={!!errors.Postcode}
                                                    helperText={
                                                        errors.Postcode
                                                            ? errors.Postcode
                                                                  .message
                                                            : ''
                                                    }
                                                    data-id="campoCepOrigem"
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={5}>
                                    <Controller
                                        name="Address"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <TextField
                                                    {...field}
                                                    inputRef={ref}
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                    fullWidth
                                                    disabled={
                                                        !usuarioBuscouPlaca
                                                    }
                                                    inputProps={{
                                                        style: {
                                                            textTransform:
                                                                'uppercase',
                                                        },
                                                    }}
                                                    onChange={(e) => {
                                                        e.target.value =
                                                            e.target.value.replace(
                                                                /['",\\]/g,
                                                                ''
                                                            );

                                                        field.onChange(e);
                                                    }}
                                                    label="Logradouro do proprietário"
                                                    error={!!errors.Address}
                                                    helperText={
                                                        errors.Address
                                                            ? errors.Address
                                                                  .message
                                                            : ''
                                                    }
                                                    data-id="campoLogradouroOrigem"
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={2}>
                                    <Controller
                                        name="Number"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <InputNumero
                                                    {...field}
                                                    margin="normal"
                                                    inputRef={ref}
                                                    autoComplete="new-password"
                                                    fullWidth
                                                    disabled={
                                                        !usuarioBuscouPlaca
                                                    }
                                                    maxLength={7}
                                                    required
                                                    label="Número"
                                                    error={!!errors.Number}
                                                    helperText={
                                                        errors.Number
                                                            ? errors.Number
                                                                  .message
                                                            : ''
                                                    }
                                                    data-id="campoNumeroOrigem"
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={3}>
                                    <Controller
                                        name="County"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <TextField
                                                    {...field}
                                                    inputRef={ref}
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                    fullWidth
                                                    disabled={
                                                        !usuarioBuscouPlaca
                                                    }
                                                    inputProps={{
                                                        style: {
                                                            textTransform:
                                                                'uppercase',
                                                        },
                                                    }}
                                                    onChange={(e) => {
                                                        e.target.value =
                                                            e.target.value.replace(
                                                                /['",\\]/g,
                                                                ''
                                                            );

                                                        field.onChange(e);
                                                    }}
                                                    label="Bairro"
                                                    error={!!errors.County}
                                                    helperText={
                                                        errors.County
                                                            ? errors.County
                                                                  .message
                                                            : ''
                                                    }
                                                    data-id="campoBairroOrigem"
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    lg={6}
                                    style={{ marginTop: '16px' }}
                                >
                                    <Controller
                                        control={control}
                                        name="cidadeIbge"
                                        defaultValue={null}
                                        render={(props) => {
                                            return (
                                                <SelectCidades
                                                    {...props}
                                                    options={[]}
                                                    inputRef={props.field.ref}
                                                    label={
                                                        'Cidade do proprietário'
                                                    }
                                                    required
                                                    disabled={
                                                        !usuarioBuscouPlaca
                                                    }
                                                    onChange={(
                                                        _,
                                                        cidadeIbge
                                                    ) => {
                                                        props.field.onChange(
                                                            cidadeIbge
                                                        );
                                                    }}
                                                    fullWidth
                                                    error={!!errors.cidadeIbge}
                                                    helperText={
                                                        errors.cidadeIbge
                                                            ? (
                                                                  errors.cidadeIbge as any
                                                              ).message
                                                            : 'Pesquise pelo nome da cidade (min 3 letras)'
                                                    }
                                                    data-id="selectProduto"
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            {watchDocument && watchDocument.length === 18 && (
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={5}>
                                        <FormControl
                                            disabled={!usuarioBuscouPlaca}
                                        >
                                            <FormLabel id="demo-row-radio-buttons-group-label">
                                                Regime tributário do
                                                Proprietário
                                            </FormLabel>
                                            <RadioGroup
                                                row
                                                onChange={(
                                                    event: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    setRegimeTributarioProprietario(
                                                        (
                                                            event.target as HTMLInputElement
                                                        ).value
                                                    );
                                                }}
                                                value={
                                                    regimeTributarioProprietario
                                                }
                                            >
                                                <FormControlLabel
                                                    value="N"
                                                    control={<Radio />}
                                                    label="Normal"
                                                />
                                                <FormControlLabel
                                                    value="S"
                                                    control={<Radio />}
                                                    label="Simples Nacional"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            )}

                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={12}
                                    lg={5}
                                    style={{ marginTop: '16px' }}
                                >
                                    <Controller
                                        control={control}
                                        name="cidadeIbgePlate"
                                        defaultValue={null}
                                        render={(props) => {
                                            return (
                                                <SelectCidades
                                                    {...props}
                                                    options={[]}
                                                    inputRef={props.field.ref}
                                                    label={
                                                        'Cidade de registro do veículo'
                                                    }
                                                    required
                                                    disabled={
                                                        !usuarioBuscouPlaca
                                                    }
                                                    onChange={(
                                                        _,
                                                        cidadeIbgePlate
                                                    ) => {
                                                        props.field.onChange(
                                                            cidadeIbgePlate
                                                        );
                                                    }}
                                                    fullWidth
                                                    error={
                                                        !!errors.cidadeIbgePlate
                                                    }
                                                    helperText={
                                                        errors.cidadeIbgePlate
                                                            ? (
                                                                  errors.cidadeIbgePlate as any
                                                              ).message
                                                            : 'Pesquise pelo nome da cidade (min 3 letras)'
                                                    }
                                                    data-id="selectProduto"
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <Controller
                                        name="Document3"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <InputNumero
                                                    {...field}
                                                    inputRef={ref}
                                                    margin="normal"
                                                    required
                                                    autoComplete="new-password"
                                                    fullWidth
                                                    disabled={
                                                        !usuarioBuscouPlaca
                                                    }
                                                    maxLength={11}
                                                    label="Renavam"
                                                    error={!!errors.Document3}
                                                    helperText={
                                                        errors.Document3
                                                            ? errors.Document3
                                                                  .message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={12}
                                    lg={5}
                                    style={{ marginTop: '16px' }}
                                >
                                    <Controller
                                        control={control}
                                        name="DeviceBrand"
                                        defaultValue={null}
                                        render={(props) => {
                                            return (
                                                <SelectMarcasMaxys
                                                    {...props}
                                                    options={[]}
                                                    inputRef={props.field.ref}
                                                    label={'Marca'}
                                                    required
                                                    disabled={
                                                        !usuarioBuscouPlaca
                                                    }
                                                    onChange={(
                                                        _,
                                                        DeviceBrand
                                                    ) => {
                                                        props.field.onChange(
                                                            DeviceBrand
                                                        );
                                                    }}
                                                    fullWidth
                                                    error={!!errors.DeviceBrand}
                                                    helperText={
                                                        errors.DeviceBrand
                                                            ? (
                                                                  errors.DeviceBrand as any
                                                              ).message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    lg={3}
                                    style={{ marginTop: '16px' }}
                                >
                                    <Controller
                                        control={control}
                                        name="DeviceModel"
                                        defaultValue={null}
                                        render={(props) => {
                                            return (
                                                <SelectModelosMaxys
                                                    {...props}
                                                    options={[]}
                                                    disabled={
                                                        !usuarioBuscouPlaca
                                                    }
                                                    inputRef={props.field.ref}
                                                    marca={
                                                        watchMarca
                                                            ? watchMarca.DeviceBrand
                                                            : undefined
                                                    }
                                                    label={'Modelo'}
                                                    required
                                                    onChange={(
                                                        _,
                                                        DeviceModel
                                                    ) => {
                                                        props.field.onChange(
                                                            DeviceModel
                                                        );
                                                    }}
                                                    fullWidth
                                                    error={!!errors.DeviceModel}
                                                    helperText={
                                                        errors.DeviceModel
                                                            ? (
                                                                  errors.DeviceModel as any
                                                              ).message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    lg={3}
                                    style={{ marginTop: '16px' }}
                                >
                                    <Controller
                                        control={control}
                                        name="DeviceType"
                                        defaultValue={null}
                                        render={(props) => {
                                            return (
                                                <SelectTipoVeiculo
                                                    {...props}
                                                    options={[]}
                                                    inputRef={props.field.ref}
                                                    label={'Tipo do veículo'}
                                                    required
                                                    disabled={
                                                        !usuarioBuscouPlaca
                                                    }
                                                    onChange={(
                                                        _,
                                                        DeviceType
                                                    ) => {
                                                        props.field.onChange(
                                                            DeviceType
                                                        );
                                                    }}
                                                    fullWidth
                                                    error={!!errors.DeviceType}
                                                    helperText={
                                                        errors.DeviceType
                                                            ? (
                                                                  errors.DeviceType as any
                                                              ).message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={12}
                                    lg={3}
                                    style={{ marginTop: '16px' }}
                                >
                                    <Controller
                                        control={control}
                                        name="TipoCarroceria"
                                        defaultValue={null}
                                        render={(props) => {
                                            return (
                                                <SelectTipoBau
                                                    {...props}
                                                    options={[]}
                                                    inputRef={props.field.ref}
                                                    label={'Tipo da Carroceria'}
                                                    required
                                                    disabled={
                                                        !usuarioBuscouPlaca
                                                    }
                                                    onChange={(
                                                        _,
                                                        TipoCarroceria
                                                    ) => {
                                                        props.field.onChange(
                                                            TipoCarroceria
                                                        );
                                                    }}
                                                    fullWidth
                                                    error={
                                                        !!errors.TipoCarroceria
                                                    }
                                                    helperText={
                                                        errors.TipoCarroceria
                                                            ? (
                                                                  errors.TipoCarroceria as any
                                                              ).message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    lg={5}
                                    style={{ marginTop: '16px' }}
                                >
                                    <Controller
                                        control={control}
                                        name="TireSet"
                                        defaultValue={null}
                                        render={(props) => {
                                            return (
                                                <SelectConjuntoPneu
                                                    {...props}
                                                    options={[]}
                                                    inputRef={props.field.ref}
                                                    label={'Conjunto do pneu'}
                                                    required
                                                    disabled={
                                                        !usuarioBuscouPlaca
                                                    }
                                                    onChange={(_, TireSet) => {
                                                        props.field.onChange(
                                                            TireSet
                                                        );
                                                    }}
                                                    fullWidth
                                                    error={!!errors.TireSet}
                                                    helperText={
                                                        errors.TireSet
                                                            ? (
                                                                  errors.TireSet as any
                                                              ).message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <Controller
                                        name="Capacity"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <InputNumero
                                                    {...field}
                                                    disabled={
                                                        !usuarioBuscouPlaca
                                                    }
                                                    inputRef={ref}
                                                    margin="normal"
                                                    maxLength={4}
                                                    autoComplete="new-password"
                                                    fullWidth
                                                    inputProps={{
                                                        maxLength: 3,
                                                    }}
                                                    label="Capacidade (m³)"
                                                    error={!!errors.Capacity}
                                                    helperText={
                                                        errors.Capacity
                                                            ? errors.Capacity
                                                                  .message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={4}>
                                    <Controller
                                        name="Document4"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <TextField
                                                    {...field}
                                                    inputRef={ref}
                                                    disabled={
                                                        !usuarioBuscouPlaca
                                                    }
                                                    margin="normal"
                                                    required
                                                    autoComplete="new-password"
                                                    fullWidth
                                                    inputProps={{
                                                        maxLength: 20,
                                                    }}
                                                    onChange={(e) => {
                                                        e.target.value =
                                                            e.target.value.replace(
                                                                /['",\\]/g,
                                                                ''
                                                            );

                                                        field.onChange(e);
                                                    }}
                                                    label="Chassi"
                                                    error={!!errors.Document4}
                                                    helperText={
                                                        errors.Document4
                                                            ? errors.Document4
                                                                  .message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={1}>
                                    <Controller
                                        name="Year"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <InputNumero
                                                    {...field}
                                                    disabled={
                                                        !usuarioBuscouPlaca
                                                    }
                                                    inputRef={ref}
                                                    margin="normal"
                                                    maxLength={4}
                                                    autoComplete="new-password"
                                                    fullWidth
                                                    label="Ano"
                                                    error={!!errors.Year}
                                                    helperText={
                                                        errors.Year
                                                            ? errors.Year
                                                                  .message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <Controller
                                        name="Document2"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <InputNumero
                                                    {...field}
                                                    inputRef={ref}
                                                    disabled={
                                                        !usuarioBuscouPlaca
                                                    }
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                    fullWidth
                                                    maxLength={20}
                                                    label="RNTRC"
                                                    error={!!errors.Document2}
                                                    helperText={
                                                        errors.Document2
                                                            ? errors.Document2
                                                                  .message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    lg={3}
                                    style={{ marginTop: '16px' }}
                                >
                                    <LocalizationProvider
                                        locale={ptBR}
                                        dateAdapter={AdapterDateFns}
                                    >
                                        <Controller
                                            name="DueDocument2"
                                            control={control}
                                            defaultValue={null}
                                            render={({
                                                field: { ref, ...field },
                                                fieldState: { error },
                                            }) => {
                                                return (
                                                    <DatePicker
                                                        inputFormat="dd/MM/yyyy"
                                                        value={field.value}
                                                        disabled={
                                                            !usuarioBuscouPlaca
                                                        }
                                                        onChange={
                                                            field.onChange
                                                        }
                                                        allowSameDateSelection
                                                        renderInput={(
                                                            params: any
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                label="Data de vencimento do RNTRC"
                                                                placeholder=""
                                                                autoComplete="new-password"
                                                                onKeyDown={
                                                                    handleTabPress
                                                                }
                                                                error={
                                                                    !!errors.DueDocument2
                                                                }
                                                                helperText={
                                                                    errors.DueDocument2
                                                                        ? errors
                                                                              .DueDocument2
                                                                              .message
                                                                        : ''
                                                                }
                                                                fullWidth
                                                                required
                                                            />
                                                        )}
                                                    />
                                                );
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                            <DividerComp texto="Locatário" />

                            {mensagemLocatarioIncompleto && (
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        lg={12}
                                        style={{ marginTop: '16px' }}
                                    >
                                        <Alert
                                            variant="outlined"
                                            severity="error"
                                            style={{ marginTop: 15 }}
                                        >
                                            {mensagemLocatarioIncompleto}
                                        </Alert>
                                    </Grid>
                                </Grid>
                            )}

                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={12}
                                    lg={2}
                                    style={{ marginTop: '16px' }}
                                >
                                    <Controller
                                        name="Document5"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                type="text"
                                                label="CPF/CNPJ do locatário"
                                                onBlur={() => {
                                                    if (
                                                        (watchDocument5 &&
                                                            watchDocument5.length ===
                                                                14) ||
                                                        watchDocument5?.length ===
                                                            18
                                                    ) {
                                                        buscarLocatario(
                                                            watchDocument5
                                                        );
                                                    }
                                                }}
                                                autoComplete="new-password"
                                                fullWidth
                                                disabled={!usuarioBuscouPlaca}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{ maxLength: 18 }}
                                                error={!!errors.Document5}
                                                helperText={
                                                    errors.Document5
                                                        ? (
                                                              errors.Document5 as any
                                                          ).message
                                                        : ''
                                                }
                                                onChange={(e) => {
                                                    const { value } = e.target;
                                                    let formattedValue =
                                                        value.replace(
                                                            /\D/g,
                                                            ''
                                                        );
                                                    if (
                                                        formattedValue.length <=
                                                        11
                                                    ) {
                                                        formattedValue =
                                                            formattedValue.replace(
                                                                /(\d{3})(\d{3})(\d{3})(\d{2})/,
                                                                '$1.$2.$3-$4'
                                                            );
                                                    } else {
                                                        formattedValue =
                                                            formattedValue.replace(
                                                                /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                                                                '$1.$2.$3/$4-$5'
                                                            );
                                                    }
                                                    field.onChange(
                                                        formattedValue
                                                    );
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <Controller
                                        name="NameDocument2"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <TextField
                                                    {...field}
                                                    inputRef={ref}
                                                    margin="normal"
                                                    disabled={
                                                        !usuarioBuscouPlaca
                                                    }
                                                    fullWidth
                                                    autoComplete="new-password"
                                                    inputProps={{
                                                        maxLength: 60,
                                                        style: {
                                                            textTransform:
                                                                'uppercase',
                                                        },
                                                    }}
                                                    onChange={(e) => {
                                                        e.target.value =
                                                            e.target.value.replace(
                                                                /['",\\]/g,
                                                                ''
                                                            );

                                                        field.onChange(e);
                                                    }}
                                                    label="Nome/Razão social do locatário"
                                                    error={
                                                        !!errors.NameDocument2
                                                    }
                                                    helperText={
                                                        errors.NameDocument2
                                                            ? errors
                                                                  .NameDocument2
                                                                  .message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={3}>
                                    <Controller
                                        name="Postcode5"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <TextField
                                                    {...field}
                                                    inputRef={ref}
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                    fullWidth
                                                    disabled={
                                                        !usuarioBuscouPlaca
                                                    }
                                                    inputProps={{
                                                        maxLength: 8,
                                                    }}
                                                    label="CEP do locatário"
                                                    onChange={(
                                                        event: React.ChangeEvent<
                                                            | HTMLTextAreaElement
                                                            | HTMLInputElement
                                                        >
                                                    ) => {
                                                        event.currentTarget.value =
                                                            event.currentTarget.value =
                                                                event.currentTarget.value.replace(
                                                                    /[^0-9]/g,
                                                                    ''
                                                                );

                                                        field.onChange(event);
                                                    }}
                                                    onBlur={(event) => {
                                                        buscarCepLocatario(
                                                            event.target.value
                                                        );
                                                    }}
                                                    error={!!errors.Postcode5}
                                                    helperText={
                                                        errors.Postcode5
                                                            ? errors.Postcode5
                                                                  .message
                                                            : ''
                                                    }
                                                    data-id="campoCepOrigem"
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={5}>
                                    <Controller
                                        name="Address5"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <TextField
                                                    {...field}
                                                    inputRef={ref}
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                    fullWidth
                                                    disabled={
                                                        !usuarioBuscouPlaca
                                                    }
                                                    inputProps={{
                                                        style: {
                                                            textTransform:
                                                                'uppercase',
                                                        },
                                                    }}
                                                    onChange={(e) => {
                                                        e.target.value =
                                                            e.target.value.replace(
                                                                /['",\\]/g,
                                                                ''
                                                            );

                                                        field.onChange(e);
                                                    }}
                                                    label="Logradouro do locatário"
                                                    error={!!errors.Address5}
                                                    helperText={
                                                        errors.Address5
                                                            ? errors.Address5
                                                                  .message
                                                            : ''
                                                    }
                                                    data-id="campoLogradouroOrigem"
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={2}>
                                    <Controller
                                        name="Number5"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <InputNumero
                                                    {...field}
                                                    margin="normal"
                                                    inputRef={ref}
                                                    autoComplete="new-password"
                                                    fullWidth
                                                    disabled={
                                                        !usuarioBuscouPlaca
                                                    }
                                                    maxLength={7}
                                                    label="Número do locatário"
                                                    error={!!errors.Number5}
                                                    helperText={
                                                        errors.Number5
                                                            ? errors.Number5
                                                                  .message
                                                            : ''
                                                    }
                                                    data-id="campoNumeroOrigem"
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={3}>
                                    <Controller
                                        name="Complement5"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <TextField
                                                    {...field}
                                                    inputRef={ref}
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                    fullWidth
                                                    disabled={
                                                        !usuarioBuscouPlaca
                                                    }
                                                    inputProps={{
                                                        style: {
                                                            textTransform:
                                                                'uppercase',
                                                        },
                                                    }}
                                                    onChange={(e) => {
                                                        e.target.value =
                                                            e.target.value.replace(
                                                                /['",\\]/g,
                                                                ''
                                                            );

                                                        field.onChange(e);
                                                    }}
                                                    label="Complemento do locatário"
                                                    error={!!errors.Complement5}
                                                    helperText={
                                                        errors.Complement5
                                                            ? errors.Complement5
                                                                  .message
                                                            : ''
                                                    }
                                                    data-id="campoLogradouroOrigem"
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Controller
                                        name="County5"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <TextField
                                                    {...field}
                                                    inputRef={ref}
                                                    margin="normal"
                                                    autoComplete="new-password"
                                                    fullWidth
                                                    disabled={
                                                        !usuarioBuscouPlaca
                                                    }
                                                    inputProps={{
                                                        style: {
                                                            textTransform:
                                                                'uppercase',
                                                        },
                                                    }}
                                                    onChange={(e) => {
                                                        e.target.value =
                                                            e.target.value.replace(
                                                                /['",\\]/g,
                                                                ''
                                                            );

                                                        field.onChange(e);
                                                    }}
                                                    label="Bairro do locatário"
                                                    error={!!errors.County5}
                                                    helperText={
                                                        errors.County5
                                                            ? errors.County5
                                                                  .message
                                                            : ''
                                                    }
                                                    data-id="campoBairroOrigem"
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    lg={5}
                                    style={{ marginTop: '16px' }}
                                >
                                    <Controller
                                        control={control}
                                        name="cidadeIbge5"
                                        defaultValue={null}
                                        render={(props) => {
                                            return (
                                                <SelectCidades
                                                    {...props}
                                                    options={[]}
                                                    inputRef={props.field.ref}
                                                    label={
                                                        'Cidade do locatário'
                                                    }
                                                    disabled={
                                                        !usuarioBuscouPlaca
                                                    }
                                                    onChange={(
                                                        _,
                                                        cidadeIbge5
                                                    ) => {
                                                        props.field.onChange(
                                                            cidadeIbge5
                                                        );
                                                    }}
                                                    fullWidth
                                                    error={!!errors.cidadeIbge5}
                                                    helperText={
                                                        errors.cidadeIbge5
                                                            ? (
                                                                  errors.cidadeIbge5 as any
                                                              ).message
                                                            : 'Pesquise pelo nome da cidade (min 3 letras)'
                                                    }
                                                    data-id="selectProduto"
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            {watchDocument5 && watchDocument5.length === 18 && (
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        lg={5}
                                        style={{ marginTop: '16px' }}
                                    >
                                        <FormControl
                                            disabled={!usuarioBuscouPlaca}
                                        >
                                            <FormLabel id="demo-row-radio-buttons-group-label">
                                                Regime tributário do Locatário
                                            </FormLabel>
                                            <RadioGroup
                                                row
                                                onChange={(
                                                    event: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    setRegimeTributarioLocatario(
                                                        event.target.value
                                                    );
                                                }}
                                                value={
                                                    regimeTributarioLocatario
                                                }
                                            >
                                                <FormControlLabel
                                                    value="N"
                                                    control={<Radio />}
                                                    label="Normal"
                                                />
                                                <FormControlLabel
                                                    value="S"
                                                    control={<Radio />}
                                                    label="Simples Nacional"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            )}

                            <Button
                                type="button"
                                disabled={!usuarioBuscouPlaca}
                                variant="contained"
                                onClick={() => {
                                    setTabValue('2');
                                }}
                                style={stylesTabMotorista().button}
                                size="large"
                            >
                                Continuar
                            </Button>
                        </TabPanel>
                        <TabPanel value="2">
                            <TabelaCarreta
                                disabled={false}
                                placaJaAdicionada={watchPlate}
                                veiculosForm={veiculos}
                                veiculosAlterados={(veiculos) => {
                                    setVeiculos(veiculos);
                                }}
                                documentoProprietarioSugestao={
                                    situacaoMotorista ===
                                    TipoMotorista.ProprietarioConjunto
                                        ? documentMotorista
                                        : undefined
                                }
                            />

                            <Button
                                type="button"
                                disabled={!usuarioBuscouPlaca}
                                variant="contained"
                                style={stylesTabMotorista().button}
                                size="large"
                                onClick={() => {
                                    setIsUsuarioClicouSalvar(true);
                                    if (veiculos.length > 0) {
                                        handleSubmit(tratarDados)();
                                    } else {
                                        setMostrarModalConfirmarSalvarSemCarretas(
                                            true
                                        );
                                    }
                                }}
                            >
                                Salvar
                            </Button>
                        </TabPanel>
                    </TabContext>
                    <Dialog
                        open={mostrarModalConfirmarSalvarSemCarretas}
                        onClose={() => {
                            setMostrarModalConfirmarSalvarSemCarretas(false);
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <DialogContentText
                                id="alert-dialog-description"
                                color={'#000'}
                            >
                                Não foi informado carretas no conjunto, deseja
                                gravar mesmo assim?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    setMostrarModalConfirmarSalvarSemCarretas(
                                        false
                                    );
                                    handleSubmit(tratarDados)();
                                }}
                            >
                                Sim
                            </Button>
                            <Button
                                onClick={() => {
                                    setMostrarModalConfirmarSalvarSemCarretas(
                                        false
                                    );
                                }}
                            >
                                Não
                            </Button>
                        </DialogActions>
                    </Dialog>
                </form>
            </Box>
        </SpinnerLoading>
    );
}
