import { StandardTextFieldProps, TextField } from '@mui/material';
import InputMask from 'react-input-mask';

// type Props = StandardTextFieldProps;

interface Props extends StandardTextFieldProps {
    maxLength: number;
}

export default function InputNumero({ label = 'Ano', ...props }: Props) {
    const {
        onBlur,
        value,
        onChange,
        disabled,
        inputRef,
        maxLength,
        ...outrasPropriedades
    } = props;

    return (
        <InputMask
            mask={Array.from({ length: maxLength }, () => '9').join('')}
            maskPlaceholder={null}
            onChange={onChange}
            onBlur={onBlur}
            value={(value as string) || ''}
            disabled={disabled}
        >
            <TextField
                label={label}
                inputProps={{ autoComplete: 'off' }}
                {...outrasPropriedades}
                inputRef={inputRef}
                fullWidth
            />
        </InputMask>
    );
}
