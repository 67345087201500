import AutorenewIcon from '@mui/icons-material/Autorenew';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Tooltip,
} from '@mui/material';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import GenericBasicTable, {
    ComplexColumn,
} from '../../Components/Table/GenericBasicTable';
import { ApiResources } from '../../Models/Api';
import PerfilUsuario from '../../Models/PerfilUsuario';
import { StatusRequisicao } from '../../Models/Usuario';
import useGenericService from '../../hooks/useGenericService';
import { styles } from './helpers/Styles';

interface Props {
    aberto: boolean;
    fecharClicado: () => void;
    restaurarClicado: () => void;
}

export default function ModalPerfilUsuarioExcluido({
    aberto,
    fecharClicado,
    restaurarClicado,
}: Props) {
    const [perfisUsuarios, setPerfisUsuarios] = useState<PerfilUsuario[]>();
    const { enqueueSnackbar } = useSnackbar();

    const { getService } = useGenericService();

    useEffect(() => {
        const buscarPerfilUsuariosDesativados = async () => {
            const servico = getService(ApiResources.PerfilUsuario);

            try {
                const resposta = await servico.api.get('/desativadas');
                if (resposta.status === StatusRequisicao.OK) {
                    setPerfisUsuarios(resposta?.data?.content);
                }
            } catch (error) {}
        };

        buscarPerfilUsuariosDesativados();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aberto]);

    const restaurarPerfilUsuario = async (PerfilUsuarioId: number) => {
        const servico = getService(ApiResources.PerfilUsuario);

        try {
            const resposta = await servico.api.put(
                '/restaurar/' + PerfilUsuarioId,
                {}
            );

            if (resposta.status === StatusRequisicao.OK) {
                enqueueSnackbar('PerfilUsuario restaurado com sucesso', {
                    variant: 'success',
                });
                restaurarClicado();
            }
        } catch (error) {}
    };

    const colunas: ComplexColumn[] = [
        {
            attribute: 'id',
            label: 'ID',
            id: 'id',
            align: 'left',
            width: 50,
        },
        {
            attribute: 'nome',
            label: 'Nome',
            id: 'nome',
            align: 'left',
            width: 50,
        },
        {
            attribute: 'dataHoraDeletado',
            label: 'Data da exclusão',
            id: 'dataHoraDeletado',
            align: 'left',
            width: 150,
            format: (row) =>
                format(new Date(row.dataHoraDeletado), 'dd/MM/Y').toString(),
        },
        {
            attribute: 'nome',
            label: 'Ações',
            id: 'acao',
            width: 50,
            format: (row) => {
                return (
                    <>
                        <Tooltip
                            title="Restaurar"
                            placement="top"
                            disableInteractive
                        >
                            <IconButton
                                aria-label="delete"
                                onClick={() => {
                                    restaurarPerfilUsuario(row.id);
                                }}
                            >
                                <AutorenewIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    return (
        <Dialog
            key={'perfisUsuariosExcluidos'}
            open={aberto}
            onClose={() => fecharClicado()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
        >
            <DialogTitle id="alert-dialog-title">
                Perfis de Usuários excluídos
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} style={styles().grid}>
                    <GenericBasicTable
                        columns={colunas}
                        rows={perfisUsuarios}
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => fecharClicado()} autoFocus>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
