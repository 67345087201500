import { RevisionType } from '../../../Models/Auditoria';
import { StyleSheet } from '../../../StyleSheet';

const getBorderColorRevisionType = (revisionType: any) => {
    switch (revisionType) {
        case RevisionType.Adicionado:
            return '#3AB4F2';
        case RevisionType.Modificado:
            return '#FEB139';
        case RevisionType.Deletado:
            return '#D61C4E';
    }
};

export const styles = (props?: any) => {
    return StyleSheet.create({
        error: { color: '#D94C4C' },
        button: {
            marginTop: 25,
            marginBottom: 15,
            backgroundColor: '#465f75',
        },
        cardStyle: {
            marginTop: 2,
            width: props ? 280 : 500,
        },
        alert: {
            marginTop: 15,
            borderColor: getBorderColorRevisionType(props),
        },
        divCenter: {
            alignItems: 'center',
        },
        listStyle: {
            width: '100%',
            maxWidth: 360,
            bgcolor: 'background.paper',
        },
        listItemText: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
        },
        arrowForwardIcon: {
            marginLeft: 10,
            marginRight: 10,
        },
    });
};
