import ArticleIcon from '@mui/icons-material/Article';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Container, Divider, IconButton, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { PermissionGate } from '../../Components/Navegacao/PermissionGate';
import PageTitle from '../../Components/PageTitle/PageTitle';
import GenericComplexTable, {
    ComplexColumn,
} from '../../Components/Table/GenericComplexTable';
import { ApiResources, FrontEndPathResources } from '../../Models/Api';
import ClienteFornecedor from '../../Models/ClienteFornecedor';
import Usuario, {
    ChavesArmazenamentoStorage,
    RecursosPerfisEnum,
    StatusRequisicao,
} from '../../Models/Usuario';
import LoginResponse from '../../Models/responses/LoginResponse';
import useGenericService from '../../hooks/useGenericService';
import { usePermission } from '../../hooks/usePermission';
import ModalClienteFornecedorExcluido from './ModalClienteFornecedorExcluido';
import ModalHistoricoAlteracoes from './ModalHistoricoAlteracoes';

export default function Listar() {
    const [cliforMostrarAuditoria, setCliforMostrarAuditoria] =
        useState<ClienteFornecedor>();

    const [mostrarModalAuditoria, setMostrarModalAuditoria] =
        useState<boolean>(false);

    const [mostrarModalItensExcluidos, setMostrarModalItensExcluidos] =
        useState<boolean>(false);
    const navigate = useNavigate();

    const { isAllowed: isPermitidoExcluir } = usePermission(
        RecursosPerfisEnum.CLIENTES_FORNECEDORES_EXCLUIR
    );

    const [clifors, setClifors] = useState<ClienteFornecedor[]>();

    const { getService } = useGenericService();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        let loginResponse = localStorage.getItem(
            ChavesArmazenamentoStorage.LOGIN_RESPONSE
        );

        if (loginResponse) {
            const usuario: Usuario = (
                JSON.parse(loginResponse) as LoginResponse
            ).usuario;

            if (usuario.trocarSenha) {
                window.location.pathname = FrontEndPathResources.AlterarSenha;
            }
        }
    }, []);

    const desativarClienteFornecedor = async (url: string) => {
        const servico = getService(ApiResources.ClienteFornecedor);

        try {
            const resposta = await servico.api.delete(url);

            if (resposta.status === StatusRequisicao.OK) {
                enqueueSnackbar('Cliente/Fornecedor desativado com sucesso', {
                    variant: 'success',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
                navigate(FrontEndPathResources.ClienteFornecedor);
                carregar();
            }
        } catch (error) {}
    };

    const carregar = async () => {
        const servico = getService(ApiResources.ClienteFornecedor);

        const resposta = await servico.api.get('');

        setClifors(resposta?.data?.content);
    };

    const colunas: ComplexColumn[] = [
        {
            attribute: 'id',
            label: 'ID',
            id: 'id',
            sortable: true,
        },
        {
            attribute: 'nome',
            label: 'Nome',
            id: 'nome',
            sortable: true,
        },
        {
            attribute: 'cnpj',
            label: 'CNPJ/CPF',
            id: 'cnpj',
            width: 200,
            sortable: false,
            format: (value: any) =>
                value.cnpj
                    ? value.cnpj.replace(
                          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                          '$1.$2.$3/$4.$5'
                      )
                    : value.cpf.replace(
                          /(\d{3})(\d{3})(\d{3})(\d{2})/,
                          '$1.$2.$3-$4'
                      ),
        },
        {
            attribute: 'id',
            label: 'Ações',
            id: 'acao',
            width: 200,
            format: (row) => {
                return (
                    <>
                        <Tooltip title="Ver" placement="top" disableInteractive>
                            <IconButton
                                aria-label="view"
                                onClick={() => {
                                    navigate(
                                        FrontEndPathResources.ClienteFornecedor +
                                            '/ver/' +
                                            row.id
                                    );
                                }}
                            >
                                <RemoveRedEyeIcon />
                            </IconButton>
                        </Tooltip>
                        <PermissionGate
                            recurso={
                                RecursosPerfisEnum.CLIENTES_FORNECEDORES_ALTERAR
                            }
                            redirect={''}
                        >
                            <Tooltip
                                title="Alterar"
                                placement="top"
                                disableInteractive
                            >
                                <IconButton
                                    aria-label="edit"
                                    onClick={() => {
                                        navigate(
                                            FrontEndPathResources.ClienteFornecedor +
                                                '/alterar/' +
                                                row.id
                                        );
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </PermissionGate>
                        <PermissionGate
                            recurso={
                                RecursosPerfisEnum.CLIENTES_FORNECEDORES_EXCLUIR
                            }
                            redirect={''}
                        >
                            <Tooltip
                                title="Desativar"
                                placement="top"
                                disableInteractive
                            >
                                <IconButton
                                    aria-label="delete"
                                    onClick={() => {
                                        desativarClienteFornecedor(
                                            '/' + row.id
                                        );
                                    }}
                                >
                                    <CancelIcon />
                                </IconButton>
                            </Tooltip>
                        </PermissionGate>
                        <PermissionGate
                            recurso={
                                RecursosPerfisEnum.CLIENTES_FORNECEDORES_CONSULTAR_HISTORICO
                            }
                            redirect={''}
                        >
                            <Tooltip
                                title="Histórico de alterações"
                                placement="top"
                                disableInteractive
                            >
                                <IconButton
                                    aria-label="ver"
                                    onClick={() => {
                                        setCliforMostrarAuditoria(
                                            row as ClienteFornecedor
                                        );
                                        setMostrarModalAuditoria(true);
                                    }}
                                >
                                    <ArticleIcon />
                                </IconButton>
                            </Tooltip>
                        </PermissionGate>
                    </>
                );
            },
        },
    ];

    return (
        <PermissionGate
            recurso={RecursosPerfisEnum.CLIENTES_FORNECEDORES_VER}
            redirect={FrontEndPathResources.Dashboard}
        >
            <Container>
                <PageTitle title="Clientes/Fornecedores" />
                <Divider />

                <ModalClienteFornecedorExcluido
                    aberto={mostrarModalItensExcluidos}
                    fecharClicado={() => {
                        setMostrarModalItensExcluidos(false);
                    }}
                    restaurarClicado={() => {
                        carregar();
                        setMostrarModalItensExcluidos(false);
                    }}
                />

                <PermissionGate
                    recurso={
                        RecursosPerfisEnum.CLIENTES_FORNECEDORES_CONSULTAR_HISTORICO
                    }
                    redirect={''}
                >
                    {mostrarModalAuditoria && cliforMostrarAuditoria && (
                        <ModalHistoricoAlteracoes
                            key={'historico_alteracoes'}
                            clifor={cliforMostrarAuditoria}
                            aberto={mostrarModalAuditoria}
                            fecharClicado={() => {
                                setMostrarModalAuditoria(false);
                            }}
                        />
                    )}
                </PermissionGate>

                <GenericComplexTable
                    service={getService(ApiResources.ClienteFornecedor)}
                    rows={clifors}
                    columnToSearch={'nome'}
                    columns={colunas}
                    createButtonText="Cadastrar novo"
                    linkCreateButtonText={
                        FrontEndPathResources.ClienteFornecedor + '/criar'
                    }
                    mostrarIconeItensExcluidos={isPermitidoExcluir}
                    onIconeItensExcluidosClicado={() => {
                        setMostrarModalItensExcluidos(true);
                    }}
                />
            </Container>
        </PermissionGate>
    );
}
