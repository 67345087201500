import { IBaseUseCase } from '../../../../types/interfaces/IBaseUseCase';
import { IOrdemColetaValidacaoDescarga } from '../dtos/IOrdemColetaValidacaoDescarga';
import { IOrdemColetaRepository } from '../repositories/IOrdemColetaRepository';

export class BuscarValidacaoDescargaPorIdOrdemColeta
    implements IBaseUseCase<number, IOrdemColetaValidacaoDescarga>
{
    constructor(private readonly repository: IOrdemColetaRepository) {}

    execute(id: number): Promise<any> {
        return this.repository.buscarValidacaoDescargaPorIdOrdemColeta(id);
    }
}
