import { Card, CardContent, Typography } from '@mui/material';
import { format, isDate } from 'date-fns';
import { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import SpinnerLoading from '../../../Components/SpinnerLoading/SpinnerLoading';
import Util from '../../../Helpers/Util';
import useGenericService from '../../../hooks/useGenericService';
import { ApiResources } from '../../../Models/Api';
import EmbarquePorClassificador from '../../../Models/relatorios/EmbarquePorClassificador';
import { styles } from '../helpers/Styles';
import { DataGrafico } from '../Home';

interface Props {
    dataInicio: Date;
    dataFim: Date;
    fazerRequisicao?: boolean;
}

export default function RelatorioEmbarquesPorClassificador({
    dataInicio,
    dataFim,
    fazerRequisicao = true,
}: Props) {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [embarquesPorClassificador, setEmbarquesPorClassificador] =
        useState<EmbarquePorClassificador[]>();

    const [
        dadosGraficoEmbarquesPorClassificador,
        setDadosGraficoEmbarquesPorClassificador,
    ] = useState<DataGrafico>();

    const { getService } = useGenericService();

    useEffect(() => {
        setIsLoading(true);
        const buscarEmbarquesPorClassificador = async () => {
            const servico = getService(ApiResources.Relatorio);

            const resposta = await servico.api.get(
                '/quantidade-embarques-classificadores',
                {
                    params: {
                        dataInicio: format(dataInicio, 'Y-MM-dd').toString(),
                        dataFim: format(dataFim, 'Y-MM-dd').toString(),
                    },
                }
            );

            setEmbarquesPorClassificador(resposta.data);
            setIsLoading(false);
        };

        if (isDate(dataInicio) && isDate(dataFim) && fazerRequisicao) {
            buscarEmbarquesPorClassificador();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataFim, dataInicio, fazerRequisicao]);

    useEffect(() => {
        setIsLoading(true);
        if (embarquesPorClassificador) {
            setDadosGraficoEmbarquesPorClassificador({
                labels: embarquesPorClassificador.map(
                    (epc) => epc.classificador.nome
                ),
                datasets: [
                    {
                        label: '# of Votes',
                        data: embarquesPorClassificador.map(
                            (epc) => epc.quantidadeEmbarques
                        ),
                        backgroundColor: embarquesPorClassificador.map((epc) =>
                            Util.generateRGBColor()
                        ),
                        borderWidth: 1,
                    },
                ],
            });
        }
        setIsLoading(false);
    }, [embarquesPorClassificador]);

    return (
        <SpinnerLoading isLoading={isLoading}>
            {dadosGraficoEmbarquesPorClassificador && (
                <Card sx={styles.card}>
                    <CardContent>
                        <Typography component="div" variant="h6">
                            Quantidade de embarques por classificador
                        </Typography>
                        <Doughnut
                            data={dadosGraficoEmbarquesPorClassificador}
                            style={styles.doughnut}
                        />
                    </CardContent>
                </Card>
            )}
        </SpinnerLoading>
    );
}
