import { UseAutocompleteProps } from '@mui/base';
import { StandardTextFieldProps } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { UFEstadoArray } from '../../types/IdentificadorProduto';

type InputProps = Pick<
    StandardTextFieldProps,
    | 'margin'
    | 'error'
    | 'helperText'
    | 'fullWidth'
    | 'required'
    | 'inputRef'
    | 'InputLabelProps'
    | 'label'
    | 'placeholder'
    | 'disabled'
    | 'name'
    | 'FormHelperTextProps'
>;

export interface Props
    extends UseAutocompleteProps<any, boolean, boolean, undefined>,
        InputProps {
    label: string;
    disabled?: boolean;
    required?: boolean;
}

export default function SelectUF({
    margin,
    error,
    helperText,
    fullWidth,
    required,
    InputLabelProps,
    inputRef,
    label,
    placeholder,
    name,
    disabled = false,
    ...outrasPropriedades
}: Props) {
    return (
        <Autocomplete
            {...outrasPropriedades}
            fullWidth
            options={UFEstadoArray.map((ue) => ue.sigla)}
            value={(outrasPropriedades as any).field.value}
            multiple={false}
            disabled={disabled}
            filterSelectedOptions
            getOptionLabel={(option) => option}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={name}
                    inputRef={inputRef}
                    label={label}
                    autoComplete="new-password"
                    required={required}
                    margin={margin}
                    disabled={disabled}
                    error={error}
                    helperText={helperText}
                    fullWidth={fullWidth}
                    placeholder={placeholder}
                    InputLabelProps={InputLabelProps}
                />
            )}
        />
    );
}
