import { PermissionGate } from '../../../Components/Navegacao/PermissionGate';
import { FrontEndPathResources } from '../../../Models/Api';
import { RecursosPerfisEnum } from '../../../Models/Usuario';
import { FindLoteTransportadoraById } from '../domain/usecases/FindLoteTransportadoraById';
import { LotesPorTransportadoraRepository } from '../infra/LotesPorTransportadoraRepository';
import Ver from '../pages/Ver';

export const LotesPorTransportadoraVer: React.FC = () => {
    const lotesPorTransportadoraRepository =
        new LotesPorTransportadoraRepository();

    const findLoteTransportadoraById = new FindLoteTransportadoraById(
        lotesPorTransportadoraRepository
    );

    return (
        <PermissionGate
            recurso={RecursosPerfisEnum.LOTE_EMBARQUE_POR_TRANSPORTADORA}
            redirect={FrontEndPathResources.Dashboard}
        >
            <Ver findLoteTransportadoraById={findLoteTransportadoraById} />
        </PermissionGate>
    );
};
