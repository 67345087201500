import { Button, Grid, TextField } from '@mui/material';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { styles } from '../OrdemColetaForm/Styles';
import ModalAnexarTicket from './components/ModalAnexarTicket';

type FormProps = {
    disabled?: boolean;
};

export function EnviarTicketCaminhaoCarregadoInput({
    disabled = false,
}: FormProps) {
    const { control, watch, formState: { errors } } = useFormContext();

    const [mostrarModalAnexarTicket, setMostrarModalAnexarTicket] =
        useState<boolean>(false);

    const ticketName = watch('ticketCaminhaoCarregado.nome');

    const errorMessages = {
    '"ticketCaminhaoCarregado" must be of type object': 'campo obrigatório',
    };

    type ErrorKeysProps = keyof typeof errorMessages;

    const translateError = (errorKey: ErrorKeysProps) => {
        console.log("errorKey")
        console.log(errorKey)
    return errorMessages[errorKey];
    };


    return (
        <Grid container item spacing={2}>
            <Grid item xs={12} lg={3}>
                <Controller
                    name="ticketCaminhaoCarregado"
                    control={control}
                    defaultValue={''}
                    render={({
                        field: { ref, value, ...field },
                        fieldState: { error },
                    }) => (
                        <TextField
                            {...field}
                            margin="normal"
                            disabled={true}
                            fullWidth
                            required
                            value={ticketName}
                            label="Ticket"
                            error={!!errors?.ticketCaminhaoCarregado}
                            helperText={errors?.ticketCaminhaoCarregado ? translateError(errors.ticketCaminhaoCarregado.message) : ''}
                            InputLabelProps={{
                                shrink: !!ticketName,
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} lg={3}>
                <Button
                    onClick={() => {
                        setMostrarModalAnexarTicket(true);
                    }}
                    disabled={disabled}
                    variant="contained"
                    style={styles().button}
                    size="large"
                >
                    Anexar Ticket
                </Button>
            </Grid>
            {mostrarModalAnexarTicket && (
                <>
                    <ModalAnexarTicket
                        open={mostrarModalAnexarTicket}
                        onClose={() => {
                            setMostrarModalAnexarTicket(false);
                        }}
                    />
                </>
            )}
        </Grid>
    );
}
