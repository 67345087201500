import {
    Alert,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import { FunctionComponent, useState } from 'react';
import {
    useFieldArray,
    useFormContext,
} from 'react-hook-form';
import Util, { removeMoedaSpecialChars } from '../../../Helpers/Util';
import { IDoubleCheck } from '../entities/IDoubleCheck';
import { EnumTransgeniaSodru } from '../entities/INotaComContratos';
import InputKGModalContratos from './InputKGModalContratos';


interface ContratosModalProps {
    onClose: () => void;
    open: boolean;
    nestedIndex: number;
}

interface TemporaryValues {
    [index: number]: {
        pesoDeduzirDoContrato: number;
        active: boolean;
        transgenia: EnumTransgeniaSodru;
    };
}

const ContratosModal: FunctionComponent<ContratosModalProps> = ({
    onClose,
    open = false,
    nestedIndex,
}) => {
   
    const { control, setValue, getValues, reset } =
        useFormContext<IDoubleCheck>();
    const [error, setError] = useState<boolean>(false);
    const [errorsPesoEmbarcar, setErrorsPesoEmbarcar] = useState<{message: string, index: number}[]>([]);
    const [temporaryValues, setTemporaryValues] = useState<TemporaryValues>({});
    const { fields } = useFieldArray({
        control,
        name: 'listaContratos',
        keyName: 'idGenerico',
    });

    const translateEnum = (value: EnumTransgeniaSodru) => {
        switch (value) {
            case EnumTransgeniaSodru.CONVENCIONAL:
                return 'Convencional';
            case EnumTransgeniaSodru.COMPRADEARMAZEMPARTICIPANTE:
                return 'Compra de Armazem Participante';
            case EnumTransgeniaSodru.BIOTECNOLOGIACOMPATENTEVALIDA:
                return 'Biotecnologia com Patente Válida';
            case EnumTransgeniaSodru.BIOTECNOLOGICANEGATIVA:
                return 'Biotécnologica Negativa';
            default:
                return 'Convencional';
        }
    };

    const handleChangePesoAEmbarcar = (value: any, index: number, id: number) => {
        errorsPesoEmbarcar.find((error) => error.index === index) && setErrorsPesoEmbarcar(errorsPesoEmbarcar.filter((error) => error.index !== index));
        let valueFormatado = Number(removeMoedaSpecialChars(value));
    
        const max = getValues('pesagem.pesoLiquido');
        if (max && valueFormatado > max) {
            value = Util.formatNumberWithKG(max)
            setErrorsPesoEmbarcar([...errorsPesoEmbarcar, {message: 'Peso a embarcar máximo é ' + value + ' KG', index}]);
        } 
        setTemporaryValues((prevValues) => ({
            ...prevValues,
            [id]: {
                ...prevValues[id],
                pesoDeduzirDoContrato: value,
            },
        }));
    };

    const handleChangeActive = (e: any, id: number) => {
        setTemporaryValues((prevValues) => ({
            ...prevValues,
            [id]: {
                ...prevValues[id],
                active: e.target.checked,
            },
        }));
    };

    const handleChangeTransgenia = (e: any, id: number) => {
        setTemporaryValues((prevValues) => ({
            ...prevValues,
            [id]: {
                ...prevValues[id],
                transgenia: e.target.value as EnumTransgeniaSodru,
            },
        }));
    };

    const getContractsSelected = () => {
        const contractsSelected = getValues('listaContratos').filter(
            (contrato) => {
                return temporaryValues[contrato.id]?.active;
            }
        );
        return contractsSelected;
    };

    const getContractsUpdatedWithTemporaryValues = () => {
        const contractsUpdated = getContractsSelected().map((contrato) => {
            const temporaryValue = temporaryValues[contrato.id];
            if (temporaryValue) {
                return {
                    ...contrato,
                    active: temporaryValue.active,
                    pesoDeduzirDoContrato: temporaryValue.pesoDeduzirDoContrato ? removeMoedaSpecialChars(temporaryValue.pesoDeduzirDoContrato) : pesoAEmbarcarDefault(),
                    transgenia: temporaryValue.transgenia ? temporaryValue.transgenia : contrato.transgenia,
                };
            }
            return contrato;
        });

        return contractsUpdated;
    };

    const handleConclusionSelection = (nestedIndex: number) => {
        const selectedValues = getContractsUpdatedWithTemporaryValues();
        const oldSelectedContracts = getValues(
            `notas.${nestedIndex}.contratos`
        );
        let newSelectedContracts;
        if (oldSelectedContracts) {
            newSelectedContracts = oldSelectedContracts.concat(selectedValues);
        } else {
            newSelectedContracts = selectedValues;
        }

        if (newSelectedContracts.some((value) => value.transgenia == null)) {
            setError(true);
            return;
        } else {
            setError(false);
        }
        setValue(`notas.${nestedIndex}.contratos`, newSelectedContracts);
        reset({ ...getValues() });
        onClose();
    };

    const pesoAEmbarcarDefault = () => {
        // Caso tenha só um contrato e uma nota
        if (getValues('notas').length == 1 && getValues('listaContratos').length == 1) {
            return getValues('pesagem.pesoLiquido')
        } else {
            return 0
        }
    };

    return (
        <Dialog
            open={open}
            onClose={() => onClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
        >
            <DialogTitle id="alert-dialog-title">Contratos</DialogTitle>
            <DialogContent>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Vincular</TableCell>
                                <TableCell>Contrato</TableCell>
                                <TableCell>Clifor Maxys</TableCell>
                                <TableCell>Município</TableCell>
                                <TableCell>Inscrição Estadual</TableCell>
                                <TableCell>Tipo Contrato</TableCell>
                                <TableCell>Peso Contrato + Excedido</TableCell>
                                <TableCell>Volume Vinculado</TableCell>
                                <TableCell>Saldo a Embarcar</TableCell>
                                <TableCell>Peso Embarcado</TableCell>
                                <TableCell>Peso A Embarcar</TableCell>
                                <TableCell>Transgenia</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {fields.map((contrato, index) => {
                                const errorMessagePesoEmbarcar = errorsPesoEmbarcar.find((error) => error.index === index)?.message;
                                const selectedContracts = getValues(
                                    `notas.${nestedIndex}.contratos`
                                );

                                if (
                                    selectedContracts === null ||
                                    (selectedContracts &&
                                        !selectedContracts.some(
                                            (sc) => sc.id === contrato.id
                                        ))
                                )
                                    return (
                                        <TableRow key={contrato.id}>
                                            <TableCell>
                                                <Checkbox
                                                    checked={
                                                        temporaryValues[
                                                            contrato.id
                                                        ]?.active || false
                                                    }
                                                    defaultChecked={
                                                        contrato.active
                                                    }
                                                    onChange={(e) => {
                                                        handleChangeActive(
                                                            e,
                                                            contrato.id
                                                        );
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                {contrato.numeroContrato}
                                            </TableCell>
                                            <TableCell>
                                                {`${contrato.codigoCliforMaxys} - ${contrato.nomeCliforMaxys}`}
                                            </TableCell>
                                            <TableCell>
                                                {contrato.municipioNome}
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    contrato.inscricaoEstadualMaxys
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {contrato.tipoContrato}
                                            </TableCell>
                                            <TableCell>
                                                {contrato.pesoContratoExcedido}
                                            </TableCell>
                                            <TableCell>
                                                {contrato.volumeEmbarcado}
                                            </TableCell>
                                            <TableCell>
                                                {contrato.pesoSaldoembr}
                                            </TableCell>
                                            <TableCell>
                                                {contrato.pesoEmbarcado}
                                            </TableCell>
                                            <TableCell>
                                                <InputKGModalContratos                                                        
                                                    margin="normal"                                
                                                    required                    
                                                    fullWidth                        
                                                    style={{ minWidth: 100 }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={
                                                        temporaryValues[
                                                            contrato.id
                                                        ]?.pesoDeduzirDoContrato
                                                    }
                                                    defaultValue={pesoAEmbarcarDefault()}
                                                    onChange={(event) =>
                                                        handleChangePesoAEmbarcar(
                                                            event.target.value,
                                                            index,
                                                            contrato.id
                                                        )
                                                    }
                                                    error={!!errorMessagePesoEmbarcar}
                                                    helperText={
                                                        errorMessagePesoEmbarcar? errorMessagePesoEmbarcar : ''
                                                    }
                                                />
                                               
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    defaultValue={
                                                        contrato.transgenia
                                                    }
                                                    value={
                                                        temporaryValues[
                                                            contrato.id
                                                        ]?.transgenia
                                                    }
                                                    onChange={(e) => {
                                                        handleChangeTransgenia(
                                                            e,
                                                            contrato.id
                                                        );
                                                    }}
                                                    required
                                                    style={{ minWidth: 300 }}
                                                    disabled={
                                                        !getValues(
                                                            `listaContratos.${index}.permiteEditarTransgenia`
                                                        )
                                                    }
                                                >
                                                    {Object.values(
                                                        EnumTransgeniaSodru
                                                    ).map((value) => (
                                                        <MenuItem
                                                            key={value}
                                                            value={value}
                                                        >
                                                            {translateEnum(
                                                                value
                                                            )}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </TableCell>
                                        </TableRow>
                                    );
                                // }
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {error && (
                    <Alert severity="error">
                        Ao selecionar um contrato informar o peso a embarcar e
                        selecionar a Transgenia
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        handleConclusionSelection(nestedIndex);
                    }}
                    autoFocus
                >
                    Salvar
                </Button>
                <Button onClick={() => onClose()} autoFocus>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ContratosModal;
