import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    TextField,
} from '@mui/material';
import { useState } from 'react';
import OrdemCarregamento from '../../Models/OrdemCarregamento';

interface Props {
    ordem: OrdemCarregamento;
    fecharClicado: () => void;
    gravarClicado: (pesoPlanejado: number, ordemId: number) => void;
    aberto: boolean;
}

export default function ModalEditarPesoPlanejado({
    fecharClicado,
    gravarClicado,
    aberto = false,
    ordem,
}: Props) {
    const [pesoPlanejado, setPesoPlanejado] = useState<string>();

    const [mensagemErro, setMensagemErro] = useState<string>();

    const getTotalEmbarcado = (ordem: OrdemCarregamento): number => {
        let totalEmbarcado = 0;
        if (ordem.embarques && ordem.embarques.length > 0) {
            totalEmbarcado = ordem.embarques
                .map((oc) => {
                    return Math.abs(
                        oc.pesagem.pesoSaida - oc.pesagem.pesoEntrada
                    );
                })
                .reduce((a, b) => a + b, 0);
        }

        return totalEmbarcado;
    };

    const tratarPesoAlterado = () => {
        setMensagemErro(undefined);

        const totalEmbarcado = getTotalEmbarcado(ordem);

        if (pesoPlanejado) {
            if (totalEmbarcado > Number(pesoPlanejado)) {
                setMensagemErro(
                    'O peso deve ser maior ou igual a ' + totalEmbarcado + ' KG'
                );
                return;
            }
        } else {
            setMensagemErro('O peso precisa ser informado');
            return;
        }

        gravarClicado(Number(pesoPlanejado), ordem.id);
    };

    return (
        <Dialog
            open={aberto}
            onClose={() => fecharClicado()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Alterando o peso planejado da ordem {ordem.id}.
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Alert variant="outlined" severity="warning">
                        Total já embarcado: <b>{getTotalEmbarcado(ordem)} KG</b>
                    </Alert>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    label={'Peso planejado'}
                                    onChange={(
                                        event: React.ChangeEvent<
                                            | HTMLTextAreaElement
                                            | HTMLInputElement
                                        >
                                    ) => {
                                        setPesoPlanejado(
                                            event.currentTarget.value.replace(
                                                /[^.0-9]/g,
                                                ''
                                            )
                                        );
                                    }}
                                    InputProps={{
                                        endAdornment: 'KG',
                                    }}
                                    error={!!mensagemErro}
                                    helperText={
                                        mensagemErro
                                            ? mensagemErro
                                            : 'O peso não pode ser menor do que o total já embarcado'
                                    }
                                    data-id="campoPesoPlanejado"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        tratarPesoAlterado();
                    }}
                    autoFocus
                >
                    Gravar
                </Button>
                <Button onClick={() => fecharClicado()} autoFocus>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
