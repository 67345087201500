import Api from './Api';
import ConfigService from './services/ConfigService';

type Params = {
    url: string;
    identificador?: string;
    api?: Api;
};

export type KeyValueQueryParams = {
    key: string;
    value: string;
};

export type FilterParam = {
    attribute: string;
    value: string;
};

export default abstract class Service {
    api: ConfigService;

    constructor(public host: string) {
        this.api = new ConfigService(host, true);
    }

    getAllWithParams(params: FilterParam[]) {
        return this.api.get('', {
            params: this.traduzirParametrosUrl(params),
        });
    }

    traduzirParametrosUrl(parametros: FilterParam[]): any {
        let obj: any = {};

        parametros.forEach((parametro) => {
            obj = { ...obj, [parametro.attribute]: parametro.value };
        });
        return obj;
    }
}
