import { IBaseUseCase } from '../../../../types/interfaces/IBaseUseCase';
import { IFindJuncaoByFilterDto } from '../dtos/IFindJuncaoByFilterDtoRequest';
import { IFindJuncaoByFilterDtoResponse } from '../dtos/IFindJuncaoByFilterDtoResponse';

import { IOrdemColetaRepository } from '../repositories/IOrdemColetaRepository';

export class FindJuncaoByFilter
    implements
        IBaseUseCase<IFindJuncaoByFilterDto, IFindJuncaoByFilterDtoResponse>
{
    constructor(private readonly repository: IOrdemColetaRepository) {}

    execute(
        filter: IFindJuncaoByFilterDto
    ): Promise<IFindJuncaoByFilterDtoResponse> {
        return this.repository.findJuncaoByFilter(filter);
    }
}
