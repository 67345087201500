import { Button, Grid, IconButton, Tooltip } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { ComplexColumn } from '../../../Components/Table/GenericComplexTable';
import GenericBasicTable from '../../../Components/Table/GenericBasicTable';
import { styles } from '../../ClienteFornecedor/helpers/Styles';
import { Vehicle, VehicleForm } from '../types/Juncao';
import ModalCarreta from './ModalCarreta';
import { useSnackbar } from 'notistack';

interface Props {
    veiculosForm?: VehicleForm[];
    disabled?: boolean;
    documentoProprietarioSugestao?: string;
    placaJaAdicionada: string;
    veiculosAlterados: (referencias: VehicleForm[]) => void;
}

export default function TabelaCarreta({
    veiculosForm,
    disabled = false,
    documentoProprietarioSugestao,
    veiculosAlterados,
    placaJaAdicionada,
}: Props) {
    const [veiculoAlterar, setVeiculoAlterar] = useState<VehicleForm>();

    const [mostrarModalCarreta, setMostrarModalCarreta] =
        useState<boolean>(false);

    const [veiculos, setVeiculos] = useState<VehicleForm[]>([]);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        if (veiculosForm && veiculosForm.length > 0) {
            setVeiculos(veiculosForm);
        }
    }, [veiculosForm]);

    const colunas: ComplexColumn[] = [
        {
            attribute: 'Plate',
            label: 'Placa',
            id: 'Plate',
        },
        {
            attribute: 'Name',
            label: 'Nome do proprietário',
            id: 'phone',
        },
        {
            attribute: 'Name',
            label: 'Marca',
            id: 'phone',
            format: (row: any) => {
                return row.DeviceBrand.DeviceBrandDescription;
            },
        },
        {
            attribute: 'Tipo',
            label: 'Tipo',
            id: 'phone',
            format: (row: any) => {
                return row.DeviceType.DeviceTypeDescription;
            },
        },
        {
            attribute: 'Modelo',
            label: 'Modelo',
            id: 'phone',
            format: (row: any) => {
                return row.DeviceModel.DeviceModelDescription;
            },
        },
        {
            attribute: 'Year',
            label: 'Ano',
            id: 'phone',
        },
        {
            attribute: 'id',
            label: 'Ações',
            id: 'acao',
            width: 200,
            format: (row: any) => {
                return (
                    <>
                        <Tooltip
                            title="Alterar"
                            placement="top"
                            disableInteractive
                        >
                            <IconButton
                                disabled={disabled}
                                aria-label="alterar"
                                onClick={() => {
                                    setVeiculoAlterar(row as VehicleForm);
                                    setMostrarModalCarreta(true);
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title="Desativar"
                            placement="top"
                            disableInteractive
                        >
                            <IconButton
                                disabled={disabled}
                                aria-label="delete"
                                onClick={() => {
                                    removerVeiculo(row as Vehicle);
                                }}
                            >
                                <CancelIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    const adicionarVehiculo = (veiculo: VehicleForm) => {
        if (veiculoAlterar) {
            let veiculosAtualizados = veiculos.filter(
                (e) => e.Plate !== veiculoAlterar.Plate
            );

            setVeiculos([...veiculosAtualizados, veiculo]);

            setVeiculoAlterar(undefined);
        } else {
            let isRepetido = false;

            veiculos.forEach((r) => {
                if (r.Plate.toUpperCase() === veiculo.Plate.toUpperCase()) {
                    isRepetido = true;
                }
            });

            if (!isRepetido) {
                if (
                    placaJaAdicionada.toUpperCase() ===
                    veiculo.Plate.toUpperCase()
                ) {
                    enqueueSnackbar('Essa placa está adicionada no cavalo.', {
                        variant: 'info',
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
                } else {
                    setVeiculos([...veiculos, veiculo]);
                }
            } else {
                enqueueSnackbar('Carreta já foi adicionada.', {
                    variant: 'info',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
            }
        }
    };

    const removerVeiculo = (veiculo: Vehicle) => {
        let veiculosAtualizados = veiculos.filter(
            (e) => e.Plate !== veiculo.Plate
        );

        setVeiculos(veiculosAtualizados);
    };

    useEffect(() => {
        veiculosAlterados(veiculos);
    }, [veiculos, veiculosAlterados]);

    return (
        <>
            {mostrarModalCarreta && (
                <ModalCarreta
                    aberto={mostrarModalCarreta}
                    documentoProprietarioSugestao={
                        documentoProprietarioSugestao
                    }
                    placaJaAdicionada={placaJaAdicionada}
                    carreta={veiculoAlterar}
                    fecharClicado={() => {
                        setMostrarModalCarreta(false);
                        setVeiculoAlterar(undefined);
                    }}
                    gravarClicado={(veiculo: VehicleForm) => {
                        setMostrarModalCarreta(false);
                        adicionarVehiculo(veiculo);
                    }}
                />
            )}
            <Button
                variant="outlined"
                startIcon={<AddIcon />}
                disabled={veiculos.length === 3}
                onClick={() => setMostrarModalCarreta(true)}
            >
                Adicionar Carreta
            </Button>
            <Grid container spacing={2} style={styles().grid}>
                <Grid item xs={12} lg={12}>
                    <GenericBasicTable columns={colunas} rows={veiculos} />
                </Grid>
            </Grid>
        </>
    );
}
