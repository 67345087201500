import GenericService from '../Config/services/GenericService';
import { ApiResources } from '../Models/Api';
import Server from '../Models/Server';
import { ChavesArmazenamentoStorage } from '../Models/Usuario';

export default function useGenericService() {
    const getService = (resource: ApiResources) => {
        const server = localStorage.getItem(ChavesArmazenamentoStorage.HOST);

        const servico = new GenericService(
            (JSON.parse(server!) as Server).host +
                ':' +
                (JSON.parse(server!) as Server).port +
                resource
        );

        return servico;
    };

    return {
        getService,
    };
}
