import { IBaseUseCase } from '../../../../types/interfaces/IBaseUseCase';
import { IOrdemColetaRepository } from '../repositories/IOrdemColetaRepository';

export class GetSaldoLoteEmbarqueTransportadoraById
    implements IBaseUseCase<number, number>
{
    constructor(private readonly repository: IOrdemColetaRepository) {}

    execute(id: number): Promise<number> {
        return this.repository.getSaldoLoteEmbarqueTransportadoraById(id);
    }
}
