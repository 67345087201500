import { joiResolver } from '@hookform/resolvers/joi';
import {
    Button,
    Checkbox,
    Container,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    TextField,
} from '@mui/material';
import Joi from 'joi';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import SelectAmostra from '../../Components/Select/SelectAmostra';
import Amostra from '../../Models/Amostra';
import { FrontEndPathResources } from '../../Models/Api';
import Produto from '../../Models/Produto';
import { styles } from './helpers/Styles';

export type CamposFormulario = {
    nome: string;
    amostras: Amostra[];
};

export interface CamposFormularioTratados
    extends Omit<CamposFormulario, 'amostras'> {
    amostras: Amostra[];
    transgenico: boolean;
}

interface Props {
    disabled?: boolean;
    produto?: Produto;
    onSubmit?: (dadosFormulario: CamposFormularioTratados) => {};
}

export default function Formulario({
    disabled = false,
    produto,
    onSubmit,
}: Props) {
    const [produtoTransgenico, setProdutoTransgenico] =
        useState<boolean>(false);

    const navigate = useNavigate();

    const validacao = Joi.object({
        nome: Joi.string().trim().required().min(3).max(50).messages({
            'string.base': 'O nome deve ser uma string',
            'string.empty': 'O nome não pode ficar em branco',
            'any.required': 'O nome não pode ficar em branco',
            'string.min': 'Deve ter no mínimo 3 caracteres',
            'string.max': 'Permitido até 50 caracteres',
        }),
        amostras: Joi.array().required().min(1).messages({
            'array.min': 'Deve ser selecionado ao menos 1 amostra',
        }),
    });

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<CamposFormulario>({
        resolver: joiResolver(validacao),
        criteriaMode: 'all',
    });

    useEffect(() => {
        const preencherFormulario = (produto: Produto) => {
            setProdutoTransgenico(produto.transgenico);

            reset({
                nome: produto.nome,
                amostras: produto.amostras,
            });
        };

        if (produto) {
            preencherFormulario(produto);
        }
    }, [reset, produto]);

    const tratarDados = (dadosFormulario: CamposFormulario) => {
        if (onSubmit) {
            const dadosFormTratados: CamposFormularioTratados = {
                nome: dadosFormulario.nome,
                amostras: dadosFormulario.amostras!,
                transgenico: produtoTransgenico,
            };

            onSubmit(dadosFormTratados);
        }
    };

    return (
        <Container>
            <form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(tratarDados)}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <Controller
                            name="nome"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        required
                                        disabled={disabled}
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                        label="Nome"
                                        autoFocus
                                        error={!!errors.nome}
                                        helperText={
                                            errors.nome
                                                ? errors.nome?.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <FormGroup>
                            <FormControlLabel
                                disabled={disabled}
                                control={
                                    <Checkbox
                                        checked={produtoTransgenico}
                                        onClick={() =>
                                            setProdutoTransgenico(
                                                !produtoTransgenico
                                            )
                                        }
                                    />
                                }
                                label="Produto transgênico"
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
                <Divider textAlign="left" style={styles().divider}>
                    Preferências de classificação
                </Divider>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={10}>
                        <Controller
                            name="amostras"
                            control={control}
                            defaultValue={[]}
                            render={(props) => {
                                return (
                                    <SelectAmostra
                                        {...props}
                                        options={[]}
                                        required
                                        disabled={disabled}
                                        inputRef={props.field.ref}
                                        label="Amostras"
                                        onChange={(_, amostras) =>
                                            props.field.onChange(amostras)
                                        }
                                        fullWidth
                                        multiple
                                        error={!!errors.amostras}
                                        helperText={
                                            errors.amostras
                                                ? (errors.amostras as any)
                                                    .message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} justifyContent="end">
                    {!disabled ? (
                        <Button
                            type="submit"
                            variant="contained"
                            style={styles().button}
                            size="large"
                        >
                            Salvar
                        </Button>
                    ) : (
                        <Button
                            onClick={() => {
                                navigate(
                                    FrontEndPathResources.Produto +
                                    '/alterar/' +
                                    produto?.id
                                );
                            }}
                            variant="contained"
                            style={styles().button}
                            size="large"
                            data-test-id="botaoSalvar"
                        >
                            Alterar
                        </Button>
                    )}
                </Grid>
            </form>
        </Container>
    );
}
