import { IArquivoEncoded } from '../../OrdemColeta/domain/entities/IArquivoEncoded';

export interface ILoteEmbarqueContratos {
    id: number;
    loteEmbarqueId: number;
    numeroContrato: number;
    tipoContrato: string;
    codigoCliforMaxys: number;
    cpfCnpjCliforMaxys: string;
    nomeCliforMaxys: string;
    volumeEmbarcado: number;
    pesoEmbarcado: number;
    pesoContratoExcedido: number;
    pesoSaldoembr: number;
    municipioMaxys: number;
    inscricaoEstadualMaxys: string;
    transgenia?: EnumTransgeniaSodru | null;
    municipioNome?: string;
    active?: boolean;
    pesoDeduzirDoContrato: number;
    permiteEditarTransgenia?: boolean;
}

enum EnumTipoContrato {}

export enum EnumTransgeniaSodru {
    CONVENCIONAL = 'CONVENCIONAL',
    COMPRADEARMAZEMPARTICIPANTE = 'COMPRA DE ARMAZEM PARTICIPANTE',
    BIOTECNOLOGIACOMPATENTEVALIDA = 'BIOTECNOLOGIA COM PATENTE VALIDA',
    BIOTECNOLOGICANEGATIVA = 'BIOTECNOLOGICA NEGATIVA',
}

export interface INotaComContratos {
    chaveNota: string;
    numeroNfe: string;
    numeroNf: string;
    serie: string;
    peso: number;
    unidadeMedida: string;
    dataEmissao: string;
    valorTotal: number;
    danfe?: IArquivoEncoded | null;
    contratos?: ILoteEmbarqueContratos[];
}
