import Contato from './Contato';
import Endereco from './Endereco';

export enum TipoInscricao {
    Isento = 'isento',
}
export default class ClienteFornecedor {
    constructor(
        public id: number,
        public nome: string,
        public enderecos: Endereco[],
        public nomeFantasia?: string,
        public idIntegracao?: string | null,
        public contato?: Contato,
        public cnpj?: string,
        public cpf?: string
    ) {}
}
