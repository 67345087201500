import ArticleIcon from '@mui/icons-material/Article';
import CheckIcon from '@mui/icons-material/Check';
import ContrastIcon from '@mui/icons-material/Contrast';
import DiscFullIcon from '@mui/icons-material/DiscFull';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Chip,
    Container,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Tooltip,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAuth } from '../../AuthProvider';
import { PermissionGate } from '../../Components/Navegacao/PermissionGate';
import PageTitle from '../../Components/PageTitle/PageTitle';
import SpinnerLoading from '../../Components/SpinnerLoading/SpinnerLoading';
import GenericComplexTable, {
    ComplexColumn,
} from '../../Components/Table/GenericComplexTable';
import Search from '../../Components/Table/Search';
import { ApiResources, FrontEndPathResources } from '../../Models/Api';
import Embarque from '../../Models/Embarque';
import LoteEmbarque from '../../Models/LoteEmbarque';
import NotaFiscal from '../../Models/NotaFiscal';
import OrdemCarregamento from '../../Models/OrdemCarregamento';
import { RecursosPerfisEnum, StatusRequisicao } from '../../Models/Usuario';
import { useFetchUseCase } from '../../hooks/useFetchUseCase';
import useGenericService from '../../hooks/useGenericService';
import { EStatusOrdemColeta } from '../OrdemColeta/domain/entities/EStatusOrdemColeta';
import { EmbarquesTotais } from './EmbarquesTotais';
import { Status } from './Formulario';
import ModalAnexarNota from './ModalAnexarNota';
import ModalClassificacoes from './ModalClassificacoes';
import ModalConfirmacaoExclusaoEmbarque from './ModalConfirmacaoExclusaoEmbarque';
import ModalEditarPesoPlanejado from './ModalEditarPesoPlanejado';
import ModalHistoricoAlteracoes from './ModalHistoricoAlteracoes';
import ModalOrdensExcluidas from './ModalOrdensExcluidas';
import ModalRomaneio from './ModalRomaneio';
import { TransportadorasVinculadas } from './TransportadorasVinculadas';
import { OrdemColetaFactory } from './factories/OrdemColetaFactory';
import { styles } from './helpers/Styles';

export type Arquivo = {
    name: string;
};

const useStyles = makeStyles((theme) => ({
    detailRoot: {
        padding: 0,
    },
    accordion: {
        display: 'flex',
        flexDirection: 'row-reverse',
        gap: 2,
    },
}));

export default function Listar() {
    const { executePromise, loading } = useFetchUseCase();

    const { getService } = useGenericService();
    const { usuarioLogado } = useAuth();

    const classes = useStyles();

    const service = getService(ApiResources.LoteEmbarque);

    const [mostrarModalAuditoria, setMostrarModalAuditoria] =
        useState<boolean>(false);

    const [mostrarModalExcluirEmbarque, setMostrarModalExcluirEmbarque] =
        useState<boolean>(false);

    const [ordemEditarPeso, setOrdemEditarPeso] = useState<OrdemCarregamento>();

    const [
        mostrarModalAlterarPesoPlanejado,
        setMostrarModalAlterarPesoPlanejado,
    ] = useState<boolean>(false);

    const [ordeMostrarAuditoria, setOrdemMostrarAuditoria] =
        useState<OrdemCarregamento>();

    const [mostrarModalClassificacoes, setMostrarModalClassificacoes] =
        useState<boolean>(false);

    const [embarqueMostrar, setEmbarqueMostrar] = useState<Embarque>();

    const [mostrarModalAnexarNota, setMostrarModalAnexarNota] =
        useState<boolean>(false);
    const [embarqueAnexarNota, setEmbarqueAnexarNota] = useState<Embarque>();

    const [embarqueGerarRomaneio, setEmbarqueGerarRomaneio] =
        useState<Embarque>();

    const [ordemGerarRomaneio, setOrdemGerarRomaneio] =
        useState<OrdemCarregamento>();

    const [mostrarModalItensExcluidos, setMostrarModalItensExcluidos] =
        useState<boolean>(false);

    const [ordemModal, setOrdemModal] = useState<OrdemCarregamento>();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [embarqueExcluir, setEmbarqueExcluir] = useState<Embarque>();

    const [mostrarModalGerarRomaneio, setMostrarModalGerarRomaneio] =
        useState<boolean>(false);

    const [loteEmbarque, setLoteEmbarque] = useState<LoteEmbarque[]>();

    const [textSearch, setTextSearch] = useState('');

    const [
        filtroStatusLoteEmbarqueTransportadora,
        setFiltroStatusLoteEmbarqueTransportadora,
    ] = useState('');

    const [rowsPerPageCustom, setRowsPerPageCustom] = useState(10);

    const [pageCustom, setPageCustom] = useState(0);

    const [sortCustom, setSortCustom] = useState('desc');

    const [totalCustom, setTotalCustom] = useState(0);

    const [orderByCustom, setOrderByCustom] = useState('id');

    useEffect(() => {
        if (usuarioLogado) {
            executePromise(
                () =>
                    service.api.get('', {
                        params: {
                            ...(textSearch && { q: textSearch }),
                            ...(filtroStatusLoteEmbarqueTransportadora && {
                                status: filtroStatusLoteEmbarqueTransportadora,
                            }),
                            page: pageCustom,
                            size: rowsPerPageCustom,
                            sort: orderByCustom + ',' + sortCustom,
                        },
                    }),
                (response) => {
                    setTotalCustom(response.data.totalElements);
                    setPageCustom(response.data.number);
                    return setLoteEmbarque(response.data.content);
                },
                () => {
                    enqueueSnackbar('Não foi possível encontrar o registro', {
                        variant: 'error',
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
                }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        usuarioLogado,
        textSearch,
        filtroStatusLoteEmbarqueTransportadora,
        rowsPerPageCustom,
        pageCustom,
        sortCustom,
        orderByCustom,
    ]);

    const colunas: ComplexColumn[] = [
        {
            attribute: 'lote',
            label: 'Lote',
            id: 'lote',
            minWidth: 20,
            sortable: true,
        },
        {
            attribute: 'localEmbarque.cidade',
            label: 'Local de Embarque',
            id: 'localEmbarque.cidade',
            format: (row) =>
                row.localEmbarque?.descricao
                    ? row.localEmbarque?.descricao
                    : row.localEmbarque?.cidade,
        },
        {
            attribute: 'localDestino.cidade',
            label: 'Local de Destino',
            id: 'localDestino.cidade',
            format: (row) =>
                row.localDestino?.descricao
                    ? row.localDestino?.descricao
                    : row.localDestino?.cidade,
        },

        {
            attribute: 'produto.nome',
            label: 'Produto',
            id: 'produto.nome',
            sortable: false,
            format: (row) => row.produto.nome,
        },

        {
            attribute: 'pesoPlanejado',
            label: 'Peso planejado',
            id: 'pesoPlanejado',
            sortable: true,
            format: (row) =>
                row.pesoPlanejado
                    .toString()
                    .replace('.', ',')
                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' KG',
        },
        {
            attribute: 'status',
            label: 'Status',
            id: 'status',
            sortable: true,
            format: (row) => getStatusOrdemFormatado(row.status),
        },
        {
            attribute: 'tipoEmbarque',
            label: 'Tipo',
            id: 'tipoEmbarque',
            sortable: false,
        },
        {
            attribute: 'nome',
            label: 'Ações',
            id: 'acao',
            minWidth: 200,
            format: (row) => {
                return (
                    <>
                        <PermissionGate
                            recurso={RecursosPerfisEnum.LOTE_EMBARQUE_VER}
                            redirect={''}
                        >
                            <Tooltip
                                title="Ver"
                                placement="top"
                                disableInteractive
                            >
                                <IconButton
                                    aria-label="ver"
                                    onClick={() => {
                                        navigate(
                                            FrontEndPathResources.LoteEmbarque +
                                                '/ver/' +
                                                row.id
                                        );
                                    }}
                                >
                                    <RemoveRedEyeIcon />
                                </IconButton>
                            </Tooltip>
                        </PermissionGate>
                        <PermissionGate
                            recurso={
                                RecursosPerfisEnum.LOTE_EMBARQUE_CONSULTAR_HISTORICO
                            }
                            redirect={''}
                        >
                            <Tooltip
                                title="Histórico de alterações"
                                placement="top"
                                disableInteractive
                            >
                                <IconButton
                                    aria-label="ver"
                                    onClick={() => {
                                        setOrdemMostrarAuditoria(
                                            row as OrdemCarregamento
                                        );
                                        setMostrarModalAuditoria(true);
                                    }}
                                >
                                    <ArticleIcon />
                                </IconButton>
                            </Tooltip>
                        </PermissionGate>
                        <PermissionGate
                            recurso={RecursosPerfisEnum.LOTE_EMBARQUE_ALTERAR}
                            redirect={''}
                        >
                            {(row.status === Status.ABERTO ||
                                row.status === Status.SUSPENSO) && (
                                <>
                                    <Tooltip
                                        title={'Vincular transportadoras'}
                                        placement="top"
                                    >
                                        <span>
                                            <IconButton
                                                aria-label="alterar"
                                                onClick={() => {
                                                    navigate(
                                                        FrontEndPathResources.LoteEmbarque +
                                                            '/vincular-transportadoras/' +
                                                            row.id
                                                    );
                                                }}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </>
                            )}
                        </PermissionGate>
                    </>
                );
            },
        },
    ];

    function expandedRowContent(row: LoteEmbarque) {
        return (
            <>
                <EmbarquesTotais row={row} />
                <TransportadorasVinculadas row={row} />

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        classes={{ root: classes.accordion }}
                    >
                        <Typography
                            sx={{ fontSize: 20 }}
                            color="text.secondary"
                            gutterBottom
                        >
                            Ordens de coleta
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails classes={{ root: classes.detailRoot }}>
                        <OrdemColetaFactory row={row} />
                    </AccordionDetails>
                </Accordion>
            </>
        );
    }

    const getStatusOrdemFormatado = (statusOrdem: Status) => {
        switch (statusOrdem) {
            case Status.ABERTO:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <ImportContactsIcon
                                    style={styles().iconStyle}
                                />{' '}
                                {Status.ABERTO}
                            </div>
                        }
                        color="primary"
                        variant="outlined"
                    />
                );
            case Status.CANCELADO:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <ContrastIcon style={styles().iconStyle} />{' '}
                                {Status.CANCELADO}
                            </div>
                        }
                        color="error"
                        variant="outlined"
                    />
                );
            case Status.ENCERRADO:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <CheckIcon style={styles().iconStyle} />{' '}
                                {Status.ENCERRADO}
                            </div>
                        }
                        color="warning"
                        variant="outlined"
                    />
                );
            case Status.SUSPENSO:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <DiscFullIcon style={styles().iconStyle} />{' '}
                                {Status.SUSPENSO}
                            </div>
                        }
                        color="warning"
                        variant="outlined"
                    />
                );
            case Status.LIBERADO:
                return (
                    <Chip
                        style={styles().chipStatus}
                        label={
                            <div style={styles().divChip}>
                                <CheckIcon style={styles().iconStyle} />{' '}
                                {Status.LIBERADO}
                            </div>
                        }
                        color="success"
                        variant="outlined"
                    />
                );
        }
    };

    const enviarNotaFiscalApi = async (notaFiscal: NotaFiscal) => {
        try {
            const service = getService(ApiResources.NotaFiscal);

            const resposta = await service.api.post('/portal', notaFiscal);

            if (resposta.status === StatusRequisicao.OK) {
                enqueueSnackbar('Nota enviada com sucesso!', {
                    variant: 'success',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
            } else {
                enqueueSnackbar('Erro ao tentar enviar a nota!', {
                    variant: 'error',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
            }
        } catch (error) {
            enqueueSnackbar('Erro ao tentar enviar a nota!', {
                variant: 'error',
                onClick: () => {
                    closeSnackbar();
                },
            });
        }
    };

    const alterarPesoOrdem = async (pesoPlanejado: number, idOrdem: number) => {
        try {
            const service = getService(ApiResources.OrdemCarregamento);

            const resposta = await service.api.put(
                '/alterar-peso-planejado/' + idOrdem,
                {
                    pesoPlanejado,
                }
            );

            if (resposta.status === StatusRequisicao.OK) {
                enqueueSnackbar(
                    'Peso planejado alterado com sucesso com sucesso!',
                    {
                        variant: 'success',
                        onClick: () => {
                            closeSnackbar();
                        },
                    }
                );
            } else {
                enqueueSnackbar('Erro ao tentar alterar o peso planejado!', {
                    variant: 'error',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
            }
        } catch (error) {
            const erroTratado = error as { atributo: string; mensagem: string };

            enqueueSnackbar(
                erroTratado.atributo + ': ' + erroTratado.mensagem,
                {
                    variant: 'error',
                    onClick: () => {
                        closeSnackbar();
                    },
                }
            );
        }
    };

    const excluirEmbarque = async () => {
        const servico = getService(ApiResources.Embarque);

        try {
            const resultado = await servico.api.delete(
                '/cancelar/' + embarqueExcluir?.id
            );

            if (resultado.status === StatusRequisicao.OK) {
                enqueueSnackbar('Embarque excluido com sucesso', {
                    variant: 'success',
                });
            } else {
                enqueueSnackbar('Embarque não foi excluido!', {
                    variant: 'error',
                });
            }
        } catch (error) {
            enqueueSnackbar(error as string, {
                variant: 'error',
            });
        } finally {
            setMostrarModalExcluirEmbarque(false);
            setEmbarqueExcluir(undefined);
        }

        setMostrarModalExcluirEmbarque(false);
        setEmbarqueExcluir(undefined);
    };

    return (
        <PermissionGate
            recurso={RecursosPerfisEnum.LOTE_EMBARQUE_VER}
            redirect={FrontEndPathResources.Dashboard}
        >
            <Container maxWidth={false}>
                <PageTitle title="Lote embarque" />

                <Divider />

                {mostrarModalExcluirEmbarque && embarqueExcluir && (
                    <ModalConfirmacaoExclusaoEmbarque
                        aberto={mostrarModalExcluirEmbarque}
                        embarque={embarqueExcluir}
                        fecharClicado={() => {
                            setMostrarModalExcluirEmbarque(false);
                            setEmbarqueExcluir(undefined);
                        }}
                        confirmarClicado={() => {
                            setMostrarModalExcluirEmbarque(false);
                            setEmbarqueExcluir(undefined);
                            excluirEmbarque();
                        }}
                    />
                )}

                {mostrarModalAlterarPesoPlanejado && ordemEditarPeso && (
                    <ModalEditarPesoPlanejado
                        aberto={mostrarModalAlterarPesoPlanejado}
                        fecharClicado={() => {
                            setOrdemEditarPeso(undefined);
                            setMostrarModalAlterarPesoPlanejado(false);
                        }}
                        gravarClicado={(
                            pesoPlanejado: number,
                            ordemId: number
                        ) => {
                            alterarPesoOrdem(pesoPlanejado, ordemId);
                            setMostrarModalAlterarPesoPlanejado(false);
                            setOrdemEditarPeso(undefined);
                        }}
                        ordem={ordemEditarPeso}
                    />
                )}

                <ModalOrdensExcluidas
                    aberto={mostrarModalItensExcluidos}
                    fecharClicado={() => {
                        setMostrarModalItensExcluidos(false);
                    }}
                    restaurarClicado={() => {
                        setMostrarModalItensExcluidos(false);
                    }}
                />

                {mostrarModalAuditoria && ordeMostrarAuditoria && (
                    <ModalHistoricoAlteracoes
                        ordemCarregamento={ordeMostrarAuditoria}
                        aberto={mostrarModalAuditoria}
                        fecharClicado={() => {
                            setMostrarModalAuditoria(false);
                        }}
                    />
                )}

                {embarqueMostrar && ordemModal && (
                    <ModalClassificacoes
                        aberto={mostrarModalClassificacoes}
                        embarque={embarqueMostrar}
                        ordem={ordemModal}
                        fecharClicado={() => {
                            setMostrarModalClassificacoes(false);
                            setEmbarqueMostrar(undefined);
                        }}
                    />
                )}

                {embarqueAnexarNota && mostrarModalAnexarNota && (
                    <ModalAnexarNota
                        aberto={mostrarModalAnexarNota}
                        embarque={embarqueAnexarNota}
                        fecharClicado={() => {
                            setMostrarModalAnexarNota(false);
                            setEmbarqueAnexarNota(undefined);
                        }}
                        enviarAnexoClicado={(notaFiscal: NotaFiscal) => {
                            enviarNotaFiscalApi(notaFiscal);
                            setMostrarModalAnexarNota(false);
                            setEmbarqueAnexarNota(undefined);
                        }}
                    />
                )}

                {embarqueGerarRomaneio && ordemGerarRomaneio && (
                    <ModalRomaneio
                        aberto={mostrarModalGerarRomaneio}
                        embarque={embarqueGerarRomaneio}
                        ordem={ordemGerarRomaneio}
                        fecharClicado={() => {
                            setEmbarqueGerarRomaneio(undefined);
                            setOrdemGerarRomaneio(undefined);
                            setMostrarModalGerarRomaneio(false);
                        }}
                    />
                )}

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={4} mt={3}>
                        <Search
                            onSearch={(searchText) => {
                                setTextSearch(searchText);
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} lg={4} mt={3}>
                        <FormControl fullWidth>
                            <InputLabel id="labelStatus">Status</InputLabel>
                            <Select
                                labelId="labelStatus"
                                id="status"
                                value={filtroStatusLoteEmbarqueTransportadora}
                                label="Status"
                                onChange={(event) =>
                                    setFiltroStatusLoteEmbarqueTransportadora(
                                        event.target.value as EStatusOrdemColeta
                                    )
                                }
                            >
                                <MenuItem value={''}>
                                    <em>Nenhum</em>
                                </MenuItem>
                                <MenuItem value={Status.ABERTO}>
                                    {Status.ABERTO}
                                </MenuItem>
                                <MenuItem value={Status.CANCELADO}>
                                    {Status.CANCELADO}
                                </MenuItem>
                                <MenuItem value={Status.ENCERRADO}>
                                    {Status.ENCERRADO}
                                </MenuItem>
                                <MenuItem value={Status.LIBERADO}>
                                    {Status.LIBERADO}
                                </MenuItem>
                                <MenuItem value={Status.SUSPENSO}>
                                    {Status.SUSPENSO}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <SpinnerLoading isLoading={loading}>
                    <GenericComplexTable
                        columnToSearch={'id'}
                        expandedRows
                        contentExpandedRow={expandedRowContent}
                        service={getService(ApiResources.LoteEmbarque)}
                        rows={loteEmbarque}
                        columns={colunas}
                        naoMostrarBotaoNovo
                        createButtonText="Registrar nova"
                        linkCreateButtonText={
                            FrontEndPathResources.OrdemCarregamento + '/criar'
                        }
                        naoMostrarCampoPesquisar
                        mostrarIconeItensExcluidos={false}
                        onIconeItensExcluidosClicado={() => {
                            setMostrarModalItensExcluidos(true);
                        }}
                        setRowsPerPageCustom={setRowsPerPageCustom}
                        setPageCustom={setPageCustom}
                        totalCustom={totalCustom}
                        setSortCustom={setSortCustom}
                        setOrderByCustom={setOrderByCustom}
                        usarComponentesCustom={true}
                    />
                </SpinnerLoading>
            </Container>
        </PermissionGate>
    );
}
