export enum RevisionType {
    Adicionado = 'ADD',
    Modificado = 'MOD',
    Deletado = 'DEL',
}

export class UsuarioAuditoria {
    constructor(public id: number, public login: string) {}
}

export class Revision {
    constructor(
        public id: number,
        public timestamp: number,
        public sessionId: string,
        public revisionType: RevisionType,
        public usuario: UsuarioAuditoria,
        public revisionDate: Date
    ) {}
}
export default class Auditoria {
    constructor(public revision: Revision, public entidade: any) {}
}