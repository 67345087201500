import { Container, Divider } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import PageTitle from '../../Components/PageTitle/PageTitle';
import SpinnerLoading from '../../Components/SpinnerLoading/SpinnerLoading';
import { MensagemErroRequisicaoApi } from '../../Config/Api';
import { ApiResources, FrontEndPathResources } from '../../Models/Api';
import useGenericService from '../../hooks/useGenericService';

import { useAuth } from '../../AuthProvider';
import { PermissionGate } from '../../Components/Navegacao/PermissionGate';
import Usuario, {
    ChavesArmazenamentoStorage,
    RecursosPerfisEnum,
    StatusRequisicao,
} from '../../Models/Usuario';
import LoginResponse from '../../Models/responses/LoginResponse';
import Formulario, { CamposFormulario } from './Formulario';

type Params = {
    id: string;
};

export default function Alterar() {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { id } = useParams<Params>();

    const { usuarioLogado } = useAuth();

    const [usuario, setUsuario] = useState<CamposFormulario>();

    const { getService } = useGenericService();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const navigate = useNavigate();

    useEffect(() => {
        let loginResponse = localStorage.getItem(
            ChavesArmazenamentoStorage.LOGIN_RESPONSE
        );

        if (loginResponse) {
            const usuario: Usuario = (
                JSON.parse(loginResponse) as LoginResponse
            ).usuario;

            if (usuario.trocarSenha) {
                window.location.pathname = FrontEndPathResources.AlterarSenha;
            }
        }
    }, []);

    useEffect(() => {
        setIsLoading(true);
        const carregarObjeto = async () => {
            const servico = getService(ApiResources.Usuario);

            try {
                const resposta = await servico.api.get('/' + id);

                if (resposta.status === StatusRequisicao.OK) {
                    setUsuario({
                        ...resposta.data,
                        cpfCnpj: resposta.data.cpf || resposta.data.cnpj,
                    });
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    enqueueSnackbar('Não foi possível encontrar o registro', {
                        variant: 'error',
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
                }
            } catch (error) {
                setIsLoading(false);
            }
        };

        carregarObjeto();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, enqueueSnackbar]);

    const deslogarQuandoUsuarioLogadoForIgualAoAlterado = () => {
        if (usuarioLogado?.id.toString() === id) {
            enqueueSnackbar(
                'Usuário logado foi alterado, você será deslogado!',
                {
                    variant: 'warning',
                    onClick: () => {
                        closeSnackbar();
                    },
                }
            );
            localStorage.removeItem(ChavesArmazenamentoStorage.LOGIN_RESPONSE);
            navigate('');
        }
    };

    const onSubmit = async (dadosFormulario: CamposFormulario) => {
        setIsLoading(true);
        const servico = getService(ApiResources.Usuario);

        try {
            const resultado = await servico.api.put('/' + id, {
                ...dadosFormulario,
                cpf:
                    dadosFormulario.cpfCnpj?.length === 11
                        ? dadosFormulario.cpfCnpj
                        : null,
                cnpj:
                    dadosFormulario.cpfCnpj?.length === 14
                        ? dadosFormulario.cpfCnpj
                        : null,
            });

            if (resultado.status === StatusRequisicao.OK) {
                deslogarQuandoUsuarioLogadoForIgualAoAlterado();
                enqueueSnackbar('Usuário atualizado com sucesso!', {
                    variant: 'success',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
                navigate(FrontEndPathResources.CadastroUsuario);
                setIsLoading(false);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            (error as MensagemErroRequisicaoApi[]).forEach((erro) => {
                enqueueSnackbar(erro.atributo + ': ' + erro.mensagem, {
                    variant: 'error',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
            });
        }
    };

    return (
        <PermissionGate
            recurso={RecursosPerfisEnum.CADASTRO_USUARIOS_ALTERAR}
            redirect={FrontEndPathResources.CadastroUsuario}
        >
            <SpinnerLoading isLoading={isLoading}>
                <Container>
                    <PageTitle title={'Edição de usuário'} />
                    <Divider />
                    <Formulario
                        usuario={usuario}
                        onSubmit={onSubmit}
                        permitirSenhaVazia
                    />
                </Container>
            </SpinnerLoading>
        </PermissionGate>
    );
}
