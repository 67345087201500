import AutorenewIcon from '@mui/icons-material/Autorenew';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Tooltip,
} from '@mui/material';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import GenericBasicTable from '../../Components/Table/GenericBasicTable';
import { ComplexColumn } from '../../Components/Table/GenericComplexTable';
import { ApiResources } from '../../Models/Api';
import Produto from '../../Models/Produto';
import { StatusRequisicao } from '../../Models/Usuario';
import useGenericService from '../../hooks/useGenericService';
import { styles } from './helpers/Styles';

interface Props {
    aberto: boolean;
    fecharClicado: () => void;
    restaurarClicado: () => void;
}

export default function ModalProdutosExcluidos({
    aberto,
    fecharClicado,
    restaurarClicado,
}: Props) {
    const [produtos, setProdutos] = useState<Produto[]>();

    const { getService } = useGenericService();

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const buscarProdutosDesativados = async () => {
            const servico = getService(ApiResources.Produto);

            try {
                const resposta = await servico.api.get('/desativados');
                if (resposta.status === StatusRequisicao.OK) {
                    setProdutos(resposta?.data?.content);
                }
            } catch (error) {}
        };

        buscarProdutosDesativados();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aberto]);

    const restaurarProdutos = async (produtoId: number) => {
        const servico = getService(ApiResources.Produto);

        try {
            const resposta = await servico.api.put(
                '/restaurar/' + produtoId,
                {}
            );

            if (resposta.status === StatusRequisicao.OK) {
                enqueueSnackbar('Produto restaurado com sucesso', {
                    variant: 'success',
                });
                restaurarClicado();
            }
        } catch (error) {}
    };

    const colunas: ComplexColumn[] = [
        {
            attribute: 'id',
            label: 'ID',
            id: 'id',
            align: 'left',
            width: 50,
        },
        {
            attribute: 'nome',
            label: 'Nome',
            id: 'nome',
            align: 'left',
            width: 150,
        },
        {
            attribute: 'dataHoraDeletado',
            label: 'Data da exclusão',
            id: 'dataHoraDeletado',
            align: 'left',
            width: 150,
            format: (row) =>
                format(new Date(row.dataHoraDeletado), 'dd/MM/Y').toString(),
        },
        {
            attribute: 'nome',
            label: 'Ações',
            id: 'acao',
            width: 50,
            format: (row) => {
                return (
                    <>
                        <Tooltip
                            title="Restaurar"
                            placement="top"
                            disableInteractive
                        >
                            <IconButton
                                aria-label="delete"
                                onClick={() => {
                                    restaurarProdutos(row.id);
                                }}
                            >
                                <AutorenewIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    return (
        <Dialog
            key={'produtosexcluidos'}
            open={aberto}
            onClose={() => fecharClicado()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
        >
            <DialogTitle id="alert-dialog-title">
                Produtos excluídos
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} style={styles().gridExcluidos}>
                    <GenericBasicTable columns={colunas} rows={produtos} />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => fecharClicado()} autoFocus>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
