import { PermissionGate } from '../../../Components/Navegacao/PermissionGate';
import { FrontEndPathResources } from '../../../Models/Api';
import { RecursosPerfisEnum } from '../../../Models/Usuario';
import { FindJuncaoByFilter } from '../domain/usecases/FindJuncaoByFilter';
import { GetOrdemColetaById } from '../domain/usecases/GetOrdemColetaById';
import { GetSaldoLoteEmbarqueTransportadoraById } from '../domain/usecases/GetSaldoLoteEmbarqueTransportadoraById';
import { PutOrdemColeta } from '../domain/usecases/PutOrdemColeta';
import { OrdemColetaRepository } from '../infra/OrdemColetaRepository';
import Alterar from '../pages/Alterar';

export const OrdemColetaAlterar: React.FC = () => {
    const ordemColetaRepository = new OrdemColetaRepository();

    const putOrdemColeta = new PutOrdemColeta(ordemColetaRepository);
    const getOrdemColetaById = new GetOrdemColetaById(ordemColetaRepository);
    const getSaldoLoteEmbarqueTransportadoraById =
        new GetSaldoLoteEmbarqueTransportadoraById(ordemColetaRepository);

    const findJuncaoByFilter = new FindJuncaoByFilter(ordemColetaRepository);

    return (
        <PermissionGate
            recurso={RecursosPerfisEnum.ORDEM_COLETA_ALTERAR}
            redirect={FrontEndPathResources.OrdemColeta}
        >
            <Alterar
                putOrdemColeta={putOrdemColeta}
                getOrdemColetaById={getOrdemColetaById}
                getSaldoLoteEmbarqueTransportadoraById={
                    getSaldoLoteEmbarqueTransportadoraById
                }
                findJuncaoByFilter={findJuncaoByFilter}
            />
        </PermissionGate>
    );
};
