import { joiResolver } from '@hookform/resolvers/joi';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
    Alert,
    Box,
    Button,
    Container,
    Grid,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    TextField,
    Typography,
} from '@mui/material';
import Joi from 'joi';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthProvider';
import Util from '../../Helpers/Util';
import { ApiResources } from '../../Models/Api';
import Usuario, {
    ChavesArmazenamentoStorage,
    StatusRequisicao,
} from '../../Models/Usuario';
import LoginResponse from '../../Models/responses/LoginResponse';
import useGenericService from '../../hooks/useGenericService';
import { styles } from './helpers/Style';

export type CamposFormulario = {
    senhaAntiga: string;
    senhaNova: string;
    confirmacaoNovaSenha: string;
};

export default function AlterarSenha() {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const [usuarioLogado, setUsuarioLogado] = useState<Usuario>();

    const [showPasswordSenhaAtual, setShowPasswordSenhaAtual] = useState(false);

    const handleClickShowPasswordSenhaAtual = () =>
        setShowPasswordSenhaAtual((show) => !show);

    const handleMouseDownPasswordSenhaAtual = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    const [showPasswordSenhaNova, setShowPasswordSenhaNova] = useState(false);

    const handleClickShowPasswordSenhaNova = () =>
        setShowPasswordSenhaNova((show) => !show);

    const handleMouseDownPasswordSenhaNova = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    const [
        showPasswordSenhaNovaConfirmacao,
        setShowPasswordSenhaNovaConfirmacao,
    ] = useState(false);

    const handleClickShowPasswordSenhaNovaConfirmacao = () =>
        setShowPasswordSenhaNovaConfirmacao((show) => !show);

    const handleMouseDownPasswordSenhaNovaConfirmacao = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    useEffect(() => {
        let loginResponse = localStorage.getItem(
            ChavesArmazenamentoStorage.LOGIN_RESPONSE
        );

        if (loginResponse) {
            setUsuarioLogado(
                (JSON.parse(loginResponse) as LoginResponse).usuario
            );
        }
    }, []);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const state: { mensagem: string } = location.state as { mensagem: string };

    const { enqueueSnackbar } = useSnackbar();

    const { getService } = useGenericService();

    const validacao = Joi.object({
        senhaAntiga: Joi.string().trim().required().messages({
            'string.base': 'Deve ser uma string',
            'string.empty': 'Não pode ficar em branco',
            'any.required': 'Não pode ficar em branco',
        }),
        senhaNova: Joi.string().trim().required().messages({
            'string.base': 'Deve ser uma string',
            'string.empty': 'Não pode ficar em branco',
            'any.required': 'Não pode ficar em branco',
        }),
        confirmacaoNovaSenha: Joi.string()
            .trim()
            .valid(Joi.ref('senhaNova'))
            .required()
            .messages({
                'string.base': 'Deve ser uma string',
                'string.empty': 'Não pode ficar em branco',
                'any.required': 'Não pode ficar em branco',
                'any.only': 'As senhas não conferem',
            }),
    });

    const {
        control,
        formState: { errors },
        handleSubmit,
    } = useForm<CamposFormulario>({
        resolver: joiResolver(validacao),
        criteriaMode: 'all',
    });

    const tratarDados = async (dadosFormulario: CamposFormulario) => {
        if (
            !Util.temNumeroECaracteresEspeciais(dadosFormulario.senhaNova) ||
            !Util.temLetraMaiuscula(dadosFormulario.senhaNova)
        ) {
            enqueueSnackbar(
                'A nova senha deve conter ao menos 1 número, 1 caractere especial e 1 letra maiúscula.',
                { variant: 'error' }
            );
            return;
        }

        const servico = getService(ApiResources.Usuario);

        try {
            const resposta = await servico.api.put(
                '/' + usuarioLogado?.id + '/alterar-senha',
                {
                    senhaAntiga: dadosFormulario.senhaAntiga,
                    senhaNova: dadosFormulario.senhaNova,
                }
            );

            if (resposta.status === StatusRequisicao.OK) {
                enqueueSnackbar(
                    'Senha alterada com sucesso. Faça o login novamente',
                    { variant: 'success' }
                );
                auth.signout(() => {
                    navigate('/easymbark/login');
                });
            } else {
                enqueueSnackbar('Falha ao alterar senha.', {
                    variant: 'error',
                });
            }
        } catch (error) {
            enqueueSnackbar('Falha ao alterar senha.', { variant: 'error' });
        }
    };

    return (
        <Container>
            <form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(tratarDados)}
            >
                {/* {state && state.mensagem && (
                    <Alert severity="warning">{state.mensagem}</Alert>
                )} */}
                {usuarioLogado && usuarioLogado.trocarSenha && (
                    <Alert severity="warning">
                        Você recebeu uma senha temporaria. Altere-a para
                        proteger sua conta.
                    </Alert>
                )}

                <Box>
                    <Typography
                        sx={[styles.textInformation, { fontWeight: 'bold' }]}
                    >
                        Sua senha deve conter pelo menos:
                    </Typography>
                    <List sx={styles.textInformation} dense={true}>
                        <ListItem>
                            <ListItemText primary="8 caracteres" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="1 número" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="1 letra maiúscula" />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="1 caractere especial" />
                        </ListItem>
                    </List>
                </Box>

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="senhaAntiga"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        fullWidth
                                        label="Senha atual"
                                        type={
                                            showPasswordSenhaAtual
                                                ? 'text'
                                                : 'password'
                                        }
                                        id="password"
                                        autoComplete="current-password"
                                        error={!!errors.senhaAntiga}
                                        helperText={
                                            errors.senhaAntiga
                                                ? errors.senhaAntiga.message
                                                : ''
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={
                                                            handleClickShowPasswordSenhaAtual
                                                        }
                                                        onMouseDown={
                                                            handleMouseDownPasswordSenhaAtual
                                                        }
                                                        edge="end"
                                                    >
                                                        {showPasswordSenhaAtual ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="senhaNova"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        fullWidth
                                        label="Nova senha"
                                        type={
                                            showPasswordSenhaNova
                                                ? 'text'
                                                : 'password'
                                        }
                                        id="password"
                                        autoComplete="current-password"
                                        error={!!errors.senhaNova}
                                        helperText={
                                            errors.senhaNova
                                                ? errors.senhaNova.message
                                                : ''
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={
                                                            handleClickShowPasswordSenhaNova
                                                        }
                                                        onMouseDown={
                                                            handleMouseDownPasswordSenhaNova
                                                        }
                                                        edge="end"
                                                    >
                                                        {showPasswordSenhaNova ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="confirmacaoNovaSenha"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        fullWidth
                                        label="Confirmação da nova senha"
                                        type={
                                            showPasswordSenhaNovaConfirmacao
                                                ? 'text'
                                                : 'password'
                                        }
                                        id="password"
                                        autoComplete="current-password"
                                        error={!!errors.confirmacaoNovaSenha}
                                        helperText={
                                            errors.confirmacaoNovaSenha
                                                ? errors.confirmacaoNovaSenha
                                                      .message
                                                : ''
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={
                                                            handleClickShowPasswordSenhaNovaConfirmacao
                                                        }
                                                        onMouseDown={
                                                            handleMouseDownPasswordSenhaNovaConfirmacao
                                                        }
                                                        edge="end"
                                                    >
                                                        {showPasswordSenhaNovaConfirmacao ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2} justifyContent="end">
                    <Button
                        type="submit"
                        variant="contained"
                        style={styles.button}
                        size="large"
                    >
                        Salvar
                    </Button>
                </Grid>
            </form>
        </Container>
    );
}
