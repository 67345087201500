import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ChavesArmazenamentoStorage } from '../../Models/Usuario';
import LoginResponse from '../../Models/responses/LoginResponse';

export type MensagemErroRequisicaoApi = {
    atributo: string;
    mensagem: string;
};

export default class ConfigService {
    private requestConfig: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        timeout: 100000,
        timeoutErrorMessage: 'Api não está respondendo.',
        responseType: 'json',
        withCredentials: false,
        validateStatus: (status) =>
            status >= 200 &&
            (status < 400 ||
                status === 401 ||
                status === 403 ||
                status === 422),
    };

    private api: AxiosInstance;
    private baseUrl: string;
    private enviarHeaderOrigemCadastro?: boolean;

    constructor(public url: string, public headerOrigemCadastro?: boolean) {
        this.baseUrl = url;
        this.enviarHeaderOrigemCadastro = headerOrigemCadastro;

        this.requestConfig.baseURL = this.baseUrl;
        this.api = axios.create(this.requestConfig);

        this.api.interceptors.response.use(
            this.handleResponse,
            this.handleError
        );
        this.api.interceptors.request.use(this.handleRequest);
    }

    private handleResponse = (response: AxiosResponse) => {
        if (response?.status === 401 || response?.status === 403) {
            this.handleUnauthorized();
        }

        return response;
    };

    private handleError = (error: any) => {
        let mensagens: MensagemErroRequisicaoApi[] = [];

        if (error.response?.status === 400) {
            mensagens = this.handleBadRequestError(error.response.data);
        }

        if (error.response?.status === 401 || error.response?.status === 403) {
            this.handleUnauthorized();
        }

        return Promise.reject(mensagens);
    };

    private handleBadRequestError(data: any): MensagemErroRequisicaoApi[] {
        if (data.length >= 1) {
            return data.map((d: any) => ({
                atributo: d.atributo,
                mensagem: d.mensagem,
            }));
        }

        return [
            {
                atributo: data.atributo,
                mensagem: data.mensagem,
            },
        ];
    }

    private handleUnauthorized(): void {
        localStorage.removeItem(ChavesArmazenamentoStorage.LOGIN_RESPONSE);
        window.location.href =
            '/easymbark/login?reason=Usuário não autenticado';
    }

    private handleRequest = (config: AxiosRequestConfig) => {
        const loginResponse = localStorage.getItem(
            ChavesArmazenamentoStorage.LOGIN_RESPONSE
        );

        if (loginResponse) {
            const token = (JSON.parse(loginResponse) as LoginResponse).token;
            config!.headers!.Authorization = token ? `Bearer ${token}` : '';
        }

        if (this.enviarHeaderOrigemCadastro) {
            config!.headers!.OrigemCadastro = '@ORDEM_CRIADA_PORTAL';
        }

        return config;
    };

    endpoint(url: string): string {
        const urlWithoutStartEndSlash = url.replace(/^\/|\/$/g, '');
        return this.baseUrl + '/' + urlWithoutStartEndSlash;
    }

    post(url: string, data?: unknown): Promise<AxiosResponse> {
        return this.api.post(this.endpoint(url), data);
    }

    login(url: string, login: string, senha: string): Promise<AxiosResponse> {
        return this.api.post(this.endpoint(url), { login, senha });
    }

    get(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        return this.api.get(this.endpoint(url), config);
    }

    put(url: string, data: unknown): Promise<AxiosResponse> {
        return this.api.put(this.endpoint(url), data);
    }

    patch(url: string, data: unknown): Promise<AxiosResponse> {
        return this.api.patch(this.endpoint(url), data);
    }

    delete(url: string): Promise<AxiosResponse> {
        return this.api.delete(this.endpoint(url));
    }
}
