import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { IMenuItemsProps } from '../MenuLateral';
import { styles } from './Style';

interface Props {
    item: IMenuItemsProps;
    onMenuClick: () => void;
}

const MenuItem: React.FC<Props> = ({ item, onMenuClick }) => {
    return (
        <Link
            to={item.path}
            style={styles.link}
            onClick={() => {
                onMenuClick();
            }}
        >
            <ListItem button key={item.title}>
                <ListItemIcon>{item.icon}</ListItemIcon>

                <ListItemText primary={item.title} />
            </ListItem>
        </Link>
    );
};

export default MenuItem;
