import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Chip,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    TableSortLabel,
    useMediaQuery,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface Props {
    columns: ComplexColumn[];
    rows?: any[];
}

export interface ComplexColumn {
    id: string;
    attribute: string;
    label: string;
    width?: number;
    minWidth?: number;
    align?: 'right' | 'left' | 'center';
    sortable?: boolean;
    format?: (value: any) => React.ReactNode;
}

export default function GenericBasicTable({ columns, rows = [] }: Props) {
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <Paper sx={{ width: '100%' }}>
            {isMobile ? (
                <div>
                    {rows.map((tableRow: any, index: number) => {
                        return (
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <div style={{ display: 'block' }}>
                                        <Chip
                                            label={
                                                <div>
                                                    <strong>
                                                        {columns[0].label +
                                                            ': '}
                                                    </strong>
                                                    {
                                                        tableRow[
                                                            columns[0].attribute
                                                        ]
                                                    }
                                                </div>
                                            }
                                            variant="outlined"
                                        />
                                        <Chip
                                            label={
                                                <div>
                                                    <strong>
                                                        {columns[1].label +
                                                            ': '}
                                                    </strong>
                                                    {
                                                        tableRow[
                                                            columns[1].attribute
                                                        ]
                                                    }
                                                </div>
                                            }
                                            variant="outlined"
                                        />
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <List>
                                        {columns.map((column, index) => {
                                            if (
                                                index > 1 &&
                                                column.id !== 'acao'
                                            ) {
                                                return (
                                                    <ListItem disablePadding>
                                                        <ListItemButton>
                                                            <ListItemText
                                                                primary={
                                                                    <div>
                                                                        <strong>
                                                                            {
                                                                                column.label
                                                                            }
                                                                            :
                                                                        </strong>
                                                                        {column.format
                                                                            ? column.format(
                                                                                  tableRow
                                                                              )
                                                                            : tableRow[
                                                                                  column
                                                                                      .attribute
                                                                              ]}
                                                                    </div>
                                                                }
                                                            />
                                                        </ListItemButton>
                                                    </ListItem>
                                                );
                                            } else {
                                                if (column.id === 'acao') {
                                                    return (
                                                        column.format && (
                                                            <TableCell
                                                                key={column.id}
                                                                align={
                                                                    column.align
                                                                }
                                                                width={
                                                                    column.width
                                                                }
                                                            >
                                                                {column.format
                                                                    ? column.format(
                                                                          tableRow
                                                                      )
                                                                    : undefined}
                                                            </TableCell>
                                                        )
                                                    );
                                                }
                                            }
                                        })}
                                    </List>
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                </div>
            ) : (
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.sortable ? (
                                            <TableSortLabel>
                                                {column.label}
                                            </TableSortLabel>
                                        ) : (
                                            column.label
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row: any, index: number) => {
                                return (
                                    <RowBody
                                        columns={columns}
                                        row={row}
                                        expandedRow={false}
                                        key={index.toString()}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Paper>
    );
}

function RowBody({
    columns,
    expandedRow = false,
    row,
}: {
    expandedRow: boolean;
    columns: ComplexColumn[];
    row: any;
    contentExpandedRow?: (row: any) => JSX.Element;
}) {
    const [open, setOpen] = React.useState(false);

    return (
        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
            {expandedRow && (
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </TableCell>
            )}

            {columns.map((column) => {
                const value = row[column.attribute];
                return (
                    <TableCell
                        key={column.id}
                        align={column.align}
                        width={column.width}
                    >
                        {column.format ? column.format(row) : value}
                    </TableCell>
                );
            })}
        </TableRow>
    );
}
