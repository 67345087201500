import { Grid, TextField } from '@mui/material';
import { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IDoubleCheck } from '../entities/IDoubleCheck';

interface MotoristaFormProps {}

const MotoristaForm: FunctionComponent<MotoristaFormProps> = () => {
    const { control } = useFormContext<IDoubleCheck>();

    return (
        <Grid container item spacing={2}>
            <Grid item xs={12} lg={6}>
                <Controller
                    name="motorista.transportadora"
                    control={control}
                    defaultValue={''}
                    render={({
                        field: { ref, onChange, onBlur, value, ...field },
                        fieldState: { error },
                    }) => (
                        <TextField
                            {...field}
                            margin="normal"
                            fullWidth
                            value={value}
                            label="Transportadora"
                            error={!!error?.message}
                            helperText={error?.message ? error?.message : ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={onChange}
                            disabled={true}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <Controller
                    name="motorista.nome"
                    control={control}
                    defaultValue={''}
                    render={({
                        field: { ref, onChange, onBlur, value, ...field },
                        fieldState: { error },
                    }) => (
                        <TextField
                            {...field}
                            margin="normal"
                            fullWidth
                            value={value}
                            label="Nome"
                            error={!!error?.message}
                            helperText={error?.message ? error?.message : ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={onChange}
                            disabled={true}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} lg={3}>
                <Controller
                    name="motorista.cpf"
                    control={control}
                    defaultValue={''}
                    render={({
                        field: { ref, onChange, onBlur, value, ...field },
                        fieldState: { error },
                    }) => (
                        <TextField
                            {...field}
                            margin="normal"
                            fullWidth
                            value={value}
                            label="CPF"
                            error={!!error?.message}
                            helperText={error?.message ? error?.message : ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={onChange}
                            disabled={true}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} lg={4}>
                <Controller
                    name="motorista.cnh"
                    control={control}
                    defaultValue={''}
                    render={({
                        field: { ref, onChange, onBlur, value, ...field },
                        fieldState: { error },
                    }) => (
                        <TextField
                            {...field}
                            margin="normal"
                            fullWidth
                            value={value}
                            label="CNH"
                            error={!!error?.message}
                            helperText={error?.message ? error?.message : ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={onChange}
                            disabled={true}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} lg={1}>
                <Controller
                    name="motorista.uf"
                    control={control}
                    defaultValue={''}
                    render={({
                        field: { ref, onChange, onBlur, value, ...field },
                        fieldState: { error },
                    }) => (
                        <TextField
                            {...field}
                            margin="normal"
                            fullWidth
                            value={value}
                            label="UF"
                            error={!!error?.message}
                            helperText={error?.message ? error?.message : ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={onChange}
                            disabled={true}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
};

export default MotoristaForm;
