import { Divider, List, ListItem, ListItemText } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ClienteFornecedor from '../../Models/ClienteFornecedor';
import Endereco from '../../Models/Endereco';
import { styles } from './helpers/Styles';

export default function useAuditoriaCamposClienteFornecedor() {

    const getEnderecoRemovido = (enderecosAnteriores: Endereco[], enderecosAtuais: Endereco[]) => {
        var enderecoR: Endereco[] = []

        enderecosAnteriores.forEach(enderecoAnterior => {
            if (enderecosAtuais
                .filter(enderecoAtual => enderecoAtual.id === enderecoAnterior.id).length === 0) {
                enderecoR.push(enderecoAnterior)
            }
        })

        return enderecoR
    }

    const getEnderecoInserido = (enderecosAnteriores: Endereco[], enderecosAtuais: Endereco[]) => {
        var enderecoI: Endereco[] = []

        enderecosAtuais.forEach(enderecoAtual => {
            if (enderecosAnteriores
                .filter(enderecoAnterior => enderecoAtual.id === enderecoAnterior.id).length === 0) {
                enderecoI.push(enderecoAtual)
            }
        })

        return enderecoI
    }

    const getEnderecoAlterado = (enderecosAnteriores: Endereco[], enderecosAtuais: Endereco[]) => {

        var enderecosA: Endereco[][] = [[], []];

        enderecosAnteriores.forEach(enderecoAnterior => {
            var enderecoAtual = enderecosAtuais
                .filter(enderecoAtual => enderecoAtual.id === enderecoAnterior.id);

            if (enderecoAtual.length === 1) {

                for (const [keyAnterior, valueAnterior] of Object.entries(enderecoAnterior)) {
                    for (const [key, value] of Object.entries(enderecoAtual[0])) {
                        if (keyAnterior === key && valueAnterior !== value)

                            enderecosA.unshift([enderecoAnterior, enderecoAtual[0]])
                    }
                }
            }
        })
        return enderecosA;
    }

    function getEnderecoFormatado(endereco: Endereco) {
        let enderecoFormatado = '';

        for (const [key, value] of Object.entries(endereco)) {
            if (value) {

                if (key === 'inscricaoMunicipal') {
                    enderecoFormatado += 'Inscrição Municipal: ' + value
                } else if (key === 'inscricaoEstadual') {
                    enderecoFormatado += 'Inscrição Estadual: ' + value + ', '
                } else if (key !== 'id')
                    enderecoFormatado += value + ', ';

            }
        }
        return enderecoFormatado.trimEnd();
    }

    const getItensAlterados = (
        cliforAtual: ClienteFornecedor,
        cliforAnterior: ClienteFornecedor
    ) => {
        return (
            <List sx={styles().listStyle} >
                {cliforAtual.nome !==
                    cliforAnterior.nome && (
                        <ListItem>
                            <ListItemText
                                primary="Nome"
                                secondary={
                                    <div style={styles().ListItemText} >
                                        {cliforAnterior.nome}
                                        <ArrowForwardIcon
                                            fontSize="small"
                                            style={styles().ArrowForwardIcon}
                                        />{' '}
                                        {cliforAtual.nome}
                                    </div>
                                }
                            />
                        </ListItem>
                    )}

                {cliforAtual.nomeFantasia !==
                    cliforAnterior.nomeFantasia && (
                        <ListItem>
                            <ListItemText
                                primary="Nome Fantasia"
                                secondary={
                                    <div style={styles().ListItemText} >
                                        {cliforAnterior.nomeFantasia}
                                        <ArrowForwardIcon
                                            fontSize="small"
                                            style={styles().ArrowForwardIcon}
                                        />{' '}
                                        {cliforAtual.nomeFantasia}
                                    </div>
                                }
                            />
                        </ListItem>
                    )}

                {cliforAtual.cpf !==
                    cliforAnterior.cpf && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="CPF"
                                    secondary={
                                        <div style={styles().ListItemText} >
                                            {cliforAnterior.cpf}{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().ArrowForwardIcon}
                                            />{' '}
                                            {cliforAtual.cpf}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {cliforAtual.cnpj !==
                    cliforAnterior.cnpj && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="CNPJ"
                                    secondary={
                                        <div style={styles().ListItemText} >
                                            {cliforAnterior.cnpj}{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().ArrowForwardIcon}
                                            />{' '}
                                            {cliforAtual.cnpj}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {cliforAnterior.contato?.email !==
                    cliforAtual.contato?.email && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Contato - Email"
                                    secondary={
                                        <div style={styles().ListItemText} >
                                            {cliforAnterior.contato?.email}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().ArrowForwardIcon}
                                            />{' '}
                                            {cliforAtual.contato?.email}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {cliforAnterior.contato?.telefone !==
                    cliforAtual.contato?.telefone && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Contato - Telefone"
                                    secondary={
                                        <div style={styles().ListItemText} >
                                            {cliforAnterior.contato?.telefone}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().ArrowForwardIcon}
                                            />{' '}
                                            {cliforAtual.contato?.telefone}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {cliforAnterior.contato?.celular !==
                    cliforAtual.contato?.celular && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Contato - Celular"
                                    secondary={
                                        <div style={styles().ListItemText} >
                                            {cliforAnterior.contato?.celular}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().ArrowForwardIcon}
                                            />{' '}
                                            {cliforAtual.contato?.celular}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {cliforAnterior.idIntegracao !==
                    cliforAtual.idIntegracao && (
                        <>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Id Integração"
                                    secondary={
                                        <div style={styles().ListItemText} >
                                            {cliforAnterior.idIntegracao}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().ArrowForwardIcon}
                                            />{' '}
                                            {cliforAtual.idIntegracao}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                {getEnderecoRemovido(cliforAnterior.enderecos, cliforAtual.enderecos).map((endereco) => {
                    return (<>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                            <ListItemText
                                primary="Endereços Removidos"
                                secondary={
                                    <div style={styles().ListItemText} >
                                        {getEnderecoFormatado(endereco)},
                                    </div>
                                }
                            />
                        </ListItem>
                    </>)
                })}

                {getEnderecoInserido(cliforAnterior.enderecos, cliforAtual.enderecos).map(endereco => {
                    return (<>
                        <Divider variant="inset" component="li" />
                        <ListItem>
                            <ListItemText
                                primary="Endereços Inseridos"
                                secondary={
                                    <div style={styles().ListItemText} >
                                        {getEnderecoFormatado(endereco)},
                                    </div>
                                }
                            />
                        </ListItem>
                    </>)
                })}

                {getEnderecoAlterado(cliforAnterior.enderecos, cliforAtual.enderecos).map(e => {
                    if (e.length !== 0)
                        return (<>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                                <ListItemText
                                    primary="Endereços Alterados"
                                    secondary={
                                        <div style={styles().ListItemText} >
                                            {getEnderecoFormatado(e[0])}{' '}
                                            <ArrowForwardIcon
                                                fontSize="small"
                                                style={styles().ArrowForwardIcon}
                                            />{' '}
                                            {getEnderecoFormatado(e[1])}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </>)
                    else return null;
                })}
            </List>
        );
    };

    return {
        getItensAlterados,
    };
}