import { Button, Divider, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { maskCPForCNPJ } from '../../../Components/Input/InputCpfCnpj';
import PageTitle from '../../../Components/PageTitle/PageTitle';
import SpinnerLoading from '../../../Components/SpinnerLoading/SpinnerLoading';
import { useFetchUseCase } from '../../../hooks/useFetchUseCase';
import { FrontEndPathResources } from '../../../Models/Api';
import { IOrdemColetaRequestDto } from '../domain/dtos/IOrdemColetaRequestDto';
import { FindJuncaoByFilter } from '../domain/usecases/FindJuncaoByFilter';
import { GetOrdemColetaById } from '../domain/usecases/GetOrdemColetaById';
import { GetSaldoLoteEmbarqueTransportadoraById } from '../domain/usecases/GetSaldoLoteEmbarqueTransportadoraById';
import { OrdemColetaForm } from './components/OrdemColetaForm';
import { styles } from './components/OrdemColetaForm/Styles';

interface Props {
    getOrdemColetaById: GetOrdemColetaById;
    getSaldoLoteEmbarqueTransportadoraById: GetSaldoLoteEmbarqueTransportadoraById;
    findJuncaoByFilter: FindJuncaoByFilter;
}

export default function Ver({
    getOrdemColetaById,
    getSaldoLoteEmbarqueTransportadoraById,
    findJuncaoByFilter,
}: Props) {
    const navigate = useNavigate();
    const location = useLocation();
    const { executePromise, loading } = useFetchUseCase();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { id } = useParams();
    const [state] = useState(location.state as any);
    const { lote: loteEmbarqueId } = state;

    const [pesoDisponivel, setPesoDisponivel] = useState<number>(0);
    const [isStatusAgendado, setIsStatusAgendado] = useState<boolean>(false);

    const ordemColetaForm = useForm<IOrdemColetaRequestDto>({
        criteriaMode: 'all',
    });

    const { reset, watch } = ordemColetaForm;
    const loteEmbarqueTransportadoraId = watch('loteEmbarqueTransportadoraId');

    useEffect(() => {
        executePromise(
            () => getOrdemColetaById.execute(Number(id)),
            (response: IOrdemColetaRequestDto) => {
                reset({
                    ...response,
                    motorista: {
                        ...response.motorista,
                        cpf: maskCPForCNPJ(response.motorista.cpf),
                    },
                    cavalo: {
                        ...response.cavalo,
                        proprietarioDocumento: maskCPForCNPJ(
                            response.cavalo.proprietarioDocumento
                        ),
                    },
                    loteEmbarqueId: loteEmbarqueId,
                });
                setIsStatusAgendado(response.statusOrdemColeta === 'Agendado');
            },
            () => {
                enqueueSnackbar('Não foi possível encontrar o registro', {
                    variant: 'error',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
            }
        );

        loteEmbarqueTransportadoraId &&
            executePromise(
                () =>
                    getSaldoLoteEmbarqueTransportadoraById.execute(
                        loteEmbarqueTransportadoraId
                    ),
                (response) => {
                    setPesoDisponivel(response);
                },
                () => {
                    enqueueSnackbar(
                        'Saldo de peso para o Lote Embarque Transpordora não encontrado',
                        {
                            variant: 'error',
                            onClick: () => {
                                closeSnackbar();
                            },
                        }
                    );
                }
            );
    }, [
        closeSnackbar,
        enqueueSnackbar,
        executePromise,
        getOrdemColetaById,
        getSaldoLoteEmbarqueTransportadoraById,
        id,
        loteEmbarqueId,
        loteEmbarqueTransportadoraId,
        reset,
        watch,
    ]);

    return (
        <SpinnerLoading isLoading={loading}>
            <FormProvider {...ordemColetaForm}>
                <PageTitle title={'Visualizando ordem de coleta ' + id} />
                <Divider />
                <OrdemColetaForm
                    disabled={true}
                    pesoDisponivel={pesoDisponivel}
                    findJuncaoByFilter={findJuncaoByFilter}
                />
                <Grid container spacing={2} justifyContent="end">
                    {isStatusAgendado && (
                        <Button
                            onClick={() => {
                                navigate(
                                    FrontEndPathResources.OrdemColeta +
                                        '/alterar/' +
                                        id,
                                    {
                                        state,
                                    }
                                );
                            }}
                            variant="contained"
                            style={styles().button}
                            size="large"
                        >
                            Alterar
                        </Button>
                    )}
                </Grid>
            </FormProvider>
        </SpinnerLoading>
    );
}
