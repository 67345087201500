import { IOrdemColetaValidacaoDescarga } from './../dtos/IOrdemColetaValidacaoDescarga';
import { IBaseUseCase } from '../../../../types/interfaces/IBaseUseCase';
import { IOrdemColetaRepository } from '../repositories/IOrdemColetaRepository';

export class EditarValidacaoDescarga
    implements IBaseUseCase<IOrdemColetaValidacaoDescarga, void>
{
    constructor(private readonly repository: IOrdemColetaRepository) {}

    execute(params: IOrdemColetaValidacaoDescarga): Promise<void> {
        return this.repository.editarValidacaoDescarga(params.id, params);
    }
}
