import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import * as React from 'react';
import { StatusRequisicao } from '../../Models/Usuario';
import { UseAutocompleteProps } from '@mui/base';
import { IconButton, StandardTextFieldProps } from '@mui/material';
import { ApiResources } from '../../Models/Api';
import useGenericService from '../../hooks/useGenericService';
import Transportadora from '../../Models/Transportadora';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';

type InputProps = Pick<
    StandardTextFieldProps,
    | 'margin'
    | 'error'
    | 'helperText'
    | 'fullWidth'
    | 'required'
    | 'inputRef'
    | 'InputLabelProps'
    | 'label'
    | 'placeholder'
    | 'disabled'
    | 'name'
    | 'FormHelperTextProps'
>;

export interface Props
    extends UseAutocompleteProps<any, boolean, boolean, undefined>,
        InputProps {
    label: string;
    disabled?: boolean;
    required?: boolean;
}

export default function SelectTransportadoras({
    margin,
    error,
    helperText,
    fullWidth,
    required,
    InputLabelProps,
    inputRef,
    label,
    placeholder,
    name,
    disabled = false,
    ...outrasPropriedades
}: Props) {
    const [transportadoras, setTransportadoras] = React.useState<
        Transportadora[]
    >([]);

    const { getService } = useGenericService();

    React.useEffect(() => {
        const carregarOpcoes = async () => {
            const servico = getService(ApiResources.Transportadora);

            try {
                const resposta = await servico.api.get('');

                if (resposta.status === StatusRequisicao.OK) {
                    setTransportadoras(resposta.data.content);
                }
            } catch (error) {
                console.log('erro', error);
            }
        };

        carregarOpcoes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (outrasPropriedades as any).field.value != null ? (
        (outrasPropriedades as any).field.value.map((props: any) => {
            return (
                <TextField
                    name={name}
                    inputRef={inputRef}
                    label={label}
                    required={required}
                    InputProps={{
                        startAdornment: (
                            <LocalShippingOutlinedIcon
                                style={{ color: '#757575' }}
                            />
                        ),
                    }}
                    margin={margin}
                    disabled={disabled}
                    error={error}
                    helperText={helperText}
                    fullWidth={fullWidth}
                    placeholder={placeholder}
                    InputLabelProps={InputLabelProps}
                    value={props.nome}
                />
            );
        })
    ) : (
        <></>
    );
}
