import { PermissionGate } from '../../../Components/Navegacao/PermissionGate';
import { FrontEndPathResources } from '../../../Models/Api';
import { RecursosPerfisEnum } from '../../../Models/Usuario';
import { BuscarValidacaoDescargaPorIdOrdemColeta } from '../domain/usecases/ValidacaoDescargaBuscarPorIdOrdemColeta';
import { CadastrarValidacaoDescarga } from '../domain/usecases/ValidacaoDescargaCadastrar';
import { EditarValidacaoDescarga } from '../domain/usecases/ValidacaoDescargaEditar';
import { OrdemColetaRepository } from '../infra/OrdemColetaRepository';
import ValidarDescarga from '../pages/ValidarDescarga';

export const OrdemColetaValidarDescarga: React.FC = () => {
    const ordemColetaRepository = new OrdemColetaRepository();

    const cadastrar = new CadastrarValidacaoDescarga(ordemColetaRepository);
    const buscarPorIdOrdemColeta = new BuscarValidacaoDescargaPorIdOrdemColeta(
        ordemColetaRepository
    );
    const editar = new EditarValidacaoDescarga(ordemColetaRepository);

    return (
        <PermissionGate
            recurso={RecursosPerfisEnum.ORDEM_COLETA_CRIAR}
            redirect={FrontEndPathResources.OrdemColeta}
        >
            <ValidarDescarga
                cadastrarValidacaoDescarga={cadastrar}
                buscarValidacaoDescargaPorIdOrdemColeta={buscarPorIdOrdemColeta}
                editarValidacaoDescarga={editar}
            />
        </PermissionGate>
    );
};
