import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';

import { useState } from 'react';
import Upload from '../../../../../../../Components/Upload/Upload';
import { IArquivoEncoded } from '../../../../../../OrdemColeta/domain/entities/IArquivoEncoded';
import { styles } from '../../../../../helpers/Styles';

interface Props {
    fecharClicado: () => void;
    aberto: boolean;
    enviarAnexoClicado: (arquivo: IArquivoEncoded) => void;
}

export default function ModalAnexarDocumento({
    fecharClicado,
    aberto = false,
    enviarAnexoClicado,
}: Props) {
    const [arquivoSelecionado, setArquivoSelecionado] =
        useState<IArquivoEncoded>();

    const handleClose = () => {
        setArquivoSelecionado(undefined);
        fecharClicado();
    };

    return (
        <Dialog
            open={aberto}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Anexar documento</DialogTitle>
            <DialogContent>
                <Box sx={{ bgcolor: 'white', p: 2 }}>
                    <Upload
                        setFile={(file) => {
                            setArquivoSelecionado(file);

                            let fileToLoad = file;
                            let fileReader = new FileReader();

                            fileReader.onload = function (fileLoadedEvent) {
                                file = fileLoadedEvent!.target!.result;
                                setArquivoSelecionado({
                                    stringBase64: file,
                                    nome: fileToLoad.name,
                                    tipo: fileToLoad.type,
                                });
                            };
                            fileReader.readAsDataURL(fileToLoad);
                        }}
                        arquivosSuportados={'.pdf, .jpeg, .jpg'}
                        mensagemIsDragAtivo="Arraste o documento aqui."
                        mensagemPadrao="Araste o documento aqui ou clique para selecionar do
                        computador"
                    />
                    {arquivoSelecionado ? (
                        <List>
                            <ListItem key={arquivoSelecionado.nome}>
                                <ListItemText
                                    primary={`${arquivoSelecionado.nome}`}
                                />
                            </ListItem>
                        </List>
                    ) : (
                        <></>
                    )}

                    <Button
                        style={styles().button}
                        variant="contained"
                        type="submit"
                        onClick={() => {
                            arquivoSelecionado &&
                                enviarAnexoClicado(arquivoSelecionado);
                        }}
                    >
                        Enviar
                    </Button>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
