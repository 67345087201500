import { DeviceBrand } from '../../../../Components/Select/SelectMarcasMaxys';
import { DeviceModel } from '../../../../Components/Select/SelectModelosMaxys';
import { DeviceType } from '../../../../Components/Select/SelectTipoVeiculo';
import { ApiResources } from '../../../../Models/Api';
import { StatusRequisicao } from '../../../../Models/Usuario';
import useGenericService from '../../../../hooks/useGenericService';

export default function useMarcaTipoModelo() {
    const { getService } = useGenericService();

    const getMarcaByCodigo = async (codigo: number): Promise<DeviceBrand> => {
        const servico = getService(ApiResources.Juncao);

        try {
            const resposta = await servico.api.get('/buscar-marcas-maxys-atac');

            if (resposta.status === StatusRequisicao.OK) {
                return resposta?.data?.filter(
                    (m: any) => m.DeviceBrand === codigo.toString()
                )[0];
            }
        } catch (error) {
            throw Error;
        }
        throw Error;
    };

    const getModeloByCodigo = async (
        codigo: number,
        marca: number
    ): Promise<DeviceModel> => {
        const servico = getService(ApiResources.Juncao);

        try {
            const resposta = await servico.api.get(
                '/buscar-modelos-maxys-atac/' + marca
            );

            if (resposta.status === StatusRequisicao.OK) {
                return resposta?.data?.filter(
                    (m: any) => m.DeviceModel === codigo.toString()
                )[0];
            }
        } catch (error) {
            throw Error;
        }
        throw Error;
    };

    const getTipoByCodigo = async (codigo: number): Promise<DeviceType> => {
        const servico = getService(ApiResources.Juncao);

        try {
            const resposta = await servico.api.get(
                '/buscar-tipos-veiculos-maxys-atac'
            );

            if (resposta.status === StatusRequisicao.OK) {
                return resposta?.data?.filter(
                    (m: any) => m.DeviceType === codigo.toString()
                )[0];
            }
        } catch (error) {
            throw Error;
        }
        throw Error;
    };

    return { getMarcaByCodigo, getModeloByCodigo, getTipoByCodigo };
}
