import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
    page: {
        backgroundColor: '#F0F1F4',
        fontFamily: 'Arial',
    },
    section: {
        margin: 30,
        flexGrow: 1,
        border: 1,
    },
    title: {
        fontSize: 12,
        fontWeight: 'bold',
        margin: '1px 0px',
        padding: '0px 10px',
    },
    contratanteSection: {
        margin: '1px 0px',
    },
    inLineText: {
        fontSize: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: '1px 0px',
        padding: '0px 10px',
    },
    inColumText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    contratanteIdRomaneioText: {
        margin: '0px 0px 0px 16px',
    },
    cliforTex: {
        fontSize: 10,
        fontWeight: 'bold',
        padding: '0px 10px',
    },
    commonText: {
        fontSize: 10,
        margin: '1px 0px',
    },
    divider: {
        borderTop: 1,
        marginVertical: 5,
    },
    img: {
        width: 135,
        height: 70,
    },
    containerFornecedor: { marginBottom: '10%' },
});
