import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import {
    Button,
    Card,
    CardContent,
    Container,
    Divider,
    Grid,
    IconButton,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import SelectEnderecoTransportadora from '../../Components/Select/SelectEnderecoTransportadora';
import SelectTransportadoraMaxys from '../../Components/Select/SelectTransportadoraMaxys';
import GenericComplexTable, {
    ComplexColumn,
} from '../../Components/Table/GenericComplexTable';
import ViaCepService from '../../Helpers/ViaCepService';
import { ApiResources, FrontEndPathResources } from '../../Models/Api';
import Endereco from '../../Models/Endereco';
import LoteEmbarque from '../../Models/LoteEmbarque';
import Transportadora from '../../Models/Transportadora';
import useGenericService from '../../hooks/useGenericService';
import { styles } from './helpers/Styles';
import { Status } from './Formulario';
import LoteEmbarqueTransportadora from '../../Models/LoteEmbarqueTransportadora';
import { useSnackbar } from 'notistack';
import { StatusRequisicao } from '../../Models/Usuario';
import SelectStatusLoteEmbarque from '../../Components/Select/SelectStatusLoteEmbarque';
import { el } from 'date-fns/locale';

type CamposFormulario = {
    transportadora: Transportadora | null;
    pesoAgendado: number | null;
    observacao: string | null;
    endereco: Endereco | null;
    status: Status;
};

interface Props {
    disabled?: boolean;
    // id: string;
}

export default function VincularTransportadora({ disabled = false }: Props) {
    const [editMode, setEditMode] = useState(false)

    const [maxWeightExceeded, setMaxWeightExceeded] = useState(false)

    const [loteEmbarque, setLoteEmbarque] = useState<LoteEmbarque>();

    const [transportadorasVinculadas, setTransportadorasVinculadas] = useState<
        LoteEmbarqueTransportadora[]
    >([]);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { getService } = useGenericService();

    const navigate = useNavigate();

    useEffect(() => {
        if (
            loteEmbarque &&
            loteEmbarque.loteEmbarqueTransportadoras &&
            loteEmbarque.loteEmbarqueTransportadoras.length > 0
        ) {
            setTransportadorasVinculadas(
                loteEmbarque.loteEmbarqueTransportadoras
            );

            loteEmbarque.loteEmbarqueTransportadoras.forEach((element) => {
                element.pesoPlanejado = loteEmbarque.pesoPlanejado;
            });
            setVolumeTotalVinculado(loteEmbarque.pesoPlanejado);

            setValue('status', loteEmbarque.status);
        }
    }, [loteEmbarque]);

    const [volumeTotalVinculado, setVolumeTotalVinculado] = useState<number>(0);

    const { id } = useParams();

    const [enderecos, setEnderecos] = useState<Endereco[]>([]);

    useEffect(() => {
        const getLoteEmbarque = async () => {
            const service = getService(ApiResources.LoteEmbarque);

            const resposta = await service.api.get('/' + id);

            setLoteEmbarque(resposta.data);
        };

        getLoteEmbarque();
    }, []);

    const {
        control,
        handleSubmit,
        setValue,
        reset,
        getValues,
        watch,
        formState: { errors },
    } = useForm<CamposFormulario>({
        criteriaMode: 'all',
    });

    const watchPesoAgendado = watch("pesoAgendado", 0)
    const watchStatus = watch("status", Status.ABERTO)
    const watchEndereco = watch("endereco", null)

    useEffect(() => {
        if (loteEmbarque) {
            const pesoAgendado = watchPesoAgendado

            if (pesoAgendado != null && pesoAgendado != 0) {
                setMaxWeightExceeded(pesoAgendado > loteEmbarque?.pesoPlanejado - volumeTotalVinculado) 
            } else {
                setMaxWeightExceeded(false)
            }
        } else {
            setMaxWeightExceeded(false)
        }
    }, [watchPesoAgendado]);

    useEffect(() => {
        if ((transportadorasVinculadas.length == 0 && getValues('status') == Status.LIBERADO) || (transportadorasVinculadas.length > 0 && getValues('status') == Status.SUSPENSO)){
            reset({...getValues(), status: Status.ABERTO})
        }
    }, [transportadorasVinculadas]);

    const colunas: ComplexColumn[] = [
        {
            attribute: 'nomeFantasia',
            label: 'Clifor',
            id: 'nomeFantasia',
            width: 300,
            format: (row) => {
                return row.transportadora.nomeFantasia;
            },
        },
        {
            attribute: 'pesoAgendado',
            label: 'Volume total',
            id: 'pesoAgendado',
            width: 200,
            format: (row) => {
                return row.pesoAgendado.toLocaleString()
            }
        },
        {
            attribute: 'peso',
            label: 'Agendado',
            id: 'peso',
            width: 200,
            format: (row) => {
                return (0) // TEMPORARIO
            }
        },
        {
            attribute: 'saldo',
            label: 'Saldo a faturar',
            id: 'saldo',
            width: 200,
            format: (row) => {
                return row.saldo.toLocaleString()
            }
        },
        {
            attribute: 'id',
            label: 'Ações',
            id: 'acao',
            width: 200,
            format: (row) => {
                return (
                    <>
                        <Tooltip
                            title="Alterar"
                            placement="top"
                            disableInteractive
                        >
                            <IconButton
                                disabled={editMode}
                                aria-label="alterar"
                                onClick={() => {
                                    editTransportadora(row);
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title="Excluir"
                            placement="top"
                            disableInteractive
                        >
                            <IconButton
                                disabled={editMode}
                                aria-label="delete"
                                onClick={() => {
                                    removeTransportadora(
                                        row as LoteEmbarqueTransportadora
                                    );
                                }}
                            >
                                <CancelIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    function expandedRowContent(row: any) {
        const { endereco } = row;

        const { observacao } = row;

        return (
            <>
                <Grid container spacing={2} marginY={1}>
                    <Grid item xs={2} lg={2}>
                        <TextField
                            disabled={true}
                            value={endereco.cep}
                            label={'CEP'}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={5} lg={5}>
                        <TextField
                            disabled={true}
                            value={endereco.cidade}
                            label={'Cidade'}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2} lg={2}>
                        <TextField
                            disabled={true}
                            value={''}
                            label={'Estado'}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={5} lg={5}>
                        <TextField
                            disabled={true}
                            value={endereco.logradouro}
                            label={'Logradouro'}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3} lg={3}>
                        <TextField
                            disabled={true}
                            value={endereco.bairro}
                            label={'Bairro'}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1} lg={1}>
                        <TextField
                            disabled={true}
                            value={endereco.numero}
                            label={'Número'}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={5} lg={5}>
                        <TextField
                            disabled={true}
                            value={observacao}
                            label={'Observação'}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </>
        );
    }

    const addTransportadora = (data: any) => {
        if (
            transportadorasVinculadas.findIndex(
                (tv) => tv.transportadora.cnpj === data.transportadora.cnpj
            ) >= 0
        ) {
            enqueueSnackbar(
                'A transportadora já foi vinculada, você pode editar ela clicando no ícone de edição abaixo.',
                {
                    variant: 'info',
                    onClick: () => {
                        closeSnackbar();
                    },
                }
            );
        } else {
            if (loteEmbarque) {

                const lt = new LoteEmbarqueTransportadora(
                    loteEmbarque?.pesoPlanejado,
                    data.pesoAgendado,
                    data.pesoAgendado,
                    data.observacao,
                    'Liberado',
                    data.transportadora,
                    data.endereco
                );

                loteEmbarque.loteEmbarqueTransportadoras =
                    loteEmbarque.loteEmbarqueTransportadoras || [];

                setVolumeTotalVinculado(
                    volumeTotalVinculado + Number(lt.pesoAgendado)
                );

                setTransportadorasVinculadas([
                    ...transportadorasVinculadas,
                    lt,
                ]);
            }

            reset({
                ...getValues(),
                transportadora: null,
                pesoAgendado: 0,
                endereco: null,
                observacao: '',
            });

            setEditMode(false)
        }
    };

    const removeTransportadora = (
        loteTransportadora: LoteEmbarqueTransportadora
    ) => {
        let transportadorasAtualizadas: LoteEmbarqueTransportadora[] =
            transportadorasVinculadas.filter(
                (t) =>
                    t.transportadora.cnpj !==
                    loteTransportadora.transportadora.cnpj
            );

        setTransportadorasVinculadas(transportadorasAtualizadas);

        setVolumeTotalVinculado(volumeTotalVinculado - loteTransportadora.pesoAgendado)
    };

    const editTransportadora = (
        loteTransportadora: LoteEmbarqueTransportadora
    ) => {
        reset({
            ...getValues(),
            transportadora: null,
            pesoAgendado: 0,
            endereco: null,
            observacao: '',
        });

        setValue('transportadora', loteTransportadora.transportadora);
        setValue('pesoAgendado', loteTransportadora.pesoAgendado);
        setValue('observacao', loteTransportadora.observacao);
        setValue('endereco', loteTransportadora.endereco);

        setVolumeTotalVinculado(
            volumeTotalVinculado - loteTransportadora.pesoAgendado
        );

        removeTransportadora(loteTransportadora);

        setEditMode(true)
    };

    const enviaLoteEmbarque = async () => {
        const servico = getService(ApiResources.LoteEmbarque);

        let loteETCriar: LoteEmbarque = {
            ...loteEmbarque!,
            loteEmbarqueTransportadoras: transportadorasVinculadas,
        };

        loteETCriar.status = getValues('status');

        try {
            const resposta = await servico.api.post(
                `vincular-transportadoras/${id}`,
                loteETCriar
            );

            if (resposta.status === StatusRequisicao.OK) {
                enqueueSnackbar('Transportadoras vinculadas com sucesso!', {
                    variant: 'success',
                    onClick: () => {
                        closeSnackbar();
                    },
                });

                navigate(FrontEndPathResources.LoteEmbarque);
            }
        } catch (error) {
            console.log('erro', error);
        }
    };

    return (
        <Container>
            <form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(addTransportadora)}
            >
                {loteEmbarque && (
                    <Grid container spacing={2} style={styles().gridListar}>
                        <Grid item xs={12} lg={3}>
                            <Card>
                                <CardContent>
                                    <Typography
                                        sx={{ fontSize: 14 }}
                                        color="text.secondary"
                                        gutterBottom
                                    >
                                        Volume total
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        component="div"
                                        color="#75DB07"
                                    >
                                        {loteEmbarque.pesoPlanejado.toLocaleString()} KG
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Card>
                                <CardContent>
                                    <Typography
                                        sx={{ fontSize: 14 }}
                                        color="text.secondary"
                                        gutterBottom
                                    >
                                        Volume vinculado
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        component="div"
                                        color="#DBCE07"
                                    >
                                        {volumeTotalVinculado.toLocaleString()} KG
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Card>
                                <CardContent>
                                    <Typography
                                        sx={{ fontSize: 14 }}
                                        color="text.secondary"
                                        gutterBottom
                                    >
                                        Volume ainda não vinculado
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        component="div"
                                        color="#db6607"
                                    >
                                        {(Number(loteEmbarque.pesoPlanejado) -
                                            Number(volumeTotalVinculado)).toLocaleString()}{' '}
                                        KG
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                )}

                <Divider textAlign="left" style={styles().divider}>
                    Status do lote embarque
                </Divider>

                <Grid item xs={6} lg={3}>
                    <Controller
                        control={control}
                        name="status"
                        defaultValue={Status.ABERTO}
                        render={(props) => {
                            return (
                                <SelectStatusLoteEmbarque
                                    {...props}
                                    options={[]}
                                    disabled={disabled}
                                    inputRef={props.field.ref}
                                    label={'Status'}
                                    onChange={(_, status) => {
                                        props.field.onChange(status);
                                    }}
                                    fullWidth
                                    data-id="selectStatusLoteEmbarque"
                                    isTransportadoraVinculada={transportadorasVinculadas.length > 0}
                                />
                            );
                        }}
                    />
                </Grid>

                <Divider textAlign="left" style={styles().divider}>
                    Vincular nova transportadora
                </Divider>

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <Controller
                            control={control}
                            name="transportadora"
                            defaultValue={null}
                            render={(props) => {
                                return (
                                    <SelectTransportadoraMaxys
                                        {...props}
                                        options={[]}
                                        disabled={disabled}
                                        inputRef={props.field.ref}
                                        label={'Transportadora'}
                                        onChange={(_, transportadora) => {
                                            props.field.onChange(
                                                transportadora
                                            );
                                            reset({
                                                ...getValues(),
                                                endereco: null,
                                            });
                                            setEnderecos(
                                                transportadora.enderecos
                                            );
                                            if (transportadora.enderecos.length === 1) {
                                                setValue('endereco',transportadora.enderecos[0])
                                            }
                                        }}
                                        fullWidth
                                        data-id="selectTransportadora"
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Controller
                            control={control}
                            name="endereco"
                            defaultValue={null}
                            render={(props) => {
                                return (
                                    <SelectEnderecoTransportadora
                                        {...props}
                                        options={[]}
                                        disabled={disabled}
                                        inputRef={props.field.ref}
                                        label={'Endereços'}
                                        onChange={(_, endereco) => {
                                            props.field.onChange(endereco);
                                        }}
                                        fullWidth
                                        data-id="selectEndereco"
                                        enderecos={enderecos}
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} lg={3}>
                        <Controller
                            name="pesoAgendado"
                            control={control}
                            defaultValue={0}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        required
                                        disabled={disabled}
                                        fullWidth
                                        label={'Peso planejado'}
                                        onChange={(
                                            event: React.ChangeEvent<
                                                | HTMLTextAreaElement
                                                | HTMLInputElement
                                            >
                                        ) => {
                                            event.currentTarget.value =
                                                event.currentTarget.value =
                                                    event.currentTarget.value.replace(
                                                        /[^.0-9]/g,
                                                        ''
                                                    );

                                            field.onChange(event);
                                        }}
                                        InputProps={{
                                            endAdornment: 'KG',
                                        }}
                                        data-id="campoPesoPlanejado"
                                        error={maxWeightExceeded}
                                        helperText={maxWeightExceeded ? 'Peso máximo atingido' : ''}
                                    />
                                );
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} lg={9}>
                        <Controller
                            name="observacao"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        disabled={disabled}
                                        fullWidth
                                        label={'Observação'}
                                        onChange={(
                                            event: React.ChangeEvent<
                                                | HTMLTextAreaElement
                                                | HTMLInputElement
                                            >
                                        ) => {
                                            field.onChange(event);
                                        }}
                                        data-id="campoPesoPlanejado"
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>

                <Button
                    type="submit"
                    variant="contained"
                    style={styles().button}
                    size="large"
                    data-id="botaoSalvar"
                    disabled={maxWeightExceeded || watchPesoAgendado == 0 || watchEndereco == null}
                >
                    {editMode ? 'Alterar' : 'Adicionar'}
                </Button>
            </form>

            <Divider textAlign="left" style={styles().divider}>
                Transportadoras
            </Divider>

            {/* <TabelaTransportadora transportadoraForm={loteEmbarque?.transportadoras} pesoPlanejado={pesoPlanejado}></TabelaTransportadora> */}

            {/* <Grid container spacing={2} style={styles().grid}>
                <GenericBasicTable
                    columns={colunas}
                    rows={loteEmbarque?.transportadoras}
                    contentExpandedRow={expandedRowContent}
                />
            </Grid> */}

            <GenericComplexTable
                service={getService(ApiResources.Empty)} // ARRUMAR
                columnToSearch={'codigoContrato'}
                columns={colunas}
                rows={transportadorasVinculadas}
                naoMostrarBotaoNovo={true}
                naoMostrarCampoPesquisar={true}
                naoMostrarRodape={true}
                createButtonText="Editar"
                linkCreateButtonText={
                    FrontEndPathResources.OrdemCarregamento + '/criar'
                }
                expandedRows
                contentExpandedRow={expandedRowContent}
                mostrarIconeItensExcluidos={false}
                // onIconeItensExcluidosClicado={() => {
                //     setMostrarModalItensExcluidos(true);
                // }}
            />

            <Button
                type="button"
                variant="contained"
                style={styles().button}
                size="large"
                data-id="botaoSalvar"
                onClick={() => enviaLoteEmbarque()}
                disabled={!((
                    loteEmbarque &&
                    (
                        Number(loteEmbarque.pesoPlanejado) -
                            Number(volumeTotalVinculado) ===
                        0
                    )) ||
                    (
                        transportadorasVinculadas.length == 0 && watchStatus == Status.SUSPENSO
                    )
                ) || watchStatus == null}
            >
                Salvar
            </Button>
        </Container>
    );
}
