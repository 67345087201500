import ArticleIcon from '@mui/icons-material/Article';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Container, Divider, IconButton, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageTitle from '../../Components/PageTitle/PageTitle';
import GenericComplexTable, {
    ComplexColumn,
} from '../../Components/Table/GenericComplexTable';
import { ApiResources, FrontEndPathResources } from '../../Models/Api';
import Contratante from '../../Models/Contratante';
import Usuario, { ChavesArmazenamentoStorage } from '../../Models/Usuario';
import LoginResponse from '../../Models/responses/LoginResponse';
import useGenericService from '../../hooks/useGenericService';
import ModalHistoricoAlteracoes from './ModalHistoricoAlteracoes';

export default function Listar() {
    const [contratanteMostrarAuditoria, setContratanteMostrarAuditoria] =
        useState<Contratante>();

    const [mostrarModalAuditoria, setMostrarModalAuditoria] =
        useState<boolean>(false);

    const navigate = useNavigate();

    const [podeCadastrarContratante, setPodeCadastrarContratante] =
        useState<boolean>(true);

    const [contratantes, setContratantes] = useState<Contratante[]>();

    const { getService } = useGenericService();

    useEffect(() => {
        let loginResponse = localStorage.getItem(
            ChavesArmazenamentoStorage.LOGIN_RESPONSE
        );

        if (loginResponse) {
            const usuario: Usuario = (
                JSON.parse(loginResponse) as LoginResponse
            ).usuario;

            if (usuario.trocarSenha) {
                window.location.pathname = FrontEndPathResources.AlterarSenha;
            }
        }
    }, []);

    useEffect(() => {
        const carregarContratantes = async () => {
            const servico = getService(ApiResources.Contratante);

            const resposta = await servico.api.get('');

            setPodeCadastrarContratante(resposta?.data?.content.length === 0);
        };

        carregarContratantes();
    }, [contratantes, getService]);

    const colunas: ComplexColumn[] = [
        {
            attribute: 'id',
            label: 'ID',
            id: 'id',
            sortable: true,
        },
        {
            attribute: 'nome',
            label: 'Nome',
            id: 'nome',
            sortable: true,
        },
        {
            attribute: 'cnpj',
            label: 'CNPJ',
            id: 'cnpj',
            sortable: false,
            format: (value) =>
                value.cnpj.replace(
                    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                    '$1.$2.$3/$4.$5'
                ),
        },
        {
            attribute: 'contato.email',
            label: 'E-mail',
            id: 'email',
            sortable: false,
            format: (row) =>
                row.contato && row.contato.email ? row.contato.email : '',
        },
        {
            attribute: 'contato.telefone',
            label: 'Telefone',
            id: 'telefone',
            sortable: false,
            format: (row) =>
                row.contato && row.contato.telefone ? row.contato.telefone : '',
        },
        {
            attribute: 'contato.celular',
            label: 'Celular',
            id: 'celular',
            sortable: false,
            format: (row) =>
                row.contato && row.contato.celular ? row.contato.celular : '',
        },
        {
            attribute: 'nome',
            label: 'Ações',
            id: 'acao',
            width: 200,
            format: (row) => {
                return (
                    <>
                        <Tooltip title="Ver" placement="top" disableInteractive>
                            <IconButton
                                aria-label="view"
                                onClick={() => {
                                    navigate(
                                        FrontEndPathResources.Contratante +
                                            '/ver/' +
                                            row.id
                                    );
                                }}
                            >
                                <RemoveRedEyeIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title="Alterar"
                            placement="top"
                            disableInteractive
                        >
                            <IconButton
                                aria-label="edit"
                                onClick={() => {
                                    navigate(
                                        FrontEndPathResources.Contratante +
                                            '/alterar/' +
                                            row.id
                                    );
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title="Histórico de alterações"
                            placement="top"
                            disableInteractive
                        >
                            <IconButton
                                aria-label="ver"
                                onClick={() => {
                                    setContratanteMostrarAuditoria(
                                        row as Contratante
                                    );
                                    setMostrarModalAuditoria(true);
                                }}
                            >
                                <ArticleIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    return (
        <Container>
            <PageTitle title="Contratantes" />

            <Divider />

            {mostrarModalAuditoria && contratanteMostrarAuditoria && (
                <ModalHistoricoAlteracoes
                    key={'historico_alteracoes'}
                    contratante={contratanteMostrarAuditoria}
                    aberto={mostrarModalAuditoria}
                    fecharClicado={() => {
                        setMostrarModalAuditoria(false);
                    }}
                />
            )}

            <GenericComplexTable
                service={getService(ApiResources.Contratante)}
                rows={contratantes}
                columnToSearch={'nome'}
                columns={colunas}
                naoMostrarBotaoNovo={!podeCadastrarContratante}
                naoMostrarCampoPesquisar={true}
                createButtonText="Cadastrar novo"
                linkCreateButtonText={
                    FrontEndPathResources.Contratante + '/criar'
                }
            />
        </Container>
    );
}
