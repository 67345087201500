import { StandardTextFieldProps, TextField } from '@mui/material';

export default function InputKGModalContratos({
    onChange,
    disabled,
    inputRef,
    label,
    ...props
}: StandardTextFieldProps) {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = e.target;
    
        // Remove todos os pontos para lidar apenas com números e a vírgula
        value = value.replace(/\./g, '');
    
        // Adiciona "0" antes da vírgula se necessário
        if (value.charAt(0) === ',') {
            value = '0' + value;
        }
    
        // Regex para permitir números com até 4 casas decimais e separadores de milhar
        const regex = /^[0-9]+(,[0-9]{0,4})?$/;
    
        // Valida o valor
        if (!regex.test(value)) {
            value = value.substring(0, value.length - 1);
        }
    
        // Divide o valor em parte inteira e parte decimal
        let [integerPart, decimalPart] = value.split(',');
    
        // Formata a parte inteira com separadores de milhar
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    
        // Junta a parte inteira e a parte decimal
        if (decimalPart !== undefined) {
            value = `${integerPart},${decimalPart}`;
        } else {
            value = integerPart;
        }
    
        e.target.value = value;
        if (onChange) onChange(e);
    };

    return (
        <TextField
            {...props}
            label={label}
            inputRef={inputRef}
            fullWidth
            disabled={disabled}
            InputProps={{
                endAdornment: 'KG',
                autoComplete: 'off',
            }}
            onChange={handleChange}
        />
    );
}
