import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import { EStatusDoubleCheck } from '../entities/EStatusDoubleCheck';
import { IDoubleCheck } from '../entities/IDoubleCheck';

interface ConfirmacaoModalProps {
    open: boolean;
    setModalOpen: (open: boolean) => void;
    saveDoubleCheck: () => void;
}

const ConfirmacaoModal: FunctionComponent<ConfirmacaoModalProps> = ({
    open,
    setModalOpen,
    saveDoubleCheck,
}) => {
    const { getValues } = useFormContext<IDoubleCheck>();

    const mensagemConfirmacao = () => {
        const status = getValues('status');
        if (status == EStatusDoubleCheck.PENDENTE) {
            return 'Atenção! A ordem de embarque será retornada ao transportador.';
        } else if (status == EStatusDoubleCheck.LIBERADO) {
            return 'Atenção! A ordem de embarque será enviado ao ERP para faturamento.';
        } else {
            return 'Sua ordem de embarque será salva.';
        }
    };

    return (
        <Dialog
            open={open}
            onClose={() => setModalOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {mensagemConfirmacao()}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Deseja continuar?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        saveDoubleCheck();
                        setModalOpen(false);
                    }}
                >
                    Salvar
                </Button>
                <Button onClick={() => setModalOpen(false)}>Fechar</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmacaoModal;
