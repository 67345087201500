import { joiResolver } from '@hookform/resolvers/joi';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import Settings from '@mui/icons-material/Settings';
import {
    Alert,
    Button,
    Card,
    CardContent,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@mui/material';
import Joi from 'joi';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { PermissionGate } from '../../Components/Navegacao/PermissionGate';
import { MensagemErroRequisicaoApi } from '../../Config/Api';
import { ApiResources, FrontEndPathResources } from '../../Models/Api';
import Usuario, {
    ChavesArmazenamentoStorage,
    RecursosPerfisEnum,
    StatusRequisicao,
} from '../../Models/Usuario';
import LoginResponse from '../../Models/responses/LoginResponse';
import useGenericService from '../../hooks/useGenericService';
import ModalAnexarLogoEmpresa from './ModalAnexarLogoEmpresa';
import { styles } from './helpers/Styles';

export type CamposFormulario = {
    email_envio: string;
    senha_email_envio: string;
    provedor_email_contratante: ProvedorEmail;
    host: string;
    port: string;
    imagemBase64: string | null;
};

export enum ProvedorEmail {
    Gmail = 'GMAIL',
    Outlook = 'OUTLOOK',
    Outros = 'OUTROS',
}

export default function Configuracao() {
    const [auxImagemBase64, setAuxImagemBase64] = useState<string>();

    const [mostrarModalAnexarImagem, setMostrarModalAnexarImagem] =
        useState<boolean>(false);

    const validacao = Joi.object({
        email_envio: Joi.string()
            .max(100)
            .trim()
            .allow('', null)
            .optional()
            .messages({
                'string.max': 'Deve ter no máximo 150 caractereres',
            }),
        imagemBase64: Joi.string().trim().allow('', null).optional(),
        senha_email_envio: Joi.string().trim().allow('', null).optional(),
        provedor_email_contratante: Joi.string().allow('', null).optional(),
        host: Joi.string()
            .pattern(new RegExp(/([\w\-]+\.)+([\w\-]+\.)+[\w\-]{2,}$/))
            .message('host invalido'),
        port: Joi.number().allow('', null).optional(),
    });

    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        watch,
        reset,
    } = useForm<CamposFormulario>({
        resolver: joiResolver(validacao),
        criteriaMode: 'all',
    });

    const watchImagemBase64 = watch('imagemBase64');

    const [integracaoAutomatica, setIntegracaoAutomatica] =
        useState<boolean>(false);

    const [desativar_ordens_Portal, setOrdensPortal] = useState<boolean>(false);

    const [vincular_clifor_erp, setVincularCliforErp] =
        useState<boolean>(false);

    const { getService } = useGenericService();

    const [usaTlsEmail, setUsaTlsEmail] = useState<boolean>(false);

    const [provedorEmail, setProvedorEmail] = useState<ProvedorEmail>(
        ProvedorEmail.Gmail
    );

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [port, setPort] = useState<string>('');

    useEffect(() => {
        let loginResponse = localStorage.getItem(
            ChavesArmazenamentoStorage.LOGIN_RESPONSE
        );

        if (loginResponse) {
            const usuario: Usuario = (
                JSON.parse(loginResponse) as LoginResponse
            ).usuario;

            if (usuario.trocarSenha) {
                window.location.pathname = FrontEndPathResources.AlterarSenha;
            }
        }
    }, []);

    useEffect(() => {
        const carregarDados = async () => {
            const servicoConfigGeral = getService(ApiResources.ConfigGeral);
            const servicoConfigEmail = getService(ApiResources.ConfigEmail);

            const respostaConfigGeral = await servicoConfigGeral.api.get('');
            const respostaConfigEmail = await servicoConfigEmail.api.get('');

            if (respostaConfigGeral.status === StatusRequisicao.OK) {
                setIntegracaoAutomatica(
                    respostaConfigGeral.data.integracao_automatica
                );

                setOrdensPortal(
                    respostaConfigGeral.data.desativar_ordens_portal
                );

                setVincularCliforErp(
                    respostaConfigGeral.data.vincular_clifor_erp
                );

                if (respostaConfigEmail.status !== StatusRequisicao.OK) {
                    reset({
                        imagemBase64: respostaConfigGeral.data.imagemBase64,
                    });
                }
            }

            if (respostaConfigEmail.status === StatusRequisicao.OK) {
                setProvedorEmail(
                    respostaConfigEmail.data
                        .provedor_email_contratante as ProvedorEmail
                );

                setUsaTlsEmail(respostaConfigEmail.data.usa_tls_email);

                setPort(respostaConfigEmail.data.port);

                if (respostaConfigGeral.status !== StatusRequisicao.OK) {
                    reset({
                        email_envio: respostaConfigEmail.data.email_envio,
                        senha_email_envio:
                            respostaConfigEmail.data.senha_email_envio,
                        provedor_email_contratante:
                            respostaConfigEmail.data.provedor_email_contratante,
                        host: respostaConfigEmail.data.host,
                        port: respostaConfigEmail.data.port,
                    });
                }
            }

            if (
                respostaConfigGeral.status === StatusRequisicao.OK &&
                respostaConfigEmail.status === StatusRequisicao.OK
            ) {
                reset({
                    email_envio: respostaConfigEmail.data.email_envio,
                    senha_email_envio:
                        respostaConfigEmail.data.senha_email_envio,
                    provedor_email_contratante:
                        respostaConfigEmail.data.provedor_email_contratante,
                    host: respostaConfigEmail.data.host,
                    port: respostaConfigEmail.data.port,
                    imagemBase64: respostaConfigGeral.data.imagemBase64,
                });
            }
        };

        carregarDados();
    }, [reset]);

    const atualizarImagemStorage = async (imagemBase64: string | null) => {
        let loginResponse = localStorage.getItem(
            ChavesArmazenamentoStorage.LOGIN_RESPONSE
        );

        if (loginResponse) {
            let lr = JSON.parse(loginResponse) as LoginResponse;

            lr.imagemBase64 = imagemBase64 ? imagemBase64 : undefined;

            localStorage.removeItem(ChavesArmazenamentoStorage.LOGIN_RESPONSE);

            localStorage.setItem(
                ChavesArmazenamentoStorage.LOGIN_RESPONSE,
                JSON.stringify(lr)
            );
        }
    };

    const tratarDados = async (dadosFormulario: CamposFormulario) => {
        try {
            const servicoConfigGeral = getService(ApiResources.ConfigGeral);
            const servicoConfigEmail = getService(ApiResources.ConfigEmail);

            const { imagemBase64 } = dadosFormulario;

            const respostaConfigGeral = await servicoConfigGeral.api.post('', {
                integracao_automatica: integracaoAutomatica,
                desativar_ordens_portal: desativar_ordens_Portal,
                vincular_clifor_erp: vincular_clifor_erp,
                imagemBase64: imagemBase64 ? imagemBase64 : null,
            });

            const respostaConfigEmail = await servicoConfigEmail.api.post('', {
                ...dadosFormulario,
                provedor_email_contratante: provedorEmail,
                usa_tls_email: usaTlsEmail,
                port: port.toString(),
            });

            if (
                respostaConfigGeral.status === StatusRequisicao.OK &&
                respostaConfigEmail.status === StatusRequisicao.OK
            ) {
                await atualizarImagemStorage(
                    respostaConfigGeral.data.imagemBase64
                );

                enqueueSnackbar(
                    'Configurações Gerais e de Email salvas com sucesso!',
                    {
                        variant: 'success',
                        onClick: () => {
                            closeSnackbar();
                        },
                    }
                );
            } else {
                if (respostaConfigGeral.status !== StatusRequisicao.OK) {
                    enqueueSnackbar('Erro ao salvar Configurações Gerais!', {
                        variant: 'error',
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
                }
                if (respostaConfigEmail.status !== StatusRequisicao.OK) {
                    enqueueSnackbar('Erro ao salvar Configurações de Email!', {
                        variant: 'error',
                        onClick: () => {
                            closeSnackbar();
                        },
                    });
                }
            }
        } catch (error) {
            (error as MensagemErroRequisicaoApi[]).forEach((erro) => {
                enqueueSnackbar(erro.atributo + ': ' + erro.mensagem, {
                    variant: 'error',
                    onClick: () => {
                        closeSnackbar();
                    },
                });
            });
        }
    };

    return (
        <PermissionGate
            recurso={RecursosPerfisEnum.CONFIGURACOES_USUARIO}
            redirect={FrontEndPathResources.Dashboard}
        >
            <Container>
                {mostrarModalAnexarImagem && (
                    <ModalAnexarLogoEmpresa
                        aberto={mostrarModalAnexarImagem}
                        fecharClicado={() => {
                            setMostrarModalAnexarImagem(false);
                        }}
                        enviarAnexoClicado={(imagemBase64: string) => {
                            setValue('imagemBase64', imagemBase64);
                            setAuxImagemBase64(imagemBase64);
                            setMostrarModalAnexarImagem(false);
                        }}
                    />
                )}

                <form
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit(tratarDados)}
                >
                    <Card sx={styles.cardStyle}>
                        <CardContent>
                            <Grid
                                container
                                spacing={2}
                                style={styles.cardTitle}
                            >
                                <Grid item>
                                    <Settings />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="h5"
                                        component="div"
                                        style={styles.title}
                                    >
                                        Configurações Gerais
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} marginBottom={2}>
                                <Grid item xs={12} lg={10}>
                                    <FormControl>
                                        <FormGroup>
                                            <FormControlLabel
                                                id="smart-check-box"
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            integracaoAutomatica
                                                        }
                                                        onChange={() =>
                                                            setIntegracaoAutomatica(
                                                                !integracaoAutomatica
                                                            )
                                                        }
                                                    />
                                                }
                                                label="Integração automática"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                    <FormControl>
                                        <FormGroup>
                                            <FormControlLabel
                                                id="smart-check-box"
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            desativar_ordens_Portal
                                                        }
                                                        onChange={() =>
                                                            setOrdensPortal(
                                                                !desativar_ordens_Portal
                                                            )
                                                        }
                                                    />
                                                }
                                                label="Desativar, criar e alterar as ordens de carregamento no portal"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                    <FormControl>
                                        <FormGroup>
                                            <FormControlLabel
                                                id="smart-check-box"
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            vincular_clifor_erp
                                                        }
                                                        onChange={() =>
                                                            setVincularCliforErp(
                                                                !vincular_clifor_erp
                                                            )
                                                        }
                                                    />
                                                }
                                                label="Permitir vincular clifor do ERP com o usuário"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item>
                                    <Typography
                                        variant="h5"
                                        component="div"
                                        style={styles.subtitle}
                                    >
                                        Logo da Empresa
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Alert variant="outlined" severity="info">
                                Importe a logo da empresa.{' '}
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={() =>
                                        setMostrarModalAnexarImagem(true)
                                    }
                                    edge="start"
                                    style={styles.cloudIconButton}
                                >
                                    <CloudUploadIcon />
                                </IconButton>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={() => {
                                        setValue('imagemBase64', null);
                                        setAuxImagemBase64(undefined);
                                    }}
                                    edge="start"
                                    style={styles.deleteIconButton}
                                >
                                    <DeleteIcon />
                                </IconButton>
                                <i>
                                    (No aplicativo as alterações de logo serão
                                    efetivadas após a realização de um novo
                                    login)
                                </i>
                            </Alert>
                            {watchImagemBase64 && (
                                <div style={{ marginTop: 10 }}>
                                    <img
                                        src={watchImagemBase64}
                                        style={styles.img}
                                        alt="logo_usuario"
                                    />{' '}
                                </div>
                            )}
                        </CardContent>
                    </Card>

                    <Card sx={styles.cardStyle}>
                        <CardContent>
                            <Grid
                                container
                                spacing={2}
                                style={styles.cardTitle}
                            >
                                <Grid item>
                                    <ForwardToInboxIcon />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="h5"
                                        component="div"
                                        style={styles.title}
                                    >
                                        Configurações de Email
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Alert
                                variant="outlined"
                                severity="info"
                                style={styles.alert}
                            >
                                Informe um email para envio do romaneio para o
                                clifor após o embarque.
                            </Alert>

                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={12}>
                                    <Controller
                                        name="email_envio"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <TextField
                                                    {...field}
                                                    inputRef={ref}
                                                    margin="normal"
                                                    fullWidth
                                                    label="E-mail de envio"
                                                    error={!!errors.email_envio}
                                                    helperText={
                                                        errors.email_envio
                                                            ? errors.email_envio
                                                                  .message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <Controller
                                        name="senha_email_envio"
                                        control={control}
                                        defaultValue={''}
                                        render={({
                                            field: { ref, ...field },
                                            fieldState: { error },
                                        }) => {
                                            return (
                                                <TextField
                                                    {...field}
                                                    inputRef={ref}
                                                    margin="normal"
                                                    fullWidth
                                                    label="Senha do e-mail de envio"
                                                    type="password"
                                                    id="password"
                                                    autoComplete="current-password"
                                                    error={
                                                        !!errors.senha_email_envio
                                                    }
                                                    helperText={
                                                        errors.senha_email_envio
                                                            ? errors
                                                                  .senha_email_envio
                                                                  .message
                                                            : ''
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">
                                    Provedor
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        setProvedorEmail(
                                            (event.target as HTMLInputElement)
                                                .value as ProvedorEmail
                                        );
                                    }}
                                    value={provedorEmail}
                                >
                                    <FormControlLabel
                                        value={ProvedorEmail.Gmail}
                                        control={<Radio />}
                                        label="Gmail"
                                    />
                                    <FormControlLabel
                                        value={ProvedorEmail.Outlook}
                                        control={<Radio />}
                                        label="Outlook"
                                    />
                                    <FormControlLabel
                                        value={ProvedorEmail.Outros}
                                        control={<Radio />}
                                        label="Outros"
                                    />
                                </RadioGroup>
                            </FormControl>

                            {provedorEmail === ProvedorEmail.Outros ? (
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={6}>
                                        <Controller
                                            name="host"
                                            control={control}
                                            defaultValue={''}
                                            render={({
                                                field: { ref, ...field },
                                                fieldState: { error },
                                            }) => {
                                                return (
                                                    <TextField
                                                        {...field}
                                                        inputRef={ref}
                                                        margin="normal"
                                                        fullWidth
                                                        label="Host SMTP"
                                                        error={!!errors.host}
                                                        helperText={
                                                            errors.host
                                                                ? errors.host
                                                                      .message
                                                                : ''
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <Controller
                                            name="port"
                                            control={control}
                                            defaultValue={''}
                                            render={({
                                                field: { ref, ...field },
                                                fieldState: { error },
                                            }) => {
                                                return (
                                                    <TextField
                                                        {...field}
                                                        inputRef={ref}
                                                        margin="normal"
                                                        fullWidth
                                                        label="Porta SMTP"
                                                        type="number"
                                                        value={port}
                                                        onChange={(event) => {
                                                            setPort(
                                                                event.target
                                                                    .value
                                                            );
                                                        }}
                                                        error={!!errors.port}
                                                        helperText={
                                                            errors.port
                                                                ? errors.port
                                                                      .message
                                                                : ''
                                                        }
                                                    />
                                                );
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            ) : (
                                <></>
                            )}

                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={10}>
                                    <FormControl>
                                        <FormGroup>
                                            <FormControlLabel
                                                id="smart-check-box"
                                                control={
                                                    <Checkbox
                                                        checked={usaTlsEmail}
                                                        onChange={() =>
                                                            setUsaTlsEmail(
                                                                !usaTlsEmail
                                                            )
                                                        }
                                                    />
                                                }
                                                label="Usa TLS"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <Grid container spacing={2} justifyContent="end">
                        <Button
                            type="submit"
                            variant="contained"
                            style={styles.button}
                            size="large"
                        >
                            Salvar
                        </Button>
                    </Grid>
                </form>
            </Container>
        </PermissionGate>
    );
}
