import { Button, Grid, TextField } from '@mui/material';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { styles } from '../OrdemColetaForm/Styles';
import ModalAnexarTicket from './components/ModalAnexarTicket';

type FormProps = {
    disabled?: boolean;
};

export function EnviarTicketDescargaInput({ disabled = false }: FormProps) {
    const { control, watch } = useFormContext();

    const [mostrarModalAnexarTicket, setMostrarModalAnexarTicket] =
        useState<boolean>(false);

    const ticketName = watch('ticketDescarga.nome');

    return (
        <Grid container item spacing={2}>
            <Grid item xs={12} lg={3}>
                <Controller
                    name="ticketDescarga"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={''}
                    render={({
                        field: { ref, value, ...field },
                        fieldState,
                    }) => (
                        <TextField
                            {...field}
                            margin="normal"
                            disabled={true}
                            fullWidth
                            required
                            value={ticketName}
                            label="Ticket Descarga"
                            error={fieldState?.invalid}
                            helperText={
                                fieldState?.invalid &&
                                'O campo não pode ficar em branco'
                            }
                            InputLabelProps={{
                                shrink: !!ticketName,
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} lg={3}>
                <Button
                    onClick={() => {
                        setMostrarModalAnexarTicket(true);
                    }}
                    disabled={disabled}
                    variant="contained"
                    style={styles().button}
                    size="large"
                >
                    Anexar Ticket
                </Button>
            </Grid>
            {mostrarModalAnexarTicket && (
                <>
                    <ModalAnexarTicket
                        open={mostrarModalAnexarTicket}
                        onClose={() => {
                            setMostrarModalAnexarTicket(false);
                        }}
                    />
                </>
            )}
        </Grid>
    );
}
