import { IBaseUseCase } from '../../../../types/interfaces/IBaseUseCase';
import { IOrdemColetaLancamento } from '../dtos/IOrdemColetaLancamento';
import { IOrdemColetaRepository } from '../repositories/IOrdemColetaRepository';

export class PostLancarDadosEmbarque
    implements IBaseUseCase<IOrdemColetaLancamento, void>
{
    constructor(private readonly repository: IOrdemColetaRepository) {}

    execute(params: IOrdemColetaLancamento): Promise<void> {
        return this.repository.postLancarDadosEmbarque(params);
    }
}
