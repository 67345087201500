import { Card, CardContent, Typography } from '@mui/material';
import { format, isDate } from 'date-fns';
import { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import SpinnerLoading from '../../../Components/SpinnerLoading/SpinnerLoading';
import Util from '../../../Helpers/Util';
import useGenericService from '../../../hooks/useGenericService';
import { ApiResources } from '../../../Models/Api';
import OrdensPorClifor from '../../../Models/relatorios/OrdensPorClifor';
import { styles } from '../helpers/Styles';
import { DataGrafico } from '../Home';

interface Props {
    dataInicio: Date;
    dataFim: Date;
    fazerRequisicao?: boolean;
}

export default function RelatorioOrdensPorClifor({
    dataInicio,
    dataFim,
    fazerRequisicao = true,
}: Props) {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [ordensPorClifor, setOrdensPorClifor] = useState<OrdensPorClifor[]>();

    const [dadosGraficoOrdensPorClifor, setDadosGraficoOrdensPorClifor] =
        useState<DataGrafico>();

    const { getService } = useGenericService();

    useEffect(() => {
        setIsLoading(true);
        const buscarOrdensPorClifor = async () => {
            const servico = getService(ApiResources.Relatorio);

            const resposta = await servico.api.get(
                '/quantidade-ordens-clifor',
                {
                    params: {
                        dataInicio: format(dataInicio, 'Y-MM-dd').toString(),
                        dataFim: format(dataFim, 'Y-MM-dd').toString(),
                    },
                }
            );

            setOrdensPorClifor(resposta.data);
            setIsLoading(false);
        };

        if (isDate(dataInicio) && isDate(dataFim) && fazerRequisicao) {
            buscarOrdensPorClifor();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataFim, dataInicio, fazerRequisicao]);

    useEffect(() => {
        setIsLoading(true);
        if (ordensPorClifor) {
            setDadosGraficoOrdensPorClifor({
                labels: ordensPorClifor.map((epc) => epc.clifor.nome),
                datasets: [
                    {
                        label: '# of Votes',
                        data: ordensPorClifor.map(
                            (epc) => epc.quantidadeOrdens
                        ),
                        backgroundColor: ordensPorClifor.map((epc) =>
                            Util.generateRGBColor()
                        ),
                        borderWidth: 1,
                    },
                ],
            });
        }
        setIsLoading(false);
    }, [ordensPorClifor]);

    return (
        <SpinnerLoading isLoading={isLoading}>
            {dadosGraficoOrdensPorClifor && (
                <Card sx={{ maxHeight: 500 }}>
                    <CardContent>
                        <Typography component="div" variant="h6">
                            Quantidade de ordens por Clientes/Fornecedores
                        </Typography>
                        <Doughnut
                            data={dadosGraficoOrdensPorClifor}
                            style={styles.doughnut}
                        />
                    </CardContent>
                </Card>
            )}
        </SpinnerLoading>
    );
}
