import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import { SnackbarProvider } from 'notistack';

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <SnackbarProvider
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                maxSnack={3}
            >
                <App />
            </SnackbarProvider>
        </BrowserRouter>
    </React.StrictMode>, document.getElementById('root')
);
