import { IBaseUseCase } from '../../../../types/interfaces/IBaseUseCase';
import { IPutArquivoOrdemColetaLancamento } from '../dtos/IPutArquivoOrdemColetaLancamento';
import { IOrdemColetaRepository } from '../repositories/IOrdemColetaRepository';

export class PutArquivoOrdemColeta
    implements IBaseUseCase<IPutArquivoOrdemColetaLancamento, void>
{
    constructor(private readonly repository: IOrdemColetaRepository) {}

    execute(params: IPutArquivoOrdemColetaLancamento): Promise<void> {
        return this.repository.putArquivoOrdemColeta(params);
    }
}
