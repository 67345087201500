import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import {
    CssBaseline,
    Divider,
    Drawer,
    List,
    Toolbar,
    Typography,
    useMediaQuery,
} from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import { styled, useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import Footer from './Components/Footer/Footer';
import MenuLateral from './Components/Navegacao/MenuLateral';
import UsuarioBar from './Components/UsuarioBar/UsuarioBar';
import { styles } from './Helpers/Styles';
import { FrontEndPathResources } from './Models/Api';
import { ChavesArmazenamentoStorage } from './Models/Usuario';
import LoginResponse from './Models/responses/LoginResponse';

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

interface RequireAuthProps {
    children: JSX.Element;
    urlToGo?: FrontEndPathResources;
}

export default function RequireAuth({ children, urlToGo }: RequireAuthProps) {
    const theme = useTheme();

    const [loginResponse, setLoginResponse] = useState<LoginResponse | null>();

    useEffect(() => {
        let loginResponse = localStorage.getItem(
            ChavesArmazenamentoStorage.LOGIN_RESPONSE
        );

        if (loginResponse) {
            setLoginResponse(JSON.parse(loginResponse) as LoginResponse);
        }
    }, []);

    const isMobile = useMediaQuery('(max-width: 600px)');

    const [open, setOpen] = useState(!isMobile);

    useEffect(() => {
        setOpen(!isMobile);
    }, [isMobile]);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    let location = useLocation();

    if (!localStorage.getItem(ChavesArmazenamentoStorage.LOGIN_RESPONSE)) {
        return (
            <Navigate
                to="/easymbark/login"
                state={{ from: location }}
                replace
            />
        );
    }

    return (
        <Box sx={styles().boxAuth}>
            <CssBaseline />
            <AppBar position="fixed" open={open} style={styles().appBar}>
                <Toolbar style={styles(open).toolbar}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        <UsuarioBar />
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={styles(drawerWidth).drawer}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <div style={styles().divHeader}>
                        {loginResponse && loginResponse.imagemBase64 && (
                            <Link color="inherit" to="/easymbark">
                                <img
                                    src={loginResponse?.imagemBase64}
                                    style={styles().img}
                                    alt="logo_usuario"
                                />
                            </Link>
                        )}
                    </div>

                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? (
                            <ChevronLeftIcon />
                        ) : (
                            <ChevronRightIcon />
                        )}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <MenuLateral
                    onMenuClick={() => {
                        if (isMobile) {
                            setOpen(false);
                        }
                    }}
                />
                <Divider />
                <List></List>
            </Drawer>
            <Main open={open}>
                <DrawerHeader />
                {loginResponse &&
                (loginResponse.usuario.login === 'TRATOLOG' ||
                    loginResponse.usuario.login === 'VIDAL_LOG' ||
                    loginResponse.usuario.login === 'D_GRANEL' ||
                    loginResponse.usuario.login === 'SAFRA_LOG') &&
                urlToGo !== FrontEndPathResources.Juncao
                    ? null
                    : children}
            </Main>
            <Footer />
        </Box>
    );
}
