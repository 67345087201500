import TextField, { StandardTextFieldProps } from '@mui/material/TextField';
import { ChangeEvent } from 'react';
import InputMask from 'react-input-mask';

type Props = StandardTextFieldProps;

export const maskCPForCNPJ = (value: string): string => {
    if (value == null) return '';
    const cpfCnpj = value.replace(/\D/g, '');
    if (cpfCnpj.length > 11) {
        return cpfCnpj
            .replace(/(\d{2})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1/$2')
            .replace(/(\d{4})(\d)/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1');
    }
    return cpfCnpj
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1');
};

export default function InputCpfCnpj({ label = 'CPF/CNPJ', ...props }: Props) {
    const {
        onBlur,
        value,
        onChange,
        disabled,
        inputRef,
        ...outrasPropriedades
    } = props;

    return (
        <InputMask
            mask=""
            maskPlaceholder={null}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                if (onChange) {
                    onChange({
                        ...event,
                        target: { value: maskCPForCNPJ(event.target.value) },
                    } as ChangeEvent<HTMLInputElement>);
                }
            }}
            value={props?.value as string}
            disabled={disabled}
        >
            <TextField
                label={label}
                inputProps={{ autoComplete: 'off' }}
                {...outrasPropriedades}
            />
        </InputMask>
    );
}
