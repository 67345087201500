import Contato from './Contato';
import Endereco from './Endereco';

export enum TipoInscricao {
    Isento = 'isento',
}

export default class Transportadora {
    constructor(
        public id: number,
        public nome: string,
        public nomeFantasia: string,
        public cnpj: string,
        public contato: Contato,
        public endereco: Endereco
    ) {}
}
