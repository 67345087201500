import { Button, Grid, IconButton, Tooltip } from '@mui/material';
import { ComplexColumn } from '../../Components/Table/GenericComplexTable';
import CancelIcon from '@mui/icons-material/Cancel';
import Endereco from '../../Models/Endereco';
import EditIcon from '@mui/icons-material/Edit';
import GenericBasicTable from '../../Components/Table/GenericBasicTable';
import { useEffect, useState } from 'react';
import ModalEndereco from './ModalEndereco';
import AddIcon from '@mui/icons-material/Add';
import { styles } from './helpers/Styles';

interface Props {
    enderecosForm?: Endereco[];
    disabled?: boolean;
    enderecosAlterados: (enderecos: Endereco[]) => void;
}

export default function TabelaEndereco({
    enderecosForm,
    disabled = false,
    enderecosAlterados,
}: Props) {
    const [enderecoAlterar, setEnderecoAlterar] = useState<Endereco>();

    const [mostrarModalEndereco, setMostrarModalEndereco] =
        useState<boolean>(false);

    const [enderecos, setEnderecos] = useState<Endereco[]>([]);

    useEffect(() => {
        if (enderecosForm && enderecosForm.length > 0) {
            setEnderecos(enderecosForm);
        }
    }, [enderecosForm]);

    const colunas: ComplexColumn[] = [
        {
            attribute: 'id',
            label: 'ID',
            id: 'id',
        },
        {
            attribute: 'cidade',
            label: 'Cidade',
            id: 'cidade',
        },
        {
            attribute: 'estado',
            label: 'Estado',
            id: 'estado',
            width: 200,
        },
        {
            attribute: 'logradouro',
            label: 'Logradouro',
            id: 'logradouro',
            sortable: false,
        },
        {
            attribute: 'cep',
            label: 'CEP',
            id: 'cep',
            sortable: false,
        },
        {
            attribute: 'numero',
            label: 'Número',
            id: 'numero',
            sortable: false,
        },
        {
            attribute: 'id',
            label: 'Ações',
            id: 'acao',
            width: 200,
            format: (row) => {
                return (
                    <>
                        <Tooltip
                            title="Alterar"
                            placement="top"
                            disableInteractive
                        >
                            <IconButton
                                disabled={disabled}
                                aria-label="alterar"
                                onClick={() => {
                                    setEnderecoAlterar(row as Endereco);
                                    setMostrarModalEndereco(true);
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title="Desativar"
                            placement="top"
                            disableInteractive
                        >
                            <IconButton
                                disabled={disabled}
                                aria-label="delete"
                                onClick={() => {
                                    removerEndereco(row as Endereco);
                                }}
                            >
                                <CancelIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    const adicionarEndereco = (endereco: Endereco) => {
        if (enderecoAlterar) {
            let enderecosAtualizados = enderecos.filter(
                (e) =>
                    !(
                        e.bairro === enderecoAlterar.bairro &&
                        e.cep === enderecoAlterar.cep &&
                        e.cidade === enderecoAlterar.cidade &&
                        e.complemento === enderecoAlterar.complemento &&
                        e.estado === enderecoAlterar.estado &&
                        e.logradouro === enderecoAlterar.logradouro &&
                        e.numero === enderecoAlterar.numero
                    )
            );

            setEnderecos([...enderecosAtualizados, endereco]);

            setEnderecoAlterar(undefined);
        } else {
            setEnderecos([...enderecos, endereco]);
        }
    };

    const removerEndereco = (endereco: Endereco) => {
        let enderecosAtualizados = enderecos.filter(
            (e) =>
                !(
                    e.bairro === endereco.bairro &&
                    e.cep === endereco.cep &&
                    e.cidade === endereco.cidade &&
                    e.complemento === endereco.complemento &&
                    e.estado === endereco.estado &&
                    e.logradouro === endereco.logradouro &&
                    e.numero === endereco.numero
                )
        );

        setEnderecos(enderecosAtualizados);
    };

    useEffect(() => {
        enderecosAlterados(enderecos);
    }, [enderecos, enderecosAlterados]);

    return (
        <>
            {mostrarModalEndereco && (
                <ModalEndereco
                    aberto={mostrarModalEndereco}
                    endereco={enderecoAlterar}
                    fecharClicado={() => {
                        setMostrarModalEndereco(false);
                        setEnderecoAlterar(undefined);
                    }}
                    gravarClicado={(endereco: Endereco) => {
                        setMostrarModalEndereco(false);
                        adicionarEndereco(endereco);
                    }}
                />
            )}
            <Button
                variant="outlined"
                startIcon={<AddIcon />}
                disabled={disabled}
                onClick={() => setMostrarModalEndereco(true)}
            >
                Adicionar endereço
            </Button>
            <Grid
                container
                spacing={2}
                style={styles().grid}
            >
                <GenericBasicTable columns={colunas} rows={enderecos} />
            </Grid>
        </>
    );
}
