import Joi from 'joi';

const cpfOrCnpjRegexExp =
    /(\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2})|(\d{3}\.\d{3}\.\d{3}-\d{2})/;

export const validacao = (permitirSenhaVazia: boolean) => {
    return Joi.object({
        cpfCnpj: Joi.string().required().pattern(cpfOrCnpjRegexExp).messages({
            'string.pattern.base': 'Deve ser um CPF ou CNPJ válido',
            'string.empty': 'Não pode ficar em branco',
            'any.required': 'Não pode ficar em branco',
        }),
        login: Joi.string().max(70).trim().required().messages({
            'string.base': 'O login deve ser uma string',
            'string.max': 'Deve ter no máximo 70 caracteres',
            'string.empty': 'O login não pode ficar em branco',
            'any.required': 'O login não pode ficar em branco',
        }),
        senha: permitirSenhaVazia
            ? Joi.string().allow('', null)
            : Joi.string().trim().required().min(8).messages({
                  'string.base': 'A senha deve ser uma string',
                  'string.empty': 'A senha não pode ficar em branco',
                  'any.required': 'A senha não pode ficar em branco',
                  'string.min': 'A senha deve ter no mínimo 8 caracteres',
              }),

        nome: Joi.string().trim().required().min(3).max(150).messages({
            'string.base': 'O nome deve ser uma string',
            'string.empty': 'O nome não pode ficar em branco',
            'any.required': 'O nome não pode ficar em branco',
            'string.min': 'Deve ter no mínimo 3 caracteres',
            'string.max': 'Permitido até 150 caracteres',
        }),
        email: Joi.string()
            .email({ tlds: { allow: false } })
            .allow('', null)
            .messages({
                'string.email': 'Deve ser um e-mail válido',
            }),
        telefone: Joi.string().allow('', null).optional(),
        celular: Joi.string().allow('', null).optional(),
        perfilUsuario: Joi.object().min(1).required().messages({
            'object.base': 'Selecione o perfil de usuário',
            'object.min': 'Selecione o perfil de usuário',
        }),
        integradoMaxys: Joi.boolean().allow(null).optional(),
    });
};
