import { IBaseUseCase } from '../../../../types/interfaces/IBaseUseCase';
import { IOrdemColetaRequestDto } from '../dtos/IOrdemColetaRequestDto';
import { IOrdemColetaRepository } from '../repositories/IOrdemColetaRepository';

export class PostOrdemColeta
    implements IBaseUseCase<IOrdemColetaRequestDto, void>
{
    constructor(private readonly repository: IOrdemColetaRepository) {}

    execute(ordemColeta: IOrdemColetaRequestDto): Promise<void> {
        return this.repository.postOrdemColeta(ordemColeta);
    }
}
