import {
    Button,
    Dialog,
    Card,
    CardContent,
    DialogActions,
    DialogContent,
    DialogContentText,
    CardActions,
    DialogTitle,
    Typography,
} from '@mui/material';
import { format } from 'date-fns';
import Embarque from '../../Models/Embarque';

interface Props {
    embarque: Embarque;
    fecharClicado: () => void;
    confirmarClicado: () => void;
    aberto: boolean;
}

export default function ModalConfirmacaoExclusaoEmbarque({
    embarque,
    fecharClicado,
    confirmarClicado,
    aberto = false,
}: Props) {
    return (
        <Dialog
            open={aberto}
            onClose={() => fecharClicado()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Deseja realmente excluir o embarque {embarque.id}?
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography
                                sx={{ fontSize: 14 }}
                                color="text.secondary"
                                gutterBottom
                            >
                                {format(
                                    new Date(embarque.dataHoraCriacao),
                                    'dd/MM/Y HH:mm'
                                ).toString()}
                            </Typography>
                            <Typography sx={{ fontSize: 18 }} component="div">
                                <b>Placa do veículo:</b>{' '}
                                {embarque.caminhao.placaVeiculo}
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                <b>Motorista: </b> {embarque.motorista.nome}
                            </Typography>
                            <Typography variant="body2">
                                <b>Peso embarcado: </b>{' '}
                                {Math.abs(
                                    embarque.pesagem.pesoSaida -
                                    embarque.pesagem.pesoEntrada
                                )}{' '}
                                KG
                            </Typography>
                        </CardContent>
                        <CardActions></CardActions>
                    </Card>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => fecharClicado()} color="inherit">
                    Fechar
                </Button>
                <Button onClick={() => confirmarClicado()}>Confirmar</Button>
            </DialogActions>
        </Dialog>
    );
}
