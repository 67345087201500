import { Typography } from '@mui/material';

interface Props {
    title: string;
}

export default function PageTitle({ title }: Props) {
    return (
        <div style={{ flex: 1, color: '#465f75' }}>
            <Typography variant="h5" gutterBottom component="div">
                {title}
            </Typography>
        </div>
    );
}
