import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import React from 'react';

interface ModalConfirmarDeleteProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    mensagem?: string;
}

const ModalConfirmarDelete: React.FC<ModalConfirmarDeleteProps> = ({
    open,
    onClose,
    onConfirm,
    mensagem,
}) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Confirmar Exclusão</DialogTitle>
            <DialogContent>
                <p>
                    {mensagem
                        ? mensagem
                        : 'Deseja realmente excluir este item?'}
                </p>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={onConfirm} color="primary" autoFocus>
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalConfirmarDelete;
