import Joi from 'joi';

export const validacao = Joi.object({
    urlIntegracaoAtac: Joi.string().allow('', null).optional(),
    usuarioIntegracaoAtac: Joi.string().allow('', null).optional(),
    senhaIntegracaoAtac: Joi.string().allow('', null).optional(),
    tokenIntegracaoAtac: Joi.string().allow('', null).optional(),
    urlIntegracaoLoteEmbarquesOrdens: Joi.string().required().messages({
        'string.base': 'Não pode ficar em branco',
        'string.required': 'Não pode ficar em branco',
        'string.empty': 'Não pode ficar em branco',
    }),
    usuarioIntegracaoLoteEmbarquesOrdens: Joi.string()
        .required()
        .min(3)
        .messages({
            'string.base': 'Não pode ficar em branco',
            'string.required': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
            'string.min': 'Mínimo de 3 caracteres',
        }),
    senhaIntegracaoLoteEmbarquesOrdens: Joi.string()
        .required()
        .min(3)
        .messages({
            'string.base': 'Não pode ficar em branco',
            'string.required': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
            'string.min': 'Mínimo de 3 caracteres',
        }),
    tokenIntegracaoLoteEmbarquesOrdens: Joi.string().allow('', null).optional(),
    urlIntegracaoXml: Joi.string().allow('', null).optional(),
    usuarioIntegracaoXml: Joi.string().allow('', null).optional(),
    senhaIntegracaoXml: Joi.string().allow('', null).optional(),
    tokenIntegracaoXml: Joi.string().allow('', null).optional(),
    codigoOrgIntegracaoXml: Joi.number().allow().optional().messages({
        'number.base': 'Campo obrigatório para integração XML',
    }),
});
