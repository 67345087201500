import { useMemo } from 'react';
import { useAuth } from '../AuthProvider';
import { FrontEndPathResources } from '../Models/Api';
import { IRecursos } from '../Screens/PerfilUsuario/Formulario';

export function usePermission(recurso?: string) {
    const { usuarioLogado } = useAuth();

    if (usuarioLogado?.trocarSenha) {
        window.location.pathname = FrontEndPathResources.AlterarSenha;
    }

    const usuarioPermissions = useMemo(
        () =>
            usuarioLogado?.perfilUsuario?.recursos.map((r: IRecursos) =>
                r.nome.toLowerCase()
            ),
        [usuarioLogado]
    );

    const isAllowed = useMemo(() => {
        if (!recurso) return false;
        const recursoLowerCase = recurso.toLowerCase();
        return usuarioPermissions?.includes(recursoLowerCase);
    }, [usuarioPermissions, recurso]);

    return { isAllowed };
}
