import { StyleSheet } from '../../../StyleSheet';

export const styles = StyleSheet.create({
    box: {
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: '#465F75',
    },
    img: { width: '20%', height: '20%' },
});
