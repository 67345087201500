import { joiResolver } from '@hookform/resolvers/joi';
import { Button, Container, Grid, TextField } from '@mui/material';
import Joi from 'joi';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import Amostra from '../../Models/Amostra';
import { FrontEndPathResources } from '../../Models/Api';
import { styles } from './helpers/Styles';

export type CamposFormulario = {
    nome: string;
    percentualLimiteMaximo?: string;
};

interface Props {
    disabled?: boolean;
    amostra?: Amostra;
    onSubmit?: (dadosFormulario: CamposFormulario) => {};
}

export default function Formulario({
    disabled = false,
    amostra,
    onSubmit,
}: Props) {
    const navigate = useNavigate();

    const validacao = Joi.object({
        nome: Joi.string().trim().required().min(2).max(50).messages({
            'string.base': 'O nome deve ser uma string',
            'string.empty': 'O nome não pode ficar em branco',
            'any.required': 'O nome não pode ficar em branco',
            'string.min': 'Deve ter no mínimo 2 caracteres',
            'string.max': 'Permitido até 50 caracteres',
        }),
        percentualLimiteMaximo: Joi.number()
            .max(100)
            .min(0)
            .allow('', null)
            .optional()
            .messages({
                'number.min': 'Não pode ser menor do que 0',
                'number.max': 'Não pode ser maior do que 100',
            }),
    });

    const {
        control,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm<CamposFormulario>({
        resolver: joiResolver(validacao),
        criteriaMode: 'all',
    });

    useEffect(() => {
        const preencherFormulario = (amostra: Amostra) => {
            reset({
                nome: amostra.nome,
                percentualLimiteMaximo:
                    amostra.percentualLimiteMaximo?.toString(),
            });
        };

        if (amostra) {
            preencherFormulario(amostra);
        }
    }, [reset, amostra]);

    const tratarDados = (dadosFormulario: CamposFormulario) => {
        if (onSubmit) {
            onSubmit(dadosFormulario);
        }
    };

    const watchNome = watch('nome');

    return (
        <Container>
            <form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(tratarDados)}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="nome"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        required
                                        InputLabelProps={{
                                            shrink: !!watchNome,
                                        }}
                                        disabled={disabled}
                                        fullWidth
                                        label="Nome"
                                        inputProps={{ maxLength: 50 }}
                                        autoFocus
                                        error={!!errors.nome}
                                        helperText={
                                            errors.nome
                                                ? errors.nome.message
                                                : ''
                                        }
                                        data-id="campoNomeAmostra"
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Controller
                            name="percentualLimiteMaximo"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        fullWidth
                                        label="Limite máximo de classificação (%)"
                                        type="number"
                                        disabled={disabled}
                                        error={!!errors.percentualLimiteMaximo}
                                        helperText={
                                            errors.percentualLimiteMaximo
                                                ? errors.percentualLimiteMaximo
                                                      .message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} justifyContent="end">
                    {!disabled ? (
                        <Button
                            type="submit"
                            variant="contained"
                            style={styles.button}
                            size="large"
                            data-id="botaoSalvar"
                        >
                            Salvar
                        </Button>
                    ) : (
                        <Button
                            onClick={() => {
                                navigate(
                                    FrontEndPathResources.Amostra +
                                        '/alterar/' +
                                        amostra?.id
                                );
                            }}
                            variant="contained"
                            style={styles.button}
                            size="large"
                        >
                            Alterar
                        </Button>
                    )}
                </Grid>
            </form>
        </Container>
    );
}
