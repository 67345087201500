import { joiResolver } from '@hookform/resolvers/joi';
import {
    Button,
    Container,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
} from '@mui/material';
import { default as JoiOriginal } from 'joi';
import JoiCpfCnpj from 'cpf-cnpj-validator';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import InputTelefone from '../../Components/Input/InputTelefone';
import InputCnpj from '../../Components/Input/InputCnpj';
import InputCelular from '../../Components/Input/InputCelular';
import ClienteFornecedor from '../../Models/ClienteFornecedor';
import Endereco from '../../Models/Endereco';
import DividerComp from '../../Components/Divider/DividerComp';
import Contato from '../../Models/Contato';
import { TipoPessoa } from '../../Models/Pessoa';
import InputCpf from '../../Components/Input/InputCpf';
import { FrontEndPathResources } from '../../Models/Api';
import TabelaEndereco from './TabelaEndereco';
import { useSnackbar } from 'notistack';
import { styles } from './helpers/Styles';

export type CamposFormulario = {
    nome: string;
    nomeFantasia?: string;
    cpf?: string;
    cnpj?: string;
    email: string;
    telefone: string;
    celular: string;
    idIntegracao?: string | null;
};
export interface CamposFormularioTratados
    extends Omit<CamposFormulario, 'email' | 'telefone' | 'celular' | 'idIntegracao'> {
    enderecos: Endereco[];
    contato: Contato | null;
}

interface Props {
    disabled?: boolean;
    display?: boolean;
    clienteFornecedor?: ClienteFornecedor;
    onSubmit?: (dadosFormulario: CamposFormularioTratados) => {};
}

export default function Formulario({
    disabled = false,
    clienteFornecedor,
    onSubmit,
}: Props) {
    const [enderecos, setEnderecos] = useState<Endereco[]>([]);

    const navigate = useNavigate();
    const [tipoPessoa, setTipoPessoa] = useState<TipoPessoa>(
        TipoPessoa.Juridica
    );

    const { enqueueSnackbar } = useSnackbar();

    const Joi = JoiOriginal.extend(JoiCpfCnpj);

    const validacaoComCnpj = Joi.object({
        nome: Joi.string().trim().required().min(3).max(150).messages({
            'string.base': 'O nome deve ser uma string',
            'string.empty': 'O nome não pode ficar em branco',
            'any.required': 'O nome não pode ficar em branco',
            'string.min': 'Deve ter no mínimo 3 caracteres',
            'string.max': 'Permitido até 150 caracteres',
        }),
        nomeFantasia: Joi.string().trim().required().min(3).max(150).messages({
            'string.base': 'O nome fantasia deve ser uma string',
            'string.empty': 'O nome fantasia não pode ficar em branco',
            'any.required': 'O nome fantasia não pode ficar em branco',
            'string.min': 'Deve ter no mínimo 3 caracteres',
            'string.max': 'Permitido até 150 caracteres',
        }),
        cnpj: Joi.document().cnpj().messages({
            'string.empty': 'O CNPJ não pode ficar em branco',
            'any.required': 'O CNPJ não pode ficar em branco',
        }),
        cpf: Joi.string().allow('', null).optional(),
        idIntegracao: Joi.number().allow('', null).optional(),
        email: Joi.string()
            .email({ tlds: { allow: false } })
            .allow('', null)
            .messages({
                'string.email': 'Deve ser um e-mail válido',
            }),
        telefone: Joi.string().allow('', null).optional(),
        celular: Joi.string().allow('', null).optional(),
    });

    const validacaoComCpf = Joi.object({
        nome: Joi.string().trim().required().min(3).max(120).messages({
            'string.base': 'O nome deve ser uma string',
            'string.empty': 'O nome não pode ficar em branco',
            'any.required': 'O nome não pode ficar em branco',
            'string.min': 'Deve ter no mínimo 3 caracteres',
            'string.max': 'Permitido até 120 caracteres',
        }),
        cpf: Joi.document().cpf().messages({
            'string.base': 'Não pode ficar em branco',
            'string.empty': 'Não pode ficar em branco',
            'any.required': 'Não pode ficar em branco',
        }),
        nomeFantasia: Joi.string()
            .max(150)
            .allow('', null)
            .optional()
            .messages({
                'string.max': 'Não deve ser maior do que 150 caracteres',
            }),
        cnpj: Joi.string().allow('', null).optional(),
        inscricaoMunicipal: Joi.string().max(20).allow('', null).optional(),
        inscricaoEstadual: Joi.string().max(20).allow('', null).optional(),
        idIntegracao: Joi.number().allow('', null).optional(),
        endereco: Joi.object({
            id: Joi.number().optional(),
            cep: Joi.string().max(10).required().messages({
                'string.base': 'Não pode ficar em branco',
                'string.required': 'Não pode ficar em branco',
                'string.empty': 'Não pode ficar em branco',
                'string.max': 'Deve ter no máximo 10 caracteres',
            }),
            logradouro: Joi.string().max(100).required().messages({
                'string.base': 'Não pode ficar em branco',
                'string.required': 'Não pode ficar em branco',
                'string.empty': 'Não pode ficar em branco',
                'string.max': 'Deve ter no máximo 100 caracteres',
            }),
            bairro: Joi.string().max(100).required().messages({
                'string.base': 'Não pode ficar em branco',
                'string.required': 'Não pode ficar em branco',
                'string.empty': 'Não pode ficar em branco',
                'string.max': 'Deve ter no máximo 100 caracteres',
            }),
            cidade: Joi.string().max(150).required().messages({
                'string.base': 'Não pode ficar em branco',
                'string.required': 'Não pode ficar em branco',
                'string.empty': 'Não pode ficar em branco',
                'string.max': 'Deve ter no máximo 150 caracteres',
            }),
            estado: Joi.string().max(2).required().messages({
                'string.base': 'Não pode ficar em branco',
                'string.required': 'Não pode ficar em branco',
                'string.empty': 'Não pode ficar em branco',
                'string.max': 'Deve ter no máximo 2 caracteres',
            }),
            numero: Joi.number().allow('', null),
            complemento: Joi.string().max(100).allow('', null),
            ibge: Joi.string().allow('', null),
        }),
        email: Joi.string()
            .max(150)
            .email({ tlds: { allow: false } })
            .allow('', null)
            .messages({
                'string.email': 'Deve ser um e-mail válido',
                'string.max': 'Deve ter no máximo 150 caracteres',
            }),
        telefone: Joi.string().max(19).allow('', null).optional(),
        celular: Joi.string().max(19).allow('', null).optional(),
    });

    const {
        control,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm<CamposFormulario>({
        resolver: joiResolver(
            tipoPessoa === TipoPessoa.Fisica
                ? validacaoComCpf
                : validacaoComCnpj
        ),
        criteriaMode: 'all',
    });

    const getTipoPessoa = (cnpj?: string) => {
        if (cnpj === null) return TipoPessoa.Fisica;
        else return TipoPessoa.Juridica;
    };

    const getEndereco = (enderecoFormulario: any) => {
        if (enderecoFormulario.bairro === '' && enderecoFormulario.cep === '') {
            const enderecoRetornar: Endereco = {
                cidade: enderecoFormulario.cidade,
                estado: enderecoFormulario.estado,
                bairro: null,
                cep: null,
                logradouro: null,
                complemento: null,
                numero: null,
                id: enderecoFormulario.id,
                inscricaoEstadual: enderecoFormulario.inscricaoEstadual,
                inscricaoMunicipal: enderecoFormulario.inscricaoMunicipal,
                ibge: enderecoFormulario.ibge,
            };
            return enderecoRetornar;
        } else {
            const enderecoRetornar: Endereco = {
                cidade: enderecoFormulario.cidade,
                estado: enderecoFormulario.estado,
                bairro: enderecoFormulario.bairro,
                cep: enderecoFormulario.cep,
                logradouro: enderecoFormulario.logradouro,
                complemento: enderecoFormulario.complemento,
                numero: enderecoFormulario.numero,
                id: enderecoFormulario.id,
                inscricaoEstadual: enderecoFormulario.inscricaoEstadual,
                inscricaoMunicipal: enderecoFormulario.inscricaoMunicipal,
                ibge: enderecoFormulario.ibge,
            };
            return enderecoRetornar;
        }
    };

    useEffect(() => {
        const preencherFormulario = (clienteFornecedor: ClienteFornecedor) => {
            setTipoPessoa(getTipoPessoa(clienteFornecedor.cnpj));

            setEnderecos(clienteFornecedor.enderecos);

            reset({
                nome: clienteFornecedor.nome,
                cpf: clienteFornecedor ? clienteFornecedor.cpf : '',
                cnpj: clienteFornecedor ? clienteFornecedor.cnpj : '',
                idIntegracao: clienteFornecedor.idIntegracao
                    ? clienteFornecedor.idIntegracao
                    : '',
                nomeFantasia: clienteFornecedor
                    ? clienteFornecedor.nomeFantasia
                    : '',
                celular: clienteFornecedor.contato
                    ? clienteFornecedor.contato.celular
                    : '',
                email: clienteFornecedor.contato
                    ? clienteFornecedor.contato.email
                    : '',
                telefone: clienteFornecedor.contato
                    ? clienteFornecedor.contato.telefone
                    : '',
            });
        };
        if (clienteFornecedor) {
            preencherFormulario(clienteFornecedor);
        }
    }, [reset, clienteFornecedor]);

    const getContato = (email: string, telefone: string, celular: string) => {
        if (email === '' && telefone === '' && celular === '') {
            return null;
        }

        return {
            email,
            telefone,
            celular,
        };
    };

    const tratarDados = (dadosFormulario: CamposFormulario) => {
        if (onSubmit) {
            if (enderecos.length === 0) {
                enqueueSnackbar('Nenhum endereço foi adicionado.', {
                    variant: 'info',
                });

                return;
            }

            const { email, telefone, celular, ...resto } = dadosFormulario;

            let contato: Contato | null = getContato(email, telefone, celular);

            const dadosFormTratados: CamposFormularioTratados = {
                ...resto,
                enderecos: enderecos.map((e) => getEndereco(e)),
                contato: contato,
            };
            onSubmit(dadosFormTratados);
        }
    };

    return (
        <Container>
            <form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(tratarDados)}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <FormControl
                            style={styles().formControlStyle}
                            disabled={disabled}
                        >
                            <FormLabel id="demo-row-radio-buttons-group-label">
                                Tipo de Pessoa
                            </FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    setTipoPessoa(
                                        (event.target as HTMLInputElement)
                                            .value as TipoPessoa
                                    );

                                    if (tipoPessoa === 'pessoa_juridica') {
                                        setValue('cnpj', undefined, {
                                            shouldValidate: false,
                                        });
                                        setValue('nomeFantasia', undefined, {
                                            shouldValidate: false,
                                        });
                                    } else {
                                        setValue('cpf', undefined, {
                                            shouldValidate: false,
                                        });
                                    }
                                }}
                                value={tipoPessoa}
                            >
                                <FormControlLabel
                                    value={TipoPessoa.Juridica}
                                    control={<Radio />}
                                    label="Jurídica"
                                />

                                <FormControlLabel
                                    value={TipoPessoa.Fisica}
                                    control={<Radio />}
                                    label="Física"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="nome"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        label="Nome"
                                        inputProps={{ maxLength: 120 }}
                                        autoFocus
                                        error={!!errors.nome}
                                        helperText={
                                            errors.nome
                                                ? errors.nome.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    {tipoPessoa === TipoPessoa.Fisica ? (
                        <Grid item xs={12} lg={5}>
                            <Controller
                                name="cpf"
                                control={control}
                                defaultValue={''}
                                render={({
                                    field: { ref, ...field },
                                    fieldState: { error },
                                }) => {
                                    return (
                                        <InputCpf
                                            {...field}
                                            inputRef={ref}
                                            value={clienteFornecedor?.cpf}
                                            required
                                            margin="normal"
                                            autoComplete="off"
                                            disabled={disabled}
                                            fullWidth
                                            label="CPF"
                                            error={!!errors.cpf}
                                            helperText={
                                                errors.cpf
                                                    ? errors.cpf.message
                                                    : ''
                                            }
                                        />
                                    );
                                }}
                            />
                        </Grid>
                    ) : (
                        <>
                            <Grid item xs={12} lg={5}>
                                <Controller
                                    name="cnpj"
                                    control={control}
                                    defaultValue={''}
                                    render={({
                                        field: { ref, ...field },
                                        fieldState: { error },
                                    }) => {
                                        return (
                                            <InputCnpj
                                                {...field}
                                                inputRef={ref}
                                                required
                                                margin="normal"
                                                autoComplete="off"
                                                disabled={disabled}
                                                fullWidth
                                                label="CNPJ"
                                                error={!!errors.cnpj}
                                                helperText={
                                                    errors.cnpj
                                                        ? errors.cnpj.message
                                                        : ''
                                                }
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} lg={5}>
                                <Controller
                                    name="nomeFantasia"
                                    control={control}
                                    defaultValue={''}
                                    render={({
                                        field: { ref, ...field },
                                        fieldState: { error },
                                    }) => {
                                        return (
                                            <TextField
                                                {...field}
                                                inputRef={ref}
                                                margin="normal"
                                                required
                                                disabled={disabled}
                                                fullWidth
                                                inputProps={{ maxLength: 150 }}
                                                label="Nome fantasia"
                                                error={!!errors.nomeFantasia}
                                                helperText={
                                                    errors.nomeFantasia
                                                        ? errors.nomeFantasia
                                                            .message
                                                        : ''
                                                }
                                            />
                                        );
                                    }}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
                <DividerComp texto="Endereço" />

                <TabelaEndereco
                    enderecosAlterados={(enderecos: Endereco[]) => {
                        setEnderecos(enderecos);
                    }}
                    enderecosForm={enderecos}
                    disabled={disabled}
                />

                <DividerComp texto="Contato" />
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        label="Email"
                                        error={!!errors.email}
                                        helperText={
                                            errors.email
                                                ? errors.email.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="telefone"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <InputTelefone
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        label="Telefone"
                                        error={!!errors.telefone}
                                        helperText={
                                            errors.telefone
                                                ? errors.telefone.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="celular"
                            control={control}
                            defaultValue={''}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <InputCelular
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        label="Celular"
                                        error={!!errors.celular}
                                        helperText={
                                            errors.celular
                                                ? errors.celular.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>

                <DividerComp texto="Integração" />
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                        <Controller
                            name="idIntegracao"
                            control={control}
                            render={({
                                field: { ref, ...field },
                                fieldState: { error },
                            }) => {
                                return (
                                    <TextField
                                        {...field}
                                        inputRef={ref}
                                        margin="normal"
                                        autoComplete="off"
                                        disabled={disabled}
                                        fullWidth
                                        label="Id Integração"
                                        type="number"
                                        error={!!errors.idIntegracao}
                                        helperText={
                                            errors.idIntegracao
                                                ? errors.idIntegracao.message
                                                : ''
                                        }
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} justifyContent="end">
                    {!disabled ? (
                        <Button
                            type="submit"
                            variant="contained"
                            style={styles().button}
                            size="large"
                        >
                            Salvar
                        </Button>
                    ) : (
                        <Button
                            onClick={() => {
                                navigate(
                                    FrontEndPathResources.ClienteFornecedor +
                                    '/alterar/' +
                                    clienteFornecedor?.id
                                );
                            }}
                            variant="contained"
                            style={styles().button}
                            size="large"
                        >
                            Alterar
                        </Button>
                    )}
                </Grid>
            </form>
        </Container>
    );
}
